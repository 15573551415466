import { createContext, useEffect, useState } from 'react';
import { Routes, Route, Link, useNavigate, Outlet } from 'react-router-dom'



import React from 'react';

import skillImage from '../var/var';
import axios from 'axios';

import {serverKR} from "../var/server.js";
import {lv105ItemId} from '../var/editVar';
import {lv115ItemId} from '../lv115/equipList';
import Ad from "../ad";

function SearhResult(){

    let navigate = useNavigate();
    let [schbase, setSchBase] = useState();
    const [nick, setNick] = useState("");
    const [server, setServer] = useState("all");
    const [advenAuth, setAdvenAuth] = useState(false);
    const [authData, setAuthData] = useState("");
    const params = new URLSearchParams(window.location.search);

    const authEquipId = {"허름한 면 셔츠" : "0275b5e32a2dd0f6d9c87425d5b6a9bc" 
    ,"허름한 면 숄더" : "26f2d128036d7a09f39571df2d8d102f" 
    ,"허름한 면 바지" : "2a210e88599254594c9882d88fec588b" 
    ,"허름한 면 슬리퍼" : "be05fb4940a85ce3b9f7fd76e34733a6" 
    ,"허름한 면 허리띠" : "6f5f82e378c507ef40d0a1e579ed6852" 


    }
    
    let [schname, setSchName] = useState(params.get('name'));
    let [schserver, setSchServer] = useState(params.get('server'));

    
    let temp = "";
    for(var [key, value] of params.entries()) {
        if(key != 'name' 
            && key != 'server'  
            && key != 'arrow'  
            )
        {
            temp += '&'+key+ '='+ value; 
        }
    }

    
    if(window.location.href.indexOf("#") != -1)
    {
        temp += "#"+window.location.href.split("#")[1];
    }
    

    const handleOnKeyPress = e => {
        if (e.key === 'Enter') {
            if(nick.replace(/ /gi,'') != "")
            {   
                setSchName(nick);
                setSchServer(server);
                search();

            }
        }
    };


    const adventureDelete = (server,id) => {

        axios.post('/dat/auth/adventureDelete.jsp?adven='+encodeURIComponent(schname)+'&server='+server+'&able=0&id=' + id , {} ,{ cancelToken: source.token   })
        .then((base)=>{
            axios.post('/dat/searchData.jsp?name='+encodeURIComponent(schname)+'&server='+schserver+'')
            .then((base)=>{
                let copy = base.data;
                setSchBase(copy);
            })
        })

    }
    const search = (arrow,id) => {
        if(arrow == null)
        {
            window.history.replaceState("",null,'?server='+encodeURIComponent( server)+'&name='+encodeURIComponent( nick) + temp);
            axios.post('/dat/searchData.jsp?name='+encodeURIComponent(nick)+'&server='+server+'' , {} ,{ cancelToken: source.token    })
            .then((base)=>{
                if(nick.replace(/ /gi,'') != "")
                {   
                    let copy = base.data;
                    setSchBase(copy);
                    setAdvenAuth(false);
                }
            })
        }
        else
        {
            
            window.history.replaceState("",null,'?server='+encodeURIComponent( schserver)+'&name='+encodeURIComponent( schname) + temp);
            axios.post('/dat/searchData.jsp?name='+encodeURIComponent(schname)+'&server='+schserver+'&arrow='+arrow+"&id="+id+"&adven="+encodeURIComponent(schname)
              , {} ,{ cancelToken: source.token   })
            .then((base)=>{
                let copy = base.data;
                setSchBase(copy);
            })
        }

    }

    const handleOnKeyPressAuth = e => {
        if (e.key === 'Enter') {
            auth();
        }
    };

    const auth = () => {

        if(advenAuth)
        {
            setAdvenAuth(false);
        }
        else
        {
            setLockAdven(true);
            axios.post('/dat/auth/adventureAuth.jsp?adven='+encodeURIComponent(schname)+'' , {} ,{ cancelToken: source.token    })
            .then((base)=>{
                let copy = base.data;
                
                setAuthData(copy);
                setLockAdven(!lockadven);
                //setAdvenAuth(true);
            })
            .catch(()=>{
                alert("에러가 발생했습니다. 잠시 후 다시 시도해주세요."); 
            })
        }
    }

    const authDetail = () => {
        axios.post('/dat/auth/adventureAuth.jsp?Detail=true&adven='+encodeURIComponent(schname)+'' , {} ,{ cancelToken: source.token    })
        .then((base)=>{
            //let copy = base.data;

            console.log(base.data);
            console.log(base.data.result );
            if(base.data.result == "true")
            {

                setAdvenAuth(true);
                setLockAdven(!lockadven);
            }
            else
            {
                alert("장비 해체가 확인되지 않았습니다. 다른 캐릭터로 다시 시도해 주세요."); 
            }

            //setAuthData(copy)
            //setLockAdven(!lockadven);
            
            //setAdvenAuth(true);
        })
        .catch(()=>{
            alert("에러가 발생했습니다. 잠시 후 다시 시도해주세요."); 
        })
    }
    
    //schserver == adven 모험단

    let [lockadven, setLockAdven] = useState(false);

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();


    useEffect( () => { 
        
        
        axios.post('/dat/searchData.jsp?name='+encodeURIComponent(schname)+'&server='+schserver+'' ,
        {}, { cancelToken: source.token  }) 
        .then((base)=>{
          let copy = base.data;
          setSchBase(copy);
        })

        return ()=>{
            
            source.cancel();
        }
        
        

      },[]);
      
      
      useEffect( () => { 
        
        var self = window;
        self.__VM = self.__VM || [];
        
        self.__VM.push(function (admanager, scope) {
        scope.Config.get('billboard').display('ad-container-2');
        scope.Config.get('desktop_takeover').display('ad-container-1');
        scope.Config.verticalSticky().display();

        });

        return () =>
        {
            self.__VM = self.__VM || [];
            self.__VM.push((admanager, scope) => {
                admanager.removeAllPlacements();
                scope.Instances.pageManager.newPageSession();
            });
            
        };
            
    },[]);

    // useEffect( () => { 
        
    //     var placement = document.createElement("div");
    //     (window.top).__vm_add = (window.top).__vm_add || [];
    //     (function (success) {
    //         if (window.document.readyState !== "loading") {
    //             success();
    //         } else {
    //             window.document.addEventListener("DOMContentLoaded", function () {
    //                 success();
    //             });
    //         }
    //     })(function () {
    //         //console.log("mounting2", placement); 

    //         placement.setAttribute("class", "vm-placement");
    //         placement.setAttribute("data-id", "63358a82bf161d63ec3c8e8a");
    //         document.querySelector("#ad-container-2").appendChild(placement);
    //         (window.top).__vm_add.push(placement);
    //     });

            
    //     return() => { 
    //         (window.top).__vm_remove = (window.top).__vm_remove || [];
    //         //console.log("unmounting2", placement);
    //         (window.top).__vm_remove.push(placement);

    //     };

            
    // },[]);

      

    // useEffect( () => { 
        
    //     var placement = document.createElement("div");
    //     (window.top).__vm_add = (window.top).__vm_add || [];
    //     (function (success) {
    //         if (window.document.readyState !== "loading") {
    //             success();
    //         } else {
    //             window.document.addEventListener("DOMContentLoaded", function () {
    //                 success();
    //             });
    //         }
    //     })(function () {
    //         //console.log("mounting", placement); 

    //         placement.setAttribute("class", "vm-placement");
    //         placement.setAttribute("data-id", "63358a82bf161d63ec3c8e8a");
    //         placement.setAttribute("data-display-type", "hybrid-banner");
    //         document.querySelector("#ad-container-1").appendChild(placement);
    //         (window.top).__vm_add.push(placement);
    //        
    //     });
            
    //     return() => { 
    //         (window.top).__vm_remove = (window.top).__vm_remove || [];
    //         //console.log("unmounting", placement);
    //         (window.top).__vm_remove.push(placement);


    //     };

            
    // },[]);

    // useEffect( () => { 
        
    //     var placement = document.createElement("div");
    //     window.__vm_add = window.__vm_add || [];
    //     (function (success) {
    //         if (window.document.readyState !== "loading") {
    //             success();
    //         } else {
    //             window.document.addEventListener("DOMContentLoaded", function () {
    //                 success();
    //             });
    //         }
    //     })(function () {

    //         placement.setAttribute("class", "vm-placement");
    //         placement.setAttribute("data-id", "63358aa395a6d454fcf66d11");
    //         document.querySelector("#rich-media-placement").appendChild(placement);
    //         window.top.__vm_add.push(placement);
    //     });
            
    //     return() => { 
    //         (window.top).__vm_remove_category = (window).__vm_remove_category || [];
    //         (window.top).__vm_remove_category.push("richmedia_all");

    //         //console.log("unmounting", (window.top).__vm_remove_category);

    //     };



            
    // },[]);


    return (


    <section id="search_result" name="캐릭터 정보">
        


        <div className ="search-result container">
            <div id="rich-media-placement"></div>
            
            <div id="ad-container-1" />
            
            <div class="sr-top">
                <div class="search">
                    <div class="searcbox">
                        <div class="searchbox_hug">
                            <div class="server" name="서버">
                                <select id="server" name="server" value={server}  onChange={(event) => setServer(event.target.value)}>
                                    <option value="all">전체</option>
                                    <option value="adven">모험단</option>
                                    <option value="cain">카인</option>
                                    <option value="diregie">디레지에</option>
                                    <option value="siroco">시로코</option>
                                    <option value="prey">프레이</option>
                                    <option value="casillas">카시야스</option>
                                    <option value="hilder">힐더</option>
                                    <option value="anton">안톤</option>
                                    <option value="bakal">바칼</option>
                                </select>
                            </div>
                            

                            <div class="searchbox" name="검색창">
                            <input type="text" class="form-control" placeholder="캐릭터명" name="name" maxlength="20" value={nick} onKeyPress={handleOnKeyPress} onChange={(event) => {setNick(event.target.value)}}></input>
                            </div>

                            <div class="searchbutton" name="검색버튼">
                                <button onClick={()=>{search()}}>

                                </button>
                            </div>


                        </div>
                    </div>

                </div>
                <div class="result-intro">
                    <ul>
                        <li class="re-sev">
                            <span>{serverKR[schserver]}
                            </span>
                        </li>
                        <li class="re-nick">
                            <span>{schname}</span>
                        </li>
                        <li class="re-comment">
                            <span>검색 결과 입니다.</span>
                        </li>
                        {
                            //추후 공개 예정

                        schserver == 'adven'  ? 

                                <div class="sr-setting">
                                    <div class="sr-setting-hug">
                                        {/* <div class="sr-lock">
                                            <div class="sr_swc_but">
                                            </div>
                                            <div class="ballon">
                                                <span>모험단 검색 결과 잠금</span>
                                            </div>
                                        </div>

                                        <div class="sr-edit">
                                            <div class="sr_swc_but">
                                            </div>
                                            <div class="ballon">
                                                <span>모험단 검색 결과 편집</span>
                                            </div>
                                        </div> */}

                                        <div class="sr-switch" onClick={ ()=> { auth();}}>
                                            
                                            <div class="sr_swc_but">
                                            </div>
                                            <div class="ballon">
                                                <span>모험단으로 검색 시 {"\n"}검색결과의 순서 변경을 제공합니다.</span>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            
                            : ""
                        }
                    </ul>
                </div>
            </div>
            

            <div class="sr-result">

            {
                schbase && schbase.characters.map((a, i) =>
                {
                    return(

                        <div class="scon" key={i}  >
                            
                            {advenAuth ? <div class="seh_remote">
                                <div class="remot_back" onClick={()=>{search("left",schbase.characters[i].key)}}>
                                    <div class="imgt">
                                        <img src="./remote_back_1.svg"></img>
                                    </div>
                                    <div class="imgt darkmode-icon">
                                        <img src="./remote_back_dark.svg"></img>
                                    </div>
                                    
                                </div>

                                <div class="remot_forward" onClick={()=>{search("right",schbase.characters[i].key)}}>
                                    <div class="imgt">
                                        <img src="./remote_forward_1.svg"></img>
                                    </div>
                                    <div class="imgt darkmode-icon">
                                        <img src="./remote_forward_dark.svg"></img>
                                    </div>
                                </div>
                                <div class="remot_close" onClick={()=>{adventureDelete(schbase.characters[i].server,schbase.characters[i].key)}}>
                                    <div class="imgt">
                                        <img src="./remote_close_1.svg"></img>
                                    </div>
                                    <div class="imgt darkmode-icon">
                                        <img src="./remote_close_dark.svg"></img>
                                    </div>
                                </div>
                            </div> : ""}
                            
                            <div class="seh_abata" onClick={() => navigate('../character?server='+schbase.characters[i].server+'&key='+schbase.characters[i].key)}>
                                <div class="imgt">

                                     <img  
                                     src={'https://img-api.neople.co.kr/df/servers/'+schbase.characters[i].server+'/characters/' +schbase.characters[i].key+ '?zoom=1.jpg&temp='+ Math.round(Math.random() * 10000)}  />
                                </div>
                            </div>
                            <div class="seh_sever" onClick={() => navigate('../character?server='+schbase.characters[i].server+'&key='+schbase.characters[i].key)}>
                                <li class="sev">{serverKR[ schbase.characters[i].server]}</li>
                            </div>

                            <div class="seh_job" onClick={() => navigate('../character?server='+schbase.characters[i].server+'&key='+schbase.characters[i].key)}>
                                <li class="sev">{schbase.characters[i].job}</li>
                            </div>
                            <div class="seh_bacalcount">
                                <div class="sbccnt">
                                    <ul class="bac_cont">
                                        <li class="bacct-tit">
                                            <div class="bcimg">
                                                <div class="imgt">
                                                    <img src={"https://dundam.xyz/img/mu.png"}/>

                                                </div>
                                            </div>
                                        </li>
                                        <li class="bacct-num">
                                            <div class="bacal-calculate">
                                                <div class="bcimg">
                                                    <div class="imgt">
                                                    <img src={"mohum.png"}/>

                                                    </div>
                                                </div>
                                                <div class="bcresult">
                                                    <span>{schbase.characters[i].advenBakal}</span>
                                                </div>

                                            </div>
                                            <div class="bacal-calculate">
                                                <div class="bcimg">
                                                    <div class="imgt">

                                                        <img src={"img/job/"+schbase.characters[i].baseJob+".gif"}/>
                                                    </div>
                                                </div>
                                                <div class="bcresult">
                                                    <span>{schbase.characters[i].bakal}</span>
                                                </div>

                                            </div>
                                        </li>
                                    </ul>
                                </div>

                            </div>

                            <div class="seh_addinfo">
                                <div class="sainf-tr" name="세트포인트">
                                    <span class="saninf-setp">{schbase.characters[i].setPoint}</span>
                                </div>
                                <div class="sainf-tr" name="마부 스증 총합">
                                    <span class="saint-sumofms">{schbase.characters[i].skillDamage}</span>
                                </div>
                                <div class="sainf-tr" name="크리티컬 확률">
                                    <span class="saint-critical">{schbase.characters[i].cri}</span>
                                </div>
                                {
                                    schbase.characters[i].switching ? 
                                    <div class="sainf-tr" name="스위칭 레벨 / 짙편린(스위칭 강화템) 개수">
                                        <span class="saint-sumofswithing"> {schbase.characters[i].switching}</span>
                                    </div> :<></>
                                }
                                
                            </div>
                            
                            <div class="seh_name">
                                <span class="name">{schbase.characters[i].name}
                                    <span class="introd server" name="서버"> 
                                        {schbase.characters[i].adventrueName}
                                    </span>
                                </span>
                                <div class="level">
                                    <span class="fpic">
                                        <div class="imgt">
                                        </div>
                                    </span>
                                    <span class="val">{schbase.characters[i].fame}</span>
                                </div>

                            </div>
                            <div class="seh_stat">
                                 <ul className= {schbase.characters[i].buffScore == null ? 'stat_a' : 'off' }>
                                    <li>
                                        <div class="statc">
                                            <span class="tl tfive">랭킹</span>
                                            <span class="val">{schbase.characters[i].ozma}</span>
                                        </div>
                                    </li>
                                    {/* <li>
                                        <div class="statc">
                                            <span class="tl fourt">25초</span>
                                            <span class="val">{schbase.characters[i].gb}</span>
                                        </div>
                                    </li> */}
                                    {/* <li>
                                        <div class="statc">
                                            <span class="tl ozma">40초</span>
                                            <span class="val">{schbase.characters[i].yasin}</span>
                                        </div>
                                    </li> */}
                                </ul> 

                                <ul className= {schbase.characters[i].ozma == null ? 'stat_b' : 'off' }>
                                    <li>
                                        <div class="statc">
                                            <span class="tl">{schbase.characters[i].buffScore3 == null ? '버프점수': '2인' }</span>
                                            <span class="val">{schbase.characters[i].buffScore}</span>
                                        </div>
                                    </li>
                                    <li className= {schbase.characters[i].buffScore3 == null ? 'off' : '' }>
                                        <div class="statc">
                                            <span class="tl">3인</span>
                                            <span class="val">{schbase.characters[i].buffScore3}</span>
                                        </div>
                                    </li>
                                    <li className= {schbase.characters[i].buffScore4 == null ? 'off' : '' }>
                                        <div class="statc">
                                            <span class="tl">4인</span>
                                            <span class="val">{schbase.characters[i].buffScore4}</span>
                                        </div>
                                    </li>

                                </ul>
                            </div>
                        </div>
                        )
                    }
                )
            }
            </div>

            {
                        lockadven == true ? 
            <div class="bcs-item result-lock">
                <div class="modal_setting_v1">
                    <div class="bcssch">
                        <div class="dbseach">
                            <div class="result-intro-lockerror">
                                <div class="search">
                                    {/* <div class="searcbox">
                                        <div class="searchbox_hug">
                                            
                                                            
                                            <div class="searchbox" name="인증">
                                            <input type="text" class="form-control" placeholder="캐릭터명" name="name" maxlength="20" value={authNick} onKeyPress={handleOnKeyPressAuth} onChange={(event) => {setAuthNick(event.target.value)}}></input>
                                            </div>

                                            <div class="searchbutton" name="인증버튼">
                                                <button onClick={()=>{auth()}}>

                                                </button>
                                            </div>
                                        </div>

                                        <div class="avenlock-comment">
                                            <div class="alk-coment_hug">
                                                <span class="rocer-tit">
                                                    무분별한 검색 결과 순서 변경을 막기 위해 {"\n"} 
                                                    모험단 캐릭터 중 
                                                    던담에 한번도 검색하지 않은 캐릭터명을 입력해주세요.
                                                </span>
                                      
                                                <span>
                                                    입력한 캐릭터와 모험단명이 일치하면 {"\n"} 잠금이 해제되어 검색결과 순서 이동이 가능합니다.
                                                </span>

                                                <div class="alk-back-but" onClick={ ()=>{ setLockAdven(!lockadven) } }>
                                                    <div class="alk-b-b_hug">
                                                        <div class="alkb-but">
                                                            <span> 닫기</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        
                                        </div>

                                    </div> */}

                                <div class="unlock_aven">
                                    <div class="avenlock-comment">
                                            <div class="alk-coment_hug">
                                                <div class="ulockaven-coment">
                                                    <span class="rocer-tit">
                                                        무분별한 검색 결과 순서 변경을 막기 위해 {"\n"} 
                                                        모험단 중 안내된 캐릭터의 아이템을 변경해주세요.
                                                    </span>
                                                </div>

                                                <div class="item-unlock-section_hug">
                                                    <div class="innl-user-part">
                                                        <div class="innl-user">
                                                            <div class="innl-user-abata">
                                                                <div class="imgt">
                                                                    <img src={ authData ? 
                                                                    'https://img-api.neople.co.kr/df/servers/'+ authData['server']  +'/characters/'+ authData['id']  +'?zoom=1.jpg'
                                                                : ""}/>


                                                                </div>
                                                            </div>
                                                            <div class="innl-user-info">
                                                                <span class="inus-name">
                                                                {authData ? authData['name'] : ""}



                                                                </span>
                                                                <span class="inus-aven">
                                                                {authData ? serverKR[authData['server']] : ""}
                                                                </span>

                                                            </div>
                                                            {/* <div class="innl-refresh-but">
                                                                <div class="iref-b">
                                                                
                                                                </div>
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                    <div class="iunl-item-part">
                                                        <div class="innl-item innl-before">
                                                            <div class="ul-it-img">
                                                                <div class="imgt">
                                                                    {
                                                                        authData && lv105ItemId[authData['previos']] != null ? 
                                                                        <img src={authData ? "https://img-api.neople.co.kr/df/items/"+lv105ItemId[authData['previos']] : ""}></img> : 
                                                                        <img src={authData ? get115ItemSrc(authData['previos']): ""}></img>
                                                                    }
                                                                    

                                                                </div>
                                                            </div>
                                                            <div class="ul-it-tit">
                                                                <span>{authData ? authData['slot'] : ""}</span>
                                                            </div>
                                                        </div>
                                                        <div class="innl-acon">
                                                            <span>▶</span>
                                                        </div>
                                                        <div class="innl-item innl-after">
                                                            <div class="ul-it-img">
                                                                <div class="imgt equip_X">
                                                                    {
                                                                        authData && lv105ItemId[authData['previos']] != null ? 
                                                                        <img src={authData ? "https://img-api.neople.co.kr/df/items/"+lv105ItemId[authData['previos']] : ""}></img> : 
                                                                        <img src={authData ? get115ItemSrc(authData['previos']): ""}></img>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div class="ul-it-tit">
                                                                <span>{authData ? authData['slot'] : ""}</span>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div class="ulockaven-coment">

                                                    <span>
                                                        안내된 캐릭터의 아이템을 장착 해제 한 후 적용 버튼을 눌러주시면
                                                        {"\n"} 잠금이 해제되어 검색결과 순서 이동이 가능합니다.
                                                        {"\n"}
                                                    </span>
                                                </div>

                                                <div class="alk-back-but">
                                                    <div class="alk-b-b_hug">
                                                        <ul>
                                                            <li class="alkb-but-2" onClick={ () => {authDetail()}}>
                                                                <span> 적용</span>
                                                            </li>
                                                            <li class="alkb-but-2" onClick={ ()=>{ setLockAdven(!lockadven) } }>
                                                                <span> 닫기</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        
                                        </div>
                                    </div>
                                </div>
                  
                            </div>
        
                        </div>
                    </div>
                </div>
            </div> : ""
            }
        </div>       
        <div id="ad-container-2"></div>
        

    </section>

    )
}

function get115ItemSrc(name)
{
    if(lv115ItemId[name] != null)
    {
        let folderName = "weapon";
        let splitString = lv115ItemId[name].split("_");

        switch(splitString[splitString.length-1])
        {
            case 'coat': folderName ="coat"; break;
            case 'pants': folderName ="pants"; break;
            case 'neck': folderName ="neck"; break;
            case 'belt': folderName ="belt"; break;
            case 'shoes': folderName ="shoes"; break;
        }

        return 'https://dundam.xyz/img/lv115/'+folderName+'/'+lv115ItemId[name] +'.png';
    }
}


export default SearhResult
