import { createContext, useEffect, useState ,useMemo, memo } from 'react';

import { Routes, Route, Link, useNavigate, Outlet , useLocation  } from 'react-router-dom'


import React from 'react';
import Ad from '../ad';


import {names,speNames,accNames,lv105OptionMap,lv105WeaponOptionMap,lv105CustomOptionMap,lv105WeaponThirdOptionMap,lv105ItemId,legendStone} from '../var/editVar';
import list from '../var/itemVar';
import skillImage from '../var/var';
import {editSetting,avatarOption,avatarSkillOption,avatarTypeList,emblemSkillOption,gradeToNum,runeSkill,talismanList,customEquip} from '../var/editSetting';

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import { Pagination,Navigation } from "swiper";
import Script from '../utill/Script';
import {weaponImage,lv105WeaponImage,lv105EquipImage} from '../var/image';
import {lv115AccAndSpeImage,lv115ArmourImage,lv115ImageOrderList} from '../lv115/euqipMap';



var characterDataTemp = {};
var [characterData, setCharacterData] = [null,null];
var [present,setPresent]  = [null,null];
var [avaPresent,setAvaPresent]  = [null,null];
var [setsItem,setSetsItem]  = [null,null];

let [itemSearch,setItemSearch] =  [null,null];
let [itemSearchTemp,setItemSearchTemp] =  [null,null];
let optionArray = Array.from({length: 13}, (v, i) => 0);
let searchArray = Array.from({length: 13}, (v, i) => '');

let [setPoint,setSetPoint] =  [null,null];
let [setName,setSetName] =  [null,null];
let dStat = [100,25,125,100,100,100,125,100,100,100,100,100,200];
let dAttack = [90,0,110,30,110,15,30,0,0,0,110,0,0];
let dEle =  [15,6,0,0,0,0,0,35,35,35,12,40,15]; // 속강 스킬렙
let dESL = [0,0,2,3,2,2,3,1,1,1,0,0,0]; // 속강 스킬렙
//let dSkillDamage = [0,0,0,2,0,0,2,0,0,0,3,0,0]; // 스증,피증


let [itemRarity,setItemRarity] =  [null,null]; // 레어 0 , 유니크 1 , 레전 2 , 에픽 3 , 흑아 4 , 태초 5 , 흑아 태초 6 


let setItemList =[
    //상의
    [
        "지휘관의 명주 상의",
        "총사령관의 제복 상의",
        "미지의 근섬유 상의",
        "순례자의 해진 바람 막이"
    ],
    //하의
    [
        "전략가의 정교한 전투 바지",
        "총사령관의 제복 하의",
        "미지의 금속 각반",
        "순례자의 카고 바지"
    ],
    //어깨
    [
        "연합의 지휘자 망토",
        "총사령관의 의무",
        "미지의 보석 숄더",
        "순례자의 어깨 보호대"
    ],
    //벨트
    [
        "신뢰의 황금 복대",
        "총사령관의 지휘",
        "미지의 기계 장치 벨트",
        "순례자의 나무 요대"
    ],
    //신발
    [
        "결의의 전쟁 부츠",
        "총사령관의 결단",
        "미지의 관절 신발",
        "순례자의 사막 부츠"
    ],
    //팔찌
    [
        "무한한 영감",
        "총사령관의 섬멸 작전",
        "미지의 비늘 암릿",
        "순례자를 짓누르는 무게" 
    ],

    //목걸이
    [
        "포지 코어 펜던트",
        "총사령관의 비장의 한발",
        "미지의 눈동자 네클리스",
        "순례자를 얽매는 고난"

    ],
    //반지
    [
        "조화의 이치",
        "총사령관의 초심",
        "미지의 골격 링",
        "순례자를 억압하는 시련"
    ],
    //보장
    [
        "무회",
        "총사령관의 전술 지도",
        "미지의 에너지 스피어",
        "순례자의 미래를 보는 시선"
    ],
    //마법석
    [
        "무결한 마음",
        "총사령관의 독도법 나침반",
        "미지의 바이탈 시그널",
        "순례자의 길을 비추는 빛"

    ],
    //귀걸이
    [
        "흔적이 새겨진 돌",
        "총사령관의 전우가 남긴 유품",
        "미지의 파워 사이드",
        "순례자의 나아가는 의지" 
    ]


]


let setItemNames =[ "지휘관의 명주 상의",
"총사령관의 제복 상의",
"미지의 근섬유 상의",
"순례자의 해진 바람 막이","전략가의 정교한 전투 바지",
"총사령관의 제복 하의",
"미지의 금속 각반",
"순례자의 카고 바지","연합의 지휘자 망토",
"총사령관의 의무",
"미지의 보석 숄더",
"순례자의 어깨 보호대","신뢰의 황금 복대",
"총사령관의 지휘",
"미지의 기계 장치 벨트",
"순례자의 나무 요대","결의의 전쟁 부츠",
"총사령관의 결단",
"미지의 관절 신발",
"순례자의 사막 부츠", "무한한 영감",
"총사령관의 섬멸 작전",
"미지의 비늘 암릿",
"순례자를 짓누르는 무게" ,"포지 코어 펜던트",
"총사령관의 비장의 한발",
"미지의 눈동자 네클리스",
"순례자를 얽매는 고난","조화의 이치",
"총사령관의 초심",
"미지의 골격 링",
"순례자를 억압하는 시련", "무회",
"총사령관의 전술 지도",
"미지의 에너지 스피어",
"순례자의 미래를 보는 시선","무결한 마음",
"총사령관의 독도법 나침반",
"미지의 바이탈 시그널",
"순례자의 길을 비추는 빛","흔적이 새겨진 돌",
"총사령관의 전우가 남긴 유품",
"미지의 파워 사이드",
"순례자의 나아가는 의지" ];

//융합석
let fusionStoneList =[
    //반지
    [   
        '기민한 움직임',
        '얼음 송곳니',
        '세계의 경계',
        '무한한 가능성의 흐름',
        '보호를 담은 괴',
        '저항을 담은 철',
        '추억의 속삭임',
        '서리에 묻힌 분노',
        '망각의 마석',
        '부화하는 악령의 씨앗',
        '월드 아이돌 링',
        '이단을 지명하는 검지',
        '레드 래빗'

    ],
    //목걸이
    [   
        '멈추지 않는 질주',
        '광폭화 제어 장치',
        '지면 파괴자',
        '무한한 가능성의 흔적',
        '미지의 세계의 균형',
        '뒤집힌 기억',
        '향기로운 환희',
        '냉기에 갇힌 원망',
        '시간을 거스르는 기억',
        '터져나오는 악몽',
        '수호 : 혼',
        '펠 로스 제국의 영광',
        '소울 트레이싱 디바이스'
    ],
    //팔찌
    [
        '결속된 영혼',
        '무지한 영역의 통로',
        '마나 오버플로우',
        '무한한 가능성의 탐구',
        '스킬 펄 익스텐션',
        '지배자의 위엄'
        
    ],
    //보장
    [
        '사신의 함정',
        '인비시블 스톤',
        '수평선 위 균형',
        '혼돈이 담긴 소용돌이',
        '일렉트릭 플레임',
        '피할 수 없는 일격'
        , '영원의 조각 - 보조장비'       

    ],
    //마법석
    [
        '평화로운 과거',
        '천상의 보호',
        '영광의 재림',
        '지식의 향연',
        '혈독초',
        '펼쳐지는 예언'
        , '영원의 조각 - 마법석' 
    ],
    //귀걸이
    [
        '종말의 예고',
        '멸망의 안식처',
        '달콤한 향기의 속삭임',
        '지나간 과오',
        '종언을 고하는 서약',
        '검객의 유산'
        , '영원의 조각 - 귀걸이' 

    ]
]



let traitList = [
    [
        111,112,113,114,
        121,122,123,124,
        131,132,133,
        141,142,143
    ],
    [
        211,212,214,
        221,222,224,
        231,232,
        241,242,
    ],
    [
        312,314,
        321,323,324,
        332,
        341,343,
        351,353
    ],
    [
        412,414,
        421,422,423,424,
        431,432,433,
        442,
        451,452,453
    ]
];


let traitMaster = [
    [
        [7,10,7,5],
        [7,5,5,2],
        [1,7,5,0],
        [2,7,5,0]
    ],
    [
        [5,5,0,5],
        [5,5,0,2],
        [5,5,0,0],
        [3,3,0,0]
    ],
    [
        [0,5,0,5],
        [5,0,5,2],
        [0,1,0,0],
        [7,0,7,0],
        [5,0,5,0]
    ],
    [
        [0,7,0,5],
        [7,10,7,2],
        [7,1,0,0],
        [0,1,7,0],
        [7,1,7,0]
    ]
];

let bStat = [110,25,110,100,100,100,100,100,100,100,100,100,200];
let bSkillLevel = [0,0,{'stat':4},{'stat':3},{'stat':3},{'stat':3},{'awake':3},{'stat':1},{'stat':1},{'stat':1},{'stat':3},{'stat':3},0];

let [customArray,setCustomArray] = [null,null]; 
let [customSearch,setCustomSearch] = [null,null]; 
let [weaponOpSearch,setWeaponOpSearch] = [null,null]; 
let [weaponPlusArray,setWeaponPlusArray] = [null,null]; 
let [legendStoneArray,setLegendStoneArray] = [null,null]; 
let [legendStoneSelectArray,setLegendStoneSelectArray] = [null,null]; 
let [weaponPlusSelectArray,setWeaponPlusSelectArray] = [null,null]; 
let [customSelectArray,setCustomSelectArray] = [null,null]; 

let nick = "";
let init = false;

let itemCount = accNames.length / 3;
let speCount = speNames.length / 3;
let armourCount = names.length / 5;

let talismanSkillList = [];
let [selectTalisType,setSelectTalisType] = [null,null];
let [presentTalis,setPresentTalis] = [null,null];
let [selectTalisSkill,setSelectTalisSkill] = [null,null];
let [selectSpecType,setSelectSpecType] = [null,null];
let [selectWeaponType,setSelectWeaponType] = [null,null];
let [selectFusionType,setSelectFusionType] = [null,null];


let [selectItemType,setSelectItemType] = [null,null];

let characterObj = null;
let navigate = null

let weaponListTemp = null;
let weaponList = null;
let statSkill = "";
let awakeSkill = "";
let [navigateValue,setNavigateValue]  = [null,null];

function EqupEditMO() 

{
    [selectWeaponType,setSelectWeaponType]  = useState(0);
    [selectFusionType,setSelectFusionType]  = useState(0);

    [present,setPresent] =  useState(11500);
    [setsItem,setSetsItem] = useState(100);
    [avaPresent,setAvaPresent] = useState(100);
    [presentTalis,setPresentTalis] =  useState(100);
    [selectItemType,setSelectItemType] =  useState(100);
    [selectSpecType,setSelectSpecType] = useState(1);
    let equipArray = Array.from({length: 13}, (v, i) => i);
    navigate = useNavigate();
    [navigateValue,setNavigateValue] = useState();
    const location = useLocation();
    const character = { ...location.state };
    characterObj = character.character;
    [itemRarity,setItemRarity] = useState(3);
    [setPoint,setSetPoint] =  useState(0);
    [setName,setSetName] = useState("");


    [itemSearch,setItemSearch] = useState('');
    [itemSearchTemp,setItemSearchTemp] = useState('');
    
    
    if(characterObj == null)
    {
        if( localStorage.getItem('characterObj') == null)
        {
            window.history.replaceState("",null,"../error");
            window.location.reload();
        }
        
        characterObj = JSON.parse( localStorage.getItem('characterObj')).character;
    }
    else
    {
        localStorage.setItem('characterObj', JSON.stringify(character));
    }

    //console.log(characterObj);

    //선언부

    for(let loop = 0 ; loop < 13 ; loop++)
    {
        optionArray[loop] = [0,0,0,0];
    }
    for(let loop = 0 ; loop < 13 ; loop++)
    {
        searchArray[loop] = ['','','',''];
    }
    [customArray,setCustomArray] = useState(optionArray);
    [customSearch,setCustomSearch] = useState(searchArray);
    [weaponOpSearch,setWeaponOpSearch] = useState(['','','']);
    [legendStoneArray,setLegendStoneArray] = useState([[0,0,0],[0,0,0],[0,0,0]]);
    [legendStoneSelectArray,setLegendStoneSelectArray] = useState([[0,0,0],[0,0,0],[0,0,0]]);
    [weaponPlusArray,setWeaponPlusArray] = useState([0,0,0]);
    [weaponPlusSelectArray,setWeaponPlusSelectArray] = useState([0,0,0]);
    
    [customSelectArray,setCustomSelectArray] = useState([...optionArray]);

    if(nick != characterObj.name || !init)
    {
        nick = characterObj.name;
        init = true;
        
        selectTalisType = [0,0,0];
        selectTalisSkill = ['','',''];
        characterDataTemp = {};
        editSetting(characterObj,characterDataTemp,{talismanList,talismanSkillList,selectTalisType,selectTalisSkill,talismanToSkillName,setSelectSpecType,avatarTypeList});


    }

    
    switch(characterDataTemp['job'])
	{
		//귀검사 시작
		case "검신": case "眞 웨펀마스터":
		{
			 weaponListTemp = ["소검", "도" ,"둔기", "대검", "광검"];
			weaponList = weaponListTemp;
			break;
		}
		
		case "블러드 이블": case "眞 버서커":
		{
			 weaponListTemp = ["소검", "도" ,"둔기", "대검"];
			weaponList = weaponListTemp;
			break;
		}
		
		case "인다라천": case "眞 아수라":
		{
			 weaponListTemp = ["소검", "도" ,"둔기", "대검"];
			weaponList = weaponListTemp;
			break;
		}
		
		case "다크로드": case "眞 소울브링어":
		{
			 weaponListTemp = ["소검", "도" ,"둔기", "대검"];
			weaponList = weaponListTemp;
			break;
		}
		case "악귀나찰": case "眞 검귀":
		{
			 weaponListTemp = ["소검", "도" ,"둔기", "대검"];
			weaponList = weaponListTemp;
			break;
		}
	
		case "트렌드세터": case "眞 뮤즈":
		{
			 weaponListTemp = ["선현궁"]
			weaponList = weaponListTemp;
			break;
		}
		case "셀레스티얼": case "眞 트래블러":
		{
			 weaponListTemp = ["장궁"];
			weaponList = weaponListTemp;
			break;
		}
        case "메이븐": case "眞 헌터":
		{
			 weaponListTemp = ["크로스슈터"];
			weaponList = weaponListTemp;
			break;
		}
        case "프레데터": case "眞 비질란테":
		{
			 weaponListTemp = ["에테리얼 보우"];
			weaponList = weaponListTemp;
			break;
		}
		
		
		//귀검사 끝
		
		//여귀검사 시작
	
		
		case "마제스티": case "眞 소드마스터":
		{
			 weaponListTemp = ["소검", "도" ,"둔기", "대검"];
			weaponList = weaponListTemp;
			break;
		}
		
		case "네메시스": case "眞 다크템플러":
		{
			 weaponListTemp = ["소검", "도" ,"둔기", "대검"];
			weaponList = weaponListTemp;
			break;
		}
		
		case "검제": case "眞 베가본드":
		{	
			 weaponListTemp = ["소검", "도" ,"둔기", "대검", "광검"];
			weaponList = weaponListTemp;
			break;
		}
		
		case "디어사이드": case "眞 데몬슬레이어":
		{
			 weaponListTemp = ["소검", "도" ,"둔기", "대검"];
			weaponList = weaponListTemp;
			break;
		}
		case "벤데타": case "眞 블레이드":
		{
			 weaponListTemp = ["소검", "도" ,"둔기", "대검"];
			weaponList = weaponListTemp;
			break;
		}
	
		//여귀검사 끝
		
		//여격투가 시작
		
		case "염제 폐월수화":
		{
			 weaponListTemp = ["너클", "건틀릿" ,"클로", "통파"];
			weaponList = weaponListTemp;
			break;
		}
		
		case "카이저":
		{
			 weaponListTemp = ["너클", "건틀릿" ,"클로", "통파", "권투글러브"];
			weaponList = weaponListTemp;
			break;
		}
		
		case "용독문주": // 중호
		{
			 weaponListTemp = ["너클", "건틀릿" ,"클로", "통파"];
			weaponList = weaponListTemp;
			break;
		}
		
		case "얼티밋 디바": // 내계정
		{
			 weaponListTemp = ["너클", "건틀릿" ,"클로", "통파"];
			weaponList = weaponListTemp;
			break;
		}
		
		//여격투가 끝
		
		//남격투가 시작
		
		case "명왕":  case "眞 스트리트파이터": case "眞 스트리트 파이터":// 뱀파님
		{
			 weaponListTemp = ["너클", "건틀릿" ,"클로", "통파"];
			weaponList = weaponListTemp;
			break;
		}
		case "그랜드 마스터":  case "眞 그래플러": // 
		{
			 weaponListTemp = ["너클", "건틀릿" ,"클로", "통파"];
			weaponList = weaponListTemp;
			break;
		}
		case "패황":  case "眞 스트라이커":
		{
			 weaponListTemp = ["너클", "건틀릿" ,"클로", "통파", "권투글러브"];
			weaponList = weaponListTemp;
			break;
		}
		case "염황 광풍제월":  case "眞 넨마스터"://뱀파님
		{
			 weaponListTemp = ["너클", "건틀릿" ,"클로", "통파"];
			weaponList = weaponListTemp;
			break;
		}
		
		
		//남격투가 끝
		
		//남거너 시작
		case "프라임": case "眞 메카닉":// 뱀파님
		{
			 weaponListTemp = ["리볼버", "자동권총" ,"머스켓", "핸드캐넌", "보우건"];
			weaponList = weaponListTemp;
			break;
		}
		
		case "디스트로이어":  case "眞 런처":// 뱀파님
		{
			 weaponListTemp = ["리볼버", "자동권총" ,"머스켓", "핸드캐넌", "보우건"];
			weaponList = weaponListTemp;
			break;
		}
		
		case "레이븐":   case "眞 레인저":// 뱀파님
		{
			 weaponListTemp = ["리볼버", "자동권총" ,"머스켓", "핸드캐넌", "보우건"];
			weaponList = weaponListTemp;
			break;	
		}
		
		case "커맨더": case "眞 스핏파이어":// 뱀파님
		{
			 weaponListTemp = ["리볼버", "자동권총" ,"머스켓", "핸드캐넌", "보우건"];
			weaponList = weaponListTemp;
			break;
		}
		case "엑스마키나": case "眞 어썰트":// 뱀파님
		{
			 weaponListTemp = ["리볼버", "자동권총" ,"머스켓", "핸드캐넌", "보우건"];
			weaponList = weaponListTemp;
			break;
		}
	
	
		//남거너 끝
		//여거너 시작
		case "스톰 트루퍼":
		{
			 weaponListTemp = ["리볼버", "자동권총" ,"머스켓", "핸드캐넌", "보우건"];
			weaponList = weaponListTemp;
			break;
		}
		case "프레이야":
		{
			 weaponListTemp = ["리볼버", "자동권총" ,"머스켓", "핸드캐넌", "보우건"];
			weaponList = weaponListTemp;
			break;
		}
		case "크림슨 로제":
		{
			 weaponListTemp = ["리볼버", "자동권총" ,"머스켓", "핸드캐넌", "보우건"];
			weaponList = weaponListTemp;
			break;
		}
		case "옵티머스":
		{
			 weaponListTemp = ["리볼버", "자동권총" ,"머스켓", "핸드캐넌", "보우건"];
			weaponList = weaponListTemp;
			break;
		}
		
		
		//여거너 끝
		
		//여마법사 시작
		case "아슈타르테": case "眞 배틀메이지":
		{
			 weaponListTemp = ["창", "봉" ,"로드", "스탭"];
			weaponList = weaponListTemp;
			break;
		}
		
	
		case "이클립스": case "眞 소환사":
		{
			 weaponListTemp = ["창", "봉" ,"로드", "스탭"];
			weaponList = weaponListTemp;
			break;
		}
		
		case "오버마인드": case "眞 엘레멘탈마스터":
		{
			 weaponListTemp = ["창", "봉" ,"로드", "스탭"];
			weaponList = weaponListTemp;
			break;
		}
		
		case "지니위즈": case "眞 마도학자":// 내 계정
		{
			 weaponListTemp = ["창", "봉" ,"로드", "스탭", "빗자루"];
			weaponList = weaponListTemp;
			break;
		}
		case "헤카테": case "眞 인챈트리스":// 내 계정
		{
			 weaponListTemp = ["창", "봉" ,"로드", "스탭", "빗자루"];
			weaponList = weaponListTemp;
			break;
		}
		//여마법사 끝
		//남마법사 시작
	
		case "아이올로스":
		case "眞 스위프트 마스터":
		{
			 weaponListTemp = ["창", "봉" ,"로드", "스탭"];
			weaponList = weaponListTemp;
			break;
		}
					
		case "뱀파이어 로드":
		case "眞 블러드 메이지":
		{
			 weaponListTemp = ["창", "봉" ,"로드", "스탭"];
			weaponList = weaponListTemp;
			break;
			
		}
		
		case "오블리비언":
		case "眞 엘레멘탈 바머":
		{
			 weaponListTemp = ["창", "봉" ,"로드", "스탭"];
			weaponList = weaponListTemp;
			break;
		}
		case "이터널":
		case "眞 빙결사":
		{
			 weaponListTemp = ["창", "봉" ,"로드", "스탭"];
			weaponList = weaponListTemp;
			break;
		}
		case "어센션": // 내꺼든 중호꺼든
		case "眞 디멘션워커":
			
		{
			 weaponListTemp = ["창", "봉" ,"로드", "스탭", "빗자루"];
			weaponList = weaponListTemp;
			break;
		}
		
		//남마법사 끝
		//남프리스트 시작
		case "세인트":  case "眞 크루세이더":// 내꺼
		{
			 weaponListTemp = ["십자가", "염주" ,"토템", "낫", "배틀액스"];
			weaponList = weaponListTemp;
			break;
		}
		case "저스티스":  case "眞 인파이터":
		{
			 weaponListTemp = ["십자가", "염주" ,"토템", "낫", "배틀액스"];
			weaponList = weaponListTemp;
			break;
		}
		case "태을선인":  case "眞 퇴마사": // ?
		{
			 weaponListTemp = ["십자가", "염주" ,"토템", "낫", "배틀액스"];
			weaponList = weaponListTemp;
			break;
		}
		case "이모탈":  case "眞 어벤저": // 뱀파님
		{
			 weaponListTemp = ["십자가", "염주" ,"토템", "낫", "배틀액스"];
			weaponList = weaponListTemp;
			break;
		}
		//남프리스트 끝
		//여프리스트 시작
		
		case "세라핌": 
		{
			 weaponListTemp = ["십자가", "염주" ,"토템", "낫", "배틀액스"];
			weaponList = weaponListTemp;
			break;
		}
		
		
		case "리디머": case "眞 미스트리스":
		{
			weaponListTemp = ["십자가", "염주" ,"토템", "낫", "배틀액스"];
			weaponList = weaponListTemp;
			break;
		}
	
		case "천선낭랑":  case "眞 무녀":
		{
			 weaponListTemp = ["십자가", "염주" ,"토템", "낫", "배틀액스"];
			weaponList = weaponListTemp;
			break;
		}
		
		case "인페르노": case "眞 이단심판관":
		{
			 weaponListTemp = ["십자가", "염주" ,"토템", "낫", "배틀액스"];
			weaponList = weaponListTemp;
			break;
		}
		
		
		
		
		
		//여프리스트 끝
		//나이트 시작
		case "眞 엘븐나이트": case "가이아": // 내꺼
		{
			 weaponListTemp = ["소검", "도" ,"둔기", "대검"];
			weaponList = weaponListTemp;
			break;
		}
		case "眞 카오스": case "마신": // ?
		{
			 weaponListTemp = ["소검", "도" ,"둔기", "대검"];
			weaponList = weaponListTemp;
			break;
		}
		
		case "眞 드래곤나이트": case "드레드노트":
		{
			 weaponListTemp = ["소검", "도" ,"둔기", "대검"];
			weaponList = weaponListTemp;
			break;
		}
	
		case "眞 팔라딘": case "세이비어":
		{
			 weaponListTemp = ["소검", "도" ,"둔기", "대검"];
			weaponList = weaponListTemp;
			break;
		}
		
		//나이트 끝
		//여도적 시작
		case "알키오네": case "眞 로그":// 중호
		{
			 weaponListTemp = ["단검", "쌍검" ,"완드", "차크라 웨펀"];
			weaponList = weaponListTemp;
			break;
		}
		case "시라누이": case "眞 쿠노이치": // 내꺼
		{
			 weaponListTemp = ["단검", "쌍검" ,"완드", "차크라 웨펀"];
			weaponList = weaponListTemp;
			break;
		}
		case "그림리퍼": case "眞 섀도우댄서":
		{
			 weaponListTemp = ["단검", "쌍검" ,"완드", "차크라 웨펀"];
			weaponList = weaponListTemp;
			break;
		}
		case "타나토스": case "眞 사령술사":// ?
		{
			 weaponListTemp = ["단검", "쌍검" ,"완드", "차크라 웨펀"];
			weaponList = weaponListTemp;
			break;
		}
		//여도적 끝
		//마창사 시작
		case "듀란달": case "眞 듀얼리스트": // ?
		{
			 weaponListTemp = ["장창", "미늘창" ,"광창", "투창"];
			weaponList = weaponListTemp;
			break;
		}
		case "워로드": case "眞 뱅가드":
		{
			 weaponListTemp = ["장창", "미늘창" ,"광창", "투창"];
			weaponList = weaponListTemp;
			break;
		}
		case "에레보스":  case "眞 다크 랜서": // ..?
		{
			 weaponListTemp = ["장창", "미늘창" ,"광창", "투창"];
			weaponList = weaponListTemp;
			break;
		}
		case "제노사이더":  case "眞 드래고니안 랜서": // 볼배님
		{
			 weaponListTemp = ["장창", "미늘창" ,"광창", "투창"];
			weaponList = weaponListTemp;
			break;
		}
		//마창사 끝
		//외전 시작
		
		case "크리에이터(자각2)": case "眞 크리에이터":
		{
			 weaponListTemp = ["창", "봉" ,"로드", "스탭", "빗자루"];
			weaponList = weaponListTemp;
			break;
			
			
		}
		case "다크나이트(자각2)": case "眞 다크나이트":// 볼배님
		{
			 weaponListTemp = ["소검", "도" ,"둔기", "대검", "광검"];
			weaponList = weaponListTemp;
			break;
		}
		case "레퀴엠": case "眞 요원": // 참수님
		{
			 weaponListTemp = ["장도", "소태도" ,"중검", "코어블레이드"];
			weaponList = weaponListTemp;
			break;
		}
		case "언터처블": case "眞 트러블 슈터": // 참수님
		{
			 weaponListTemp = ["장도", "소태도" ,"중검", "코어블레이드"];
			weaponList = weaponListTemp;
			break;
		}
		case "패스파인더": case "眞 스페셜리스트": // 참수님
		{
			 weaponListTemp = ["장도", "소태도" ,"중검", "코어블레이드"];
			weaponList = weaponListTemp;
			break;
		}
		
		case "갓파더": case "眞 히트맨":
		{
			 weaponListTemp = ["장도", "소태도" ,"중검", "코어블레이드"];
			weaponList = weaponListTemp;
			break;
		}
		
	}

    if(characterDataTemp['equipName0'] != null)
        if(characterDataTemp['equipName0'].indexOf("_") != -1)
        {
            let temp = characterDataTemp['equipName0'].split("_")[1];
            switch(temp)
            {
                case "cbow" : characterDataTemp['weaponType'] = "크로스슈터"; break;
                case "ebow" : characterDataTemp['weaponType'] = "에테리얼 보우"; break;
                case "lbow" : characterDataTemp['weaponType'] = "장궁"; break;
                case "sbow" : characterDataTemp['weaponType'] = "선현궁"; break;
    
                case "bs" : characterDataTemp['weaponType'] = "광창"; break;
                case "hb" : characterDataTemp['weaponType'] = "미늘창"; break;
                case "jav" : characterDataTemp['weaponType'] = "투창"; break;
                case "pike" : characterDataTemp['weaponType'] = "장창"; break;
    
                case "bg" : characterDataTemp['weaponType'] = "권투글러브"; break;
                case "claw" : characterDataTemp['weaponType'] = "클로"; break;
                case "gau" : characterDataTemp['weaponType'] = "건틀릿"; break;
                case "knu" : characterDataTemp['weaponType'] = "너클"; break;
                case "tonfa" : characterDataTemp['weaponType'] = "통파"; break;
    
                case "core" : characterDataTemp['weaponType'] = "코어블레이드"; break;
                case "lbla" : characterDataTemp['weaponType'] = "장도"; break;
                case "mswd" : characterDataTemp['weaponType'] = "중검"; break;
                case "sbla" : characterDataTemp['weaponType'] = "소태도"; break;
    
                case "auto" : characterDataTemp['weaponType'] = "자동권총"; break;
                case "bowgun" : characterDataTemp['weaponType'] = "보우건"; break;
                case "hcan" : characterDataTemp['weaponType'] = "핸드캐넌"; break;
                case "musk" : characterDataTemp['weaponType'] = "머스켓"; break;
                case "revol" : characterDataTemp['weaponType'] = "리볼버"; break;
    
                case "bro" : characterDataTemp['weaponType'] = "빗자루"; break;
                case "pole" : characterDataTemp['weaponType'] = "봉"; break;
                case "rod" : characterDataTemp['weaponType'] = "로드"; break;
                case "spear" : characterDataTemp['weaponType'] = "창"; break;
                case "staff" : characterDataTemp['weaponType'] = "스태프"; break;
    
                case "axe" : characterDataTemp['weaponType'] = "배틀액스"; break;
                case "cross" : characterDataTemp['weaponType'] = "십자가"; break;
                case "rosa" : characterDataTemp['weaponType'] = "염주"; break;
                case "scythe" : characterDataTemp['weaponType'] = "낫"; break;
                case "totem" : characterDataTemp['weaponType'] = "토템"; break;
    
                case "chakra" : characterDataTemp['weaponType'] = "차크라 웨펀"; break;
                case "dagger" : characterDataTemp['weaponType'] = "단검"; break;
                case "twins" : characterDataTemp['weaponType'] = "쌍검"; break;
                case "wand" : characterDataTemp['weaponType'] = "완드"; break;
    
                case "beams" : characterDataTemp['weaponType'] = "광검"; break;
                case "gs" : characterDataTemp['weaponType'] = "대검"; break;
                case "katana" : characterDataTemp['weaponType'] = "도"; break;
                case "club" : characterDataTemp['weaponType'] = "둔기"; break;
                case "ss" : characterDataTemp['weaponType'] = "소검"; break;
    
            }
        }
        
        let weaponCount = 0;
        for(let weapon of weaponList)
        {
            if(characterDataTemp['weaponType'] != null)
            if(characterDataTemp['weaponType'].indexOf(weapon.replace("스탭","스태프")) != -1)
            {
                break;
            }
            if(characterDataTemp['equipName0'].indexOf(weapon.replace("스탭","스태프")) != -1)
            {
                characterDataTemp['weaponType'] = weapon.replace("스탭","스태프");
                break;
            }
            weaponCount++;
        }
    useEffect(()=>{setSelectWeaponType(weaponCount);},[]);

    if(characterObj.buffCal != null)
    {
        if(characterDataTemp['job'].indexOf("세라핌") != -1)
        {
            statSkill = "계시 : 아리아";
            awakeSkill = '신실한 열정';
        }
        else if(characterDataTemp['job'].indexOf("세인트") != -1)
        {
            statSkill = "수호의 은총";
            awakeSkill = '신념의 오라';
        }
        else if(characterDataTemp['job'].indexOf("트렌드세터") != -1)
        {
            statSkill = "센세이션";
            awakeSkill = '유명세';
        }
        else
        {
            statSkill = "퍼페티어";
            awakeSkill = '소악마';
        }

        characterDataTemp['isBuffer'] = true;
        dESL = bSkillLevel;
    }
    else
    {
        statSkill = "";
        awakeSkill = '';
        dESL = [0,0,2,3,2,2,3,1,1,1,0,0,0]; 
    }

    [selectTalisType,setSelectTalisType] =  useState(selectTalisType);
    [selectTalisSkill,setSelectTalisSkill] =  useState(selectTalisSkill);
    [characterData, setCharacterData] = useState(characterDataTemp);
    //console.log(characterData);
    //console.log(selectTalisSkill);

    useEffect( () => { 

        var self = window;
        self.__VM = self.__VM || [];
        self.__VM.push(function (admanager, scope) {
            scope.Config.get('mobile_mpu').display('mo-ad-container-1');

        });

        return () =>
        {
            self.__VM = self.__VM || [];
            self.__VM.push((admanager, scope) => {
                admanager.removeAllPlacements();
                scope.Instances.pageManager.newPageSession();
            });
            
        };

    },[]);
    useEffect(()=>
    {
        getSetPoint(characterDataTemp);
    },[]);
    
    // useEffect( () => { 
    //     var placement = document.createElement("div");
    
    //     (window.top).__vm_add = (window.top).__vm_add || [];

    //     (function (success) {
    
    //         if (window.document.readyState !== "loading") {
    
    //             success();
    
    //         } else {
    
    //             window.document.addEventListener("DOMContentLoaded", function () {
    
    //                 success();
    
    //             });
    
    //         }
    
    //     })(function () {
    
    
    //         placement.setAttribute("class", "vm-placement");
    
    //         placement.setAttribute("data-id", "63358a7095a6d454fcf66d0d");
    
    //         document.querySelector("#mo-ad-container-1").appendChild(placement);
    
    //         (window.top).__vm_add.push(placement);
    
    //     });
        
    //     return() => { 
    //         (window.top).__vm_remove = (window.top).__vm_remove || [];
    //         //console.log("unmounting", placement);
    //         (window.top).__vm_remove.push(placement);


    //     };

    // },[]);

    
    // useEffect( () => { 
        
    //     var placement = document.createElement("div");
    //     window.top.__vm_add = window.top.__vm_add || [];
    //     (function (success) {
    //         if (window.document.readyState !== "loading") {
    //             success();
    //         } else {
    //             window.document.addEventListener("DOMContentLoaded", function () {
    //                 success();
    //             });
    //         }
    //     })(function () {

    //         placement.setAttribute("class", "vm-placement");
    //         placement.setAttribute("data-id", "63358aa395a6d454fcf66d11");
    //         document.querySelector("#rich-media-placement").appendChild(placement);
    //         window.top.__vm_add.push(placement);
    //     });
            
    //     return() => { 
    //         (window.top).__vm_remove_category = (window.top).__vm_remove_category || [];
    //         (window.top).__vm_remove_category.push("richmedia_all");

    //         //console.log("unmounting", (window.top).__vm_remove_category);

    //     };

            
    // },[]);
        
    return(
        <section id="mobile-custom">
            <div id="rich-media-placement"></div>
            <div class="cust">
                <div class="tabc-custom">
                        <div class="cus-custome">
                            <div class="tab-wrap" name="장비변경 탭">
                                    <input type="radio" id="tab1" name="tabGroup2" class="tab" defaultChecked></input>
                                    <label for="tab1"><span>장비</span></label>
                        
                                    <input type="radio" id="tab2" name="tabGroup2" class="tab"></input>
                                    <label for="tab2"><span>아바타</span></label>
                        
                                    <input type="radio" id="tab3" name="tabGroup2" class="tab"></input>
                                    <label for="tab3"><span>탈리스만</span></label>


                                    <div class="tab__content" name="장비">
                                        <div class="c_equip">

                                        <div class="c_equip_setpoint">
                                            <div class="c-e-sepoint-tr cespo-header">
                                                
                                                <span>세트포인트</span>
                                            </div>
                                            <div class="c-e-sepoint-tr cespo-point">
                                                <span>{setPoint}</span>
                                            </div>
                                        
                                        </div>
                            
                                        <div class="cep">
                                            <ul>
                                                <PresentEquip number={3}/>
                                                <PresentEquip number={2}/>
                                                <PresentEquip number={4}/>
                                                <PresentEquip number={6}/>
                                                <PresentEquip number={5}/>
                                            
                                            </ul>
                                        </div>
                                        <div class="cep cepr">
                                            <ul>
                                                <PresentEquip number={0}/>
                                                { characterObj.buffCal != null ? <PlusTitle number={1}/> : <PresentEquip number={1}/> }
                                                
                                                
                                                <PresentEquip number={8}/>
                                                <PresentEquip number={7}/>
                                                <PresentEquip number={10}/>
                                                <PresentEquip number={9}/>
                                                <PresentEquip number={12}/>
                                                <PresentEquip number={11}/>
                                            </ul>
                                        </div>
                                        {
                                            characterObj.buffCal != null ? 
                                            <div class="cep cepr epuip-plus">
                                                <ul>
                                                    <PlusTitle number={115}/>
                                                    <PlusEquip number={113}/>
                                                    <PlusEquip number={114}/>

                                                </ul>
                                            </div>
                                            : <></>

                                        }
                                        {
                                            characterData.job == '검제' ? 
                                            <div class="cep cepr epuip-plus">
                                                <ul>
                                                    <SubWeapon number={116}/>
                                                </ul>
                                            </div>
                                            : <></>
                                        }

                                
                                        </div>

                                        <div class="cus_button" onClick={()=>{submitSetting()}}>
                                            <div class="c_but">
                                                <span className='cbut-tag'  >
                                                적용
                                                </span>
                                            </div>
                                        </div>

                                        <div class="cus_de-cep small-device-fix">
                                            <div class="cset">
                                                <ul>
                                                    <li onClick={()=>setPresent(11500)}>115제</li>

                                                    <li onClick={()=>setPresent(100)}>105제</li>
                                                    <li onClick={()=>setPresent(102)}>세트</li> 
                                                    <li onClick={()=>setPresent(101)}>융합장비</li>
                                                </ul>
                                            </div>
                                        </div>

                                        {
                                        //115제 장비변경
                                        }
                                        <div class="raid" style={present != 11500 ? {display : 'none'} : {}}>
                                            <div class="cus_elst">
                                                <div class="cus_ellist">
                                                    <div class="ems-sel-list">
                                                        <div class="eslist-con-hug">
                                                            <div class="eslist-con">
                                                                <ul>
                                                                    <li class={itemRarity == 0 ? "is-active" :""} onClick ={()=>{setItemRarity(0) }}>
                                                                        <span>레어</span>
                                                                    </li>
                                                                    <li class={itemRarity == 1 ? "is-active" :""} onClick ={()=>{setItemRarity(1) }}>
                                                                        <span>유니크</span>
                                                                    </li>
                                                                    <li class={itemRarity == 2 ? "is-active" :""} onClick ={()=>{setItemRarity(2) }}>
                                                                        <span>레전더리</span>
                                                                    </li>
                                                                    <li class={itemRarity == 3 ? "is-active" :""} onClick ={()=>{setItemRarity(3) }}>
                                                                        <span>에픽</span>
                                                                    </li>
                                                                    <li class={itemRarity == 5 ? "is-active" :""} onClick ={()=>{setItemRarity(5) }}>
                                                                        <span>태초</span>
                                                                    </li>
                                                                    <li class={itemRarity == 4 ? "is-active" :""} onClick ={()=>{setItemRarity(4) }}>
                                                                        <span>흑아</span>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="cus_epdt e105ep e115ep e105mob e110mob">
                                            {
                                                //레어일땐
                                                
                                                itemRarity == 0 ?  
                                                <div class="e115ep-mo-box">
                                                    <div class="e115-m-set-boc">
                                                        <div class="cus_elst">
                                                            <ul>
                                                                <li class="">
                                                                    <div class="imgt">
                                                                        <img src="../img/set-test-img.png"/>
                                                                    </div>
                                                                </li>
                                                                <span class="cuep115-set-name">고유</span> 
                                                
                                                            </ul>
                                                        </div>

                                                        <div class="e115-color-anon small-device-fix">
                                                            <div class="c-anonuce-box">
                                                                <span>방어구</span>
                                                                <span>악세서리</span>
                                                                <span>특수장비</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="e115-m-Shield-boc">
                                                        <div class="cus_elst">
                                                            <ul>
                                                                
                                                                <li className={(characterData['equipName2'] == 'rare'+"_coat" ? "on" : "" )}>
                                                                    <div class="imgt">
                                                                        <img src={"https://dundam.xyz/img/lv115/coat/"+'rare'+"_coat.png?_v=10"} onClick={()=>{lv115EquipChange(2,'rare'+"_coat")}}/>
                                                                    </div>
                                                                </li>
                                                                <li className={(characterData['equipName4'] == 'rare'+"_pants" ? "on" : "" )}>
                                                                    <div class="imgt">
                                                                        <img src={"https://dundam.xyz/img/lv115/pants/"+'rare'+"_pants.png?_v=10"} onClick={()=>{lv115EquipChange(4,'rare'+"_pants")}}/>
                                                                    </div>
                                                                </li>
                                                                <li className={(characterData['equipName3'] == 'rare'+"_c_neck" ? "on" : "" )}>
                                                                    <div class="imgt">
                                                                        <img src={"https://dundam.xyz/img/lv115/neck/"+'rare'+"_c_neck.png?_v=10"} onClick={()=>{lv115EquipChange(3,'rare'+"_c_neck")}}/>
                                                                    </div>
                                                                </li>
                                                                <li className={(characterData['equipName6'] == 'rare'+"_belt" ? "on" : "" )}>
                                                                    <div class="imgt">
                                                                        <img src={"https://dundam.xyz/img/lv115/belt/"+'rare'+"_belt.png?_v=10"} onClick={()=>{lv115EquipChange(6,'rare'+"_belt")}}/>
                                                                    </div>
                                                                </li>
                                                                <li className={(characterData['equipName5'] == 'rare'+"_shoes" ? "on" : "" )}>
                                                                    <div class="imgt">
                                                                        <img src={"https://dundam.xyz/img/lv115/shoes/"+'rare'+"_shoes.png?_v=10"} onClick={()=>{lv115EquipChange(5,'rare'+"_shoes")}}/>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                            
                                                        </div>

                                                    </div>
                                                    <div class="e115-m-acc-boc">
                                                        <div class="part-ul">
                                                            <div class="e115-ac-acc-part">
                                                                <div class="cus_elst">
                                                                    <ul>
                                                                    <li className={(characterData['equipName8'] == 'rare'+"_brac" ? "on" : "" )}>
                                                                        <div class="imgt">
                                                                            <img src={"https://dundam.xyz/img/lv115/bracelet/"+('rare')+"_brac.png?_v=10"} onClick={()=>{lv115EquipChange(8,'rare'+"_brac")}}/>
                                                                        </div>
                                                                    </li>
                                                                    <li className={(characterData['equipName7'] == 'rare'+"_neck" ? "on" : "" )}>
                                                                        <div class="imgt">
                                                                            <img src={"https://dundam.xyz/img/lv115/necklace/"+('rare')+"_neck.png?_v=10"} onClick={()=>{lv115EquipChange(7,'rare'+"_neck")}}/>
                                                                        </div>
                                                                    </li>
                                                                    <li className={(characterData['equipName9'] == 'rare'+"_ring" ? "on" : "" )}>
                                                                        <div class="imgt">
                                                                            <img src={"https://dundam.xyz/img/lv115/ring/"+('rare')+"_ring.png?_v=10"} onClick={()=>{lv115EquipChange(9,'rare'+"_ring")}}/>
                                                                        </div>
                                                                    </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div class="e115-ac-unusual-part">
                                                                <div class="cus_elst">
                                                                    
                                                                    <ul>
                                                                    <li className={(characterData['equipName10'] == 'rare'+"_sup" ? "on" : "" )}>
                                                                        <div class="imgt">
                                                                            <img src={"https://dundam.xyz/img/lv115/support/"+('rare')+"_sup.png?_v=10"} onClick={()=>{lv115EquipChange(10,'rare'+"_sup")}}/>
                                                                        </div>
                                                                    </li>
                                                                    <li className={(characterData['equipName11'] == 'rare'+"_ms" ? "on" : "" )}>
                                                                        <div class="imgt">
                                                                            <img src={"https://dundam.xyz/img/lv115/magicstone/"+('rare')+"_ms.png?_v=10"} onClick={()=>{lv115EquipChange(11,'rare'+"_ms")}}/>
                                                                        </div>
                                                                    </li>
                                                                    <li className={(characterData['equipName12'] == 'rare'+"_ear" ? "on" : "" )}>
                                                                        <div class="imgt">
                                                                            <img src={"https://dundam.xyz/img/lv115/earrring/"+('rare')+"_ear.png?_v=10"} onClick={()=>{lv115EquipChange(12,'rare'+"_ear")}}/>
                                                                        </div>
                                                                    </li>
                                                                    </ul>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                                :<></>
                                                
                                            }
                                            {
                                                itemRarity != 0 && lv115ImageOrderList.map((a,i) => {
                                                    let src ="";
                                                    let accSrc ="";
                                                    let speSrc ="";

                                                    let temp = lv115ArmourImage[a].split("_")[1];
                                                    let value = Number(lv115ArmourImage[a].split("_")[0]);
                                                    src = (value+itemRarity-1)+"_"+temp;
                                                    accSrc = (lv115AccAndSpeImage[a]-1) * 6 + itemRarity;
                                                    speSrc = (lv115AccAndSpeImage[a]-1) * 3 + itemRarity;

                                                    if(itemRarity > 0 && ((itemRarity >= 4 && i != 0) || itemRarity < 4))
                                                    return(
                                                        <>
                                                            <div class="e115ep-mo-box">
                                                                <div class="e115-m-set-boc">
                                                                    <div class="cus_elst">
                                                                        <ul>
                                                                            <li class="">
                                                                                <div class="imgt">
                                                                                        <img style={{width: '24px',height: '28px',overflow: 'hidden','object-fit': 'cover'}} src={"https://dundam.xyz/img/lv115/set/"+lv115AccAndSpeImage[a]+".png"}/>
                                                                                </div>
                                                                            </li>
                                                                            <span class="cuep115-set-name">{a}</span> 
                                                            
                                                                        </ul>
                                                                    </div>

                                                                    <div class="e115-color-anon small-device-fix">
                                                                        <div class="c-anonuce-box">
                                                                            <span>방어구</span>
                                                                            <span>악세서리</span>
                                                                            <span>{itemRarity==4 ? "태초" : "특수장비"}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="e115-m-Shield-boc">
                                                                    <div class="cus_elst">
                                                                        { 
                                                                        itemRarity > 0 && itemRarity < 4 ?
                                                                        <ul>
                                                                            <li className={(characterData['equipName2'] == src+"_coat" ? "on" : "" )}>
                                                                                <div class="imgt">
                                                                                    <img src={"https://dundam.xyz/img/lv115/coat/"+src+"_coat.png?_v=10"} onClick={()=>{lv115EquipChange(2,src+"_coat")}}/>
                                                                                </div>
                                                                            </li>
                                                                            <li className={(characterData['equipName4'] == src+"_pants" ? "on" : "" )}>
                                                                                <div class="imgt">
                                                                                    <img src={"https://dundam.xyz/img/lv115/pants/"+src+"_pants.png?_v=10"} onClick={()=>{lv115EquipChange(4,src+"_pants")}}/>
                                                                                </div>
                                                                            </li>
                                                                            <li className={(characterData['equipName3'] == src+"_neck" ? "on" : "" )}>
                                                                                <div class="imgt">
                                                                                    <img src={"https://dundam.xyz/img/lv115/neck/"+src+"_neck.png?_v=10"} onClick={()=>{lv115EquipChange(3,src+"_neck")}}/>
                                                                                </div>
                                                                            </li>
                                                                            <li className={(characterData['equipName6'] == src+"_belt" ? "on" : "" )}>
                                                                                <div class="imgt">
                                                                                    <img src={"https://dundam.xyz/img/lv115/belt/"+src+"_belt.png?_v=10"} onClick={()=>{lv115EquipChange(6,src+"_belt")}}/>
                                                                                </div>
                                                                            </li>
                                                                            <li className={(characterData['equipName5'] == src+"_shoes" ? "on" : "" )}>
                                                                                <div class="imgt">
                                                                                    <img src={"https://dundam.xyz/img/lv115/shoes/"+src+"_shoes.png?_v=10"} onClick={()=>{lv115EquipChange(5,src+"_shoes")}}/>
                                                                                </div>
                                                                            </li>
                                                                        </ul>
                                                                        : <></> }
                                                                        
                                                                    </div>

                                                                </div>
                                                                <div class="e115-m-acc-boc">
                                                                    <div class="part-ul">
                                                                        <div class="e115-ac-acc-part">
                                                                            <div class="cus_elst">
                                                                                <ul>
                                                                                    <li className={(characterData['equipName8'] == accSrc+"_brac" ? "on" : "" )}>
                                                                                        <div class="imgt">
                                                                                            <img src={"https://dundam.xyz/img/lv115/bracelet/"+(accSrc)+"_brac.png?_v=10"} onClick={()=>{lv115EquipChange(8,accSrc+"_brac")}}/>
                                                                                        </div>
                                                                                    </li>
                                                                                    <li className={(characterData['equipName7'] == accSrc+"_neck" ? "on" : "" )}>
                                                                                        <div class="imgt">
                                                                                            <img src={"https://dundam.xyz/img/lv115/necklace/"+(accSrc)+"_neck.png?_v=10"} onClick={()=>{lv115EquipChange(7,accSrc+"_neck")}}/>
                                                                                        </div>
                                                                                    </li>
                                                                                    <li className={(characterData['equipName9'] == accSrc+"_ring" ? "on" : "" )}>
                                                                                        <div class="imgt">
                                                                                            <img src={"https://dundam.xyz/img/lv115/ring/"+(accSrc)+"_ring.png?_v=10"} onClick={()=>{lv115EquipChange(9,accSrc+"_ring")}}/>
                                                                                        </div>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                        <div class="e115-ac-unusual-part">
                                                                            <div class="cus_elst">
                                                                                {itemRarity > 0 && itemRarity < 4 ?
                                                                                <ul>
                                                                                    <li className={(characterData['equipName10'] == speSrc+"_sup" ? "on" : "" )}>
                                                                                        <div class="imgt">
                                                                                            <img src={"https://dundam.xyz/img/lv115/support/"+(speSrc)+"_sup.png?_v=10"} onClick={()=>{lv115EquipChange(10,speSrc+"_sup")}}/>
                                                                                        </div>
                                                                                    </li>
                                                                                    <li className={(characterData['equipName11'] == speSrc+"_ms" ? "on" : "" )}>
                                                                                        <div class="imgt">
                                                                                            <img src={"https://dundam.xyz/img/lv115/magicstone/"+(speSrc)+"_ms.png?_v=10"} onClick={()=>{lv115EquipChange(11,speSrc+"_ms")}}/>
                                                                                        </div>
                                                                                    </li>
                                                                                    <li className={(characterData['equipName12'] == speSrc+"_ear" ? "on" : "" )}>
                                                                                        <div class="imgt">
                                                                                            <img src={"https://dundam.xyz/img/lv115/earrring/"+(speSrc)+"_ear.png?_v=10"} onClick={()=>{lv115EquipChange(12,speSrc+"_ear")}}/>
                                                                                        </div>
                                                                                    </li>
                                                                                </ul>:<></>
                                                                                }
                                                                                {itemRarity == 4 ?
                                                                                <ul>
                                                                                    <li className={(characterData['equipName8'] == (accSrc+2)+"_brac" ? "on" : "" )}>
                                                                                        <div class="imgt">
                                                                                            <img src={"https://dundam.xyz/img/lv115/bracelet/"+(accSrc+2)+"_brac.png?_v=10"} onClick={()=>{lv115EquipChange(8,(accSrc+2)+"_brac")}}/>
                                                                                        </div>
                                                                                    </li>
                                                                                    <li className={(characterData['equipName7'] == (accSrc+2)+"_neck" ? "on" : "" )}>
                                                                                        <div class="imgt">
                                                                                            <img src={"https://dundam.xyz/img/lv115/necklace/"+(accSrc+2)+"_neck.png?_v=10"} onClick={()=>{lv115EquipChange(7,(accSrc+2)+"_neck")}}/>
                                                                                        </div>
                                                                                    </li>
                                                                                    <li className={(characterData['equipName9'] == (accSrc+2)+"_ring" ? "on" : "" )}>
                                                                                        <div class="imgt">
                                                                                            <img src={"https://dundam.xyz/img/lv115/ring/"+(accSrc+2)+"_ring.png?_v=10"} onClick={()=>{lv115EquipChange(9,(accSrc+2)+"_ring")}}/>
                                                                                        </div>
                                                                                    </li>
                                                                                </ul>:<></>
                                                                                }
                                                                            
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                
                                                            </div>
                                                        </>

                                                    )
                                                })
                                            }
                                            </div>
                                        </div>


                                        <div class="raid" style={present != 101 ? {display : 'none'} : {}}>

                                            <div class="cus_epdt accSpe apev2 e105ep">
                                                <div class="tabc">
                                                    <div class="tab-wrap" name="105제 110제 전환 탭">

                                                        <input type="radio" id="tab42" name="tabGroup3" class="tab" defaultChecked></input>
                                                        
                                                        <div class="tab__content" name="110제">
                                                            <div class="cus_elst">
                                                            {/*
                                                                <div class="cus_eltit_v2 e-m-set">
                                                                    <span>110제</span>
                                                                </div>
                                                                            */}
                                                                <div class="cus_ellist">

                                                                    <div class="ems-sel-list">
                                                                        <div class="eslist-con-hug">
                                                                            <div class="eslist-con">
                                                                                <ul>
                                                                                    <li class={selectFusionType == 0 ? "is-active" :""} onClick ={()=>{setSelectFusionType(0) }}>
                                                                                        <span>아스라한</span>
                                                                                    </li>
                                                                                    <li class={selectFusionType == 1 ? "is-active" :""} onClick ={()=>{setSelectFusionType(1) }}>
                                                                                        <span>융합석</span>
                                                                                    </li>
                                                                                    <li class={selectFusionType == 2 ? "is-active" :""} onClick ={()=>{setSelectFusionType(2) }}>
                                                                                        <span>융합석 각인</span>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                
                                                                {
                                                                    //신규 융합석 
                                                                    selectFusionType == 1 ? 
                                                                    <>
                                                                    <div class="ape2-flex">

                                                                        <div class="ape2-split">
                                                                            <ul class="setList">
                                                                                {
                                                                                    fusionStoneList[1].map((a,i)=>
                                                                                        {
                                                                                            return(
                                                                                                <>
                                                                                                    <ul>
                                                                                                        <li class="setName cus_etit">
                                                                                                            <span></span>
                                                                                                        </li>
                                                                                                        {fusionStoneList[2][i] != null ? <FusionStone slot='8' name={fusionStoneList[2][i]} image={lv105ItemId[fusionStoneList[2][i]]} /> : <li class="emptyItem"></li> }
                                                                                                        <FusionStone slot='7' name={fusionStoneList[1][i]} image={lv105ItemId[fusionStoneList[1][i]]} />
                                                                                                        <FusionStone slot='9' name={fusionStoneList[0][i]} image={lv105ItemId[fusionStoneList[0][i]]} />
                                                                                                       
                                                                                                       
                                                                                                     </ul>
                                                                                                </>
                                                                                            )
                                                                                        })
                                                                                }
                                                                            </ul>
                                                                        </div>
                                                                        <div class="ape2-split">

                                                                            <ul class="setList">
                                                                                
                                                                                {
                                                                                    fusionStoneList[3].map((a,i)=>
                                                                                    {
                                                                                        return(
                                                                                            <>
                                                                                                <ul>
                                                                                                    <li class="setName cus_etit">
                                                                                                        <span></span>
                                                                                                    </li>
                                                                                                    <FusionStone slot='10' name={fusionStoneList[3][i]} image={lv105ItemId[fusionStoneList[3][i]]} />
                                                                                                    <FusionStone slot='11' name={fusionStoneList[4][i]} image={lv105ItemId[fusionStoneList[4][i]]} />
                                                                                                    <FusionStone slot='12' name={fusionStoneList[5][i]} image={lv105ItemId[fusionStoneList[5][i]]} />
                                                                                                </ul>
                                                                                    
                                                                                            </>
                                                                                        )
                                                                                    })
                                                                                }
                                                                               
                                                                            
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                    {characterData['siroco10'] == '영원의 조각 - 보조장비' ?  <LegendStone slot='10'/> : <></>}
                                                                    {characterData['siroco11'] == '영원의 조각 - 마법석' ?  <LegendStone slot='11'/> : <></>}
                                                                    {characterData['siroco12'] == '영원의 조각 - 귀걸이' ?  <LegendStone slot='12'/> : <></>}
                                                                    </>
                                                                    :
                                                                    <>
                                                                    </>
                                                                }
                                                                
                                                                
                                                                {
                                                                    //기존 융합석
                                                                    
                                                                    //기존 융합석
                                                                    selectFusionType == 0 ? 
                                                                    <>
                                                                    <div class="ape-bacal ape2-block">
                                                                        <ul class="setList">
                                                                            <li class="setName cus_etit"><span>열화</span></li>
                                                                            <FusionEquip slot='2' setName="열화" name='열화 : 흔들린 불꽃의 이성' image="8c61142a45d62fe0d7026dbcac044810" />
                                                                            <FusionEquip slot='4' setName="열화" name='열화 : 엉겨붙은 의지' image="6bab8cc6a330eb195775b22765a9c319" />
                                                                            <FusionEquip slot='3' setName="열화" name='열화 : 밀려오는 불길' image="d61a06e03d9d71e7e796feb0979873fe" />
                                                                            <FusionEquip slot='6' setName="열화" name='열화 : 끝없는 타오름' image="3d70c6e7769d3f0279393bb3af1af587" />
                                                                            <FusionEquip slot='5' setName="열화" name='열화 : 내디딘 마지막 발걸음' image="b0f88a406788da99debe08ec96f0dbd8" />
                                                                            </ul>
                                                                        <ul class="setList">
                                                                            <li class="setName cus_etit"><span>설경</span></li>
                                                                            <FusionEquip slot='2' setName="설경" name='설경 : 스며드는 한기' image="d8f457479508ba3159430e137d99f52f" />
                                                                            <FusionEquip slot='4' setName="설경" name='설경 : 고요한 단말마' image="d52deb46528d91d333306bac701226ec" />
                                                                            <FusionEquip slot='3' setName="설경" name='설경 : 얼어붙는 감각' image="dc62f7c8c83de81a91b6fe07e64a0a84" />
                                                                            <FusionEquip slot='6' setName="설경" name='설경 : 정할 수 없는 운명' image="eba306ed78063847b3943dc44b1d40e9" />
                                                                            <FusionEquip slot='5' setName="설경" name='설경 : 남겨지지 않는 발자국' image="d82f1b5562bfca6f5003640175742d0f" />
                                                                        </ul>
                                                                        <ul class="setList">
                                                                            <li class="setName cus_etit"><span>대류</span></li>
                                                                            <FusionEquip slot='2' setName="대류" name='대류 : 몰아치기 전의 고요함' image="44e90b09a8bbab556f4958feb6168663" />
                                                                            <FusionEquip slot='4' setName="대류" name='대류 : 휩쓸려 지나간 흔적' image="bd35b37823d4109d3d62fea1b25fad5c" />
                                                                            <FusionEquip slot='3' setName="대류" name='대류 : 변덕스러운 흐름' image="0fb48a898584f41764bbd49b14f88d87" />
                                                                            <FusionEquip slot='6' setName="대류" name='대류 : 제어할 수 없는 돌풍' image="3c8e697eee47723378749a502136174f" />
                                                                            <FusionEquip slot='5' setName="대류" name='대류 : 주어진 바람의 무게' image="be92b91c14836f2d5e0c5f899793a07d" />
                                                                        </ul>
                                                                        <ul class="setList">
                                                                            <li class="setName cus_etit"><span>우레</span></li>
                                                                            <FusionEquip slot='2' setName="우레" name='우레 : 대지를 뒤덮는 암운' image="9037932df635f5cc5a6fc7f036e0d569" />
                                                                            <FusionEquip slot='4' setName="우레" name='우레 : 뒤따르는 고동' image="a50ec36485ce4d6b1fc569cca0710113" />
                                                                            <FusionEquip slot='3' setName="우레" name='우레 : 곤두서는 감각' image="2c786522e7909a0a3addd7aa129bcc41" />
                                                                            <FusionEquip slot='6' setName="우레" name='우레 : 하늘에 새겨진 흉터' image="10e021b102c7d24e0fb75a7fd1e2cbd7" />
                                                                            <FusionEquip slot='5' setName="우레" name='우레 : 구름을 향한 발돋움' image="64670f9c086ab784afb049fc0e5cbf23" />
                                                                        </ul>
                                                                        <ul class="setList">
                                                                            <li class="setName cus_etit"><span>대지</span></li>
                                                                            <FusionEquip slot='2' setName="대지" name='대지 : 생명이 돋아나는 땅' image="cfb10e82f068a4afa752d8e15fb1349c" />
                                                                            <FusionEquip slot='4' setName="대지" name='대지 : 죽음이 내려앉는 시간' image="3b0a259d06f696b63f1ce3eaca4abc52" />
                                                                            <FusionEquip slot='3' setName="대지" name='대지 : 피어나는 계절' image="bcafa147938ebfc88bea72187b0d8fa7" />
                                                                            <FusionEquip slot='6' setName="대지" name='대지 : 삶과 죽음의 교차' image="84d9918a4fb186deff8fe8678e236b60" />
                                                                            <FusionEquip slot='5' setName="대지" name='대지 : 바스러지는 발걸음' image="6bbbaaa2dc770708ac755462d33c2c18" />
                                                                        </ul>
                                                                    
                                                                    </div>
                                                                    
                                                                    
                                                                    </>

                                                                    :
                                                                    <></>
                                                                }
                                                                

                                                                {
                                                                    //융합석 각인 07-10 
                                                                    
                                                                    selectFusionType == 2 ? 
                                                                    <>


                                                                        <div class="fusion-stone-engraving-main">
                                                                            <div class="fsem-hug fsem">
                                                                                <div class="fsem-con">
                                                                                    {/* <div class="eset_legendstone_optionadd">
                                                                                        <div class="et-lston-h-name">
                                                                                            <span> 
                                                                                            <b>팔찌</b> 융합석 각인 
                                                                                            </span>
                                                                                            
                                                                                        </div>
                                                                                        <div class="fsem-content-part">
                                                                                            <div class="fsem-item">
                                                                                                <div class="abtit"><img src="https://img-api.neople.co.kr/df/items/53a02e917af9ccf63720982e561db8f0">
                                                                                                </img>
                                                                                                    <span class="abt_name">보호를 담은 괴</span></div>
                                                                                            </div>
                                                                                            <div class="fsem-item-engraving-set">
                                                                                                <div class="fieset-con">
                                                                                                    <div class="fieset-op">
                                                                                                    
                                                                                                        <div class="fieset-op-set">
                                                                                                            <div class="fiopset">
                                                                                                                <div class="fiopset-ul">
                                                                                                                    <div class="fioset-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_s.png"></img>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div class="fioset-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_s.png"></img>

                                                                                                                        </div>

                                                                                                                    </div>
                                                                                                                    <div class="fioset-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_s.png"></img>

                                                                                                                        </div>
                                                                                                                    </div>

                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div class="fieset-op-tit">
                                                                                                            <span>1옵션</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div class="fieset-op">
                                                                                                     
                                                                                                        <div class="fieset-op-set">
                                                                                                            <div class="fiopset">
                                                                                                                    <div class="fiopset-ul">
                                                                                                                        <div class="fioset-li">
                                                                                                                            <div class="fioset-first-engraving">
                                                                                                                                <img src="img/engraving_f.png"></img>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div class="fioset-li">
                                                                                                                            <div class="fioset-first-engraving">
                                                                                                                                <img src="img/engraving_f.png"></img>

                                                                                                                            </div>

                                                                                                                        </div>
                                                                                                                        <div class="fioset-li">
                                                                                                                            <div class="fioset-first-engraving">
                                                                                                                                <img src="img/engraving_f.png"></img>

                                                                                                                            </div>
                                                                                                                        </div>

                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div class="fieset-op-tit">
                                                                                                            <span>2옵션</span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                       
                                                                                                    <div class="fieset-op">
                                                                                                  
                                                                                                        <div class="fieset-op-set">
                                                                                                            <div class="fiopset">
                                                                                                                    <div class="fiopset-ul">
                                                                                                                        <div class="fioset-li">
                                                                                                                            <div class="fioset-first-engraving">
                                                                                                                                <img src="img/engraving_f.png"></img>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div class="fioset-li">
                                                                                                                            <div class="fioset-first-engraving">
                                                                                                                                <img src="img/engraving_f.png"></img>

                                                                                                                            </div>

                                                                                                                        </div>
                                                                                                                        <div class="fioset-li">
                                                                                                                            <div class="fioset-first-engraving">
                                                                                                                                <img src="img/engraving_f.png"></img>

                                                                                                                            </div>
                                                                                                                        </div>

                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div class="fieset-op-tit">
                                                                                                                <span>3옵션</span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                      
                                                                                                    
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div> */}


{
                                                                                        Array.from({length: 6}, (v, i) => 0).map((a,i) =>{

                                                                                            let slotNumber = 0;
                                                                                            let slotName = "";

                                                                                            switch(i)
                                                                                            {
                                                                                                case 0: slotName = '팔찌'; slotNumber = 8; break;
                                                                                                case 1: slotName = '목걸이'; slotNumber = 7; break;
                                                                                                case 2: slotName = '보조장비'; slotNumber = 10; break;
                                                                                                case 3: slotName = '반지'; slotNumber = 9; break;
                                                                                                case 4: slotName = '귀걸이'; slotNumber = 12; break;
                                                                                                case 5: slotName = '마법석'; slotNumber = 11; break;
                                                                                            }
                                                                                            return(

                                                                                                <>
                                                                                                <div class="eset_legendstone_optionadd">
                                                                                                    <div class="et-lston-h-name">
                                                                                                        <span> 
                                                                                                        <b>{slotName}</b> 융합석 각인 
                                                                                                        </span>
                                                                                                        
                                                                                                    </div>
                                                                                                    <div class="fsem-content-part fsem_vertical_v2">
                                                                                                        <div class="fsem-item-engraving-set">
                                                                                                            <div class="fieset-con">
                                                                                                                {
                                                                                                                    Array.from({length: 3}, (a, loop) => 0).map((a,loop)=>
                                                                                                                    {
                                                                                                                        var image =  loop >= 1 ? "img/engraving_f.png" : 'img/engraving_s.png';
                                                                                                                        //img/engraving_f.png
                                                                                                                        return(
                                                                                                                        <div class="fieset-op">
                                                                                                                        
                                                                                                                            <div class="fieset-op-set">
                                                                                                                                <div class="fiopset_vertical">
                                                                                                                                <div class="fiopset_vertical-tit">
                                                                                                                                        <span>{loop+1}옵션</span>
                                                                                                                                    </div>
                                                                                                                                    <div class="fiopset_vertical-ul">
                                                                                                                                        {
                                                                                                                                            Array.from({length: 3}, (v, i) => 0).map((b,i)=>
                                                                                                                                            {
                                                                                                                                                if(characterData['siroco'+slotNumber+'up'+loop] != null)
                                                                                                                                                {
                                                                                                                                                    return(
                                                                                                                                                        <div onClick={()=>{characterData['siroco'+slotNumber+'up'+loop] == (i+1) ? change('siroco'+slotNumber+'up'+loop,0) : change('siroco'+slotNumber+'up'+loop,i+1) }} class={("fioset-vertical-li" + (characterData['siroco'+slotNumber+'up'+loop] >= i+1 ? " is-active" : "") )}>
                                                                                                                                                            <div class="fioset-first-engraving">
                                                                                                                                                                <img src={image}></img>
                                                                                                                                                            </div>
                                                                                                                                                        </div>
                                                                                                                                                    )
                                                                                                                                                }
                                                                                                                                                else
                                                                                                                                                {
                                                                                                                                                    return(
                                                                                                                                                        <div onClick={()=>{change('siroco'+slotNumber+'up'+loop,i+1)}}  class={("fioset-vertical-li")}>
                                                                                                                                                            <div class="fioset-first-engraving">
                                                                                                                                                                <img src={image}></img>
                                                                                                                                                            </div>
                                                                                                                                                        </div>
                                                                                                                                                    )
                                                                                                                                                }
                                                                                                                                            })

                                                                                                                                        }
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        
                                                                                                                        </div>
                                                                                                                        )
                                                                                                                    }
                                                                                                                    )
                                                                                                                }
                                                                                                                {/* <div class="fieset-op">
                                                                                                                
                                                                                                                    <div class="fieset-op-set">
                                                                                                                        <div class="fiopset_vertical">
                                                                                                                            <div class="fiopset_vertical-tit">
                                                                                                                                <span>2옵션</span>
                                                                                                                            </div>

                                                                                                                            <div class="fiopset_vertical-ul">
                                                                                                                                <div class="fioset-vertical-li is-active">
                                                                                                                                    <div class="fioset-first-engraving">
                                                                                                                                        <img src="img/engraving_f.png"></img>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                                <div class="fioset-vertical-li">
                                                                                                                                    <div class="fioset-first-engraving">
                                                                                                                                        <img src="img/engraving_f.png"></img>

                                                                                                                                    </div>

                                                                                                                                </div>
                                                                                                                                <div class="fioset-vertical-li">
                                                                                                                                    <div class="fioset-first-engraving">
                                                                                                                                        <img src="img/engraving_f.png"></img>

                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                
                                                                                                                </div>
                                                                                                                <div class="fieset-op">
                                                                                                                
                                                                                                                    <div class="fieset-op-set">
                                                                                                                        <div class="fiopset_vertical">
                                                                                                                            <div class="fiopset_vertical-tit">
                                                                                                                                <span>3옵션</span>
                                                                                                                            </div>
                                                                                                                            <div class="fiopset_vertical-ul">
                                                                                                                                <div class="fioset-vertical-li">
                                                                                                                                    <div class="fioset-first-engraving">
                                                                                                                                        <img src="img/engraving_f.png"></img>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                                <div class="fioset-vertical-li">
                                                                                                                                    <div class="fioset-first-engraving">
                                                                                                                                        <img src="img/engraving_f.png"></img>

                                                                                                                                    </div>

                                                                                                                                </div>
                                                                                                                                <div class="fioset-vertical-li">
                                                                                                                                    <div class="fioset-first-engraving">
                                                                                                                                        <img src="img/engraving_f.png"></img>

                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                
                                                                                                                </div> */}
                                                                                                                
                                                                                                                
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    
                                                                                                    </div>
                                                                                                </div>
                                                                                                
                                                                                                </>

                                                                                            )

                                                                                        })

                                                                                    }
                                                                                    {/* <div class="eset_legendstone_optionadd">
                                                                                        <div class="et-lston-h-name">
                                                                                            <span> 
                                                                                            <b>팔찌</b> 융합석 각인 
                                                                                            </span>
                                                                                            
                                                                                        </div>
                                                                                        <div class="fsem-content-part fsem_vertical_v2">
                                                                                            <div class="fsem-item-engraving-set">
                                                                                                <div class="fieset-con">
                                                                                                    <div class="fieset-op">
                                                                                                    
                                                                                                        <div class="fieset-op-set">
                                                                                                            <div class="fiopset_vertical">
                                                                                                            <div class="fiopset_vertical-tit">
                                                                                                                    <span>1옵션</span>
                                                                                                                </div>
                                                                                                                <div class="fiopset_vertical-ul">
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_s.png"></img>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_s.png"></img>

                                                                                                                        </div>

                                                                                                                    </div>
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_s.png"></img>

                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                     
                                                                                                    </div>
                                                                                                    <div class="fieset-op">
                                                                                                    
                                                                                                        <div class="fieset-op-set">
                                                                                                            <div class="fiopset_vertical">
                                                                                                                <div class="fiopset_vertical-tit">
                                                                                                                    <span>2옵션</span>
                                                                                                                </div>

                                                                                                                <div class="fiopset_vertical-ul">
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_f.png"></img>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_f.png"></img>

                                                                                                                        </div>

                                                                                                                    </div>
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_f.png"></img>

                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                     
                                                                                                    </div>
                                                                                                    <div class="fieset-op">
                                                                                                    
                                                                                                        <div class="fieset-op-set">
                                                                                                            <div class="fiopset_vertical">
                                                                                                                <div class="fiopset_vertical-tit">
                                                                                                                    <span>3옵션</span>
                                                                                                                </div>
                                                                                                                <div class="fiopset_vertical-ul">
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_f.png"></img>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_f.png"></img>

                                                                                                                        </div>

                                                                                                                    </div>
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_f.png"></img>

                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                     
                                                                                                    </div>
                                                                                                      
                                                                                                    
                                                                                                </div>
                                                                                            </div>
                                                                                          
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="eset_legendstone_optionadd">
                                                                                        <div class="et-lston-h-name">
                                                                                            <span> 
                                                                                            <b>팔찌</b> 융합석 각인 
                                                                                            </span>
                                                                                            
                                                                                        </div>
                                                                                        <div class="fsem-content-part fsem_vertical_v2">
                                                                                            <div class="fsem-item-engraving-set">
                                                                                                <div class="fieset-con">
                                                                                                    <div class="fieset-op">
                                                                                                    
                                                                                                        <div class="fieset-op-set">
                                                                                                            <div class="fiopset_vertical">
                                                                                                            <div class="fiopset_vertical-tit">
                                                                                                                    <span>1옵션</span>
                                                                                                                </div>
                                                                                                                <div class="fiopset_vertical-ul">
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_s.png"></img>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_s.png"></img>

                                                                                                                        </div>

                                                                                                                    </div>
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_s.png"></img>

                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                     
                                                                                                    </div>
                                                                                                    <div class="fieset-op">
                                                                                                    
                                                                                                        <div class="fieset-op-set">
                                                                                                            <div class="fiopset_vertical">
                                                                                                                <div class="fiopset_vertical-tit">
                                                                                                                    <span>2옵션</span>
                                                                                                                </div>

                                                                                                                <div class="fiopset_vertical-ul">
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_f.png"></img>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_f.png"></img>

                                                                                                                        </div>

                                                                                                                    </div>
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_f.png"></img>

                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                     
                                                                                                    </div>
                                                                                                    <div class="fieset-op">
                                                                                                    
                                                                                                        <div class="fieset-op-set">
                                                                                                            <div class="fiopset_vertical">
                                                                                                                <div class="fiopset_vertical-tit">
                                                                                                                    <span>3옵션</span>
                                                                                                                </div>
                                                                                                                <div class="fiopset_vertical-ul">
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_f.png"></img>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_f.png"></img>

                                                                                                                        </div>

                                                                                                                    </div>
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_f.png"></img>

                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                     
                                                                                                    </div>
                                                                                                      
                                                                                                    
                                                                                                </div>
                                                                                            </div>
                                                                                          
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="eset_legendstone_optionadd">
                                                                                        <div class="et-lston-h-name">
                                                                                            <span> 
                                                                                            <b>팔찌</b> 융합석 각인 
                                                                                            </span>
                                                                                            
                                                                                        </div>
                                                                                        <div class="fsem-content-part fsem_vertical_v2">
                                                                                            <div class="fsem-item-engraving-set">
                                                                                                <div class="fieset-con">
                                                                                                    <div class="fieset-op">
                                                                                                    
                                                                                                        <div class="fieset-op-set">
                                                                                                            <div class="fiopset_vertical">
                                                                                                            <div class="fiopset_vertical-tit">
                                                                                                                    <span>1옵션</span>
                                                                                                                </div>
                                                                                                                <div class="fiopset_vertical-ul">
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_s.png"></img>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_s.png"></img>

                                                                                                                        </div>

                                                                                                                    </div>
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_s.png"></img>

                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                     
                                                                                                    </div>
                                                                                                    <div class="fieset-op">
                                                                                                    
                                                                                                        <div class="fieset-op-set">
                                                                                                            <div class="fiopset_vertical">
                                                                                                                <div class="fiopset_vertical-tit">
                                                                                                                    <span>2옵션</span>
                                                                                                                </div>

                                                                                                                <div class="fiopset_vertical-ul">
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_f.png"></img>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_f.png"></img>

                                                                                                                        </div>

                                                                                                                    </div>
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_f.png"></img>

                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                     
                                                                                                    </div>
                                                                                                    <div class="fieset-op">
                                                                                                    
                                                                                                        <div class="fieset-op-set">
                                                                                                            <div class="fiopset_vertical">
                                                                                                                <div class="fiopset_vertical-tit">
                                                                                                                    <span>3옵션</span>
                                                                                                                </div>
                                                                                                                <div class="fiopset_vertical-ul">
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_f.png"></img>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_f.png"></img>

                                                                                                                        </div>

                                                                                                                    </div>
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_f.png"></img>

                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                     
                                                                                                    </div>
                                                                                                      
                                                                                                    
                                                                                                </div>
                                                                                            </div>
                                                                                          
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="eset_legendstone_optionadd">
                                                                                        <div class="et-lston-h-name">
                                                                                            <span> 
                                                                                            <b>팔찌</b> 융합석 각인 
                                                                                            </span>
                                                                                            
                                                                                        </div>
                                                                                        <div class="fsem-content-part fsem_vertical_v2">
                                                                                            <div class="fsem-item-engraving-set">
                                                                                                <div class="fieset-con">
                                                                                                    <div class="fieset-op">
                                                                                                    
                                                                                                        <div class="fieset-op-set">
                                                                                                            <div class="fiopset_vertical">
                                                                                                            <div class="fiopset_vertical-tit">
                                                                                                                    <span>1옵션</span>
                                                                                                                </div>
                                                                                                                <div class="fiopset_vertical-ul">
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_s.png"></img>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_s.png"></img>

                                                                                                                        </div>

                                                                                                                    </div>
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_s.png"></img>

                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                     
                                                                                                    </div>
                                                                                                    <div class="fieset-op">
                                                                                                    
                                                                                                        <div class="fieset-op-set">
                                                                                                            <div class="fiopset_vertical">
                                                                                                                <div class="fiopset_vertical-tit">
                                                                                                                    <span>2옵션</span>
                                                                                                                </div>

                                                                                                                <div class="fiopset_vertical-ul">
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_f.png"></img>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_f.png"></img>

                                                                                                                        </div>

                                                                                                                    </div>
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_f.png"></img>

                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                     
                                                                                                    </div>
                                                                                                    <div class="fieset-op">
                                                                                                    
                                                                                                        <div class="fieset-op-set">
                                                                                                            <div class="fiopset_vertical">
                                                                                                                <div class="fiopset_vertical-tit">
                                                                                                                    <span>3옵션</span>
                                                                                                                </div>
                                                                                                                <div class="fiopset_vertical-ul">
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_f.png"></img>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_f.png"></img>

                                                                                                                        </div>

                                                                                                                    </div>
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_f.png"></img>

                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                     
                                                                                                    </div>
                                                                                                      
                                                                                                    
                                                                                                </div>
                                                                                            </div>
                                                                                          
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="eset_legendstone_optionadd">
                                                                                        <div class="et-lston-h-name">
                                                                                            <span> 
                                                                                            <b>팔찌</b> 융합석 각인 
                                                                                            </span>
                                                                                            
                                                                                        </div>
                                                                                        <div class="fsem-content-part fsem_vertical_v2">
                                                                                            <div class="fsem-item-engraving-set">
                                                                                                <div class="fieset-con">
                                                                                                    <div class="fieset-op">
                                                                                                    
                                                                                                        <div class="fieset-op-set">
                                                                                                            <div class="fiopset_vertical">
                                                                                                            <div class="fiopset_vertical-tit">
                                                                                                                    <span>1옵션</span>
                                                                                                                </div>
                                                                                                                <div class="fiopset_vertical-ul">
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_s.png"></img>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_s.png"></img>

                                                                                                                        </div>

                                                                                                                    </div>
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_s.png"></img>

                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                     
                                                                                                    </div>
                                                                                                    <div class="fieset-op">
                                                                                                    
                                                                                                        <div class="fieset-op-set">
                                                                                                            <div class="fiopset_vertical">
                                                                                                                <div class="fiopset_vertical-tit">
                                                                                                                    <span>2옵션</span>
                                                                                                                </div>

                                                                                                                <div class="fiopset_vertical-ul">
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_f.png"></img>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_f.png"></img>

                                                                                                                        </div>

                                                                                                                    </div>
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_f.png"></img>

                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                     
                                                                                                    </div>
                                                                                                    <div class="fieset-op">
                                                                                                    
                                                                                                        <div class="fieset-op-set">
                                                                                                            <div class="fiopset_vertical">
                                                                                                                <div class="fiopset_vertical-tit">
                                                                                                                    <span>3옵션</span>
                                                                                                                </div>
                                                                                                                <div class="fiopset_vertical-ul">
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_f.png"></img>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_f.png"></img>

                                                                                                                        </div>

                                                                                                                    </div>
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_f.png"></img>

                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                     
                                                                                                    </div>
                                                                                                      
                                                                                                    
                                                                                                </div>
                                                                                            </div>
                                                                                          
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="eset_legendstone_optionadd">
                                                                                        <div class="et-lston-h-name">
                                                                                            <span> 
                                                                                            <b>팔찌</b> 융합석 각인 
                                                                                            </span>
                                                                                            
                                                                                        </div>
                                                                                        <div class="fsem-content-part fsem_vertical_v2">
                                                                                            <div class="fsem-item-engraving-set">
                                                                                                <div class="fieset-con">
                                                                                                    <div class="fieset-op">
                                                                                                    
                                                                                                        <div class="fieset-op-set">
                                                                                                            <div class="fiopset_vertical">
                                                                                                            <div class="fiopset_vertical-tit">
                                                                                                                    <span>1옵션</span>
                                                                                                                </div>
                                                                                                                <div class="fiopset_vertical-ul">
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_s.png"></img>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_s.png"></img>

                                                                                                                        </div>

                                                                                                                    </div>
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_s.png"></img>

                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                     
                                                                                                    </div>
                                                                                                    <div class="fieset-op">
                                                                                                    
                                                                                                        <div class="fieset-op-set">
                                                                                                            <div class="fiopset_vertical">
                                                                                                                <div class="fiopset_vertical-tit">
                                                                                                                    <span>2옵션</span>
                                                                                                                </div>

                                                                                                                <div class="fiopset_vertical-ul">
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_f.png"></img>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_f.png"></img>

                                                                                                                        </div>

                                                                                                                    </div>
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_f.png"></img>

                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                     
                                                                                                    </div>
                                                                                                    <div class="fieset-op">
                                                                                                    
                                                                                                        <div class="fieset-op-set">
                                                                                                            <div class="fiopset_vertical">
                                                                                                                <div class="fiopset_vertical-tit">
                                                                                                                    <span>3옵션</span>
                                                                                                                </div>
                                                                                                                <div class="fiopset_vertical-ul">
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_f.png"></img>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_f.png"></img>

                                                                                                                        </div>

                                                                                                                    </div>
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_f.png"></img>

                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                     
                                                                                                    </div>
                                                                                                      
                                                                                                    
                                                                                                </div>
                                                                                            </div>
                                                                                          
                                                                                        </div>
                                                                                    </div>
                                                                                    */}
                                                                                    
                                                                                    
                                                                                </div>
                                                                            </div>
                                                                        
                                                                        </div>
                                                                    
                                                                    </>

                                                                    :
                                                                    <></>
                                                                }
                                                                
                                                            </div>

                                                        </div>
                                                        <div class="tab__content">
                                                            <div class="esetting_tr cusp2 o105je" >
                                                                <div class="estcont  cussa" id="weapon1" style={{padding:"unset"}}>

                                                                    <div class="cus_105op" >
                                                                        <div class="option">
                                                                            {characterData['weapon1'] != null ? lv105WeaponOptionMap[characterData['weapon1'] - 1].replace(/<br>/gi, "\n") : ""}

                                                                        </div>

                                                                        <div class="cus_opsetbut" onClick={() => { weaponPlusChange(0, 1) }} >
                                                                            <div class="imgt">
                                                                            <img src="img/set1.svg" />
                                                                            </div>

                                                                        </div>

                                                                    </div>

                                                                    <div class={"cus_opset" + (weaponPlusArray[0] == 1 ? " on" : "")}>
                                                                        <div class="cos_c">
                                                                            <div class="cossc">
                                                                                <div class="searchbox" name="검색창" >
                                                                                    <input type="text" onChange={(event) => weaponOpSearchChange(0, event.target.value)} class="form-control js-bound" placeholder="검색" name="name" maxlength="40" />
                                                                                </div>
                                                                                <div class="searchbut105">
                                                                                    <div class="imgt">
                                                                                        <img src="img/sr_search.svg" />
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                            <div class="coscc">
                                                                                <ul>
                                                                                    {
                                                                                        
                                                                                        lv105WeaponOptionMap ? lv105WeaponOptionMap.map((a, i) => {

                                                                                            if (optionSearch(a,weaponOpSearch[0]) != -1)
                                                                                                return (

                                                                                                    <li key={'weaponOPF' + i} className={weaponPlusSelectArray[0] == i + 1 ? "is-active" : ""} onClick={() => weaponPlusSelectArrayChange(0, i + 1)}>{a.replace(/<br>/gi, '\n')}</li>
                                                                                                )

                                                                                        }) : ""


                                                                                    }
                                                                                </ul>
                                                                            </div>
                                                                            <div class="cos_but">
                                                                                <div class="csbt" slot="1" onClick={() => { if(weaponPlusSelectArray[0] != 0) { change('weapon1', weaponPlusSelectArray[0]); weaponPlusChange(0, 0); } }} >
                                                                                    <span class="cbut-tag" >적용</span>
                                                                                </div>
                                                                                <div class="csbt csbtc" onClick={() => { weaponPlusChange(0, 0) }}>
                                                                                    <span class="cbut-tag">
                                                                                        <div class="imgt">
                                                                                            <img src="img/arrow_go_back2.svg" />
                                                                                        </div>
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>

                                                                
                                                            <div class="esetting_tr cusp2 o105je">
                                                                <div class="estcont  cussa" id="weapon2" style={{padding:"unset"}}>

                                                                    <div class="cus_105op" >
                                                                        <div class="option">
                                                                            {characterData['weapon2'] != null ? lv105WeaponOptionMap[characterData['weapon2'] - 1].replace(/<br>/gi, "\n") : ""}
                                                                        </div>
                                                                        <div class="cus_opsetbut" onClick={() => { weaponPlusChange(1, 1) }}  >
                                                                            <div class="imgt">
                                                                            <img src="img/set1.svg" />
                                                                            </div>

                                                                        </div>

                                                                    </div>

                                                                    <div class={"cus_opset" + (weaponPlusArray[1] == 1 ? " on" : "")}>
                                                                        <div class="cos_c">
                                                                            <div class="cossc">
                                                                                <div class="searchbox" name="검색창">
                                                                                    <input type="text" onChange={(event) => weaponOpSearchChange(1, event.target.value)} class="form-control js-bound" placeholder="검색" name="name" maxlength="40" />
                                                                                </div>
                                                                                <div class="searchbut105">
                                                                                    <div class="imgt">
                                                                                        <img src="img/sr_search.svg" />
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                            <div class="coscc">
                                                                                <ul>
                                                                                    {
                                                                                        lv105WeaponOptionMap ? lv105WeaponOptionMap.map((a, i) => {
                                                                                            if (optionSearch(a,weaponOpSearch[1]) != -1)
                                                                                                return (
                                                                                                    <li key={'weaponOPS' + i} className={weaponPlusSelectArray[1] == i + 1 ? "is-active" : ""} onClick={() => weaponPlusSelectArrayChange(1, i + 1)}>{a.replace(/<br>/gi, '\n')}</li>
                                                                                                )

                                                                                        }) : ""

                                                                                    }
                                                                                </ul>
                                                                            </div>
                                                                            <div class="cos_but">
                                                                                <div class="csbt" slot="1" onClick={() => { if(weaponPlusSelectArray[1] != 0) { change('weapon2', weaponPlusSelectArray[1]); weaponPlusChange(1, 0); }}} >
                                                                                    <span class="cbut-tag" >적용</span>
                                                                                </div>
                                                                                <div class="csbt csbtc" onClick={() => { weaponPlusChange(1, 0) }}>
                                                                                    <span class="cbut-tag">
                                                                                        <div class="imgt">
                                                                                            <img src="img/arrow_go_back2.svg" />
                                                                                        </div>
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>


                                                            <div class="esetting_tr cusp2 o105je">
                                                                <div class="estcont  cussa" id="weapon3" style={{padding:"unset"}}>

                                                                    <div class="cus_105op" >
                                                                        <div class="option">

                                                                            {characterData['weapon3'] != null ? lv105WeaponThirdOptionMap[characterData['weapon3'] - 1].replace(/<br>/gi, "\n") : ""}
                                                                        </div>

                                                                        <div class="cus_opsetbut" onClick={() => { weaponPlusChange(2, 1) }} >
                                                                            <div class="imgt">
                                                                            <img src="img/set1.svg" />
                                                                            </div>

                                                                        </div>

                                                                    </div>

                                                                    <div class={"cus_opset" + (weaponPlusArray[2] == 1 ? " on" : "")}>
                                                                        <div class="cos_c">
                                                                            <div class="cossc">
                                                                                <div class="searchbox" name="검색창">
                                                                                    <input type="text" onChange={(event) => weaponOpSearchChange(2, event.target.value)} class="form-control js-bound" placeholder="검색" name="name" maxlength="40" />
                                                                                </div>
                                                                                <div class="searchbut105">
                                                                                    <div class="imgt">
                                                                                        <img src="img/sr_search.svg" />
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                            <div class="coscc">
                                                                                <ul>
                                                                                    {

                                                                                        lv105WeaponThirdOptionMap ? lv105WeaponThirdOptionMap.map((a, i) => {
                                                                                            if (optionSearch(a,weaponOpSearch[2]) != -1)
                                                                                                return (
                                                                                                    <li key={'weaponOPT' + i} className={weaponPlusSelectArray[2] == i + 1 ? "is-active" : ""} onClick={() => weaponPlusSelectArrayChange(2, i + 1)}>{a.replace(/<br>/gi, '\n')}</li>

                                                                                                )

                                                                                        }) : ""

                                                                                    }
                                                                                </ul>
                                                                            </div>
                                                                            <div class="cos_but">
                                                                                <div class="csbt" slot="1" onClick={() => { if(weaponPlusSelectArray[2] != 0) { change('weapon3', weaponPlusSelectArray[2]); weaponPlusChange(2, 0); }}} >
                                                                                    <span class="cbut-tag" >적용</span>
                                                                                </div>
                                                                                <div class="csbt csbtc" onClick={() => { weaponPlusChange(2, 0) }}>
                                                                                    <span class="cbut-tag">
                                                                                        <div class="imgt">
                                                                                            <img src="img/arrow_go_back2.svg" />
                                                                                        </div>
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>


                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div class="cus_epdt accSpe"> */}
                                                {/* <div class="cus_elst">
                                                
                                                    <div class="cus_eltit">
                                                        <span>이스핀즈</span>
                                                    </div>
                                                    <ul class="setList">
                                                        <li class="setName cus_etit">진룡</li>
                                                        <li className={characterData['siroco'+9] == '진룡' ? " on" : ""} onClick={()=>change('siroco'+9,"진룡")}><div class="imgt raidItem" slot="109"><img name="진룡 : 무거운 철의 투지" src="https://dundam.xyz/img/item/6255f92b7b56a7ddd76f566a47ada800"/></div></li>
                                                        <li className={characterData['siroco'+7] == '진룡' ? " on" : ""} onClick={()=>change('siroco'+7,"진룡")}><div class="imgt raidItem" slot="107"><img name="진룡 : 잔혹한 용의 발톱" src="https://dundam.xyz/img/item/568018f4d9ed013f352d412331ab67ba"/></div></li>
                                                        <li className={characterData['siroco'+8] == '진룡' ? " on" : ""} onClick={()=>change('siroco'+8,"진룡")}><div class="imgt raidItem" slot="108"><img name="진룡 : 압도하는 힘의 원천" src="https://dundam.xyz/img/item/cc64795c07f57cb9e52967063276f795"/></div></li>
                                                    </ul>
                                                    <ul class="setList">
                                                        <li class="setName cus_etit">금룡</li>
                                                        <li className={characterData['siroco'+9] == '금룡' ? " on" : ""} onClick={()=>change('siroco'+9,"금룡")}><div class="imgt raidItem" slot="109"><img name="금룡 : 무한한 빛의 결계" src="https://dundam.xyz/img/item/52688ab6a97da9368e86343d0a3ac4a5"/></div></li>
                                                        <li className={characterData['siroco'+7] == '금룡' ? " on" : ""} onClick={()=>change('siroco'+7,"금룡")}><div class="imgt raidItem" slot="107"><img name="금룡 : 찬란한 용의 비늘" src="https://dundam.xyz/img/item/eeb469cdd183e41318837d2dbd98dd42"/></div></li>
                                                        <li className={characterData['siroco'+8] == '금룡' ? " on" : ""} onClick={()=>change('siroco'+8,"금룡")}><div class="imgt raidItem" slot="108"><img name="금룡 : 화려한 힘의 기원" src="https://dundam.xyz/img/item/5285d14664a9aa41559ca68f440c3909"/></div></li>
                                                    </ul>
                                                    <ul class="setList">
                                                        <li class="setName cus_etit">화룡</li>
                                                        <li className={characterData['siroco'+9] == '화룡' ? " on" : ""} onClick={()=>change('siroco'+9,"화룡")}><div class="imgt raidItem" slot="109"><img name="화룡 : 타오르는 불의 분노" src="https://dundam.xyz/img/item/6f388dd09d7149044abfd92fed63b4a6"/></div></li>
                                                        <li className={characterData['siroco'+7] == '화룡' ? " on" : ""} onClick={()=>change('siroco'+7,"화룡")}><div class="imgt raidItem" slot="107"><img name="화룡 : 끓어오르는 용의 숨결" src="https://dundam.xyz/img/item/73f46c965770a5680576ad52219da4a5"/></div></li>
                                                        <li className={characterData['siroco'+8] == '화룡' ? " on" : ""} onClick={()=>change('siroco'+8,"화룡")}><div class="imgt raidItem" slot="108"><img name="화룡 : 폭발하는 힘의 근원" src="https://dundam.xyz/img/item/0e79738a52d19e87cf910fc16537887b"/></div></li>
                                                    </ul>
                                                    <ul class="setList">
                                                        <li class="setName cus_etit">흑룡</li>
                                                        <li className={characterData['siroco'+9] == '흑룡' ? " on" : ""} onClick={()=>change('siroco'+9,"흑룡")}><div class="imgt raidItem" slot="109"><img name="흑룡 : 끝없는 암흑의 굴레" src="https://dundam.xyz/img/item/1fd082cd996a9eb02645be09c5e89d41"/></div></li>
                                                        <li className={characterData['siroco'+7] == '흑룡' ? " on" : ""} onClick={()=>change('siroco'+7,"흑룡")}><div class="imgt raidItem" slot="107"><img name="흑룡 : 주시하는 용의 눈동자" src="https://dundam.xyz/img/item/27843216918f310d010f1014661c81bd"/></div></li>
                                                        <li className={characterData['siroco'+8] == '흑룡' ? " on" : ""} onClick={()=>change('siroco'+8,"흑룡")}><div class="imgt raidItem" slot="108"><img name="흑룡 : 어두운 힘의 근본" src="https://dundam.xyz/img/item/db3a4579b25e7dbdca33f56195ce259f"/></div></li>
                                                    </ul>
                                                    <ul class="setList">
                                                        <li class="setName cus_etit">천계</li>
                                                        <li className={characterData['siroco'+9] == '천계' ? " on" : ""} onClick={()=>change('siroco'+9,"천계")}><div class="imgt raidItem" slot="109"><img name="천계 연합군 : 밝게 빛나는 희망" src="https://dundam.xyz/img/item/f0683ab71a21f9166ccb3423fa0a928c"/></div></li>
                                                        <li className={characterData['siroco'+7] == '천계' ? " on" : ""} onClick={()=>change('siroco'+7,"천계")}><div class="imgt raidItem" slot="107"><img name="천계 연합군 : 모든 이를 위한 기도" src="https://dundam.xyz/img/item/3b62a91abc21df1a2609af53cb15d432"/></div></li>
                                                        <li className={characterData['siroco'+8] == '천계' ? " on" : ""} onClick={()=>change('siroco'+8,"천계")}><div class="imgt raidItem" slot="108"><img name="천계 연합군 : 꺾이지 않는 의지" src="https://dundam.xyz/img/item/cb41d2e497f1a7ac1ae2711670da9861"/></div></li>
                                                    </ul>
                                                
                                                    <div class="cus_eltit">
                                                        <span>차원회랑</span>
                                                    </div>
                                                    <ul class="setList">
                                                        <li class="setName cus_etit">슈페리어</li>
                                                        <li className={characterData['siroco'+10] == '슈페' ? " on" : ""} onClick={()=>change('siroco'+10,"슈페")}><div class="imgt raidItem" slot="1010"><img name="슈페리어 : 오리진 오브 더 마이어" src="https://dundam.xyz/img/item/a6baa41de89c3a9bf280b65ca5b24b58"/></div></li>
                                                        <li className={characterData['siroco'+11] == '슈페' ? " on" : ""} onClick={()=>change('siroco'+11,"슈페")}><div class="imgt raidItem" slot="1011"><img name="슈페리어 : 매직 서클로" src="https://dundam.xyz/img/item/323e201088055e1ed622be84b7479103"/></div></li>
                                                        <li className={characterData['siroco'+12] == '슈페' ? " on" : ""} onClick={()=>change('siroco'+12,"슈페")}><div class="imgt raidItem" slot="1012"><img name="슈페리어 : 코스믹 이어링" src="https://dundam.xyz/img/item/70558b17d3d49fc8599f8e0d751bce9d"/></div></li>
                                                    </ul>
                                                    <ul class="setList">
                                                        <li class="setName cus_etit">심연의 결집</li>
                                                        <li className={characterData['siroco'+10] == '심연' ? " on" : ""} onClick={()=>change('siroco'+10,"심연")}><div class="imgt raidItem" slot="1010"><img name="심연의 결집 : 정의되지 않는 존재" src="https://dundam.xyz/img/item/56422d257499f7c60c7dad02d0f43db2"/></div></li>
                                                        <li className={characterData['siroco'+11] == '심연' ? " on" : ""} onClick={()=>change('siroco'+11,"심연")}><div class="imgt raidItem" slot="1011"><img name="심연의 결집 : 무한한 수축" src="https://dundam.xyz/img/item/2950e00eced48e19bf4c161b3bc7df7e"/></div></li>
                                                        <li className={characterData['siroco'+12] == '심연' ? " on" : ""} onClick={()=>change('siroco'+12,"심연")}><div class="imgt raidItem" slot="1012"><img name="심연의 결집 : 무정형의 힘" src="https://dundam.xyz/img/item/1ba6221619f88691ed8f0c3adba4db7a"/></div></li>
                                                    </ul>
                                                    <ul class="setList">
                                                        <li class="setName cus_etit">영혼의 구속</li>
                                                        <li className={characterData['siroco'+10] == '영혼' ? " on" : ""} onClick={()=>change('siroco'+10,"영혼")}><div class="imgt raidItem" slot="1010"><img name="영혼의 구속 : 지배하는 권능" src="https://dundam.xyz/img/item/332409131f5fcbb418bff7a0a5dd8a13"/></div></li>
                                                        <li className={characterData['siroco'+11] == '영혼' ? " on" : ""} onClick={()=>change('siroco'+11,"영혼")}><div class="imgt raidItem" slot="1011"><img name="영혼의 구속 : 무거운 업보" src="https://dundam.xyz/img/item/6e1dc4f395dd54d7750ac47b2631518c"/></div></li>
                                                        <li className={characterData['siroco'+12] == '영혼' ? " on" : ""} onClick={()=>change('siroco'+12,"영혼")}><div class="imgt raidItem" slot="1012"><img name="영혼의 구속 : 거스를 수 없는 봉인" src="https://dundam.xyz/img/item/e0e357b5cce04625dc8b0538bd5c8d01"/></div></li>
                                                    </ul>
                                                    <ul class="setList">
                                                        <li class="setName cus_etit">광휘</li>
                                                        <li className={characterData['siroco'+10] == '광휘' ? " on" : ""} onClick={()=>change('siroco'+10,"광휘")}><div class="imgt raidItem" slot="1010"><img name="광휘 : 고귀한 사명" src="https://dundam.xyz/img/item/078ddee1101ba2dd3f62f7b2841a260d"/></div></li>
                                                        <li className={characterData['siroco'+11] == '광휘' ? " on" : ""} onClick={()=>change('siroco'+11,"광휘")}><div class="imgt raidItem" slot="1011"><img name="광휘 : 멈출 수 없는 의지" src="https://dundam.xyz/img/item/7dc30234ecc008bc2ddc550ad8fb22a5"/></div></li>
                                                        <li className={characterData['siroco'+12] == '광휘' ? " on" : ""} onClick={()=>change('siroco'+12,"광휘")}><div class="imgt raidItem" slot="1012"><img name="광휘 : 전해지는 지혜" src="https://dundam.xyz/img/item/ea1a209be12a78a2a6c872e75ab0970c"/></div></li>
                                                    </ul>
                                                    <ul class="setList">
                                                        <li class="setName cus_etit">죄악의 지배</li>
                                                        <li className={characterData['siroco'+10] == '죄악' ? " on" : ""} onClick={()=>change('siroco'+10,"죄악")}><div class="imgt raidItem" slot="1010"><img name="죄악의 지배 : 절망을 뒤덮는 공포" src="https://dundam.xyz/img/item/47b419903a0b8542416e528e1079e29e"/></div></li>
                                                        <li className={characterData['siroco'+11] == '죄악' ? " on" : ""} onClick={()=>change('siroco'+11,"죄악")}><div class="imgt raidItem" slot="1011"><img name="죄악의 지배 : 절규가 매달리는 공포" src="https://dundam.xyz/img/item/49e7e73cd1ec8573ed0c0ba9c127b350"/></div></li>
                                                        <li className={characterData['siroco'+12] == '죄악' ? " on" : ""} onClick={()=>change('siroco'+12,"죄악")}><div class="imgt raidItem" slot="1012"><img name="죄악의 지배 : 태초가 선사하는 공포" src="https://dundam.xyz/img/item/5f8b29a66f1c13f6e3a47169af5cc598"/></div></li>
                                                    </ul>
                                                </div>
                                            
                                                <div class="cus_elst">
                                                    <div class="cus_eltit">
                                                        <span>바칼</span>
                                                    </div>
                                                    <ul class="setList">
                                                        <li class="setName cus_etit">빙화</li>
                                                        <li className={characterData['siroco'+2] == '빙화' ? " on" : ""} onClick={()=>change('siroco'+2,"빙화")}><div class="imgt raidItem" slot="102"><img name="빙화 : 피어난 꽃잎" src="https://dundam.xyz/img/item/240853586bd8ae7296378e1a6b7745d5"/></div></li>
                                                        <li className={characterData['siroco'+4] == '빙화' ? " on" : ""} onClick={()=>change('siroco'+4,"빙화")}><div class="imgt raidItem" slot="104"><img name="빙화 : 가혹한 한기" src="https://dundam.xyz/img/item/5cdf68192c0f5838a3f3f0c0bef41ab4"/></div></li>
                                                        <li className={characterData['siroco'+3] == '빙화' ? " on" : ""} onClick={()=>change('siroco'+3,"빙화")}><div class="imgt raidItem" slot="103"><img name="빙화 : 얽히는 서리" src="https://dundam.xyz/img/item/aac73f110fc4ef6963f083cde4fc9850"/></div></li>
                                                        <li className={characterData['siroco'+6] == '빙화' ? " on" : ""} onClick={()=>change('siroco'+6,"빙화")}><div class="imgt raidItem" slot="106"><img name="빙화 : 살에는 빙설" src="https://dundam.xyz/img/item/1452dd4dde85795b5bcd8e9bd13fcbe6"/></div></li>
                                                        <li className={characterData['siroco'+5] == '빙화' ? " on" : ""} onClick={()=>change('siroco'+5,"빙화")}><div class="imgt raidItem" slot="105"><img name="빙화 : 펼쳐진 동토" src="https://dundam.xyz/img/item/0fd9a42095299db7c936b07544da52ac"/></div></li>
                                                    </ul>
                                                    <ul class="setList">
                                                        <li class="setName cus_etit">뇌광</li>
                                                        <li className={characterData['siroco'+2] == '뇌광' ? " on" : ""} onClick={()=>change('siroco'+2,"뇌광")}><div class="imgt raidItem" slot="102"><img name="뇌광 : 천둥을 품은 용심" src="https://dundam.xyz/img/item/281c2640178dd5cbcc5c58b5f1bdcaa4"/></div></li>
                                                        <li className={characterData['siroco'+4] == '뇌광' ? " on" : ""} onClick={()=>change('siroco'+4,"뇌광")}><div class="imgt raidItem" slot="104"><img name="뇌광 : 낙뢰를 내린 용각" src="https://dundam.xyz/img/item/311f77c82a2c5d84e7ef85dc9599e75e"/></div></li>
                                                        <li className={characterData['siroco'+3] == '뇌광' ? " on" : ""} onClick={()=>change('siroco'+3,"뇌광")}><div class="imgt raidItem" slot="103"><img name="뇌광 : 우레를 감싼 용익" src="https://dundam.xyz/img/item/424a5caa037bcf098cb50b70bc3b3117"/></div></li>
                                                        <li className={characterData['siroco'+6] == '뇌광' ? " on" : ""} onClick={()=>change('siroco'+6,"뇌광")}><div class="imgt raidItem" slot="106"><img name="뇌광 : 번개를 담은 용린" src="https://dundam.xyz/img/item/b4f01254eea851567184b39616d40b6e"/></div></li>
                                                        <li className={characterData['siroco'+5] == '뇌광' ? " on" : ""} onClick={()=>change('siroco'+5,"뇌광")}><div class="imgt raidItem" slot="105"><img name="뇌광 : 벼락을 감은 용조" src="https://dundam.xyz/img/item/a3a08d4776befa1d152d2e4e5cbaa6b0"/></div></li>
                                                    </ul>
                                                    <ul class="setList">
                                                        <li class="setName cus_etit">교감</li>
                                                        <li className={characterData['siroco'+2] == '교감' ? " on" : ""} onClick={()=>change('siroco'+2,"교감")}><div class="imgt raidItem" slot="102"><img name="교감 : 공존하는 생명" src="https://dundam.xyz/img/item/1bb154696f9191e6c0a4abdf74948d41"/></div></li>
                                                        <li className={characterData['siroco'+4] == '교감' ? " on" : ""} onClick={()=>change('siroco'+4,"교감")}><div class="imgt raidItem" slot="104"><img name="교감 : 보호하는 온기" src="https://dundam.xyz/img/item/127f13f9aaf04f83c78f0134fb1b015d"/></div></li>
                                                        <li className={characterData['siroco'+3] == '교감' ? " on" : ""} onClick={()=>change('siroco'+3,"교감")}><div class="imgt raidItem" slot="103"><img name="교감 : 감싸안는 햇살" src="https://dundam.xyz/img/item/26b72a48c54e98296ff3df20a52d4c16"/></div></li>
                                                        <li className={characterData['siroco'+6] == '교감' ? " on" : ""} onClick={()=>change('siroco'+6,"교감")}><div class="imgt raidItem" slot="106"><img name="교감 : 내려앉는 이슬" src="https://dundam.xyz/img/item/33d022dd111c61b85fe68659ab1dffa4"/></div></li>
                                                        <li className={characterData['siroco'+5] == '교감' ? " on" : ""} onClick={()=>change('siroco'+5,"교감")}><div class="imgt raidItem" slot="105"><img name="교감 : 동행하는 대지" src="https://dundam.xyz/img/item/c0665715c5c4b1d6de9f4ca60f130551"/></div></li>
                                                    </ul>
                                                    <ul class="setList">
                                                        <li class="setName cus_etit">포식</li>
                                                        <li className={characterData['siroco'+2] == '포식' ? " on" : ""} onClick={()=>change('siroco'+2,"포식")}><div class="imgt raidItem" slot="102"><img name="포식 : 헤집어진 상처" src="https://dundam.xyz/img/item/5432b890b5d2f750c67951a8817c4823"/></div></li>
                                                        <li className={characterData['siroco'+4] == '포식' ? " on" : ""} onClick={()=>change('siroco'+4,"포식")}><div class="imgt raidItem" slot="104"><img name="포식 : 사그라진 고동" src="https://dundam.xyz/img/item/33e8f7122831fa045f6e84313da64e31"/></div></li>
                                                        <li className={characterData['siroco'+3] == '포식' ? " on" : ""} onClick={()=>change('siroco'+3,"포식")}><div class="imgt raidItem" slot="103"><img name="포식 : 자비없는 습격" src="https://dundam.xyz/img/item/18ddc7fb78f1230f14ee4654cc22cc20"/></div></li>
                                                        <li className={characterData['siroco'+6] == '포식' ? " on" : ""} onClick={()=>change('siroco'+6,"포식")}><div class="imgt raidItem" slot="106"><img name="포식 : 조여오는 올가미" src="https://dundam.xyz/img/item/21c05b45eb65c07383b0cad551e0ae47"/></div></li>
                                                        <li className={characterData['siroco'+5] == '포식' ? " on" : ""} onClick={()=>change('siroco'+5,"포식")}><div class="imgt raidItem" slot="105"><img name="포식 : 추적하는 사냥꾼" src="https://dundam.xyz/img/item/06a75e0f3cf3b8ad358289b3faf79303"/></div></li>
                                                    </ul>
                                                    <ul class="setList">
                                                        <li class="setName cus_etit">격노</li>
                                                        <li className={characterData['siroco'+2] == '격노' ? " on" : ""} onClick={()=>change('siroco'+2,"격노")}><div class="imgt raidItem" slot="102"><img name="격노 : 최후에 삼킨 숨결" src="https://dundam.xyz/img/item/113436105b0365c4ee0ed0c542f30710"/></div></li>
                                                        <li className={characterData['siroco'+4] == '격노' ? " on" : ""} onClick={()=>change('siroco'+4,"격노")}><div class="imgt raidItem" slot="104"><img name="격노 : 세상을 탐한 욕구" src="https://dundam.xyz/img/item/c11bb733312c6612d6fd2cd9196862c8"/></div></li>
                                                        <li className={characterData['siroco'+3] == '격노' ? " on" : ""} onClick={()=>change('siroco'+3,"격노")}><div class="imgt raidItem" slot="103"><img name="격노 : 파괴를 행한 맹목" src="https://dundam.xyz/img/item/054774144a5aab6828500305f71425ec"/></div></li>
                                                        <li className={characterData['siroco'+6] == '격노' ? " on" : ""} onClick={()=>change('siroco'+6,"격노")}><div class="imgt raidItem" slot="106"><img name="격노 : 무한히 증적한 식탐" src="https://dundam.xyz/img/item/5b3976c605e6d96878a9b94ad5fbd449"/></div></li>
                                                        <li className={characterData['siroco'+5] == '격노' ? " on" : ""} onClick={()=>change('siroco'+5,"격노")}><div class="imgt raidItem" slot="105"><img name="격노 : 방향을 잃은 갈망" src="https://dundam.xyz/img/item/f2c4246d0b59619eea5d03bb5b76cc91"/></div></li>
                                                    </ul>
                                                
                                                </div> */}
                                                {/*} 
                                                        <div class="cus_eltit">
                                                            <span>바칼 무기</span>
                                                        </div>
                                                
                                                        <div class="esetting_tr cusp2 o105je">
                                                                <div class="estcont  cussa" style="height: 450px;" id="weapon1">
                            
                                                                    <div class="cus_105op" style="height: 430px;">
                                                                        <div class="option"></div>
                                                                        
                                                                        <div class="cus_opsetbut" style="display: unset;">
                                                                            <div class="imgt">
                                                                                <img src="img/gear-wheel.svg"/>
                                                                            </div>

                                                                        </div>
                                                                        
                                                                    </div>
                                                                    
                                                                    <div class="cus_opset">
                                                                        <div class="cos_c">
                                                                            <div class="cossc" style="
                                                                                    width: 80%;
                                                                                    display: flex;
                                                                                ">
                                                                                <div class="searchbox" name="검색창" style="
                                                                                    width: 85%;
                                                                                ">
                                                                                    <input type="text" class="form-control js-bound" placeholder="검색" name="name" maxlength="40"/>
                                                                                </div>
                                                                                <div class="searchbut105">
                                                                                    <div class="imgt">
                                                                                        <img src="img/sr_search.svg"/>
                                                                                    </div>
                                        
                                                                                </div>
                                                                            </div>
                                                                            <div class="coscc">
                                                                            </div>
                                                                            <div class="cos_but">
                                                                                <div class="csbt" slot="1">
                                                                                    <span class="cbut-tag">적용</span>
                                                                                </div>
                                                                                <div class="csbt csbtc">
                                                                                    <span class="cbut-tag">
                                                                                        <div class="imgt">
                                                                                            <img src="img/arrow_go_back2.svg"/>
                                                                                        </div>
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        
                                                                    </div>
                                                                </div>
                                                                <div class="estcont  cussa" style="height: 450px;" id="weapon2">
                            
                                                                    <div class="cus_105op" style="height: 430px;">
                                                                        <div class="option"></div>
                                                                        <div class="cus_opsetbut" style="display: unset;">
                                                                            <div class="imgt">
                                                                                <img src="img/gear-wheel.svg"/>
                                                                            </div>

                                                                        </div>
                                                                        
                                                                    </div>
                                                                    
                                                                    <div class="cus_opset">
                                                                        <div class="cos_c">
                                                                            <div class="cossc" style="
                                                                                    width: 80%;
                                                                                    display: flex;
                                                                                ">
                                                                                <div class="searchbox" name="검색창" style="
                                                                                    width: 85%;
                                                                                ">
                                                                                    <input type="text" class="form-control js-bound" placeholder="검색" name="name" maxlength="40"/>
                                                                                </div>
                                                                                <div class="searchbut105">
                                                                                    <div class="imgt">
                                                                                        <img src="img/sr_search.svg"/>
                                                                                    </div>
                                        
                                                                                </div>
                                                                            </div>
                                                                            <div class="coscc">
                                                                            </div>
                                                                            <div class="cos_but">
                                                                                <div class="csbt" slot="1">
                                                                                    <span class="cbut-tag">적용</span>
                                                                                </div>
                                                                                <div class="csbt csbtc">
                                                                                    <span class="cbut-tag">
                                                                                        <div class="imgt">
                                                                                            <img src="img/arrow_go_back2.svg"/>
                                                                                        </div>
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        
                                                                    </div>
                                                                </div>
                                                                
                                                                
                                                            </div>
                                                        
                                                        <div class="esetting_tr cusp2 o105je">
                                                                <div class="estcont  cussa" style="height: 450px;" id="weapon3">
                            
                                                                    <div class="cus_105op" style="height: 430px;">
                                                                        <div class="option"></div>
                                                                        
                                                                        <div class="cus_opsetbut" >
                                                                            <div class="imgt">
                                                                                <img src="img/gear-wheel.svg"/>
                                                                            </div>

                                                                        </div>
                                                                        
                                                                    </div>
                                                                    
                                                                    <div class="cus_opset">
                                                                        <div class="cos_c">
                                                                            <div class="cossc" style="
                                                                                    width: 80%;
                                                                                    display: flex;
                                                                                ">
                                                                                <div class="searchbox" name="검색창" style="
                                                                                    width: 85%;
                                                                                ">
                                                                                    <input type="text" class="form-control js-bound" placeholder="검색" name="name" maxlength="40"/>
                                                                                </div>
                                                                                <div class="searchbut105">
                                                                                    <div class="imgt">
                                                                                        <img src="img/sr_search.svg"/>
                                                                                    </div>
                                        
                                                                                </div>
                                                                            </div>
                                                                            <div class="coscc">
                                                                            </div>
                                                                            <div class="cos_but">
                                                                                <div class="csbt" slot="1">
                                                                                    <span class="cbut-tag">적용</span>
                                                                                </div>
                                                                                <div class="csbt csbtc">
                                                                                    <span class="cbut-tag">
                                                                                        <div class="imgt">
                                                                                            <img src="img/arrow_go_back2.svg"/>
                                                                                        </div>
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        
                                                                    </div>
                                                                </div>
                                                    
                                                    
                                                            </div>
                                                    
                                                {*/}

                                                {/* <div class="esetting_tr cusp2 o105je" >
                                                    <div class="estcont  cussa" id="weapon1" style={{padding:"unset"}}>

                                                        <div class="cus_105op" >
                                                            <div class="option">
                                                                {characterData['weapon1'] != null ? lv105WeaponOptionMap[characterData['weapon1'] - 1].replace(/<br>/gi, "\n") : ""}

                                                            </div>

                                                            <div class="cus_opsetbut" onClick={() => { weaponPlusChange(0, 1) }} >
                                                                <div class="imgt">
                                                                    <img src="img/gear-wheel.svg" />
                                                                </div>

                                                            </div>

                                                        </div>

                                                        <div class={"cus_opset" + (weaponPlusArray[0] == 1 ? " on" : "")}>
                                                            <div class="cos_c">
                                                                <div class="cossc">
                                                                    <div class="searchbox" name="검색창" >
                                                                        <input type="text" onChange={(event) => weaponOpSearchChange(0, event.target.value)} class="form-control js-bound" placeholder="검색" name="name" maxlength="40" />
                                                                    </div>
                                                                    <div class="searchbut105">
                                                                        <div class="imgt">
                                                                            <img src="img/sr_search.svg" />
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                <div class="coscc">
                                                                    <ul>
                                                                        {
                                                                            
                                                                            lv105WeaponOptionMap ? lv105WeaponOptionMap.map((a, i) => {

                                                                                if (optionSearch(a,weaponOpSearch[0]) != -1)
                                                                                    return (

                                                                                        <li key={'weaponOPF' + i} className={weaponPlusSelectArray[0] == i + 1 ? "is-active" : ""} onClick={() => weaponPlusSelectArrayChange(0, i + 1)}>{a.replace(/<br>/gi, '\n')}</li>
                                                                                    )

                                                                            }) : ""


                                                                        }
                                                                    </ul>
                                                                </div>
                                                                <div class="cos_but">
                                                                    <div class="csbt" slot="1" onClick={() => { if(weaponPlusSelectArray[0] != 0) { change('weapon1', weaponPlusSelectArray[0]); weaponPlusChange(0, 0); } }} >
                                                                        <span class="cbut-tag" >적용</span>
                                                                    </div>
                                                                    <div class="csbt csbtc" onClick={() => { weaponPlusChange(0, 0) }}>
                                                                        <span class="cbut-tag">
                                                                            <div class="imgt">
                                                                                <img src="img/arrow_go_back2.svg" />
                                                                            </div>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>

                                                    
                                                <div class="esetting_tr cusp2 o105je">
                                                    <div class="estcont  cussa" id="weapon2" style={{padding:"unset"}}>

                                                        <div class="cus_105op" >
                                                            <div class="option">
                                                                {characterData['weapon2'] != null ? lv105WeaponOptionMap[characterData['weapon2'] - 1].replace(/<br>/gi, "\n") : ""}
                                                            </div>
                                                            <div class="cus_opsetbut" onClick={() => { weaponPlusChange(1, 1) }}  >
                                                                <div class="imgt">
                                                                    <img src="img/gear-wheel.svg" />
                                                                </div>

                                                            </div>

                                                        </div>

                                                        <div class={"cus_opset" + (weaponPlusArray[1] == 1 ? " on" : "")}>
                                                            <div class="cos_c">
                                                                <div class="cossc">
                                                                    <div class="searchbox" name="검색창">
                                                                        <input type="text" onChange={(event) => weaponOpSearchChange(1, event.target.value)} class="form-control js-bound" placeholder="검색" name="name" maxlength="40" />
                                                                    </div>
                                                                    <div class="searchbut105">
                                                                        <div class="imgt">
                                                                            <img src="img/sr_search.svg" />
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                <div class="coscc">
                                                                    <ul>
                                                                        {
                                                                            lv105WeaponOptionMap ? lv105WeaponOptionMap.map((a, i) => {
                                                                                if (optionSearch(a,weaponOpSearch[1]) != -1)
                                                                                    return (
                                                                                        <li key={'weaponOPS' + i} className={weaponPlusSelectArray[1] == i + 1 ? "is-active" : ""} onClick={() => weaponPlusSelectArrayChange(1, i + 1)}>{a.replace(/<br>/gi, '\n')}</li>
                                                                                    )

                                                                            }) : ""

                                                                        }
                                                                    </ul>
                                                                </div>
                                                                <div class="cos_but">
                                                                    <div class="csbt" slot="1" onClick={() => { if(weaponPlusSelectArray[1] != 0) { change('weapon2', weaponPlusSelectArray[1]); weaponPlusChange(1, 0); }}} >
                                                                        <span class="cbut-tag" >적용</span>
                                                                    </div>
                                                                    <div class="csbt csbtc" onClick={() => { weaponPlusChange(1, 0) }}>
                                                                        <span class="cbut-tag">
                                                                            <div class="imgt">
                                                                                <img src="img/arrow_go_back2.svg" />
                                                                            </div>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>


                                                <div class="esetting_tr cusp2 o105je">
                                                    <div class="estcont  cussa" id="weapon3" style={{padding:"unset"}}>

                                                        <div class="cus_105op" >
                                                            <div class="option">

                                                                {characterData['weapon3'] != null ? lv105WeaponThirdOptionMap[characterData['weapon3'] - 1].replace(/<br>/gi, "\n") : ""}
                                                            </div>

                                                            <div class="cus_opsetbut" onClick={() => { weaponPlusChange(2, 1) }} >
                                                                <div class="imgt">
                                                                    <img src="img/gear-wheel.svg" />
                                                                </div>

                                                            </div>

                                                        </div>

                                                        <div class={"cus_opset" + (weaponPlusArray[2] == 1 ? " on" : "")}>
                                                            <div class="cos_c">
                                                                <div class="cossc">
                                                                    <div class="searchbox" name="검색창">
                                                                        <input type="text" onChange={(event) => weaponOpSearchChange(2, event.target.value)} class="form-control js-bound" placeholder="검색" name="name" maxlength="40" />
                                                                    </div>
                                                                    <div class="searchbut105">
                                                                        <div class="imgt">
                                                                            <img src="img/sr_search.svg" />
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                <div class="coscc">
                                                                    <ul>
                                                                        {

                                                                            lv105WeaponThirdOptionMap ? lv105WeaponThirdOptionMap.map((a, i) => {
                                                                                if (optionSearch(a,weaponOpSearch[2]) != -1)
                                                                                    return (
                                                                                        <li key={'weaponOPT' + i} className={weaponPlusSelectArray[2] == i + 1 ? "is-active" : ""} onClick={() => weaponPlusSelectArrayChange(2, i + 1)}>{a.replace(/<br>/gi, '\n')}</li>

                                                                                    )

                                                                            }) : ""

                                                                        }
                                                                    </ul>
                                                                </div>
                                                                <div class="cos_but">
                                                                    <div class="csbt" slot="1" onClick={() => { if(weaponPlusSelectArray[2] != 0) { change('weapon3', weaponPlusSelectArray[2]); weaponPlusChange(2, 0); }}} >
                                                                        <span class="cbut-tag" >적용</span>
                                                                    </div>
                                                                    <div class="csbt csbtc" onClick={() => { weaponPlusChange(2, 0) }}>
                                                                        <span class="cbut-tag">
                                                                            <div class="imgt">
                                                                                <img src="img/arrow_go_back2.svg" />
                                                                            </div>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>


                                                </div> */}


                                            {/* </div> */}
                                        </div>
                                    
                                        <div class="cus_epdt e105ep setItem" style={present != 102 ? {display : 'none'} : {}}>
                                            <div class="section-tabs">
                                                    
                                                <div class="swiper-container swiper-tabs-nav nava setsItem">
                                                    <div class="swiper-wrapper">
                                                        <div class={"swiper-slide" + (setsItem == 100 ? " swiper-slide-thumb-active" : "")} onClick={()=>{setSetsItem(100)}}
                                                        >
                                                            기억
                                                        </div>
                                                        <div class={"swiper-slide" + (setsItem == 101 ? " swiper-slide-thumb-active" : "")} onClick={()=>{setSetsItem(101)}}>
                                                            흔적
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                setsItem == 100 ? 
                                                <div class="swiper-container swiper-tabs-content cona setsItem">
                                                    <div class="swiper-wrapper">
                                                        <div class="swiper-slide">
                                                            <div class="cus_elst">
                                                                {
                                                                
                                                                    present == 102 ? 
                                                                    setItemList[0].map((a,i) => {
                                                                        return(
                                                                            <ul key={'armourSet'+i}>
                                                                            <ItemList slot='2' name={setItemList[0][i]} />
                                                                            <ItemList slot='4' name={setItemList[1][i]} />
                                                                            <ItemList slot='3' name={setItemList[2][i]} />
                                                                            <ItemList slot='6' name={setItemList[3][i]} />
                                                                            <ItemList slot='5' name={setItemList[4][i]} />
                                                                            <ItemList slot='8' name={setItemList[5][i]} />
                                                                            </ul>
                                                                        )
                                                                    })
                                                                    :
                                                                    <></>

                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> : <></>
                                            }
                                            {
                                                
                                                setsItem == 101 ?
                                                
                                                <div class="swiper-container swiper-tabs-content cona setsItem">
                                                    <div class="swiper-wrapper">
                                                        <div class="swiper-slide">
                                                            <div class="cus_elst">
                                                                {
                                                                    
                                                                    present == 102 ? 
                                                                    setItemList[0].map((a,i) => {
                                                                        return(
                                                                            <ul key={'etcSet'+i}>
                                                                            <ItemList slot='7' name={setItemList[6][i]} />
                                                                            <ItemList slot='9' name={setItemList[7][i]} />
                                                                            <ItemList slot='10' name={setItemList[8][i]} />
                                                                            <ItemList slot='11' name={setItemList[9][i]} />
                                                                            <ItemList slot='12' name={setItemList[10][i]} />
                                                                            </ul>
                                                                        )
                                                                    })
                                                                    :
                                                                    <></>

                                                                }
                                                        </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                : <></>


                                            }
                                        </div>


                                        <div class="cus_epdt e105ep e105mob" style={present != 100 ? {display : 'none'} : {}}>
                                            <div class="cus_search e105s">
                                                <div class="e105_shc">
                                                        <div class="searchbox" name="검색창">
                                                            <input type="text" onChange={(event)=>setItemSearchTemp(event.target.value)} onKeyUp={(event)=>{ event.key == 'Enter' ? setItemSearch(event.target.value) : <></>}} class="form-control js-bound" placeholder="옵션검색" name="name" maxlength="11"></input>
                                                        </div>
                                                        
                                                        <div class="searchbut105" onClick={()=>setItemSearch(itemSearchTemp)}>

                                                    </div>
                                                </div>
                                            </div>
                                            
                                            
                                            <div class="section-tabs">
                                                    
                                                <div class="swiper-container swiper-tabs-nav nava">
                                                    <div class="swiper-wrapper">
                                                        <div class={"swiper-slide" + (selectItemType == 100 ? " swiper-slide-thumb-active" : "")}
                                                        onClick={()=>{setSelectItemType(100)}}>
                                                            방어구
                                                        </div>
                                                        
                                                        <div class={"swiper-slide" + (selectItemType == 101 ? " swiper-slide-thumb-active" : "")}
                                                        onClick={()=>{setSelectItemType(101)}}>
                                                            악세사리
                                                        </div>
                                                        
                                                        <div class={"swiper-slide" + (selectItemType == 102 ? " swiper-slide-thumb-active" : "")}
                                                        onClick={()=>{setSelectItemType(102)}}>
                                                            특수장비
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="swiper-container swiper-tabs-content cona">
                                                    <div class="swiper-wrapper">
                                                        <div class="swiper-slide" style={ selectItemType == 100 ? {"display":"unset"} : {"display":"none"}}>
                                                            <div class="cus_elst">
                                                                {
                                                                    present == 100 ? names.map((a, i) =>
                                                                        {
                                                                            if(i < armourCount)
                                                                            {
                                                                                let totalCheck = false;
                                                                                let check = [false,false,false,false,false];

                                                                                //console.log(itemSearch.replace(/ /gi,'').replace(/속강/gi,'속성강화').replace(/스증/gi,'스킬공격력'));

                                                                                for(let loop = 0 ; loop < 5 ; loop++)
                                                                                {
                                                                                    if(names[i+armourCount*loop].replace(/ /gi,'').indexOf(itemSearch.replace(/ /gi,'')) != -1)
                                                                                    {
                                                                                        check[loop] = true;
                                                                                        totalCheck = true;
                                                                                    }
                                                                                    

                                                                                    if(lv105OptionMap[names[i+armourCount*loop]] != null)
                                                                                    if(lv105OptionMap[names[i+armourCount*loop]].replace(/,/gi,'').replace(/ /gi,'').indexOf(itemSearch.replace(/ /gi,'').replace(/속강/gi,'속성강화').replace(/스증/gi,'스킬공격력')) != -1)
                                                                                    {
                                                                                        check[loop] = true;
                                                                                        totalCheck = true;
                                                                                    }
                                                                                }

                                                                                if(totalCheck)
                                                                                return(
                                                                                    <ul key={'armour'+i}>
                                                                                        {
                                                                                        check[0] ? 
                                                                                        
                                                                                        <Equip name={names[i+armourCount*0]} slot={2}/>: <li></li>
                                                                                        }
                                                                                        {
                                                                                        check[2] ? 
                                                                                    
                                                                                        <Equip name={names[i+armourCount*2]} slot={4}/>: <li></li>
                                                                                        }
                                                                                        {
                                                                                        check[1] ? 
                                                                                    
                                                                                        <Equip name={names[i+armourCount*1]} slot={3}/>: <li></li>
                                                                                        }
                                                                                        
                                                                                        {
                                                                                        check[4] ?
                                                                                        
                                                                                        <Equip name={names[i+armourCount*4]} slot={6}/>: <li></li>
                                                                                    
                                                                                        }
                                                                                        {
                                                                                        check[3] ?
                                                                                        
                                                                                        <Equip name={names[i+armourCount*3]} slot={5}/>: <li></li>
                                                                                    
                                                                                        }
                                                                                    
                                                                                    </ul>

                                                                                )
                                                                            }
                                                                        } 
                                                                    ) : <></>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div class="swiper-slide" style={ selectItemType == 101 ? {"display":"unset"} : {"display":"none"}}>
                                                            <div class="cus_elst">
                                                                {
                                                                    present == 100 ? accNames.map((a, i) =>
                                                                        {
                                                                            if(i < armourCount)
                                                                            {
                                                                                let totalCheck = false;
                                                                                let check = [false,false,false];

                                                                                for(let loop = 0 ; loop < 3 ; loop++)
                                                                                {
                                                                                    if(names[i+itemCount*loop].replace(/ /gi,'').indexOf(itemSearch.replace(/ /gi,'')) != -1)
                                                                                    {
                                                                                        check[loop] = true;
                                                                                        totalCheck = true;
                                                                                    }

                                                                                    if(lv105OptionMap[names[i+itemCount*loop]] != null)
                                                                                    if(lv105OptionMap[names[i+itemCount*loop]].replace(/,/gi,'').replace(/ /gi,'').indexOf(itemSearch.replace(/ /gi,'').replace(/속강/gi,'속성강화').replace(/스증/gi,'스킬공격력')) != -1)
                                                                                    {
                                                                                        check[loop] = true;
                                                                                        totalCheck = true;
                                                                                    }
                                                                                }

                                                                                if(totalCheck)
                                                                                return(
                                                                                    <ul key={i}>
                                                                    
                                                                                        {
                                                                                            check[1] ?
                                                                                            <Equip name={accNames[i+itemCount * 1]} slot={8}/>: <li></li>
                                                                                        }
                                                                                        {
                                                                                            check[0] ?
                                                                                            <Equip name={accNames[i+itemCount * 0]} slot={7}/>: <li></li>
                                                                                        }
                                                                                        {
                                                                                            check[2] ?
                                                                                            <Equip name={accNames[i+itemCount * 2]} slot={9}/>: <li></li>
                                                                                        }
                                                                                        
                                                                                    
                                                                                    </ul>

                                                                                )
                                                                            }
                                                                            
                                                                        } 
                                                                    ) : <></>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div class="swiper-slide" style={ selectItemType == 102 ? {"display":""} : {}}>
                                                            <div class="cus_elst">
                                                                {
                                                                    present == 100 ? speNames.map((a, i) =>
                                                                        {
                                                                            if(i < armourCount)
                                                                            {
                                                                                let totalCheck = false;
                                                                                let check = [false,false,false];

                                                                                for(let loop = 0 ; loop < 3 ; loop++)
                                                                                {
                                                                                    if(names[i+speCount*loop].replace(/ /gi,'').indexOf(itemSearch.replace(/ /gi,'')) != -1)
                                                                                    {
                                                                                        check[loop] = true;
                                                                                        totalCheck = true;
                                                                                    }

                                                                                    if(lv105OptionMap[names[i+speCount*loop]] != null)
                                                                                    if(lv105OptionMap[names[i+speCount*loop]].replace(/,/gi,'').replace(/ /gi,'').indexOf(itemSearch.replace(/ /gi,'').replace(/속강/gi,'속성강화').replace(/스증/gi,'스킬공격력')) != -1)
                                                                                    {
                                                                                        check[loop] = true;
                                                                                        totalCheck = true;
                                                                                    }
                                                                                }

                                                                                if(totalCheck)
                                                                                return(
                                                                                    <ul key={i}>
                                                                                    {
                                                                                        check[0] ?
                                                                                        <Equip name={speNames[i+speCount * 0]} slot={10}/>: <li></li>
                                                                                    }
                                                                                    {
                                                                                        check[1] ?
                                                                                        <Equip name={speNames[i+speCount * 1]} slot={11}/>: <li></li>
                                                                                    }
                                                                                    {
                                                                                        check[2] ?
                                                                                        <Equip name={speNames[i+speCount * 2]} slot={12}/>: <li></li>
                                                                                    }
                                                                            
                                                                                </ul>

                                                                                )
                                                                            }
                                                                        }
                                                                    ): <></>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                        
                                            </div>
                                

                                        </div>
                                        <Setting id='0'/>
                                        {
                                            characterObj['buffCal'] == null ? <TitleSetting id='1'/> : <MainTitleSetting id='1'/>
                                        }
                                        <Setting id='2'/>
                                        <Setting id='3'/>
                                        <Setting id='4'/>
                                        <Setting id='5'/>
                                        <Setting id='6'/>
                                        <Setting id='7'/>
                                        <Setting id='8'/>
                                        <Setting id='9'/>
                                        <Setting id='10'/>
                                        <Setting id='11'/>
                                        <Setting id='12'/>


                                        <PlusTitleSetting id='115'/>
                                        <PlusSetting id='114'/>
                                        <PlusSetting id='113'/>
                                        
                                        {
                                            characterObj.job != '검제' ? 
                                                    <SubWeaponSetting/>
                                            : <></>
                                        }
                                        
                                    </div>
                                    
                                    
                                    <div class="tab__content" name="아바타">
                                        <div class="c_abata">
                                            <div class="c_equip sabat">
                                                <div class="caba">
                                                    <div class="imgt">
                                                        <img src={'https://img-api.neople.co.kr/df/servers/'+ characterObj.server +'/characters/'+ characterObj.id +'?zoom=1.jpg'}/>
                                                    </div>
                                                </div>

                                                
                                                <div class="cabap">
                                                    <ul class="hwimo">
                                                        <li>
                                                            <span>
                                                                <div className= {avaPresent == 13 ? "on" : "" }>
                                                                    <img number={3013}   src="advent.png" onClick={() => setAvaPresent(13)}/>
                                                                </div>
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span>
                                                                <div className= {avaPresent == 11 ? "on" : "" }>
                                                                    <img number={3011}  src="hwi.png" onClick={ () => setAvaPresent(11)}/>
                                                                </div>
                                                            </span>
                                                        </li>
                                                    </ul>
                                                        
                                                    <ul>
                                                        <li>
                                                            <span>
                                                                <div className= {avaPresent == 10 ? "on" : "" }>
                                                                <img number={3010}  src="https://dundam.xyz/img/item/76b6e9f3fa9703f447cbb8bd4898c0d8" onClick={ () => setAvaPresent(10)}/>
                                                                </div>
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <div className= {avaPresent == 1 ? "on" : "" }>
                                                                <img number={301}  src="https://dundam.xyz/img/item/20d6374d6ab02076f7c32153568431c9" onClick={ () => setAvaPresent(1)}/>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className= {avaPresent == 0 ? "on" : "" }>
                                                                <img number={300}  src="https://dundam.xyz/img/item/f11f806769afaae8b05be488e20b6109" onClick={ () => setAvaPresent(0)}/>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className= {avaPresent == 2 ? "on" : "" }>
                                                                <img number={302}  src="https://dundam.xyz/img/item/324c85d935a5e9358825380bf2065845" onClick={ () => setAvaPresent(2)}/>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                    
                                                    <ul>
                                                        <li>
                                                            <span>
                                                                <div className= {avaPresent == 9 ? "on" : "" }>
                                                                <img number={309}  src="https://dundam.xyz/img/item/73f53737906283e27aef171d7d10dc0f" onClick={ () => setAvaPresent(9)}/>
                                                                </div>
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <div className= {avaPresent == 6 ? "on" : "" }>
                                                                <img number={306}  src="https://dundam.xyz/img/item/b5ccbf772a3883e3171f682679e2b421" onClick={ () => setAvaPresent(6)}/>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className= {avaPresent == 3 ? "on" : "" }>
                                                                <img number={303}  src="https://dundam.xyz/img/item/5838c99d8d457c6422a7b8027ef9f7df" onClick={ () => setAvaPresent(3)}/>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className= {avaPresent == 8 ? "on" : "" }>
                                                                <img number={308}  src="https://dundam.xyz/img/item/f5bf71e62cfe6015cad07b3e0dfe2107" onClick={ () => setAvaPresent(8)}/>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                    
                                                    
                                                    <ul>
                                                        <li>
                                                            <span>
                                                                <div className= {avaPresent == 12 ? "on" : "" }>
                                                                <img number={3012}  src="https://dundam.xyz/img/item/01abefd5d1958134dfb0af9a8e0c3177" onClick={ () => setAvaPresent(12)}/>
                                                                </div>
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <div  className= {avaPresent == 7 ? "on" : "" }>
                                                                <img number={307}  src="https://dundam.xyz/img/item/c81e24d23286a4897987547c5236edf1" onClick= { () => setAvaPresent(7)}/>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className= {avaPresent == 4 ? "on" : "" }>
                                                                <img number={304}  src="https://dundam.xyz/img/item/2fd4d583eef28dfcca026403aa786fa9" onClick={ () => setAvaPresent(4)}/>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className= {avaPresent == 5 ? "on" : "" }>
                                                                <img number={305}  src="https://dundam.xyz/img/item/30b8500c4447878005d9d1f433dac030" onClick={ () => setAvaPresent(5)}/>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                
                                                </div>

                                            </div>
                                            
                                        <div class="cus_button" onClick={()=>{submitSetting()}}>
                                            <div class="c_but">
                                                <span className='cbut-tag'  >
                                                적용
                                                </span>
                                            </div>
                                        </div>
                                            
                                        <AvatarSetting id={0} />
                                        <AvatarSetting id={1} />
                                        <AvatarSetting id={2} />
                                        <AvatarSetting id={3} />
                                        <AvatarSetting id={4} />
                                        <AvatarSetting id={5} />
                                        <AvatarSetting id={6} />
                                        <AvatarSetting id={7} />
                                        <AvatarSetting id={8} />
                                        <AvatarSetting id={9} />
                                        <AvatarSetting id={10} />
                                        <AvatarSetting id={11} />
                                        <AvatarSetting id={12} />
                                        <AvatarSetting id={13} />
                                        </div>
                                    </div>
                                    
                                    <div class="tab__content" name="탈리스만">
                                        <div class="c_talisman">
                                            <div class="c_tal_select">
                                                <div class="ctal_bag">
                                                    <div class="ctal_c ctcfirst" onClick={()=>{setPresentTalis(1)}}>
                                                        <ul>
                                                            <li class="ctalc_main">
                                                                <div class="imgt">
                                                                    <img src={getSkillImageURL(selectTalisSkill[0],characterData["baseJob"])}></img>
                                                                </div>
                                                            </li>
                                                            <li class="ctalc_tal1">
                                                                <div class="imgt" style={{'background' : talismanColor(characterData["rune1type"])}}>
                                                                    <img style={{'mix-blend-mode' : 'luminosity'}}  src={getSkillImageURL(characterData["rune1Skill"],characterData["baseJob"])}></img>
                                                                </div>
                                                                
                                                            </li>
                                                            <li class="ctalc_tal2">
                                                                <div class="imgt" style={{'background' : talismanColor(characterData["rune2type"])}}>
                                                                    <img style={{'mix-blend-mode' : 'luminosity'}}  src={getSkillImageURL(characterData["rune2Skill"],characterData["baseJob"])}></img>
                                                                    
                                                                </div>
                                                            </li>
                                                            <li class="ctalc_tal3">
                                                                <div class="imgt" style={{'background' : talismanColor(characterData["rune3type"])}}>
                                                                    <img style={{'mix-blend-mode' : 'luminosity'}} src={getSkillImageURL(characterData["rune3Skill"],characterData["baseJob"])}></img>
                                                                    
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div class="ctal_c ctcsecond" onClick={()=>{setPresentTalis(2)}}>
                                                        <ul>
                                                            <li class="ctalc_main">
                                                                <div class="imgt">
                                                                    <img src={getSkillImageURL(selectTalisSkill[1],characterData["baseJob"])}></img>
                                                                </div>
                                                            </li>
                                                            <li class="ctalc_tal1">
                                                                <div class="imgt" style={{'background' : talismanColor(characterData["rune4type"])}}>
                                                                    <img style={{'mix-blend-mode' : 'luminosity'}} src={getSkillImageURL(characterData["rune4Skill"],characterData["baseJob"])}></img>
                                                                    
                                                                </div>
                                                            </li>
                                                            <li class="ctalc_tal2">
                                                                <div class="imgt" style={{'background' : talismanColor(characterData["rune5type"])}}>
                                                                    <img style={{'mix-blend-mode' : 'luminosity'}} src={getSkillImageURL(characterData["rune5Skill"],characterData["baseJob"])}></img>
                                                                    
                                                                </div>
                                                            </li>
                                                            <li class="ctalc_tal3">
                                                                <div class="imgt" style={{'background' : talismanColor(characterData["rune6type"])}}>
                                                                    <img style={{'mix-blend-mode' : 'luminosity'}} src={getSkillImageURL(characterData["rune6Skill"],characterData["baseJob"])}></img>
                                                                    
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div class="ctal_c ctcthird" onClick={()=>{setPresentTalis(3)}}>
                                                        <ul>
                                                            <li class="ctalc_main">
                                                                <div class="imgt">
                                                                    <img  src={getSkillImageURL(selectTalisSkill[2],characterData["baseJob"])}></img>
                                                                
                                                                </div>
                                                            </li>
                                                            <li class="ctalc_tal1">
                                                                <div class="imgt" style={{'background' : talismanColor(characterData["rune7type"],characterData["baseJob"])}}>
                                                                    <img style={{'mix-blend-mode' : 'luminosity'}} src={getSkillImageURL(characterData["rune7Skill"],characterData["baseJob"])}></img>
                                                                    
                                                                </div>
                                                            </li>
                                                            <li class="ctalc_tal2">
                                                                <div class="imgt" style={{'background' : talismanColor(characterData["rune8type"])}}>
                                                                    <img style={{'mix-blend-mode' : 'luminosity'}} src={getSkillImageURL(characterData["rune8Skill"],characterData["baseJob"])}></img>
                                                                    
                                                                </div>
                                                            </li>
                                                            <li class="ctalc_tal3">
                                                                <div class="imgt" style={{'background' : talismanColor(characterData["rune9type"])}}>
                                                                    <img style={{'mix-blend-mode' : 'luminosity'}} src={getSkillImageURL(characterData["rune9Skill"],characterData["baseJob"])}></img>
                                                                    
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="cus_button" onClick={()=>{submitSetting()}}  >
                                                <div class="c_but">
                                                    <span className='cbut-tag'  >
                                                    적용
                                                    </span>
                                                </div>
                                                
                                            </div>
                                            
                                            <TalismanSetting id={1}/>
                                            <TalismanSetting id={2}/>
                                            <TalismanSetting id={3}/>

                                        </div>
                                    </div>
                                    {/* <div class="tab__content" name="장비특성">
                                    <div class="c_equip_feature">

                                        <div class="c_e_fea_hug new_feature_patch_v1">
                                            <div class="cefea-top">
                                                <div class="cfa-select">
                                                    <ul>
                                                        <li>
                                                            <div class={"imgt " + (selectSpecType == 1 ? "cfa-selet-active" :"") } onClick={()=>setSelectSpecType(1)}>
                                                                <img src={'/reSpec/1'+(selectSpecType == 1 ? "active" :"")+'.png'}></img>
                                                            </div>
                                                            <span class="fts-tit">규율</span>
                                                        </li>
                                                        <li>
                                                            <div class={"imgt " + (selectSpecType == 2 ? "cfa-selet-active" :"") } onClick={()=>setSelectSpecType(2)}>
                                                                <img src={'/reSpec/2'+(selectSpecType == 2 ? "active" :"")+'.png'}></img>
                                                            </div>
                                                            <span class="fts-tit">맹공</span>

                                                        </li>
                                                        <li>
                                                            <div class={"imgt " + (selectSpecType == 3 ? "cfa-selet-active" :"") } onClick={()=>setSelectSpecType(3)}>
                                                                <img src={'/reSpec/3'+(selectSpecType == 3 ? "active" :"")+'.png'}></img>
                                                            </div>
                                                            <span class="fts-tit">보호</span>

                                                        </li>
                                                        <li>
                                                            <div class={"imgt " + (selectSpecType == 4 ? "cfa-selet-active" :"") } onClick={()=>setSelectSpecType(4)}>
                                                                <img src={'/reSpec/4'+(selectSpecType == 4 ? "active" :"")+'.png'}></img>
                                                            </div>
                                                            <span class="fts-tit">구현</span>

                                                   
                                                        </li>
                                                    </ul>
                                                </div>
                                          
                                            </div>
                                            <div class="cefea-mid">
                                                <div class="cfa-skill-selcet">
                                                    <div class="cfa-select">
                                                        <ul>
                                                    
                                                            <li>
                                                                <Trait slot="11" type="spec0"/>
                                                            </li>
                                                            <li>
                                                                <Trait slot="12" type="spec0"/>
                                                            </li>
                                                            <li>
                                                                <Trait slot="13" type="spec0"/>
                                                            </li>
                                                            <li>
                                                                <Trait slot="14" type="spec0"/>
                                                            </li>
                                                        </ul>
                                           
                                                        <ul>
                                                    
                                                            <li>
                                                                <Trait slot="21" type="spec1"/>
                                                            </li>
                                                            <li>
                                                                <Trait slot="22" type="spec1"/>
                                                            </li>
                                                            <li>
                                                                <Trait slot="23" type="spec1"/>
                                                            </li>
                                                            <li>
                                                                <Trait slot="24" type="spec1"/>
                                                            </li>
                                                        </ul>

                                                        <ul>
                                                    
                                                            <li>
                                                                <Trait slot="31" type="spec2"/>
                                                            </li>
                                                            <li>
                                                                <Trait slot="32" type="spec2"/>
                                                            </li>
                                                            <li>
                                                                <Trait slot="33" type="spec2"/>
                                                            </li>
                                                            <li>
                                                                <Trait slot="34" type="spec2"/>
                                                            </li>
                                                        </ul>

                                                        <ul>
                                                    
                                                            <li>
                                                                <Trait slot="41" type="spec3"/>
                                                            </li>
                                                            <li>
                                                                <Trait slot="42" type="spec3"/>
                                                            </li>
                                                            <li>
                                                                <Trait slot="43" type="spec3"/>
                                                            </li>
                                                            <li>
                                                                <Trait slot="44" type="spec3"/>
                                                            </li>
                                                        </ul>

                                                        <ul>
                                                            <li>
                                                                <Trait slot="51" type="spec4"/>
                                                            </li>
                                                            <li>
                                                                <Trait slot="52" type="spec4"/>
                                                            </li>
                                                            <li>
                                                                <Trait slot="53" type="spec4"/>
                                                            </li>
                                                            <li>
                                                                <Trait slot="54" type="spec4"/>
                                                            </li>
                                                        </ul>
                                                     
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div class="c_e_fea_hug">
                                            <div class="cefea-top">
                                                <div class="cfa-select">
                                                    <ul>
                                                        <li>
                                                            <div class={"imgt " + (selectSpecType == 1 ? "cfa-selet-active" :"") } onClick={()=>setSelectSpecType(1)}>
                                                                <img src={'/reSpec/1'+(selectSpecType == 1 ? "active" :"")+'.png'}></img>
                                                            </div>
                                                            <span class="fts-tit">상태이상</span>
                                                        </li>
                                                        <li>
                                                            <div class={"imgt " + (selectSpecType == 2 ? "cfa-selet-active" :"") } onClick={()=>setSelectSpecType(2)}>
                                                                <img src={'/reSpec/2'+(selectSpecType == 2 ? "active" :"")+'.png'}></img>
                                                            </div>
                                                            <span class="fts-tit">MP관리</span>

                                                        </li>
                                                        <li>
                                                            <div class={"imgt " + (selectSpecType == 3 ? "cfa-selet-active" :"") } onClick={()=>setSelectSpecType(3)}>
                                                                <img src={'/reSpec/3'+(selectSpecType == 3 ? "active" :"")+'.png'}></img>
                                                            </div>
                                                            <span class="fts-tit">유틸</span>

                                                        </li>
                                                        <li>
                                                            <div class={"imgt " + (selectSpecType == 4 ? "cfa-selet-active" :"") } onClick={()=>setSelectSpecType(4)}>
                                                                <img src={'/reSpec/4'+(selectSpecType == 4 ? "active" :"")+'.png'}></img>
                                                            </div>
                                                            <span class="fts-tit">스킬</span>

                                                        </li>
                                                        <li>
                                                            <div class={"imgt " + (selectSpecType == 5 ? "cfa-selet-active" :"") } onClick={()=>setSelectSpecType(5)}>
                                                                <img src={'/reSpec/5'+(selectSpecType == 5 ? "active" :"")+'.png'}></img>
                                                            </div>
                                                            <span class="fts-tit">컨트롤/생존</span>

                                                        </li>
                                                        <li>
                                                            <div class={"imgt " + (selectSpecType == 6 ? "cfa-selet-active" :"") } onClick={()=>setSelectSpecType(6)}>
                                                                <img src={'/reSpec/6'+(selectSpecType == 6 ? "active" :"")+'.png'}></img>
                                                            </div>
                                                            <span class="fts-tit">버퍼</span>

                                                        </li>
                                                    </ul>
                                                </div>
                                          
                                            </div>
                                            <div class="cefea-mid">
                                                <div class="cfa-skill-selcet">
                                                    <div class="cfa-select">
                                                        <ul>
                                                            <li>
                                                                <div class="csk-sel-tit">
                                                                    <span>LV.1</span>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div class="cfa-hover-side">
                                                                    <div class="imgt" onClick={()=>{characterData['spec0'] != selectSpecType+'11' ? change("spec0",selectSpecType+'11') : change("spec0", 0) }}>
                                                                        <img src={'/reSpec/'+selectSpecType+'11'+(characterData['spec0'] == selectSpecType+'11' ? "active" : "")+'.png'}></img>
                                                                    </div>
                                                                    <div class="euip-fea-addbox">
                                                                        <div class="ef-ab-cont">
                                                                            <span>{trait[selectSpecType+"11"] ? trait[selectSpecType+"11"] : "" }</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div class="cfa-hover-side">

                                                                    <div class="imgt" onClick={()=>{characterData['spec0'] != selectSpecType+'12' ? change("spec0",selectSpecType+'12') : change("spec0", 0) }}>
                                                                        <img src={'/reSpec/'+selectSpecType+'12'+(characterData['spec0'] == selectSpecType+'12' ? "active" : "")+'.png'}></img>
                                                                    </div>
                                                                    <div class="euip-fea-addbox">
                                                                            <div class="ef-ab-cont">
                                                                                <span>{trait[selectSpecType+"12"] ? trait[selectSpecType+"12"] : "" }</span>
                                                                            </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div class="cfa-hover-side">
                                                                    <div class="imgt" onClick={()=>{characterData['spec0'] != selectSpecType+'13' ? change("spec0",selectSpecType+'13') : change("spec0", 0) }}>
                                                                        <img src={'/reSpec/'+selectSpecType+'13'+(characterData['spec0'] == selectSpecType+'13' ? "active" : "")+'.png'}></img>
                                                                    </div>
                                                                    <div class="euip-fea-addbox">
                                                                        <div class="ef-ab-cont">
                                                                            <span>{trait[selectSpecType+"13"] ? trait[selectSpecType+"13"] : "" }</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div class="cfa-hover-side">
                                                                    <div class="imgt" onClick={()=>{characterData['spec0'] != selectSpecType+'14' ? change("spec0",selectSpecType+'14') : change("spec0", 0) }}>
                                                                    <img src={'/reSpec/'+selectSpecType+'14'+(characterData['spec0'] == selectSpecType+'14' ? "active" : "")+'.png'}></img>
                                                                    </div>
                                                                    <div class="euip-fea-addbox">
                                                                        <div class="ef-ab-cont">
                                                                            <span>{trait[selectSpecType+"14"] ? trait[selectSpecType+"14"] : "" }</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                        <ul class="fea-2">
                                                            <li>
                                                                <div class="csk-sel-tit">
                                                                    <span>LV.120</span>
                                                                </div>
                                                            </li>
                                                            <li class="fea-2-c">
                                                                <div class="cfa-hover-side">
                                                                    <div class="imgt" onClick={()=>{characterData['spec4'] != selectSpecType+'41' ? change("spec4",selectSpecType+'41') : change("spec4", 0) }}>
                                                                        <img src={'/reSpec/'+selectSpecType+'41'+(characterData['spec4'] == selectSpecType+'41' ? "active" : "")+'.png'}></img>
                                                                    </div>
                                                                    <div class="euip-fea-addbox">
                                                                        <div class="ef-ab-cont">
                                                                            <span>{trait[selectSpecType+"41"] ? trait[selectSpecType+"41"] : "" }</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li class="fea-2-c">
                                                                <div class="cfa-hover-side">

                                                                    <div class="imgt" onClick={()=>{characterData['spec4'] != selectSpecType+'42' ? change("spec4",selectSpecType+'42') : change("spec4", 0) }}>
                                                                        <img src={'/reSpec/'+selectSpecType+'42'+(characterData['spec4'] == selectSpecType+'42' ? "active" : "")+'.png'}></img>
                                                                    </div>
                                                                    <div class="euip-fea-addbox">
                                                                            <div class="ef-ab-cont">
                                                                                <span>{trait[selectSpecType+"42"] ? trait[selectSpecType+"42"] : "" }</span>
                                                                            </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                  
                                                        </ul>
                                                        <ul>
                                                            <li>
                                                                <div class="csk-sel-tit">
                                                                    <span>LV.240</span>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div class="cfa-hover-side">
                                                                    <div class="imgt" onClick={()=>{characterData['spec1'] != selectSpecType+'21' ? change("spec1",selectSpecType+'21') : change("spec1", 0) }}>
                                                                        <img src={'/reSpec/'+selectSpecType+'21'+(characterData['spec1'] == selectSpecType+'21' ? "active" : "")+'.png'}></img>
                                                                    </div>
                                                                    <div class="euip-fea-addbox">
                                                                        <div class="ef-ab-cont">
                                                                            <span>{trait[selectSpecType+"21"] ? trait[selectSpecType+"21"] : "" }</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div class="cfa-hover-side">

                                                                    <div class="imgt" onClick={()=>{characterData['spec1'] != selectSpecType+'22' ? change("spec1",selectSpecType+'22') : change("spec1", 0) }}>
                                                                        <img src={'/reSpec/'+selectSpecType+'22'+(characterData['spec1'] == selectSpecType+'22' ? "active" : "")+'.png'}></img>
                                                                    </div>
                                                                    <div class="euip-fea-addbox">
                                                                        <div class="ef-ab-cont">
                                                                            <span>{trait[selectSpecType+"22"] ? trait[selectSpecType+"22"] : "" }</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div class="cfa-hover-side">

                                                                    <div class="imgt" onClick={()=>{characterData['spec1'] != selectSpecType+'23' ? change("spec1",selectSpecType+'23') : change("spec1", 0) }}>
                                                                        <img src={'/reSpec/'+selectSpecType+'23'+(characterData['spec1'] == selectSpecType+'23' ? "active" : "")+'.png'}></img>
                                                                    </div>
                                                                    <div class="euip-fea-addbox">
                                                                        <div class="ef-ab-cont">
                                                                            <span>{trait[selectSpecType+"23"] ? trait[selectSpecType+"23"] : "" }</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div class="cfa-hover-side">

                                                                    <div class="imgt" onClick={()=>{characterData['spec1'] != selectSpecType+'24' ? change("spec1",selectSpecType+'24') : change("spec1", 0) }}>
                                                                        <img src={'/reSpec/'+selectSpecType+'24'+(characterData['spec1'] == selectSpecType+'24' ? "active" : "")+'.png'}></img>
                                                                    </div>
                                                                    <div class="euip-fea-addbox">
                                                                        <div class="ef-ab-cont">
                                                                            <span>{trait[selectSpecType+"24"] ? trait[selectSpecType+"24"] : "" }</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                        <ul class="fea-2">
                                                            <li>
                                                                <div class="csk-sel-tit">
                                                                    <span>LV.360</span>
                                                                </div>
                                                            </li>
                                                            <li class="fea-2-c">
                                                                <div class="cfa-hover-side">
                                                                    <div class="imgt" onClick={()=>{characterData['spec5'] != selectSpecType+'41' ? change("spec5",selectSpecType+'41') : change("spec5", 0) }}>
                                                                        <img src={'/reSpec/'+selectSpecType+'41'+(characterData['spec5'] == selectSpecType+'41' ? "active" : "")+'.png'}></img>
                                                                    </div>
                                                                    <div class="euip-fea-addbox">
                                                                        <div class="ef-ab-cont">
                                                                            <span>{trait[selectSpecType+"41"] ? trait[selectSpecType+"41"] : "" }</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li class="fea-2-c">
                                                                <div class="cfa-hover-side">

                                                                    <div class="imgt" onClick={()=>{characterData['spec5'] != selectSpecType+'42' ? change("spec5",selectSpecType+'42') : change("spec5", 0) }}>
                                                                        <img src={'/reSpec/'+selectSpecType+'42'+(characterData['spec5'] == selectSpecType+'42' ? "active" : "")+'.png'}></img>
                                                                    </div>
                                                                    <div class="euip-fea-addbox">
                                                                            <div class="ef-ab-cont">
                                                                                <span>{trait[selectSpecType+"42"] ? trait[selectSpecType+"42"] : "" }</span>
                                                                            </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        
                                                        </ul>
                                                        <ul>
                                                            <li>
                                                                <div class="csk-sel-tit">
                                                                    <span>LV.480</span>
                                                                </div>
                                                            </li>
                                                         
                                                            <li>
                                                                <div class="cfa-hover-side">

                                                                    <div class="imgt" onClick={()=>{characterData['spec2'] != selectSpecType+'31' ? change("spec2",selectSpecType+'31') : change("spec2", 0) }}>
                                                                        <img src={'/reSpec/'+selectSpecType+'31'+(characterData['spec2'] == selectSpecType+'31' ? "active" : "")+'.png'}></img>
                                                                    </div>
                                                                
                                                                     <div class="euip-fea-addbox">
                                                                        <div class="ef-ab-cont">
                                                                            <span>{trait[selectSpecType+"31"] ? trait[selectSpecType+"31"] : "" }</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div class="cfa-hover-side">

                                                                    <div class="imgt" onClick={()=>{characterData['spec2'] != selectSpecType+'32' ? change("spec2",selectSpecType+'32') : change("spec2", 0) }}>
                                                                        <img src={'/reSpec/'+selectSpecType+'32'+(characterData['spec2'] == selectSpecType+'32' ? "active" : "")+'.png'}></img>
                                                                    </div>
                                                                    <div class="euip-fea-addbox">
                                                                        <div class="ef-ab-cont">
                                                                            <span>{trait[selectSpecType+"32"] ? trait[selectSpecType+"32"] : "" }</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div class="cfa-hover-side">

                                                                    <div class="imgt" onClick={()=>{characterData['spec2'] != selectSpecType+'33' ? change("spec2",selectSpecType+'33') : change("spec2", 0) }}>
                                                                        <img src={'/reSpec/'+selectSpecType+'33'+(characterData['spec2'] == selectSpecType+'33' ? "active" : "")+'.png'}></img>
                                                                    </div>
                                                                    <div class="euip-fea-addbox">
                                                                        <div class="ef-ab-cont">
                                                                            <span>{trait[selectSpecType+"33"] ? trait[selectSpecType+"33"] : "" }</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div class="cfa-hover-side">

                                                                    <div class="imgt" onClick={()=>{characterData['spec2'] != selectSpecType+'34' ? change("spec2",selectSpecType+'34') : change("spec2", 0) }}>
                                                                        <img src={'/reSpec/'+selectSpecType+'34'+(characterData['spec2'] == selectSpecType+'34' ? "active" : "")+'.png'}></img>
                                                                    </div>

                                                                    <div class="euip-fea-addbox">
                                                                        <div class="ef-ab-cont">
                                                                            <span>{trait[selectSpecType+"34"] ? trait[selectSpecType+"34"] : "" }</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                
                                                            </li>
                                                        </ul>
                                  
                                                     
                                                    </div>
                                                </div>
                                            </div>
                                        </div> 
                                        <div class="cus_button" onClick={()=>{ submitSetting()}}>
                                            <div class="c_but">
                                                <span className='cbut-tag'  >
                                                적용
                                                </span>
                                            </div>
                                        </div>

                                    </div> 
                                </div>*/}
                                </div>
                        </div>
                    </div>

            </div>
            <div id="mo-ad-container-1"></div>
            <Script src="js/swiperTab.js"/>
            
            <div class='coupang'>
            </div>
            <Script src="https://cp.edl.co.kr/cou/js.php?code=dundamdw1&flt=0&click_type=1&width=320&height=50&adid=&click_id="></Script>
            
        </section>

    )}

      


/**
변수 변경 ex) 장비 이름 변경시 change('equipName0','불가침의 영역 - 소검');
@param {*} name 변경할 변수 이름 - equip : name,level,reinforceNum,reinforceType,enchant
@param {*} value 변경 값 
*/
function weaponPlusChange(index,value)
{
    
    let temp = [...weaponPlusArray];

    temp[index] = value;
    
    
    setWeaponPlusArray(temp);
}


function legendStoneChange(slot,index,value)
{
    
    let temp = legendStoneArray.map(v => [...v]);

    temp[slot-10][index] = value;
    
    
    setLegendStoneArray(temp);
}

function legendStoneSelectChange(slot,index,value)
{
    
    let temp = legendStoneSelectArray.map(v => [...v]);

    temp[slot-10][index] = value;
    
    
    setLegendStoneSelectArray(temp);
}


function weaponPlusSelectArrayChange(index,value)
{
    
    //console.log(index);
    //console.log(value);
    
    let temp = [...weaponPlusSelectArray];

    temp[index] = value;
    //console.log(temp);
    
    
    setWeaponPlusSelectArray(temp);
}


/**
변수 변경 ex) 장비 이름 변경시 change('equipName0','불가침의 영역 - 소검');
@param {*} name 변경할 변수 이름 - equip : name,level,reinforceNum,reinforceType,enchant
@param {*} value 변경 값 
*/
function customChange(id,index,value)
{
    
    let temp = {...customArray};

    temp[id][index] = value;
    
    
    setCustomArray(temp);
}

/**
변수 변경 ex) 장비 이름 변경시 change('equipName0','불가침의 영역 - 소검');
@param {*} name 변경할 변수 이름 - equip : name,level,reinforceNum,reinforceType,enchant
@param {*} value 변경 값 
*/
function customSelectChange(id,index,value)
{
    
    let temp = {...customSelectArray};

    temp[id][index] = value;
    
    
    setCustomSelectArray(temp);
}

function customSearchChange(id,index,value)
{
    
    let temp = {...customSearch};

    temp[id][index] = value;
    
    
    setCustomSearch(temp);
}


function weaponOpSearchChange(index,value)
{
    
    let temp = {...weaponOpSearch};

    temp[index] = value;
    
    
    setWeaponOpSearch(temp);
}




/**
변수 변경 ex) 장비 이름 변경시 change('equipName0','불가침의 영역 - 소검');
@param {*} name 변경할 변수 이름 - equip : name,level,reinforceNum,reinforceType,enchant
@param {*} value 변경 값 
*/


function change(name,value,index = 0)
{
    
    let temp = {...characterData};

    if(index == 0)
    {
        temp[name] = value;
    }
    
    if(name.indexOf('equipLevel') != -1)
    {
        if(temp['itemLevel'+name.replace('equipLevel','').replace('C0','')] == 110)
        {
            if(value >= 60)
            {
                temp[name] = 60;
            }
        }
        else
        {
            if(value >= 80)
            {
                temp[name] = 80;
            }
        }
    }

    if(name.indexOf('itemLevel') != -1)
    {
        //console.log(name);
        //console.log(value);
        if(value == 110)
        if(temp['equipLevel'+name.replace('itemLevel','')+'C0'] > 60)
        {
            temp['equipLevel'+name.replace('itemLevel','')+'C0'] = 60;
        }
    }

    if(name.indexOf('equipName1') != -1)
    {
        if(value.indexOf("피해 증가") != -1)
        {
            temp['equipValue1'] = 20;
        }
    }

    if(name.indexOf('equipName') != -1)
    {
        if(value.indexOf("불가침의 영역") != -1)
        {
            temp['itemLevel'+name.replace('equipName','')] = 105;
            temp['equipLevel'+name.replace('equipName','')+'C0'] = 70;
        }
    }

    if(temp['itemLevel'+name.replace('equipName','')] == 115)
    {
        temp['itemLevel'+name.replace('equipName','')] = 110;
    }


    setCharacterData(temp);
    getSetPoint(temp);
    

}


/**
장비 한 부위 모든 레벨 수정
@param {*} name 'equipLevel0' ~ 'equipLevel12' 
@param {*} value '원하는 레벨값'
*/
function lvAllChange(name,value)
{
    let temp = {...characterData};

    for(let loop = 0 ; loop < 4 ; loop++)
    {
        temp[name+'C'+loop] = value;
    }
    
    setCharacterData(temp);

}




  

/**
전체장비 모든 레벨 수정
@param {*} value '원하는 레벨값'
*/
function allEquipLvAllChange(value)
{
    
    let temp = {...characterData};
    for(let loop = 0 ; loop < 13 ; loop++)
    {
        if(loop == 1) continue;

        for(let lvloop = 0 ; lvloop < 4 ; lvloop++)
        {
            temp['equipLevel'+loop+'C'+lvloop] = value;
        }
    }
    
    setCharacterData(temp);

}

function ItemOption(props)
{
    return (
        
        <>
        {

            lv105OptionMap[props.name] != null ?                                           
            <div class="option">
                <span class="op_gold">피해 증가</span> + {lv105OptionMap[props.name] != null ? lv105OptionMap[props.name].split('@@')[1] : ''}
                <br/>
                {lv105OptionMap[props.name] != null ? lv105OptionMap[props.name].split('@@')[0].replace(/<br>/gi,'\n')  : ''}
            </div>
            : '' 
        }
        </>
    
                        
        
        
    )
}

function PresentEquip(props)
{
    let temp = characterData['equipName'+props.number];
    let level = characterData['itemLevel'+props.number];
    if(level != 115 || isRaidWeapon(temp) || temp.indexOf("불가침의 영역") != -1)
    {
        return(
            <li onClick={ () => {setPresent(props.number)} } style={ present == props.number ? {border : '2px solid red'}  : {}} >
                <span>
                    <div class="imgt">
                        {
                            props.number != 1 ? <img src={'https://dundam.xyz/img/item/'+lv105ItemId[characterData['equipName'+props.number]]}  /> : <></>
                        }

                        {
                            props.number == 1 ? <img src={'https://dundam.xyz/img/item/'+titleToImage()}  /> : <></>
                        }

                        
                        
                    </div>
                    <div class="cus_stat">
                        <div class="cs_p">+{characterData['equipReinforceNum'+props.number]}</div>
                        <div class="cs_at">{characterData['siroco'+props.number] != null ? characterData['siroco'+props.number].charAt(0) : ""}</div>
                        <div class="cs_st">
                            <div class="csu_div">
                                <EnchantPreview id={props.number}/>
                            </div>
                            
                        </div>
                    </div>
                </span>
            </li>
        )
    }
    else
    {
        let folderName = "weapon";
        switch(props.number)
        {
            case 2: folderName ="coat"; break;
            case 4: folderName ="pants"; break;
            case 3: folderName ="neck"; break;
            case 6: folderName ="belt"; break;
            case 5: folderName ="shoes"; break;

            case 8: folderName ="bracelet"; break;
            case 7: folderName ="necklace"; break;
            case 9: folderName ="ring"; break;

            case 10: folderName ="support"; break;
            case 11: folderName ="magicstone"; break;
            case 12: folderName ="earrring"; break;
        }
        //115렙제 장착
        return(
            <li onClick={ () => {setPresent(props.number)} } style={ present == props.number ? {border : '2px solid red'}  : {}} >
                <span>
                    <div class="imgt">
                        {
                            props.number != 1 ? <img src={'https://dundam.xyz/img/lv115/'+folderName+'/'+characterData['equipName'+props.number]+'.png'}  /> : <></>
                        }
                    </div>
                    <div class="cus_stat">
                        <div class="cs_p">+{characterData['equipReinforceNum'+props.number]}</div>
                        <div class="cs_at">{characterData['siroco'+props.number] != null ? characterData['siroco'+props.number].charAt(0) : ""}</div>
                        <div class="cs_st">
                            <div class="csu_div">
                                <EnchantPreview id={props.number}/>
                            </div>
                            
                        </div>
                    </div>
                </span>
            </li>
        )
    }
}
function TitleSetting(props)
{
    
    return(

        <div class="cus_sest" style={props.id == present ? {'whiteSpace': 'pre-line'} : {display:'none'} }>

                <div class="esetting_tr cusper73 cusp6" style={{ 'display': 'unset'}}>
                    <div class="estcont">
                        <div class="cus_chkbut cusb8">
                        
                            <ul>
                                <li onClick={()=>change("equipName1","데미지 증가")} className={characterData['equipName1'] == "데미지 증가" ? "is-active" : ""}>데미지 증가</li>
                                <li onClick={()=>change("equipName1","크리티컬 증가 데미지")} className={characterData['equipName1'] == "크리티컬 증가 데미지" ? "is-active" : ""}>크리티컬 증가 데미지</li>
                            </ul>
                            <ul>
                                <li onClick={()=>change("equipName1","추가 데미지 증가")} className={characterData['equipName1'] == "추가 데미지 증가" ? "is-active" : ""}>추가 데미지 증가</li>
                                <li onClick={()=>change("equipName1","속성 강화")} className={characterData['equipName1'] == "속성 강화" ? "is-active" : ""}>속성 강화</li>
                            </ul>
                            <ul>
                                <li onClick={()=>{change("equipName1","피해 증가");}} className={characterData['equipName1'] == "피해 증가" ? "is-active" : ""}>피해 증가</li>
                                <li onClick={()=>change("equipName1","세리아의 풍성한 선물")} className={characterData['equipName1'] == "세리아의 풍성한 선물" ? "is-active" : ""}>세리아의 풍성한 선물</li>
                            </ul>
                        </div>
                    </div>
                    <div class="estcont cjae">
                        <div class="cus_chkbut">
                            <ul>
                                <li>수치</li>
                            </ul>
                        </div>

                        <div class="cus_range">
                            <span id="rangeValue">{characterData['equipValue1']}</span>
                            <input class="crange" type="range" value={characterData['equipValue1']} min={characterData['equipName1'] == "피해 증가" ? 18 : 10} max={characterData['equipName1'] == "속성 강화" ? 35 : 20} step="1"
                            onChange={(event)=>change("equipValue1",event.target.value)}></input>
                        </div>
                        
                    </div>
                    
                    
                    
                    
                    
                </div>

                <div class="esetting_tr cusper73 cusp6">
                    <div class="estcont" >
                        <div class="cus_chkbut cusb8">
                            <ul>
                                <li onClick={()=>{characterData['equip1Skill'] == "30"? change("equip1Skill","0") : change("equip1Skill","30")}} className={characterData['equip1Skill'] == "30" ? "is-active" : ""} lv="30">30Lv</li>
                                <li onClick={()=>{characterData['equip1Skill'] == "35"? change("equip1Skill","0") : change("equip1Skill","35")}} className={characterData['equip1Skill'] == "35" ? "is-active" : ""} lv="35">35Lv</li>
                                <li onClick={()=>{characterData['equip1Skill'] == "40"? change("equip1Skill","0") : change("equip1Skill","40")}} className={characterData['equip1Skill'] == "40" ? "is-active" : ""}lv="40">40Lv</li>
                                <li onClick={()=>{characterData['equip1Skill'] == "45"? change("equip1Skill","0") : change("equip1Skill","45")}} className={characterData['equip1Skill'] == "45" ? "is-active" : ""}lv="45">45Lv</li>
                                <li onClick={()=>{characterData['equip1Skill'] == "50"? change("equip1Skill","0") : change("equip1Skill","50")}} className={characterData['equip1Skill'] == "50" ? "is-active" : ""}lv="50">50Lv</li>
                                <li onClick={()=>{characterData['equip1Skill'] == "60"? change("equip1Skill","0") : change("equip1Skill","60")}} className={characterData['equip1Skill'] == "60" ? "is-active" : ""} lv="60">60Lv</li>
                            </ul>
                            <ul>
                                <li onClick={()=>{characterData['equip1Skill'] == "70"? change("equip1Skill","0") : change("equip1Skill","70")}} className={characterData['equip1Skill'] == "70" ? "is-active" : ""} lv="70">70Lv</li>
                                <li onClick={()=>{characterData['equip1Skill'] == "75"? change("equip1Skill","0") : change("equip1Skill","75")}} className={characterData['equip1Skill'] == "75" ? "is-active" : ""} lv="75">75Lv</li>
                                <li onClick={()=>{characterData['equip1Skill'] == "80"? change("equip1Skill","0") : change("equip1Skill","80")}} className={characterData['equip1Skill'] == "80" ? "is-active" : ""} lv="80">80Lv</li>
                                <li onClick={()=>{characterData['equip1Skill'] == "85"? change("equip1Skill","0") : change("equip1Skill","85")}} className={characterData['equip1Skill'] == "85" ? "is-active" : ""} lv="85">85Lv</li>
                                <li onClick={()=>{characterData['equip1Skill'] == "95"? change("equip1Skill","0") : change("equip1Skill","95")}} className={characterData['equip1Skill'] == "95" ? "is-active" : ""} lv="95">95Lv</li>
                                <li onClick={()=>{characterData['equip1Skill'] == "100"? change("equip1Skill","0") : change("equip1Skill","100")}} className={characterData['equip1Skill'] == "100" ? "is-active" : ""} lv="100">100Lv</li>
                            </ul>
                        </div>
                        
                    </div>
                    
                </div>
                    
                <div class="esetting_tr cusp2">
                    <div class="estcont  cussa">
                        <div class="cus_esttit">
                            <ul>
                                <li>스탯</li>
                            </ul>
                        </div>

                        <div class="cus_range">
                            <span id="rangeValue">{'\u00A0'}</span>
                            <div class="cus_rare">
                                
                                <input type="text" minlength="1" maxlength="2" value={characterData['equipEnchantStat'+props.id]}
                                onChange={ (event) => change('equipEnchantStat'+props.id,event.target.value)} >

                                </input>
                            </div>
                            <input class="crange" type="range" value={characterData['equipEnchantStat'+props.id]} min="0" max={dStat[props.id]}
                                 onChange={ (event) => change('equipEnchantStat'+props.id,event.target.value)} />
                        </div>
    
                    </div>
                    <div class="estcont  cussa">
                    </div>
                    <div class="estcont  cussa">
                        <div class="cus_esttit">
                            <ul>
                                <li>속강</li>
                            </ul>
                        </div>

                        <div class="cus_range">
                            <span id="rangeValue">{'\u00A0'}</span>
                            <div class="cus_rare">
                                
                                <input type="text" minlength="1" maxlength="3" value={characterData['equipEnchantEle'+props.id]}
                                onChange={ (event) => change('equipEnchantEle'+props.id,event.target.value)} >

                                </input>
                            </div>
                            <input class="crange" type="range" value={characterData['equipEnchantEle'+props.id]} min="0" max={dEle[props.id]}
                                 onChange={ (event) => change('equipEnchantEle'+props.id,event.target.value)} />
                        </div>
    
    
                    </div>
                </div>

        </div>
    )

}

//추가 장비

function SubWeaponSetting(props)
{
    return(
        <div class="esetting_tr cusp1" style={present == 116 ? {} : {display : 'none'}}>
            <div class="estcont">

                <div class="cus_chkbut">
                    <ul>
                        <li onClick={()=> change('subWeaponReinforceType','강화')} className={characterData['subWeaponReinforceType'] == '강화' ? 'is-active' : ''}>강화</li>
                        <li onClick={()=> change('subWeaponReinforceType','증폭')} className={characterData['subWeaponReinforceType'] == '증폭' ? 'is-active' : ''}>증폭</li>
                    </ul>
                </div>

                <div class="cus_range">
                <span id="rangeValue">{'\u00A0'}</span>
                    <div class="cus_rare">
                        <input type="text" minlength="1" maxlength="2" value={characterData['subWeaponReinforce']}
                        onChange={ (event) => change('subWeaponReinforce',event.target.value)} >

                        </input>
                    </div>
                    <input class="crange" type="range" value={characterData['subWeaponReinforce']} min="0" max="18"
                        onChange={ (event) => change('subWeaponReinforce',event.target.value)} >
                    </input>
                </div>
            </div>
        </div> 
        
    )
}
function SubWeapon(props)
{
    return(
        <li onClick={ () => {setPresent(props.number)} } style={ present == props.number ? {border : '2px solid red'}  : {}} >
            <span>
                <div class="imgt">
                    <img src={'https://dundam.xyz/img/item/1666f7be2f22b01b4bbb559c7bc51c99'}  />
                </div>
                <div class="cus_stat">
                    <div class="cs_p">+{characterData['subWeaponReinforce']}</div>
                </div>
            </span>


        </li>
    )
}

function PlusEquip(props)
{
    return(
        <li onClick={ () => {setPresent(props.number)} } style={ present == props.number ? {border : '2px solid red'}  : {}} >
            <span>
                <div class="imgt">
                    {
                        props.number == 113 ? <img src={'https://dundam.xyz/img/item/5838c99d8d457c6422a7b8027ef9f7df'}  /> : <></>
                    }
                    {
                        props.number == 114 ? <img src={'https://dundam.xyz/img/item/2fd4d583eef28dfcca026403aa786fa9'}  /> : <></>
                    }
                    
                </div>
                
            </span>
        </li>
    )
}

function PlusTitle(props)
{
    return(
        <li onClick={ () => {setPresent(props.number)} } style={ present == props.number ? {border : '2px solid red'}  : {}} >
            <span>
                <div class="imgt">
                    {
                        props.number == 115 ? <img src={'https://dundam.xyz/img/item/'+titleToImageBuffer(characterData['buffTitle'])}  /> : <></>
                    }
                    {
                        props.number == 1 ? <img src={'https://dundam.xyz/img/item/'+titleToImageBuffer(characterData['equipName1'])}  /> : <></>
                    }

                </div>
                
            </span>
        </li>
    )
}



function titleToImage(input)
{
    let name = characterData['equipName1'];
    let value = characterData['equipValue1'];
    
    
    if(name.indexOf('피해 증가') != -1 )
    {
        if(value == 18) return "c89a01593a155a3cbaaf272084d8fb57";
        if(value == 20) return "42fd3d4144f788b37ed19c70281dcda6";
    }
    if(name.indexOf('세리아의 풍성한 선물') != -1 )
    {
        return "29f088750881775babb636fdfda1d705";
    }

    if(name.indexOf('데미지 증가') != -1 )
    {
        if(value == 10) return "ef0aeb22ce891560570c260f55f3e93a";
        if(value == 15) return "716318716889dede836f92308e652035";
    }
    
    if(name.indexOf('크리티컬 증가 데미지') != -1 )
    {
        if(value == 10) return "67854cb66148289ea8dadcd1a3246055";
    }

    if(name.indexOf('추가 데미지 증가') != -1 )
    {
        if(value == 10) return "3b6093bb111cafc013b566dbf8c60792";
    }
    
    if(name.indexOf('속성 강화') != -1 )
    {
        if(value == 35) return "1e4e58fc3af28a88cbef9da09b8e937d";
        if(value == 32) return "74b07e0f284788f0c30dd340e7dbb2ec";
        if(value == 16) return "3fe950db4b3565f8d1ef6f616a6ecefb";
    }


}

function titleToImageBuffer(input)
{
    if(input != null)   
    if(input.indexOf('성역의 경계') != -1)
    {
        return "ef0aeb22ce891560570c260f55f3e93a";
    }
    else if(input.indexOf('세리아의 풍성한 선물') != -1 )
    {
        return "29f088750881775babb636fdfda1d705";
    }
    else if(input.indexOf('30Lv') != -1)
    {
        return "9278f6124ab894a40cb52793561439e9";
    }
    else if(input.indexOf('50Lv') != -1)
    {
        return "4ebfb6cd76c82aefb2f0be124974a5fd";
    }
    else if(input.indexOf('버프력') != -1)
    {
        return "c89a01593a155a3cbaaf272084d8fb57";
    }
    else if(input.indexOf('스탯 + 100') != -1)
    {
        return "875c6c5e8d257a0d844ac968779d3988";
    }
    else if(input.indexOf('피해 증가 20%') != -1)
    {
        return "42fd3d4144f788b37ed19c70281dcda6";
    }
}



function PlusTitleSetting(props)
{
    return(
        <div class="cus_set"  style={present == props.id ? {} : {display : 'none'}}>
            <div class="cus_esetting">
                    
                <div class="esetting_tr cusper73 cusp6">
                    <div class="estcont" >
                        <div class="cus_chkbut cusb8">
                        
                            <ul>
                                <li onClick={()=>{change("buffTitle","없음")}} className={characterData["buffTitle"] == '없음' ? "is-active" : ""}>없음</li>
                                <li onClick={()=>{change("buffTitle","30Lv + 1")}} className={characterData["buffTitle"] == '30Lv + 1' ? "is-active" : ""}>30Lv + 1</li>
                                <li onClick={()=>{change("buffTitle","30Lv + 2")}} className={characterData["buffTitle"] == '30Lv + 2' ? "is-active" : ""}>30Lv + 2</li>
                                <li onClick={()=>{change("buffTitle","성역의 경계")}} className={characterData["buffTitle"] == '성역의 경계' ? "is-active" : ""}>성역의 경계</li>
                                <li onClick={()=>{change("buffTitle","버프력 + 2%")}} className={characterData["buffTitle"] == '버프력 + 2%' ? "is-active" : ""}>버프력 + 2%</li>
                                <li onClick={()=>{change("buffTitle","세리아의 풍성한 선물")}} className={characterData["buffTitle"] == '세리아의 풍성한 선물' ? "is-active" : ""}>세리아의 풍성한 선물</li>
                                
                                <li onClick={()=>{change("buffTitle","피해 증가 20%")}} className={characterData["buffTitle"] == '피해 증가 20%' ? "is-active" : ""}>프로스트의 전설</li>
                            
                            </ul>
                            
                        </div>
                        
                    </div>
                    
                </div>
                
            </div>
        </div>

    )
}

function MainTitleSetting(props)
{
    return(
        <div class="cus_set"  style={present == props.id ? {} : {display : 'none'}}>
            <div class="cus_esetting">
                    
                <div class="esetting_tr cusper73 cusp6">
                    <div class="estcont" >
                        <div class="cus_chkbut cusb8">
                        
                            <ul>
                                <li onClick={()=>{change("equipName1","없음")}} className={characterData["equipName1"] == '없음' ? "is-active" : ""}>없음</li>
                                <li onClick={()=>{change("equipName1","스탯 + 100")}} className={characterData["equipName1"] == '스탯 + 100' ? "is-active" : ""}>스탯 + 100</li>
                                <li onClick={()=>{change("equipName1","50Lv + 1")}} className={characterData["equipName1"] == '50Lv + 1' ? "is-active" : ""}>50Lv + 1</li>
                                <li onClick={()=>{change("equipName1","50Lv + 2")}} className={characterData["equipName1"] == '50Lv + 2' ? "is-active" : ""}>50Lv + 2</li>
                                <li onClick={()=>{change("equipName1","버프력 + 2%")}} className={characterData["equipName1"] == '버프력 + 2%' ? "is-active" : ""}>버프력 + 2%</li>
                                <li onClick={()=>{change("equipName1","세리아의 풍성한 선물")}} className={characterData["equipName1"] == '세리아의 풍성한 선물' ? "is-active" : ""}>세리아의 풍성한 선물</li>
                                <li onClick={()=>{change("equipName1","피해 증가 20%")}} className={characterData["equipName1"] == '피해 증가 20%' ? "is-active" : ""}>프로스트의 전설</li>
                                
                            </ul>
                            
                        </div>
                        
                    </div>
                    
                </div>
                
            </div>
        </div>

    )
}


function PlusSetting(props)
{
    return(
        <div class="c_abata" style={present == props.id ? {} : {display : 'none'}}>
            <div class="cus_set sabatcus" >
                {
                props.id == 113 || props.id == 114 ? 
                    <div class="saba_basic" name="아바타 기본형">
                        <div class="abat_tit">
                            <span>{avatarTypeList[props.id - 110]}</span>
                        </div>
                        {
                                props.id != 114 ? 
                                <div class="abat_select">
                                <select id="v212" value={characterData["abaOption"+props.id]}  onChange={(event) => change("abaOption"+props.id,event.target.value)}>
                                    
                                    <option value={'0'}>없음</option>
                                    <option value={'30Lv + 1'}>30Lv + 1</option>
                            
                                </select>
                            </div> : ""
                        }
                        

                        { (props.id == 113 || props.id == 114) ?
                            <div class="abat_select" >
                                <select id="v212" value={characterData["emblemSkill"+props.id]} onChange={(event) => change("emblemSkill"+props.id,event.target.value)}>
                                    
                                    <option value={'0'}>없음</option>
                                    <option value={'30Lv + 1'}>30Lv + 1</option>
                                

                                </select>
                            </div> : <></>
                        }
                        
                        <div class="cus_esetting abatnum">
                            <div class="esetting_tr cusp2">
                                <div class="estcont  cussa">
                                    <div class="cus_esttit">
                                        <ul>
                                            <li>스탯</li>
                                        </ul>
                                    </div>

                                    <div class="cus_range">
                                        <span id="rangeValue">{characterData['emblemStat0'+props.id]}</span>
                                        <input class="crange" type="range" value={characterData['emblemStat0'+props.id]} min="0" max="25" step="1" onInput={(event)=>change("emblemStat0"+props.id,event.target.value)} >
                                        </input>
                                    </div>
                                </div>
                                <div class="estcont  cussa">
                                    <div class="cus_esttit">
                                        <ul>
                                            <li>스탯</li>
                                        </ul>
                                    </div>

                                    <div class="cus_range">
                                        <span id="rangeValue">{characterData['emblemStat1'+props.id]}</span>
                                        <input class="crange" type="range" value={characterData['emblemStat1'+props.id]} min="0" max="25" step="1" onChange={(event)=>change("emblemStat1"+props.id,event.target.value)} >
                                        </input>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        

                    </div>
                : 
                <></>
                
        }
        
            </div>
        </div>

    )
}





function WeaponList(props)
{
    if(weaponList[props.id] != null)
    {
        let weaponName = weaponList[props.id].replace("스탭","스태프");
        weaponName = weaponName.replace("코어블레이드","코어 블레이드");
        
        let weaponTemp = "";

        switch(weaponName)
        {
            case "크로스슈터" : weaponTemp = "cbow"; break;
            case "에테리얼 보우" : weaponTemp = "ebow"; break;
            case "장궁" : weaponTemp = "lbow"; break;
            case "선현궁" : weaponTemp = "sbow"; break;
            case "광창" : weaponTemp = "bs"; break;
            case "미늘창" : weaponTemp = "hb"; break;
            case "투창" : weaponTemp = "jav"; break;
            case "장창" : weaponTemp = "pike"; break;
            case "권투글러브" : weaponTemp = "bg"; break;
            case "클로" : weaponTemp = "claw"; break;
            case "건틀릿" : weaponTemp = "gau"; break;
            case "너클" : weaponTemp = "knu"; break;
            case "통파" : weaponTemp = "tonfa"; break;
            case "코어 블레이드" : weaponTemp = "core"; break;
            case "장도" : weaponTemp = "lbla"; break;
            case "중검" : weaponTemp = "mswd"; break;
            case "소태도" : weaponTemp = "sbla"; break;
            case "자동권총" : weaponTemp = "auto"; break;
            case "보우건" : weaponTemp = "bowgun"; break;
            case "핸드캐넌" : weaponTemp = "hcan"; break;
            case "머스켓" : weaponTemp = "musk"; break;
            case "리볼버" : weaponTemp = "revol"; break;
            case "빗자루" : weaponTemp = "bro"; break;
            case "봉" : weaponTemp = "pole"; break;
            case "로드" : weaponTemp = "rod"; break;
            case "창" : weaponTemp = "spear"; break;
            case "스태프" : weaponTemp = "staff"; break;
            case "배틀액스" : weaponTemp = "axe"; break;
            case "십자가" : weaponTemp = "cross"; break;
            case "염주" : weaponTemp = "rosa"; break;
            case "낫" : weaponTemp = "scythe"; break;
            case "토템" : weaponTemp = "totem"; break;
            case "차크라 웨펀" : weaponTemp = "chakra"; break;
            case "단검" : weaponTemp = "dagger"; break;
            case "쌍검" : weaponTemp = "twins"; break;
            case "완드" : weaponTemp = "wand"; break;
            case "광검" : weaponTemp = "beams"; break;
            case "대검" : weaponTemp = "gs"; break;
            case "도" : weaponTemp = "katana"; break;
            case "둔기" : weaponTemp = "club"; break;
            case "소검" : weaponTemp = "ss"; break;

        }
        let gender = "";

        if(characterObj.baseJob.indexOf("격투가") != -1 || characterObj.baseJob.indexOf("거너") != -1  || characterDataTemp.jinJob.indexOf("크루세이더")  != -1 )
        if(characterObj.baseJob.indexOf("(남)") != -1)
        {
            if(!characterObj.baseJob.indexOf("어썰트") != -1)
            {
                gender = "(남)";
            }
            
        }
        else if(characterObj.baseJob.indexOf("(여)") != -1)
        {
            gender = "(여)";
        }
        return(

            <>  
                <ul class="ems-choice-wapon">
                    <Lv115Weapon name={"1_"+weaponTemp}/>
                    <Lv115Weapon name={"2_"+weaponTemp}/>
                    <Lv115Weapon name={"3_"+weaponTemp}/>
                    <Lv115Weapon name={"4_"+weaponTemp}/>
                    <Lv115Weapon name={"5_"+weaponTemp}/>
                </ul>
                <ul class="ems-choice-wapon">
                    <li/>
                    <li/>
                    <Lv115Weapon name={"6_"+weaponTemp}/>
                    <Lv115Weapon name={"7_"+weaponTemp}/>
                    <Lv115Weapon name={"8_"+weaponTemp}/>
                </ul>
              <ul class="ems-choice-wapon">
                    
                    <Weapon name={"火 : 불타는 고난의 "+weaponName}/>
                    <Weapon name={"水 : 오염된 눈의 "+weaponName}/>
                    <Weapon name={"木 : 그늘진 새벽의 "+weaponName}/>
                    <Weapon name={"金 : 각인된 상처의 "+weaponName}/>
                    <Weapon name={"土 : 따뜻한 봄날의 "+weaponName}/>
                    <Weapon name={"부조화 : 무너진 경계의 "+weaponName}/>
                    
                </ul>
                <ul class="ems-choice-wapon">
                    <Weapon name={"火 : 넘어선 기억의 "+weaponName}/>
                    <Weapon name={"水 : 넘어선 기억의 "+weaponName}/>
                    <Weapon name={"木 : 넘어선 기억의 "+weaponName}/>
                    <Weapon name={"金 : 넘어선 기억의 "+weaponName}/>
                    <Weapon name={"土 : 넘어선 기억의 "+weaponName}/>
                </ul>

          </>
      )
    }
}


function Setting(props)
{
    let dESLTemp = "";
    let dESLName = "";

    if(dESL[props.id] != null)
    {
        dESLTemp = '스증';
        dESLName = '스증';
    }

    
    if(dESL[props.id]['stat'] != null)
    {
        dESLTemp = statSkill;
        dESLName = 'stat';
    }
    else if(dESL[props.id]['awake'] != null)
    {
        dESLTemp = awakeSkill;
        dESLName = 'awake';
    }

    return(
        <div class="cus_sest" style={props.id == present ? {'whiteSpace': 'pre-line'} : {display:'none'} }>
            <div class="cus_esetting">

            {
                    props.id == 0 ? 
                    <div class="epiup-mainweapontype-select">
                        <div class="ems-content-hug">
                            <div class="ecslect-con">
                                <div class="ems-sel-tit">
                                    <span>무기선택</span>
                                </div>
                                <div class="ems-sel-list">
                                    <div class="eslist-con-hug">
                                        <div class="eslist-con">
                                            <ul>
                                                
                                                {
                                                    weaponList.map((a,i) =>
                                                    {
                                                        return(
                                                            <li class={selectWeaponType == i ? "is-active" : "" } onClick={()=> {setSelectWeaponType(i)}}><span>{a}</span></li>
                                                        )
                                                    })
                                                }
                                               
                                                

                                            </ul>
                                        </div>

                                    </div>

                                </div>
                                <WeaponList id={selectWeaponType}/>
                                {/* <WeaponList id='1'/>
                                <WeaponList id='2'/>
                                <WeaponList id='3'/>
                                <WeaponList id='4'/> */}

                                
                            </div>
                            
                        </div>
                    </div>
                    
                    
                    : <></>
                    
                }
                {
                    props.id != 0 ?
                    
                    <div class="esetting_tr cusp1">
                        <div class="estcont">
                            <div class="cus_chkbut">
                                <ul>    
                                    <li onClick={()=> change('equipReinforceType'+props.id,'강화')} className={characterData['equipReinforceType'+props.id] == '강화' ? 'is-active' : ''}>강화</li>
                                    <li onClick={()=> change('equipReinforceType'+props.id,'증폭')} className={characterData['equipReinforceType'+props.id] == '증폭' ? 'is-active' : ''}>증폭</li>
                              
                                </ul>
                            </div>

                            <div class="cus_range">
                            <span id="rangeValue">{'\u00A0'}</span>
                                <div class="cus_rare">
                                    <input type="text" minlength="1" maxlength="2" value={characterData['equipReinforceNum'+props.id]}
                                    onChange={ (event) => change('equipReinforceNum'+props.id,event.target.value)} >

                                    </input>
                                </div>
                                <input class="crange" type="range" value={characterData['equipReinforceNum'+props.id]} min="0" max="18"
                                 onChange={ (event) => change('equipReinforceNum'+props.id,event.target.value)} >
                                </input>
                            </div>
                        </div>
                    </div> : <></>

                }
                {
                    /*
                }
                <div class="esetting_tr est_twe cusp1">
                    <div class="estcont">
                        <div class="cus_chkbut">
                            <ul>
                                <li className={characterData['equipReinforceType'+props.id] == '강화' ? 'is-active' : ''}>강화</li>
                                <li className={characterData['equipReinforceType'+props.id] == '증폭' ? 'is-active' : ''}>증폭</li>
                                <li className={characterData['equipReinforceType'+props.id] == '개조' ? 'is-active' : ''}>개조</li>
                            </ul>
                        </div>

                        <div class="cus_range">
                            <span id="rangeValue">0</span>
                            <div class="cus_rare">
                                <input type="text" minlength="1" maxlength="3">

                                </input>
                                
                            </div>
                            <input class="crange" type="range" value="0" min="0" max="17"></input>
                            
                        </div>
                    
                    </div>
                    <div class="estcont">
                        <div class="cus_chkbut">
                            <ul>
                                <li className={characterData['equipReinforceType'+props.id] == '강화' ? 'is-active' : ''}>강화</li>
                                <li className={characterData['equipReinforceType'+props.id] == '증폭' ? 'is-active' : ''}>증폭</li>
                                <li className={characterData['equipReinforceType'+props.id] == '개조' ? 'is-active' : ''}>개조</li>
                            </ul>
                        </div>

                        <div class="cus_range">
                            <span id="rangeValue">0</span>
                            <div class="cus_rare">
                                <input type="text" minlength="1" maxlength="3">

                                </input>
                                
                            </div>
                            <input class="crange" type="range" value="0" min="0" max="17"></input>
                            
                        </div>
                    
                    </div>
                </div>
                {
                    */
                }
                {
                    props.id == 0 ? 
                    <div class="esetting_tr cusper73 cusp1">
                        <div class="estcont">
                            <div class="cus_chkbut">
                                <ul>
                                    <li onClick={()=> change('equipReinforceType'+props.id,'강화')} className={characterData['equipReinforceType'+props.id] == '강화' ? 'is-active' : ''}>강화</li>
                                    <li onClick={()=> change('equipReinforceType'+props.id,'증폭')} className={characterData['equipReinforceType'+props.id] == '증폭' ? 'is-active' : ''}>증폭</li>
                               </ul>
                            </div>

                            <div class="cus_range">
                                <span id="rangeValue">{'\u00A0'}</span>
                                <div class="cus_rare">
                                    <input type="text" minlength="1" maxlength="2" value={characterData['equipReinforceNum'+props.id]}
                                    onChange={ (event) => change('equipReinforceNum'+props.id,event.target.value)} >

                                    </input>
                                    
                                </div>
                                <input class="crange" type="range" value={characterData['equipReinforceNum'+props.id]} min="0" max="18"
                                    onChange={ (event) => change('equipReinforceNum'+props.id,event.target.value)} />
                            </div>
        
                        </div>

                    <div class="estcont cjae">
                            <div class="cus_chkbut">
                                <ul>
                                    <li>재련</li>
                                </ul>
                            </div>
                            

                            <div class="cus_range">
                                
                            <span id="rangeValue">{'\u00A0'}</span>
                                <div class="cus_rare">
                                    <input value= {characterData['refine0']} type="text" minlength="1" maxlength="1" onChange={(event) => change('refine0',event.target.value)}>
                                       
                                    </input>
                                    
                                </div>
                                <input class="crange" type="range" value={characterData['refine0']} min="0" max="8" onChange={(event) => change('refine0',event.target.value)}></input>
                            </div>
                            
                        </div>
                </div>
                : <></>
                }
                
{/* 
                <div class="esetting_tr cusp2">
                    <div class="estcont  cussa">
                        <div class="cus_esttit">
                            <ul>
                                <li>스탯</li>
                            </ul>
                        </div>

                        <div class="cus_range">
                            <span id="rangeValue">{'\u00A0'}</span>
                            <div class="cus_rare">
                                
                                <input type="text" minlength="1" maxlength="2" value={characterData['equipEnchantStat'+props.id]}
                                onChange={ (event) => change('equipEnchantStat'+props.id,event.target.value)} >

                                </input>
                            </div>
                            <input class="crange" type="range" value={characterData['equipEnchantStat'+props.id]} min="0" max={dStat[props.id]}
                                 onChange={ (event) => change('equipEnchantStat'+props.id,event.target.value)} />
                        </div>
    
                    </div>
                    <div class="estcont  cussa">
                        {
                            characterObj.buffCal == null && dAttack[props.id] != 0 ?  <>
                            <div class="cus_esttit">
                                <ul>
                                    <li>공격력</li>
                                </ul>
                            </div>

                            <div class="cus_range">
                                <span id="rangeValue">{'\u00A0'}</span>
                                
                                <div class="cus_rare">
                                    
                                    <input type="text" minlength="1" maxlength="3" value={characterData['equipEnchantAttack'+props.id]}
                                    onChange={ (event) => change('equipEnchantAttack'+props.id,event.target.value)} >

                                    </input>
                                </div>
                                <input class="crange" type="range" value={characterData['equipEnchantAttack'+props.id]} min="0" max={dAttack[props.id]}
                                    onChange={ (event) => change('equipEnchantAttack'+props.id,event.target.value)} />
                            </div>
                            </> : <></>
                        }
                        
    
                    </div>
                    <div class="estcont  cussa">
                        {
                            dESL[props.id] != 0 ?  
                            <>
                            <div class="cus_esttit">
                                <ul>
                                    <li>
                                        {dESLTemp}
                                    </li>
                                </ul>
                            </div>

                            <div class="cus_range">
                                <span id="rangeValue">{'\u00A0'}</span>
                                <div class="cus_rare">
                                    
                                    <input type="text" minlength="1" maxlength="3" value={characterData['equipEnchantEtc'+props.id]}
                                    onChange={ (event) => change('equipEnchantEtc'+props.id,event.target.value)} >

                                    </input>
                                </div>
                                <input class="crange" type="range" value={characterData['equipEnchantEtc'+props.id]} min="0" max={dESL[props.id][dESLName]}
                                        onChange={ (event) => change('equipEnchantEtc'+props.id,event.target.value)} />
                            </div> 
                            </>
                            : <></>
                        }
                       
    
                    </div>
                </div> */}

                <div class="esetting_tr cusp2 cusp2-vetical_v1">
                    <div class="estcont  cussa">
                        <div class="cus_tit_side">
                            <div class="cus_esttit">
                                <ul>
                                    <li>스탯</li>
                                </ul>
                            </div>

                            <div class="cus_range">
                                <span id="rangeValue">{'\u00A0'}</span>
                                <div class="cus_rare">
                                    
                                    <input type="text" minlength="1" maxlength="2" value={characterData['equipEnchantStat'+props.id]}
                                    onChange={ (event) => change('equipEnchantStat'+props.id,event.target.value)} >

                                    </input>
                                </div>
                                <input class="crange" type="range" value={characterData['equipEnchantStat'+props.id]} min="0" max={dStat[props.id]}
                                    onChange={ (event) => change('equipEnchantStat'+props.id,event.target.value)} />
                            </div>
                        </div>
                    </div>
                    <div class="estcont  cussa">
                        {
                            characterObj.buffCal == null && dAttack[props.id] != 0 ?  <>
                            <div class="cus_esttit">
                                <ul>
                                    <li>공격력</li>
                                </ul>
                            </div>

                            <div class="cus_range">
                                <span id="rangeValue">{'\u00A0'}</span>
                                
                                <div class="cus_rare">
                                    
                                    <input type="text" minlength="1" maxlength="3" value={characterData['equipEnchantAttack'+props.id]}
                                    onChange={ (event) => change('equipEnchantAttack'+props.id,event.target.value)} >

                                    </input>
                                </div>
                                <input class="crange" type="range" value={characterData['equipEnchantAttack'+props.id]} min="0" max={dAttack[props.id]}
                                    onChange={ (event) => change('equipEnchantAttack'+props.id,event.target.value)} />
                            </div>
                            </> : <></>
                        }
                        
                    </div>
                    {
                            dEle[props.id] != 0 ?  
                            <div class="estcont  cussa">
                                <div class="cus_esttit">
                                    <ul>
                                        <li>
                                        속강
                                        </li>
                                    </ul>
                                </div>

                                <div class="cus_range">
                                    <span id="rangeValue">{'\u00A0'}</span>
                                    <div class="cus_rare">

                                        
                                        <input type="text" minlength="1" maxlength="3" value={characterData['equipEnchantEle'+props.id]}
                                        onChange={ (event) => change('equipEnchantEle'+props.id,event.target.value)} >

                                        </input>
                                    </div>
                                    <input class="crange" type="range" value={characterData['equipEnchantEle'+props.id]} min="0" max={dEle[props.id]}
                                            onChange={ (event) => change('equipEnchantEle'+props.id,event.target.value)} />
                                </div> 
                            </div>
                            : <></>
                    }
                    {
                        dESL[props.id] != 0 ?  
                        <div class="estcont  cussa">
                            <div class="cus_esttit">
                                <ul>
                                    <li>
                                    {dESLTemp}
                                    </li>
                                </ul>
                            </div>

                            <div class="cus_range">
                                <span id="rangeValue">{'\u00A0'}</span>
                                <div class="cus_rare">
                                    
                                    <input type="text" minlength="1" maxlength="3" value={characterData['equipEnchantEtc'+props.id]}
                                    onChange={ (event) => change('equipEnchantEtc'+props.id,event.target.value)} >

                                    </input>
                                </div>
                                <input class="crange" type="range" value={characterData['equipEnchantEtc'+props.id]} min="0" max={dESLTemp =="속강" || dESLTemp =="스증" 
                                ?  dESL[props.id] : dESL[props.id][dESLName]}
                                        onChange={ (event) =>  change('equipEnchantEtc'+props.id,event.target.value)} />
                            </div> 
                        </div>
                        : <></>
                    }
                   
                    
                </div>

                {
                    (isRaidWeapon(characterData['equipName' + props.id])) ? 
                        
                    <div class="tr_o105_level cus_chkbut">
                        <ul>
                            <li style={{"height":"30px"}} onClick={()=>{change('itemLevel'+props.id, 110 )}} className={characterData['itemLevel'+props.id] == 110 ? "is-active" : ""}><span>110레벨</span></li>
                            <li style={{"height":"30px"}} onClick={()=>{change('itemLevel'+props.id, 115 ) }} className={characterData['itemLevel'+props.id] == 115 ? "is-active" : ""}><span>115레벨</span></li>
                            
                        </ul>
                    </div> :<></>
                    
                    
                } 
                {
                    (characterData['equipName' + props.id].indexOf("불가침의 영역") != -1) ? 
                        
                    <div class="tr_o105_level cus_chkbut" style={{width:"30%"}}>
                        <ul>
                            <li style={{"height":"30px"}} onClick={()=>{change('itemLevel'+props.id, 105 )}} className={characterData['itemLevel'+props.id] == 105 ? "is-active" : ""}><span>105레벨</span></li>
                            <li style={{"height":"30px"}} onClick={()=>{change('itemLevel'+props.id, 115 ) }} className={characterData['itemLevel'+props.id] == 115 ? "is-active" : ""}><span>115레벨</span></li>
                            
                        </ul>
                    </div> :<></>
                    
                    
                } 

                {
                    //장비승급
                }
                {
                     characterData['itemLevel' + props.id] == 115 && !isTeacho(characterData['equipName' + props.id],props.id) && !isRaidWeapon(characterData['equipName' + props.id]) && !is110Legendary(characterData['equipName' + props.id]) ?
                     <>
                     <div class="esetting_tr cusp2 cusp2-vetical_v1">
                        <div class="estcont cussa">
                            <div class="cus_esttit">
                                <ul>
                                    <li>조율</li>
                                </ul>
                            </div>
                            <div class="cus_range">
                                <span id="rangeValue">{'\u00A0'}</span>
                                <div class="cus_rare">
                                    <input type="text" minlength="0" maxlength="3" value={characterData['itemUp'+props.id]}
                                    onChange={ (event) => change('itemUp'+props.id,event.target.value)} >

                                    </input>
                                    
                                </div>
                                <input class="crange" type="range" value={characterData['itemUp'+props.id]} min="0" max="3"
                                    onChange={ (event) => change('itemUp'+props.id,event.target.value)} />
                            </div>
        
                        </div>
                    </div>
                    </> :
                     <></>
                }
                {
                    //기억의 성단

                }
                {
                    props.id == 0 && characterData['itemLevel' + props.id] == 110 && characterData['equipName' + props.id].includes(" : ") ? 
                    <div class="esetting_tr cusp2 cusp2-vetical_v1">
                        <div class="estcont cussa">
                            <div class="cus_esttit">
                                <ul>
                                    <li>기억의 성단</li>
                                </ul>
                            </div>
                            <div class="cus_range">
                                <span id="rangeValue">{'\u00A0'}</span>
                                <div class="cus_rare">
                                    <input type="text" minlength="1" maxlength="2" value={characterData['cluster']}
                                    onChange={ (event) => change('cluster',event.target.value)} >

                                    </input>
                                    
                                </div>
                                <input class="crange" type="range" value={characterData['cluster']} min="1" max="20"
                                    onChange={ (event) => change('cluster',event.target.value)} />
                            </div>
        
                        </div>

                        <div class="estcont cussa">
                            <div class="cus_esttit">
                                <ul>
                                    <li>기억의 종착지</li>
                                </ul>
                            </div>
                            

                            <div class="cus_range">
                                
                            <span id="rangeValue">{'\u00A0'}</span>
                                <div class="cus_rare">
                                    <input value= {characterData['clusterEnd']} type="text" minlength="1" maxlength="1"
                                     onChange={(event) => change('clusterEnd',event.target.value)}>
                                       
                                    </input>
                                    
                                </div>
                                <input class="crange" type="range" value={characterData['clusterEnd']} min="0" max="5" 
                                onChange={(event) => change('clusterEnd',event.target.value)}></input>
                            </div>
                            
                        </div>
                        <div class="estcont cussa">
                            <div class="cus_esttit">
                                <ul>
                                    <li>반추</li>
                                </ul>
                            </div>
                            

                            <div class="cus_range">
                                
                            <span id="rangeValue">{'\u00A0'}</span>
                                <div class="cus_rare">
                                    <input value= {characterData['rumination']} type="text" minlength="1" maxlength="1"
                                     onChange={(event) => change('rumination',event.target.value)}>
                                       
                                    </input>
                                    
                                </div>
                                <input class="crange" type="range" value={characterData['rumination']} min="0" max="6" 
                                onChange={(event) => change('rumination',event.target.value)}></input>
                            </div>
                            
                        </div>
                    </div>
                : <></>
                }


                <div class="cus_set e105mob">
                    <div class="cus_esetting">
                    {
                        (props.id != 0 && characterData['itemLevel'+props.id] < 115 ) || (props.id == 0 && ( characterData['equipName' + props.id].includes("넘어선 기억의") || 
                        characterData['equipName' + props.id].includes("불가침의 영역") || !characterData['equipName' + props.id].includes(" : ") )) ?
                        <>
                        {
                            (props.id != 0 && characterData['itemLevel'+props.id] < 115 ) || (props.id == 0 && (!characterData['equipName' + props.id].includes(" : "))) ? 
                            <>
                            <div class="tr_o105_level">
                                <div class="cus_chkbut">
                                        <ul>
                                            {
                                                (characterData['equipName' + props.id].includes("넘어선 기억의") || 
                                                characterData['equipName' + props.id].includes("불가침의 영역") ||
                                                characterData['itemLevel'+props.id] >= 115)  ? <></> :
                                                <>
                                                    <li onClick={()=>{change('itemLevel'+props.id, 105 )}} className={characterData['itemLevel'+props.id] == 105 ? "is-active" : ""}>105레벨</li>
                                                    <li onClick={()=>{change('itemLevel'+props.id, 110 ) }} className={characterData['itemLevel'+props.id] == 110 ? "is-active" : ""}>110레벨</li>
                                                    
                                                </>
                                                
                                            }
                                            
                                        
                                                {
                                                (props.id != 0 && characterData['itemLevel'+props.id] < 115 ) && lv105OptionMap[characterData['equipName' + props.id]] == null 
                                                && setItemNames.indexOf(characterData['equipName' + props.id]) == -1 ? 
                                                <li onClick={()=>{change('mistGear'+props.id, characterData['mistGear' + props.id] == 'true' ? 'false' : 'true' )}}
                                                className={(characterData['mistGear' + props.id] == 'true' ? "p-mystic is-active" : "p-mystic")}>
                                                        
                                                    <div class="mystic-but">
                                                        <div class="imgt">
                                                            
                                                            <img src="mist_gear_d.png" />
                                                                    
                                                        </div>
                                                    </div>
                                                    
                                                </li> 
                                                : <></>
                                                }
                                            
                                        </ul>
                                    </div>
                            </div>
                            </>
                            :
                            <></>
                        }

                        {
                        setItemNames.indexOf(characterData['equipName' + props.id]) != -1 || (characterData['itemLevel'+props.id] >= 115 && !characterData['equipName' + props.id].includes("넘어선 기억의")) ? <></> : 
                        <Swiper pagination={true} modules={[Pagination]} className="option105">
                            <SwiperSlide>
                                <div class="esetting_tr cusp2 o105je" style={setItemNames.indexOf(characterData['equipName' + props.id]) != -1 || (characterData['itemLevel'+props.id] >= 115 && !characterData['equipName' + props.id].includes("넘어선 기억의") ) ? {display:"none"}:{}}>
                                    <div class="estcont  cussa">

                                        <div class="cus_105op">
                                            <div class="option">
                                                {
                                                    lv105OptionMap[characterData['equipName'+props.id]] != null ?    
                                                    <>
                                                        <span class="up">피해 증가 + {lv105OptionMap[characterData['equipName'+props.id]].split("@@")[1]}</span> 
                                                        <br/>
                                                        {lv105OptionMap[characterData['equipName'+props.id]].split("@@")[0].replace(/<br>/gi,'\n')}
                                                    </>
                                                    :
                                                    <>
                                                    </>
                                                }

                                                {
                                                    characterData['equipCustom'+props.id+"C"+0 ]!= null ?    
                                                    <>
                                                        1옵션 <span class="up">피해 증가 + </span> 
                                                        <br/>
                                                        {lv105CustomOptionMap[characterData['equipCustom'+props.id+"C"+0]].replace(/<br>/gi,'\n')}
                                                    </>
                                                    :
                                                    <>
                                                    </>
                                                }

                                            </div>
                                            
                                            {
                                                lv105OptionMap[characterData['equipName'+props.id]] == null ?
                                                <div class="cus_opsetbut">
                                                    <div class="imgt" onClick={()=>customChange(props.id,0,1)}> 
                                                        <img src="img/set1.svg" />
                                                    </div>

                                                </div> : <></>
                                            }
                                        </div>

                                        {lv105OptionMap[characterData['equipName'+props.id]] == null ? <CustomOptions id={props.id} index={0}/> : <></> }

                                    </div>
                                </div>
                            </SwiperSlide>
                            {
                                lv105OptionMap[characterData['equipName' + props.id]] == null ? 
                                <SwiperSlide>
                                <div class="esetting_tr cusp2 o105je">
                                    <div class="estcont  cussa">

                                        <div class="cus_105op">
                                            <div class="option">
                                                {
                                                }

                                                {
                                                    characterData['equipCustom'+props.id+"C"+1 ]!= null ?    
                                                    <>
                                                        2옵션 <span class="up">피해 증가 + </span> 
                                                        <br/>
                                                        {lv105CustomOptionMap[characterData['equipCustom'+props.id+"C"+1]].replace(/<br>/gi,'\n')}
                                                    </>
                                                    :
                                                    <>
                                                    </>
                                                }
                                            </div>
                                            
                                            
                                            {
                                            lv105OptionMap[characterData['equipName'+props.id]] == null ?
                                            <div class="cus_opsetbut">
                                                <div class="imgt" onClick={()=>customChange(props.id,1,1)}> 
                                                    <img src="img/set1.svg" />
                                                </div>

                                            </div> : <></>
                                            }
                                        </div>
                               
                                        {lv105OptionMap[characterData['equipName'+props.id]] == null ? <CustomOptions id={props.id} index={1}/> : <></> }

                                    </div>  
                                </div>
                            </SwiperSlide>
                             : <></>
                            }

                            {
                                lv105OptionMap[characterData['equipName' + props.id]] == null ?
                           
                            <SwiperSlide>
                                <div class="esetting_tr cusp2 o105je">
                                    <div class="estcont  cussa"
                                        style={lv105OptionMap[characterData['equipName' + props.id]] != null ? { display: 'none' } : {}}>
                                        <div class="cus_105op">
                                            <div class="option">
                                                {
                                                }

                                                {
                                                    characterData['equipCustom'+props.id+"C"+2 ]!= null ?    
                                                    <>
                                                        3옵션 <span class="up">피해 증가 + </span> 
                                                        <br/>
                                                        {lv105CustomOptionMap[characterData['equipCustom'+props.id+"C"+2]].replace(/<br>/gi,'\n')}
                                                    </>
                                                    :
                                                    <>
                                                    </>
                                                }
                                            </div>
                                            
                                            
                                            {
                                            lv105OptionMap[characterData['equipName'+props.id]] == null ?
                                            <div class="cus_opsetbut">
                                                <div class="imgt" onClick={()=>customChange(props.id,2,1)}> 
                                                 <img src="img/set1.svg" />
                                                </div>

                                            </div> : <></>
                                            }
                                        </div>
                            

                                        {lv105OptionMap[characterData['equipName'+props.id]] == null ? <CustomOptions id={props.id} index={2}/> : <></> }
                                    </div>
                                </div>
                            </SwiperSlide> : ""
                            }

                            {
                                lv105OptionMap[characterData['equipName' + props.id]] == null ?
                            <SwiperSlide>
                                <div class="esetting_tr cusp2 o105je">
                                    <div class="estcont  cussa"
                                        style={lv105OptionMap[characterData['equipName' + props.id]] != null ? { display: 'none' } : {}}>
                                        <div class="cus_105op">
                                            <div class="option">
                                                {
                                                }

                                                {   
                                                    characterData['equipCustom'+props.id+"C"+3 ]!= null ?    
                                                    <>
                                                        4옵션 <span class="up">피해 증가 + </span> 
                                                        <br/>
                                                        {lv105CustomOptionMap[characterData['equipCustom'+props.id+"C"+3]].replace(/<br>/gi,'\n')}
                                                    </>
                                                    :
                                                    <>
                                                    </>
                                                }

                                            </div>
                                            
                                            
                                            {
                                            lv105OptionMap[characterData['equipName'+props.id]] == null ?
                                            <div class="cus_opsetbut">
                                                <div class="imgt" onClick={()=>customChange(props.id,3,1)}> 
                                                    <img src="img/set1.svg" />
                                                </div>

                                            </div> : <></>
                                            }
                                        </div>

                             
                                        {lv105OptionMap[characterData['equipName'+props.id]] == null ? <CustomOptions id={props.id} index={3}/> : <></> }



                                    </div> 
                                </div>
                            </SwiperSlide> : ""
                            }

                            

                        </Swiper>
                        }
                        </>
                        
                    :<></>}
                    </div>
              
                </div>
            </div>
        </div>

    )
}
/*
*/
function AvatarSetting(props)
{
    return(
        <div class="cus_set sabatcus" style={avaPresent == props.id ? {} : {display : 'none'}}>
        {
            props.id == 13 ? 
            <div class="saba_adventure" name="모험단">
                <div class="abat_tit">
                    <span>모험단</span>
                </div>
                <div class="cus_esetting abatnum">
                    <div class="esetting_tr cusp2">
                        
                        <div class="estcont  cussa">
                            <div class="cus_esttit">
                                <ul>
                                    <li>레벨</li>
                                </ul>
                            </div>
            
                            <div class="cus_range">
                                 <span id="rangeValue">{characterData['adventure']}</span>
                                <input class="crange" type="range" value={characterData['adventure']} min="0" max="50" step="1" onChange={(event)=>{change('adventure',event.target.value)}}>
                                </input>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div> : <></>
        }
        
        {
            props.id < 9 ? 
                <div class="saba_basic" name="아바타 기본형">
                    <div class="abat_tit">
                        <span>{avatarTypeList[props.id]}</span>
                    </div>
                    {
                        props.id != 8 ?  
                        <div class="abat_switch">
                            <div class="estcont">
                                <div class="cus_chkbut cusbonly">
                                    <ul>
                                        <li onClick={()=>change('abaRarity'+props.id,'상급')} className={characterData['abaRarity'+props.id] =='상급' ? "is-active":""} >상급</li>
                                        <li onClick={()=>change('abaRarity'+props.id,'레어')} className={characterData['abaRarity'+props.id] =='레어' ? "is-active":""}>레어</li>
                                    </ul>
                                </div>
                            </div>
                        </div> : ""

                    }
                    {
                         props.id != 8 ? 
                         <div class="abat_select">
                         <select id="v212" value={characterData["abaOption"+props.id]} onChange={(e)=>{change("abaOption"+props.id,e.target.value)}}>
                                <option value={''}>{''}</option>
                                {
                                    avatarOption[props.id] != null  && avaPresent == props.id  ? avatarOption[props.id][characterData['abaRarity'+props.id] =='레어' ? 1 : 0].map((a,i) => {
                                        return(
                                            <option value={a}>{a}</option>
                                        )
                                    }) : ""
                                    
                                }
                                
                                {
                                    props.id == 3 && avaPresent == props.id ? avatarSkillOption[characterData['job']].map((a,i) => {
                                        return(
                                            <option value={a}>{a}</option>
                                        )
                                    }) : ""
                                    
                                }
                        
                            </select>
                        </div> : ""
                    }
                    

                    { props.id == 3 || props.id == 4 ?
                        <div class="abat_select" >
                            <select id="v212" value={characterData["emblemSkill"+props.id]} onChange={(event) => change("emblemSkill"+props.id,event.target.value)}>
                                
                                <option value={''}>{''}</option>
                                {
                                    avaPresent == props.id ? emblemSkillOption[characterData['job']].map((a,i) => {
                                        return(
                                            <option value={a}>{a}</option>
                                        )
                                    }) :<></>
                                    
                                }
                        
                            </select>
                        </div> : <></>
                    }
                    
                    <div class="cus_esetting abatnum">
                        <div class="esetting_tr cusp2">
                            <div class="estcont  cussa">
                                <div class="cus_esttit">
                                    <ul>
                                        <li>스탯</li>
                                    </ul>
                                </div>

                                <div class="cus_range">
                                    <span id="rangeValue">{characterData['emblemStat0'+props.id]}</span>
                                    <input class="crange" type="range" value={characterData['emblemStat0'+props.id]} min="0" max="25" step="1" onInput={(event)=>change("emblemStat0"+props.id,event.target.value)} >
                                    </input>
                                </div>
                            </div>
                            <div class="estcont  cussa">
                                <div class="cus_esttit">
                                    <ul>
                                        <li>스탯</li>
                                    </ul>
                                </div>

                                <div class="cus_range">
                                    <span id="rangeValue">{characterData['emblemStat1'+props.id]}</span>
                                    <input class="crange" type="range" value={characterData['emblemStat1'+props.id]} min="0" max="25" step="1" onChange={(event)=>change("emblemStat1"+props.id,event.target.value)} >
                                    </input>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div class="cus_esetting abatnum">
                        <div class="esetting_tr cusp2">
                            <div class="estcont  cussa">
                                <div class="cus_esttit">
                                    <ul>
                                        <li>모든 속성 저항</li>
                                    </ul>
                                </div>

                                <div class="cus_range">
                                    <span id="rangeValue">{characterData['emblemResist0'+props.id]}</span>
                                    <input class="crange" type="range" value={characterData['emblemResist0'+props.id]} min="0" max="5" step="1" onChange={(event)=>change("emblemResist0"+props.id,event.target.value)}  >
                                    </input>
                                </div>
                            </div>
                            <div class="estcont  cussa">
                                <div class="cus_esttit">
                                    <ul>
                                        <li>모든 속성 저항</li>
                                    </ul>
                                </div>

                                <div class="cus_range">
                                    <span id="rangeValue">{characterData['emblemResist1'+props.id]}</span>
                                    <input class="crange" type="range" value={characterData['emblemResist1'+props.id]} min="0" max="5" step="1" onChange={(event)=>change("emblemResist1"+props.id,event.target.value)}>
                                    </input>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div class="cus_esetting abatnum">
                        <div class="esetting_tr cusp2">
                            <div class="estcont  cussa">
                                <div class="cus_esttit">
                                    <ul>
                                        <li>공격속도</li>
                                    </ul>
                                </div>

                                <div class="cus_range">
                                    <span id="rangeValue">{characterData['emblemAS0'+props.id]}</span>
                                    <input class="crange" type="range" value={characterData['emblemAS0'+props.id]} min="0" max="1.5" step="0.1" onChange={(event)=>change("emblemAS0"+props.id,event.target.value)}>
                                    </input>
                                </div>
                            </div>
                            <div class="estcont  cussa">
                                <div class="cus_esttit">
                                    <ul>
                                        <li>공격속도</li>
                                    </ul>
                                </div>

                                <div class="cus_range">
                                    <span id="rangeValue">{characterData['emblemAS1'+props.id]}</span>
                                    <input class="crange" type="range" value={characterData['emblemAS1'+props.id]} min="0" max="1.5" step="0.1" onChange={(event)=>change("emblemAS1"+props.id,event.target.value)}>
                                    </input>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div> 
            : 
            <></>
        }
        
        {
            props.id == 10 ? 
            <div class="saba_weapon" name="무기">
                <div class="abat_tit">
                    <span>무기 아바타</span>
                </div>
                <div class="abat_select">
                    <select id="v212" number="402" value={characterData['abaOption10']}
                    onChange={(event)=>change("abaOption10",event.target.value)}>
                        <option>스탯 55 증가</option>
                        <option>스탯 45 증가</option>
                        <option>없음</option>
                    </select>
                </div>
                <div class="cus_esetting abatnum">
                    <div class="esetting_tr cusp2">
                        <div class="estcont  cussa">
                            <div class="cus_esttit">
                                <ul>
                                    <li>스탯</li>
                                </ul>
                            </div>

                            <div class="cus_range">
                                <span id="rangeValue">{characterData['emblemStat0'+props.id]}</span>
                                <input class="crange" type="range" value={characterData['emblemStat0'+props.id]} min="0" max="25" step="1" onChange={(event)=>change("emblemStat0"+props.id,event.target.value)} >
                                </input>
                            </div>
                        </div>
                        <div class="estcont  cussa">
                            <div class="cus_esttit">
                                <ul>
                                    <li>스탯</li>
                                </ul>
                            </div>

                            <div class="cus_range">
                                <span id="rangeValue">{characterData['emblemStat1'+props.id]}</span>
                                <input class="crange" type="range" value={characterData['emblemStat1'+props.id]} min="0" max="25" step="1" onChange={(event)=>change("emblemStat1"+props.id,event.target.value)} >
                                </input>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div class="cus_esetting abatnum">
                    <div class="esetting_tr cusp2">
                        <div class="estcont  cussa">
                            <div class="cus_esttit">
                                <ul>
                                    <li>모든 속성 저항</li>
                                </ul>
                            </div>

                            <div class="cus_range">
                                <span id="rangeValue">{characterData['emblemResist0'+props.id]}</span>
                                <input class="crange" type="range" value={characterData['emblemResist0'+props.id]} min="0" max="25" step="1" onChange={(event)=>change("emblemResist0"+props.id,event.target.value)}  >
                                </input>
                            </div>
                        </div>
                        <div class="estcont  cussa">
                            <div class="cus_esttit">
                                <ul>
                                    <li>모든 속성 저항</li>
                                </ul>
                            </div>

                            <div class="cus_range">
                                <span id="rangeValue">{characterData['emblemResist1'+props.id]}</span>
                                <input class="crange" type="range" value={characterData['emblemResist1'+props.id]} min="0" max="25" step="1" onChange={(event)=>change("emblemResist1"+props.id,event.target.value)}>
                                </input>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div class="cus_esetting abatnum">
                    <div class="esetting_tr cusp2">
                        <div class="estcont  cussa">
                            <div class="cus_esttit">
                                <ul>
                                    <li>공격속도</li>
                                </ul>
                            </div>

                            <div class="cus_range">
                                <span id="rangeValue">{characterData['emblemAS0'+props.id]}</span>
                                <input class="crange" type="range" value={characterData['emblemAS0'+props.id]} min="0" max="1.5" step="0.1" onChange={(event)=>change("emblemAS0"+props.id,event.target.value)}>
                                </input>
                            </div>
                        </div>
                        <div class="estcont  cussa">
                            <div class="cus_esttit">
                                <ul>
                                    <li>공격속도</li>
                                </ul>
                            </div>

                            <div class="cus_range">
                                <span id="rangeValue">{characterData['emblemAS1'+props.id]}</span>
                                <input class="crange" type="range" value={characterData['emblemAS1'+props.id]} min="0" max="1.5" step="0.1" onChange={(event)=>change("emblemAS1"+props.id,event.target.value)}>
                                </input>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div class="esetting_tr cusper73 cusp6">
                    <div class="estcont">
                        <div class="cus_chkbut cusb8">
                            <ul>
                                <li onClick={()=>change('aba10Skill',40)} className={characterData['aba10Skill'] == 40 ? "is-active" : ""} >40Lv</li>
                                <li onClick={()=>change('aba10Skill',45)} className={characterData['aba10Skill'] == 45 ? "is-active" : ""}>45Lv</li>
                                <li onClick={()=>change('aba10Skill',60)} className={characterData['aba10Skill'] == 60 ? "is-active" : ""}>60Lv</li>
                            </ul>
                            <ul>
                                <li onClick={()=>change('aba10Skill',70)} className={characterData['aba10Skill'] == 70 ? "is-active" : ""}>70Lv</li>
                                <li onClick={()=>change('aba10Skill',75)} className={characterData['aba10Skill'] == 75 ? "is-active" : ""}>75Lv</li>
                                <li onClick={()=>change('aba10Skill',80)} className={characterData['aba10Skill'] == 80 ? "is-active" : ""}>80Lv</li>
                            </ul>
                        </div>
                        
                    </div>
                </div>
            </div>
            :
            <>
            </>
        }

        {
            props.id == 9 ? 
            <div class="seba_ora">
                <div class="abat_tit">
                    <span>오라 아바타</span>
                </div>
                
                <div class="cus_esetting abatnum">
                    <div class="esetting_tr cusp2">
                        <div class="estcont  cussa">
                            <div class="cus_esttit">
                                <ul>
                                    <li>스탯</li>
                                </ul>
                            </div>

                            <div class="cus_range">
                                <span id="rangeValue">{characterData['aba9Stat']}</span>
                                <input class="crange" type="range" value={characterData['aba9Stat']} min="0" max="134" step="1" 
                                onChange={(event)=>change("aba9Stat",event.target.value)}>
                                </input>
                            </div>
                            
                        </div>
                        <div class="estcont  cussa">
                            <div class="cus_esttit">
                                <ul>
                                    <li>공격력</li>
                                </ul>
                            </div>

                            <div class="cus_range">
                                <span id="rangeValue">{characterData['aba9Attack']}</span>
                                <input class="crange" type="range" value={characterData['aba9Attack']} min="0" max="50" step="5" 
                                onChange={(event)=>change("aba9Attack",event.target.value)}>
                                </input>
                            </div>
                            
                        </div>
                        <div class="estcont  cussa">
                            <div class="cus_esttit">
                                <ul>
                                    <li>속강</li>
                                </ul>
                            </div>

                            <div class="cus_range">
                                <span id="rangeValue">{characterData['aba9Element']}</span>
                                <input class="crange" type="range" value={characterData['aba9Element']} min="0" max="40" step="5" 
                                onChange={(event)=>change("aba9Element",event.target.value)}>
                                </input>
                            </div>
                            
                        </div>
                    </div>
                    <div class="cus_esetting abatnum">
                        <div class="esetting_tr cusp2">
                            <div class="estcont  cussa">
                                <div class="cus_esttit">
                                    <ul>
                                        <li>스탯</li>
                                    </ul>
                                </div>

                                <div class="cus_range">
                                    <span id="rangeValue">{characterData['emblemStat0'+props.id]}</span>
                                    <input class="crange" type="range" value={characterData['emblemStat0'+props.id]} min="0" max="25" step="1" onChange={(event)=>change("emblemStat0"+props.id,event.target.value)} >
                                    </input>
                                </div>
                            </div>
                            <div class="estcont  cussa">
                                <div class="cus_esttit">
                                    <ul>
                                        <li>스탯</li>
                                    </ul>
                                </div>

                                <div class="cus_range">
                                    <span id="rangeValue">{characterData['emblemStat1'+props.id]}</span>
                                    <input class="crange" type="range" value={characterData['emblemStat1'+props.id]} min="0" max="25" step="1" onChange={(event)=>change("emblemStat1"+props.id,event.target.value)} >
                                    </input>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div class="cus_esetting abatnum">
                        <div class="esetting_tr cusp2">
                            <div class="estcont  cussa">
                                <div class="cus_esttit">
                                    <ul>
                                        <li>모든 속성 저항</li>
                                    </ul>
                                </div>

                                <div class="cus_range">
                                    <span id="rangeValue">{characterData['emblemResist0'+props.id]}</span>
                                    <input class="crange" type="range" value={characterData['emblemResist0'+props.id]} min="0" max="5" step="1" onChange={(event)=>change("emblemResist0"+props.id,event.target.value)}>
                                    </input>
                                </div>
                            </div>
                            <div class="estcont  cussa">
                                <div class="cus_esttit">
                                    <ul>
                                        <li>모든 속성 저항</li>
                                    </ul>
                                </div>

                                <div class="cus_range">
                                    <span id="rangeValue">{characterData['emblemResist1'+props.id]}</span>
                                    <input class="crange" type="range" value={characterData['emblemResist1'+props.id]} min="0" max="5" step="1" onChange={(event)=>change("emblemResist1"+props.id,event.target.value)}>
                                    </input>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div class="cus_esetting abatnum">
                        <div class="esetting_tr cusp2">
                            <div class="estcont  cussa">
                                <div class="cus_esttit">
                                    <ul>
                                        <li>공격속도</li>
                                    </ul>
                                </div>

                                <div class="cus_range">
                                    <span id="rangeValue">{characterData['emblemAS0'+props.id]}</span>
                                    <input class="crange" type="range" value={characterData['emblemAS0'+props.id]} min="0" max="1.5" step="0.1" onChange={(event)=>change("emblemAS0"+props.id,event.target.value)}>
                                    </input>
                                </div>
                            </div>
                            <div class="estcont  cussa">
                                <div class="cus_esttit">
                                    <ul>
                                        <li>공격속도</li>
                                    </ul>
                                </div>

                                <div class="cus_range">
                                    <span id="rangeValue">{characterData['emblemAS1'+props.id]}</span>
                                    <input class="crange" type="range" value={characterData['emblemAS1'+props.id]} min="0" max="1.5" step="0.1" onChange={(event)=>change("emblemAS1"+props.id,event.target.value)}>
                                    </input>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                        
                </div>
            </div>:<></>
            }
            {
            props.id == 11 ?

            <div class="seba_hwijang wide-mode">
                <div class="abat_tit">
                    <span>휘장</span>
                </div>
                <div class="hwijang_set" name="선택된 휘장젬">

                    <div class="hjang_change">
                        <div class="hjang_setting">
                            <div class="set_hwij" name="휘장 선택">
                                <div className={"shwij_img " + (characterData["flag"] == "커먼" ? "is-active" : "" )} onClick={()=>change("flag","커먼")}>
                                    <div class="shwij_cover">
                                        <div class="imgt">
                                            <img src="flag0.png"/>
                                        </div>
                                    </div>
                                </div>
                                <div className={"shwij_img " + (characterData["flag"] == "언커먼" ? "is-active" : "") } onClick={()=>change("flag","언커먼")}>
                                    <div class="shwij_cover">
                                        <div class="imgt">
                                            <img src="flag1.png"/>
                                        </div>
                                    </div>
                                </div>
                                <div className={"shwij_img " + (characterData["flag"] == "레어" ? "is-active" : "") } onClick={()=>change("flag","레어")}>
                                    <div class="shwij_cover">
                                        <div class="imgt">
                                            <img src="flag2.png"/>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className={"shwij_img " + (characterData["flag"] == "유니크" ? "is-active" : "") } onClick={()=>change("flag","유니크")}>
                                    <div class="shwij_cover">
                                        <div class="imgt">
                                            <img src="flag3.png"/>
                                        </div>
                                    </div>
                                </div>
                                <div className={"shwij_img " + (characterData["flag"] == "레전더리" ? "is-active" : "") } onClick={()=>change("flag","레전더리")}>
                                    <div class="shwij_cover">
                                        <div class="imgt">
                                            <img src="flag4.png"/>
                                        </div>
                                    </div>
                                </div>
                                <div className={"shwij_img " + (characterData["flag"] == "에픽" ? "is-active" : "") } onClick={()=>change("flag","에픽")}>
                                    <div class="shwij_cover">
                                        <div class="imgt">
                                            <img src="flag5.png"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="estcont"  name="휘장 + 수치 조정">
                 
                            <div class="cus_range">
                                <span id="rangeValue">{characterData["flagReinforceNum"]}</span>
                                <input class="crange" type="range" value={characterData['flagReinforceNum']} min="0" max="12" step="1"
                            onChange={(event)=>change("flagReinforceNum",event.target.value)}></input>
                            </div>
                        </div>
                        <div class="hjangjam_setting"  name="젬 선택">
                            <GemSelect id='1'/>
                            <GemSelect id='2'/>
                            <GemSelect id='3'/>
                            <GemSelect id='4'/>
                        </div>
                    </div>
                </div>
        
            </div>:<></>
            }
            {
            props.id == 12 ? 
            <div class="seba_cri">
                <div class="abat_tit">
                    <span>크리쳐</span>
                </div>
                <div class="esetting_tr cusper73 cusp6">
                    <div class="estcont">
                        <div class="cus_chkbut cusb8">
                        <ul>
                                <li class={(characterData['creaName'] == "데미지 증가" ? "is-active" : "") } onClick={()=>change('creaName','데미지 증가')}><span>데미지 증가</span></li>
                                <li class={(characterData['creaName'] == "크리티컬 증가 데미지" ? "is-active" : "") } onClick={()=>change('creaName','크리티컬 증가 데미지')}><span>크리티컬 {'\n'}증가 데미지</span></li>
                            </ul>
                            <ul>
                                <li class={(characterData['creaName'] == "모든 공격력 증가" ? "is-active" : "") } onClick={()=>change('creaName','모든 공격력 증가')}><span>모든 {'\n'}공격력 증가</span></li>
                                <li class={(characterData['creaName'] == "정령왕" ? "is-active" : "") } onClick={()=>change('creaName','정령왕')}><span>정령왕</span></li>
                            </ul>
                            <ul>
                                <li class={(characterData['creaName'] == "물리 마법 독립 공격력 증가" ? "is-active" : "") } onClick={()=>change('creaName','물리 마법 독립 공격력 증가')}><span>물리 마법 독립 {'\n'}공격력 증가</span></li>
                                <li class={(characterData['creaName'] == "피해 증가" ? "is-active" : "") } onClick={()=>change('creaName','피해 증가')}><span>피해 증가</span></li>
                            </ul>
                            <ul>
                                <li class={(characterData['creaName'] == "없음" ? "is-active" : "") } onClick={()=>change('creaName','없음')}><span>없음</span></li>
                                <li></li>
                            </ul>
                            
                        </div>
                        
                    </div>
                
                    <div class="estcont cjae">
                        <div class="cus_chkbut">
                            <ul>
                                <li><span>수치</span></li>
                            </ul>
                        </div>

                        <div class="cus_range">
                            <span id="rangeValue">{characterData['creaValue']}</span>
                            <input class="crange" type="range" value={characterData['creaValue']} min="15" max="25" step="1"
                            onChange={(event)=>change("creaValue",event.target.value)}></input>
                        </div>
                        
                        
                    </div>
                    <div class="hjangjam_setting"  name="젬 선택">
                        <div class="set_hwijjam">
                            <div class="sethjam_tit">
                                <span class="crit_red">레드</span>
                            </div>
                            <div class="sethjam_img">
                                <div class="set_hug">
                                    <div class="jam_move">
                                        <div className={"imgt " + (characterData["afti0"] == "커먼" ? "is-active" : "")} onClick={()=>{change("afti0","커먼")}}>
                                            <img src="red0.png"/>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="sethjam_img">
                                <div class="set_hug">
                                    <div class="jam_move">
                                        <div className={"imgt " + (characterData["afti0"] == "언커먼" ? "is-active" : "")} onClick={()=>{change("afti0","언커먼")}}>
                                            <img src="red1.png"/>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="sethjam_img">
                                <div class="set_hug">
                                    <div class="jam_move">
                                        <div className={"imgt " + (characterData["afti0"] == "레어" ? "is-active" : "")} onClick={()=>{change("afti0","레어")}}>
                                            <img src="red10.png"/>

                                        </div>
                                        <div className={"imgt " + (characterData["afti0"] == "레어모속" ? "is-active" : "")} onClick={()=>{change("afti0","레어모속")}}>
                                            <img src="red1.png"/>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="sethjam_img">
                                <div class="set_hug">
                                    <div class="jam_move">

                                        <div className={"imgt " + (characterData["afti0"] == "유니크" ? "is-active" : "")} onClick={()=>{change("afti0","유니크")}}>
                                            <img src="red3.png"/>

                                        </div>
                                        <div className={"imgt " + (characterData["afti0"] == "유니크모속" ? "is-active" : "")} onClick={()=>{change("afti0","유니크모속")}}>
                                            <img src="red3.png"/>

                                        </div>
                                    </div>
                                </div>
                        
                            </div>
            
                        </div>
                        <div class="set_hwijjam">
                            <div class="sethjam_tit">
                                <span class="crit_blue">블루</span>
                            </div>
                            <div class="sethjam_img">
                                <div className={"imgt " + (characterData["afti1"] == "커먼" ? "is-active" : "")} onClick={()=>{change("afti1","커먼")}}>
                                    <img src="blue0.png"/>

                                </div>
                            </div>
                            <div class="sethjam_img">
                                <div className={"imgt " + (characterData["afti1"] == "언커먼" ? "is-active" : "")} onClick={()=>{change("afti1","언커먼")}}>
                                    <img src="blue1.png"/>

                                </div>
                            </div>
                            <div class="sethjam_img">
                                <div className={"imgt " + (characterData["afti1"] == "레어" ? "is-active" : "")} onClick={()=>{change("afti1","레어")}}>
                                    <img src="blue2.png"/>

                                </div>
                            </div>
                            <div class="sethjam_img">
                                <div className={"imgt " + (characterData["afti1"] == "유니크" ? "is-active" : "")} onClick={()=>{change("afti1","유니크")}}> 
                                    <img src="blue3.png"/>

                                </div>
                            </div>
                
                        </div>
                        <div class="set_hwijjam">
                            <div class="sethjam_tit ">
                                <span class="crit_green">그린</span>
                            </div>
                            <div class="sethjam_img">
                                <div className={"imgt " + (characterData["afti2"] == "커먼" ? "is-active" : "")} onClick={()=>{change("afti2","커먼")}}>
                                    <img src="green0.png"/>

                                </div>
                            </div>
                            <div class="sethjam_img">
                                <div className={"imgt " + (characterData["afti2"] == "언커먼" ? "is-active" : "")} onClick={()=>{change("afti2","언커먼")}}>
                                    <img src="green1.png"/>

                                </div>
                            </div>
                            <div class="sethjam_img">
                                <div className={"imgt " + (characterData["afti2"] == "레어" ? "is-active" : "")} onClick={()=>{change("afti2","레어")}}>
                                    <img src="green2.png"/>

                                </div>
                            </div>
                            <div class="sethjam_img">
                                <div className={"imgt " + (characterData["afti2"] == "유니크" ? "is-active" : "")} onClick={()=>{change("afti2","유니크")}}>
                                    <img src="green3.png"/>

                                </div>
                            </div>
            
                        </div>

                    </div>
                </div>
            </div>:<></>
        }
        </div>


    )
}

function CustomOptions(props)
{
    
    return(
        
        <div className={"cus_opset" + (customArray[props.id][props.index] == 1 ? " on" : "")}>
            <div class="cos_c">
                <div class="cossc">
                    <div class="searchbox" name="검색창">
                        <input type="text" onChange={(event)=>{customSearchChange(props.id,props.index,event.target.value)}} class="form-control js-bound" placeholder="검색" name="name" maxlength="11"/>
                    </div>
                    
                    <div class="searchbut105">
                        <div class="imgt">
                            <img src="sr_search.svg"/>
                        </div>

                    </div>
                </div>
                <div class="coscc">
                    <ul>
                    {
                            //&& customEquip[characterData['equipName'+props.id]] != null
                            customArray[props.id][props.index] == 1 && customEquip[characterData['equipName'+props.id]] == null ? lv105CustomOptionMap.map(
                                    (a, i) => {
                                        if(customSearch[props.id][props.index] == "")
                                        {
                                            return(
                                            <li key={"custom"+i} className={customSelectArray[props.id][props.index] == i ? "is-active" : ""} onClick={()=>{customSelectChange(props.id,props.index,i); }}>{a.replace(/<br>/gi,'\n')}</li>
                                            )
                                        }
                                        else if(optionSearch(a,customSearch[props.id][props.index]) != -1)
                                        {
                                            return(
                                            <li key={"custom"+i} className={customSelectArray[props.id][props.index] == i ? "is-active" : ""} onClick={()=>{customSelectChange(props.id,props.index,i); }}>{a.replace(/<br>/gi,'\n')}</li>
                                            )
                                        }
                                        
                                    }

                                ) : <></>

                                

                        }

                        {
                            
                             customArray[props.id][props.index] == 1 && customEquip[characterData['equipName'+props.id]] != null   ? customEquip[characterData['equipName'+props.id]].map(
                                   (a, i) => {
                                    //console.log(a);
                                        if(customSearch[props.id][props.index] == "")
                                        {
                                            return(
                                            <li key={"custom"+i} className={customSelectArray[props.id][props.index] == a ? "is-active" : ""} onClick={()=>{customSelectChange(props.id,props.index,a); }}>{lv105CustomOptionMap[a].replace(/<br>/gi,'\n')}</li>
                                            )
                                        }
                                        else if(optionSearch(lv105CustomOptionMap[a],customSearch[props.id][props.index]) != -1)
                                        {
                                            return(
                                            <li key={"custom"+i} className={customSelectArray[props.id][props.index] == a ? "is-active" : ""} onClick={()=>{customSelectChange(props.id,props.index,a); }}>{lv105CustomOptionMap[a].replace(/<br>/gi,'\n')}</li>
                                            )
                                        }
                                     }

                            ) : ""

                                

                        }

                        
                    </ul>
                </div>
                <div class="cos_but">
                    <div class="csbt" onClick={()=>{
                                
                        if(customSelectArray[props.id][props.index] != 0)
                        {
                            customChange(props.id,props.index,0); 
                            change("equipCustom"+props.id+"C"+props.index, customSelectArray[props.id][props.index]);
                        }
                    }}>

                        <span class="cbut-tag" >적용</span>
                    </div>
                    <div class="csbt csbtc"  onClick={()=>customChange(props.id,props.index,0)}>
                        <span class="cbut-tag">
                            <div class="imgt">
                                <img src="arrow_go_back2.svg"/>
                            </div>
                        </span>
                    </div>
                </div>
            </div>
            
        </div>
    )

    
}

function TalismanSetting(props)
{
    return(
        <div class="set_talisman" style={presentTalis == props.id ? {}:{display:"none"}}>
            <div class="settal_setting">
                <div class="settal_attri">
                    <span>{props.id}번 탈리스만</span>
                </div>
                <div class="settal_img">
                    {
                        talismanSkillList.map( (a,i) => {
                            return(
                                <div class="talimg_cover">
                                    <div className={'imgt ' + (a == selectTalisSkill[props.id-1] ? 'on' : '')} onClick={()=>{let temp = [...selectTalisSkill]; temp[props.id-1] = a; setSelectTalisSkill(temp)}}>
                                        <img src={getSkillImageURL(a,characterData['baseJob'])}></img>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <div class="estcont">
                    <div class="cus_chkbut cusbonly">
                        <ul>
                            <li className={selectTalisType[props.id-1] == 0 ? "is-active" : ""} onClick={() => {let temp = [...selectTalisType]; temp[props.id-1] = 0; setSelectTalisType(temp)}}>온전한 탈리스만</li>
                            <li className={selectTalisType[props.id-1] == 1 ? "is-active" : ""} onClick={() => {let temp = [...selectTalisType]; temp[props.id-1] = 1; setSelectTalisType(temp)}}>성흔의 탈리스만</li>
                            <li className={selectTalisType[props.id-1] == 2 ? "is-active" : ""} onClick={() => {let temp = [...selectTalisType]; temp[props.id-1] = 2; setSelectTalisType(temp)}}>마계대전 유니크</li>
                            <li className={selectTalisType[props.id-1] == 3 ? "is-active" : ""} onClick={() => {let temp = [...selectTalisType]; temp[props.id-1] = 3; setSelectTalisType(temp)}}>카펠라의 성흔</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="settal_setting talsetp">
                <div class="setal_subp">
                    <div class="settal_img">
                        <div class="imgt" style={{'background' : talismanColor(characterData['rune'+((props.id-1) * 3 + 1)+"type"])}}>                            
                            <img style={{'mix-blend-mode' : 'luminosity'}} src={getSkillImageURL(characterData['rune'+((props.id-1) * 3 + 1)+"Skill"],characterData['baseJob'])}></img>
                        </div>
                    </div>

                    <div class="settal_set">
                        <select id="v212" number="4011" value={characterData['rune'+((props.id-1) * 3 + 1)+"Skill"]}
                        onChange={(event)=>change('rune'+((props.id-1) * 3 + 1)+"Skill",event.target.value)}>
                            {
                                runeSkill[characterData["job"]] != null ? runeSkill[characterData["job"]].map( (a,i ) => {
                                    return(
                                        <option>{a}</option>
                                    )
                                }) : ""
                            }

                        </select>
                    </div>
                    <div class="estcont">
                            <div class="cus_chkbut cusbonly">
                                <ul>
                                    <li className={characterData['rune'+((props.id-1) * 3 + 1)+'rarity'] == '갈라진' ? "is-active" : ""} onClick={()=>change('rune'+((props.id-1) * 3 + 1)+'rarity','갈라진')}>갈라진</li>
                                    <li className={characterData['rune'+((props.id-1) * 3 + 1)+'rarity'] == '빛바랜' ? "is-active" : ""} onClick={()=>change('rune'+((props.id-1) * 3 + 1)+'rarity','빛바랜')}>빛바랜</li>
                                    <li className={characterData['rune'+((props.id-1) * 3 + 1)+'rarity'] == '선명한' ? "is-active" : ""} onClick={()=>change('rune'+((props.id-1) * 3 + 1)+'rarity','선명한')}>선명한</li>
                                    <li className={characterData['rune'+((props.id-1) * 3 + 1)+'rarity'] == '화려한' ? "is-active" : ""} onClick={()=>change('rune'+((props.id-1) * 3 + 1)+'rarity','화려한')}>화려한</li>
                                </ul>
                            </div>
                        </div>
                        <div class="estcont">
                            <div class="cus_chkbut cusb12 setal5">
                            
                            <ul>
                                    <li className={(characterData['rune'+((props.id-1) * 3 + 1)+'type'] == '테라코타' ? "is-active" :    "")+  " teracota"  }  
                                    onClick={()=>change('rune'+((props.id-1) * 3 + 1)+'type',"테라코타")}></li>
                                    <li className={(characterData['rune'+((props.id-1) * 3 + 1)+'type'] == '서클 메이지' ? "is-active" : "")+  " scm"   }
                                    onClick={()=>change('rune'+((props.id-1) * 3 + 1)+'type',"서클 메이지")}></li>
                                    <li className={(characterData['rune'+((props.id-1) * 3 + 1)+'type'] == '수호자들' ? "is-active" :    "")+  " shjd"  }
                                    onClick={()=>change('rune'+((props.id-1) * 3 + 1)+'type',"수호자들")}></li>
                                    <li className={(characterData['rune'+((props.id-1) * 3 + 1)+'type'] == '고대 도서관' ? "is-active" : "")+  " dose"  }
                                    onClick={()=>change('rune'+((props.id-1) * 3 + 1)+'type',"고대 도서관")}></li>
                                    <li className={(characterData['rune'+((props.id-1) * 3 + 1)+'type'] == '세컨드 팩트' ? "is-active" : "")+  " sefect"    }
                                    onClick={()=>change('rune'+((props.id-1) * 3 + 1)+'type',"세컨드 팩트")}></li>
                                </ul>
                                
                            </div>
                        </div>
                    </div>
                    <div class="setal_subp">
                        <div class="settal_img">
                            <div class="imgt" style={{'background' : talismanColor(characterData['rune'+((props.id-1) * 3 + 2)+"type"])}}>                            
                                <img style={{'mix-blend-mode' : 'luminosity'}} src={getSkillImageURL(characterData['rune'+((props.id-1) * 3 + 2)+"Skill"],characterData['baseJob'] )}></img>
                        
                            </div>
                        </div>
                        <div class="settal_set">
                            <select id="v212" number="4011" value={characterData['rune'+((props.id-1) * 3 + 2)+"Skill"]}
                            onChange={(event)=>change('rune'+((props.id-1) * 3 + 2)+"Skill",event.target.value)}>
                                {
                                    runeSkill[characterData["job"]] != null ? runeSkill[characterData["job"]].map( (a,i ) => {
                                        return(
                                            <option>{a}</option>
                                        )
                                    }) : ""
                                }

                            </select>
                        </div>
                        <div class="estcont">
                            <div class="cus_chkbut cusbonly">
                                <ul>
                                    <li className={characterData['rune'+((props.id-1) * 3 + 2)+'rarity'] == '갈라진' ? "is-active" : ""} onClick={()=>change('rune'+((props.id-1) * 3 + 2)+'rarity','갈라진')}>갈라진</li>
                                    <li className={characterData['rune'+((props.id-1) * 3 + 2)+'rarity'] == '빛바랜' ? "is-active" : ""} onClick={()=>change('rune'+((props.id-1) * 3 + 2)+'rarity','빛바랜')}>빛바랜</li>
                                    <li className={characterData['rune'+((props.id-1) * 3 + 2)+'rarity'] == '선명한' ? "is-active" : ""} onClick={()=>change('rune'+((props.id-1) * 3 + 2)+'rarity','선명한')}>선명한</li>
                                    <li className={characterData['rune'+((props.id-1) * 3 + 2)+'rarity'] == '화려한' ? "is-active" : ""} onClick={()=>change('rune'+((props.id-1) * 3 + 2)+'rarity','화려한')}>화려한</li>
                                </ul>
                            </div>
                        </div>
                        <div class="estcont">
                            <div class="cus_chkbut cusb12 setal5">
                            
                                <ul>
                                    <li className={(characterData['rune'+((props.id-1) * 3 + 2)+'type'] == '테라코타' ? "is-active" :    "")+  " teracota"  }  
                                    onClick={()=>change('rune'+((props.id-1) * 3 + 2)+'type',"테라코타")}></li>
                                    <li className={(characterData['rune'+((props.id-1) * 3 + 2)+'type'] == '서클 메이지' ? "is-active" : "")+  " scm"   }
                                    onClick={()=>change('rune'+((props.id-1) * 3 + 2)+'type',"서클 메이지")}></li>
                                    <li className={(characterData['rune'+((props.id-1) * 3 + 2)+'type'] == '수호자들' ? "is-active" :    "")+  " shjd"  }
                                    onClick={()=>change('rune'+((props.id-1) * 3 + 2)+'type',"수호자들")}></li>
                                    <li className={(characterData['rune'+((props.id-1) * 3 + 2)+'type'] == '고대 도서관' ? "is-active" : "")+  " dose"  }
                                    onClick={()=>change('rune'+((props.id-1) * 3 + 2)+'type',"고대 도서관")}></li>
                                    <li className={(characterData['rune'+((props.id-1) * 3 + 2)+'type'] == '세컨드 팩트' ? "is-active" : "")+  " sefect"    }
                                    onClick={()=>change('rune'+((props.id-1) * 3 + 2)+'type',"세컨드 팩트")}></li>
                                </ul>
                                
                            </div>
                        </div>
                    </div>
                    <div class="setal_subp">
                        <div class="settal_img">
                            <div class="imgt" style={{'background' : talismanColor(characterData['rune'+((props.id-1) * 3 + 3)+"type"])}}>                            
                                <img style={{'mix-blend-mode' : 'luminosity'}} src={getSkillImageURL(characterData['rune'+((props.id-1) * 3 + 3)+"Skill"],characterData['baseJob'])}></img>
                            
                            </div>
                        </div>
                        <div class="settal_set">
                            <select id="v212" number="4011" value={characterData['rune'+((props.id-1) * 3 + 3)+"Skill"]}
                            onChange={(event)=>change('rune'+((props.id-1) * 3 + 3)+"Skill",event.target.value)}>
                                {
                                    runeSkill[characterData["job"]] != null ? runeSkill[characterData["job"]].map( (a,i ) => {
                                        return(
                                            <option>{a}</option>
                                        )
                                    }) : ""
                                }

                            </select>
                        </div>
                        <div class="estcont">
                            <div class="cus_chkbut cusbonly">
                                <ul>
                                    <li className={characterData['rune'+((props.id-1) * 3 + 3)+'rarity'] == '갈라진' ? "is-active" : ""} onClick={()=>change('rune'+((props.id-1) * 3 + 3)+'rarity','갈라진')}>갈라진</li>
                                    <li className={characterData['rune'+((props.id-1) * 3 + 3)+'rarity'] == '빛바랜' ? "is-active" : ""} onClick={()=>change('rune'+((props.id-1) * 3 + 3)+'rarity','빛바랜')}>빛바랜</li>
                                    <li className={characterData['rune'+((props.id-1) * 3 + 3)+'rarity'] == '선명한' ? "is-active" : ""} onClick={()=>change('rune'+((props.id-1) * 3 + 3)+'rarity','선명한')}>선명한</li>
                                    <li className={characterData['rune'+((props.id-1) * 3 + 3)+'rarity'] == '화려한' ? "is-active" : ""} onClick={()=>change('rune'+((props.id-1) * 3 + 3)+'rarity','화려한')}>화려한</li>
                                </ul>
                            </div>
                        </div>
                        <div class="estcont">
                            <div class="cus_chkbut cusb12 setal5">
                            
                                <ul>
                                    <li className={(characterData['rune'+((props.id-1) * 3 + 3)+'type'] == '테라코타' ? "is-active" :    "")+  " teracota"  }  
                                    onClick={()=>change('rune'+((props.id-1) * 3 + 3)+'type',"테라코타")}></li>
                                    <li className={(characterData['rune'+((props.id-1) * 3 + 3)+'type'] == '서클 메이지' ? "is-active" : "")+  " scm"   }
                                    onClick={()=>change('rune'+((props.id-1) * 3 + 3)+'type',"서클 메이지")}></li>
                                    <li className={(characterData['rune'+((props.id-1) * 3 + 3)+'type'] == '수호자들' ? "is-active" :    "")+  " shjd"  }
                                    onClick={()=>change('rune'+((props.id-1) * 3 + 3)+'type',"수호자들")}></li>
                                    <li className={(characterData['rune'+((props.id-1) * 3 + 3)+'type'] == '고대 도서관' ? "is-active" : "")+  " dose"  }
                                    onClick={()=>change('rune'+((props.id-1) * 3 + 3)+'type',"고대 도서관")}></li>
                                    <li className={(characterData['rune'+((props.id-1) * 3 + 3)+'type'] == '세컨드 팩트' ? "is-active" : "")+  " sefect"    }
                                    onClick={()=>change('rune'+((props.id-1) * 3 + 3)+'type',"세컨드 팩트")}></li>
                                </ul>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }

    function talismanToSkillName(job,talisName)
    {
        if(talisName == null)
        {
            return;
        }
        let skillName = '';
        if(talisName.indexOf('[') != -1)
        {
            skillName = talisName.split('[')[1].split(']')[0];
        }
        else
        {
            let stop = false;
            for(let loop = 0 ; loop < talismanList[job].length ; loop++)
            {
                if(talismanList[job][loop] == talisName)
                {
                    stop = true;
                }

                if(stop)
                if(talismanList[job][loop].indexOf('[') != -1)
                {
                    skillName = talismanList[job][loop].split('[')[1].split(']')[0];
                    break;
                }
            }
        }

        return skillName;
    }

    function talismanColor(talisType)
    {
        var color = "";
        switch(talisType)
        {
            case "테라코타" : color = 'purple'; break;
            case "서클 메이지" : color = 'green'; break;
            case "수호자들" : color = 'dodgerblue'; break;
            case "고대 도서관" : color = 'yellow'; break;
            case "세컨드 팩트" : color = 'red'; break;
        }
        return color;
    }

    function submitSetting()
    {
        init = false;
        
        if(characterData['equipName0'] != null)
        if(characterData['equipName0'].indexOf("_") != -1)
        {
            let temp = characterData['equipName0'].split("_")[1];
            switch(temp)
            {
                case "cbow" : characterData['weaponType'] = "크로스슈터"; break;
                case "ebow" : characterData['weaponType'] = "에테리얼 보우"; break;
                case "lbow" : characterData['weaponType'] = "장궁"; break;
                case "sbow" : characterData['weaponType'] = "선현궁"; break;
    
                case "bs" : characterData['weaponType'] = "광창"; break;
                case "hb" : characterData['weaponType'] = "미늘창"; break;
                case "jav" : characterData['weaponType'] = "투창"; break;
                case "pike" : characterData['weaponType'] = "장창"; break;
    
                case "bg" : characterData['weaponType'] = "권투글러브"; break;
                case "claw" : characterData['weaponType'] = "클로"; break;
                case "gau" : characterData['weaponType'] = "건틀릿"; break;
                case "knu" : characterData['weaponType'] = "너클"; break;
                case "tonfa" : characterData['weaponType'] = "통파"; break;
    
                case "core" : characterData['weaponType'] = "코어블레이드"; break;
                case "lbla" : characterData['weaponType'] = "장도"; break;
                case "mswd" : characterData['weaponType'] = "중검"; break;
                case "sbla" : characterData['weaponType'] = "소태도"; break;
    
                case "auto" : characterData['weaponType'] = "자동권총"; break;
                case "bowgun" : characterData['weaponType'] = "보우건"; break;
                case "hcan" : characterData['weaponType'] = "핸드캐넌"; break;
                case "musk" : characterData['weaponType'] = "머스켓"; break;
                case "revol" : characterData['weaponType'] = "리볼버"; break;
    
                case "bro" : characterData['weaponType'] = "빗자루"; break;
                case "pole" : characterData['weaponType'] = "봉"; break;
                case "rod" : characterData['weaponType'] = "로드"; break;
                case "spear" : characterData['weaponType'] = "창"; break;
                case "staff" : characterData['weaponType'] = "스태프"; break;
    
                case "axe" : characterData['weaponType'] = "배틀액스"; break;
                case "cross" : characterData['weaponType'] = "십자가"; break;
                case "rosa" : characterData['weaponType'] = "염주"; break;
                case "scythe" : characterData['weaponType'] = "낫"; break;
                case "totem" : characterData['weaponType'] = "토템"; break;
    
                case "chakra" : characterData['weaponType'] = "차크라 웨펀"; break;
                case "dagger" : characterData['weaponType'] = "단검"; break;
                case "twins" : characterData['weaponType'] = "쌍검"; break;
                case "wand" : characterData['weaponType'] = "완드"; break;
    
                case "beams" : characterData['weaponType'] = "광검"; break;
                case "gs" : characterData['weaponType'] = "대검"; break;
                case "katana" : characterData['weaponType'] = "도"; break;
                case "club" : characterData['weaponType'] = "둔기"; break;
                case "ss" : characterData['weaponType'] = "소검"; break;
    
            }
        }
        
        for(let weapon of weaponList)
        {
            if(characterData['equipName0'].replace("스태프","스탭").replace(/ /gi,'').indexOf(weapon.replace(/ /gi,'')) != -1)
            {
                
                characterData['weaponType'] = weapon.replace("스태프","스탭");

            }
        }


        let temp = {...characterData};

        for(let talisLoop = 0 ; talisLoop < 3 ; talisLoop++)
        {
            let talisName = "";
            //console.log(selectTalisType[talisLoop] );
            
            if(selectTalisType[talisLoop] == 0)
            {
                talisName = "온전한 탈리스만["+selectTalisSkill[talisLoop]+']';
            }
            else if(selectTalisType[talisLoop] == 1)
            {
                talisName = "성흔의 탈리스만["+selectTalisSkill[talisLoop]+']';
            }
            else if(selectTalisType[talisLoop] == 2)
            {
                let stop = false;
                for(let loop = talismanList[characterData['job']].length -1 ; loop > 0 ; loop--)
                {
                    try
                    {
                        if(talismanList[characterData['job']][loop].split("[")[1].replace("]",'') == selectTalisSkill[talisLoop])
                        {
                            stop = true;
                        }
                    }
                    catch(e)
                    {

                    }
                    
                    if(stop && talismanList[characterData['job']][loop].indexOf('카펠라의 성흔') == -1 
                    && talismanList[characterData['job']][loop].indexOf('[') == -1)
                    {
                        talisName = talismanList[characterData['job']][loop];
                        break;
                    }
                }

                
                if(talisName == "" && stop)
                {
                    for(let loop = 0 ; loop < talismanList[characterData['job']].length -1  ; loop++)
                    {
                        if(stop && talismanList[characterData['job']][loop].indexOf('카펠라의 성흔') == -1
                        && talismanList[characterData['job']][loop].indexOf('[') == -1)
                        {
                            //console.log(talismanList[characterData['job']][loop] );
                            talisName = talismanList[characterData['job']][loop];
                            break;
                        }
                    }
                }
            }
            else if(selectTalisType[talisLoop] == 3)
            {
                let stop = false;
                for(let loop = talismanList[characterData['job']].length -1 ; loop > 0 ; loop--)
                {
                    //console.log(talismanList[characterData['job']][loop].indexOf('카펠라의 성흔') );
                    try
                    {
                        if(talismanList[characterData['job']][loop].split("[")[1].replace("]",'') == selectTalisSkill[talisLoop] )
                        {
                            stop = true;
                        }
                    }
                    catch(e)
                    {

                    }
                    

                    if(stop && talismanList[characterData['job']][loop].indexOf('카펠라의 성흔') != -1)
                    {
                        //console.log(talismanList[characterData['job']][loop] );
                        talisName = talismanList[characterData['job']][loop];
                        break;
                    }
                }

                if(talisName == "" && stop)
                {
                    for(let loop = 0 ; loop < talismanList[characterData['job']].length -1  ; loop++)
                    {
                        if(stop && talismanList[characterData['job']][loop].indexOf('카펠라의 성흔') != -1)
                        {
                            //console.log(talismanList[characterData['job']][loop] );
                            talisName = talismanList[characterData['job']][loop];
                            break;
                        }
                    }
                }
            }


            temp['talis'+talisLoop] = talisName;
            //console.log("탈리 : " + talisName);
        
        }

        //장비특성
        temp['specIndex'] = selectSpecType;

        navigate('../character?server='+characterObj.server+'&key='+characterObj.id,{state:{edit:temp}});
        setNavigateValue("character");

    }

    function GemSelect(props)
    {
        return(
            <div class="set_hwijjam">
                <div class="sethjam_tit">
                    <span>{props.id}</span>
                </div>
                <div class="sethjam_img">
                    <div className={"imgt " + (characterData["gem"+props.id] == "커먼" ? "is-active" : "")}  onClick={()=>change("gem"+props.id,"커먼")}>
                        <img src={"gem"+props.id+"0.png"}/>

                    </div>
                </div>
                <div class="sethjam_img">
                    <div className={"imgt " + (characterData["gem"+props.id] == "언커먼" ? "is-active" : "")}  onClick={()=>change("gem"+props.id,"언커먼")} >
                        <img src={"gem"+props.id+"1.png"}/>

                    </div>
                </div>
                <div class="sethjam_img">
                    <div className={"imgt " + (characterData["gem"+props.id] == "레어" ? "is-active" : "")} onClick={()=>change("gem"+props.id,"레어")} >
                        <img src={"gem"+props.id+"2.png"}/>

                    </div>
                </div>
                <div class="sethjam_img">
                    <div className={"imgt " + (characterData["gem"+props.id] == "유니크" ? "is-active" : "")}  onClick={()=>change("gem"+props.id,"유니크")}>
                        <img src={"gem"+props.id+"3.png"}/>

                    </div>
                </div>
                <div class="sethjam_img">
                    <div className={"imgt " + (characterData["gem"+props.id] == "레전더리" ? "is-active" : "")}  onClick={()=>change("gem"+props.id,"레전더리")}>
                        <img src={"gem"+props.id+"4.png"}/>

                    </div>
                </div>
            </div>
        )
    }

    

    
    function EnchantPreview(props)
    {

        let img = '';
        let value = 0;
        if(characterData['equipEnchantEtc'+props.id] != 0)
        {
            value = characterData['equipEnchantEtc'+props.id]; 
            if(props.id == 3 || props.id == 6 || props.id == 5)
            {
                img = 'img/icon/281.png';
            }
            else
            {
                img = 'img/icon/108.png';
            }
        }
        else if(characterData['equipEnchantEle'+props.id] != 0)
        {
            value = characterData['equipEnchantEle'+props.id];
            img = 'img/icon/108.png';
        }
        else if(characterData['equipEnchantAttack'+props.id] != 0)
        {
            value = characterData['equipEnchantAttack'+props.id];
            img = 'img/icon/244.png';
        }
        else if(characterData['equipEnchantStat'+props.id] != 0)
        {
            value = characterData['equipEnchantStat'+props.id];
            img = 'img/icon/248.png';
        }


        return(
            <div class="csul">
                <div class="csil ctit"><img src={img}/></div>
                <div class="csil cusa">+{value}</div>
            </div>
        )
    }

    

    function optionSearch(option,search)
    {
        let optionString = option.replace(/ /gi,'').replace(/\+/gi,'').replace(/,/gi,'').toLowerCase();
        let searchString = search.replace(/ /gi,'').replace(/\+/gi,'').replace(/,/gi,'').replace(/ /gi, '').replace(/모속강/gi, '모든속성강화').replace(/속강/gi, '속성강화').replace(/스증/gi, '스킬공격력')
        .replace(/속저/gi, '속성저항').replace(/쿨회/gi, '쿨타임회복속도')
        .toLowerCase();
        
        return optionString.indexOf(searchString);
    }

    function Trait(props)
    {
        if(traitList[selectSpecType-1].includes(Number(selectSpecType+props.slot)))
        {
            
            return(
                <div class="cfa-hover-side">                              
                    <div class="imgt">
                        <img src={'/reSpec/'+selectSpecType+props.slot+(characterData["spec"+selectSpecType+props.slot] >= 1 ? "active" : "")+'.png'}></img>
                    </div>
                    {/* <div class="euip-fea-addbox">
                        <div class="ef-ab-cont">
                            <span>{trait[selectSpecType+props.slot] ? trait[selectSpecType+props.slot] : "" }</span>
                        </div>
                    </div> */}

                    <div class="euip-fea-masterpoint">
                        <div class="efmp-con">
                            <ul>
                                <li class="efmp-but efmp-b-minos" onClick={()=>change("spec"+selectSpecType+props.slot, 
                                characterData["spec"+selectSpecType+props.slot] - 1 <= 0 ? 0 : characterData["spec"+selectSpecType+props.slot] - 1 )}>
                                    <span>-</span>
                                </li>
        
                                <li class={characterData["spec"+selectSpecType+props.slot] >= (traitMaster[selectSpecType-1])[Number(props.slot[0])-1][Number(props.slot[1])-1]
                                  ? "efmp-stat-m" : "efmp-stat"  }>
                                    <span>{characterData["spec"+selectSpecType+props.slot]}</span>
                                </li>
                    
                                <li class="efmp-but efmp-b-plus" onClick={()=>change("spec"+selectSpecType+props.slot,
                                (traitMaster[selectSpecType-1])[Number(props.slot[0])-1][Number(props.slot[1])-1] <= characterData["spec"+selectSpecType+props.slot] + 1 ?
                                (traitMaster[selectSpecType-1])[Number(props.slot[0])-1][Number(props.slot[1])-1] : characterData["spec"+selectSpecType+props.slot] + 1 )}>
                                    <span>+</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            )
        }
        else
        {
            return(<></>)
        }

       
    }

    
    function FusionStone(props)
    {
        return(

            <li className={characterData['siroco'+props.slot] == props.name ? " on" : ""} >
                <div class="imgt raidItem" slot={"10"+props.slot} onClick={()=>change('siroco'+props.slot,props.name)}>
                    <img name={props.name} src={"https://img-api.neople.co.kr/df/items/"+props.image} oncontextmenu="return false"/>
                </div>

                {
                    lv105OptionMap[props.name] != null ? 
                    <div class="cus-detail-button" onClick={() => change('equipOption',props.name)}>
                        <span  >
                            <div class="imgt">
                                <img src='img/burger_line2.svg'></img>
                            </div>
                        </span>
                    
                    </div>
                    : <></>
                }
                

                {
                    characterData['equipOption'] != null ? characterData['equipOption'] == props.name ? 
                    <div class="cus-addbox">
                        
                            <div class="abtit"><img src={"https://img-api.neople.co.kr/df/items/"+props.image} oncontextmenu="return false"></img><span class="abt_name">{props.name}</span></div>

                            <div class="abop">
                                        
                                <div class="option">
                                    {
                                        lv105OptionMap[props.name] != null ? <>{lv105OptionMap[props.name].replace(/<br>/gi, "\n")}</> :<></>
                                    }
                                </div>
                            </div>
                            <div class="ab-close-button">
                                <div class="aclob-con" onClick={() => change('equipOption',"")}>
                                    <span>닫기</span>
                                </div>
                            </div>
                    </div> 
                    : "" : ""
                }

                {/* <div class="cus-addbox">
                    <div class="abtit"><img src={"https://img-api.neople.co.kr/df/items/"+props.image} oncontextmenu="return false"></img><span class="abt_name">{props.name}</span></div>

                    <div class="abop">
                        <div class="option">
                            {
                                lv105OptionMap[props.name] != null ? <>{lv105OptionMap[props.name].replace(/<br>/gi, "\n")}</> :<></>
                            }
                        </div>
                    </div>
                </div> */}
            </li>
        )
    }

    function FusionEquip(props)
    {
        return(
        <li className={characterData['siroco'+props.slot] == props.setName ? " on" : ""} onClick={()=>change('siroco'+props.slot,props.setName)}>
            <div class="imgt raidItem" slot={"10"+props.slot}>
                <img name={props.name} src={"https://img-api.neople.co.kr/df/items/"+props.image}/>
            </div>

            {/* <div class="cus-addbox">
                <div class="abtit"><img src="img/item/1ccac9965353062c04b7da144244b544"></img><span class="abt_name">불가침의 영역 - 벨트</span></div>

                <div class="abop">
                <div class="option"><span class="op_gold">피해 증가</span> + 6858 공격 시 300px 내 랜덤한 위치에 번개를 시전하여 모든 적과 자신에게 15초 동안 감전 상태 이상 부여 (쿨타임 10초)
                - 상태 이상 내성 무시
                - 스킬, 소모품으로 해제 불가
                번개는 적의 방어력을 일부 적용받고 총 피해 증가 수치의 84%만큼 피해를 줌

                자신이 감전 상태일 때 아래의 효과 적용
                - 스킬 공격력 17% 증가
                - 공격 속도 +20%
                - 캐스팅 속도 +30%
                - 이동 속도 +20%

                500px 내 감전 상태인 대상 하나마다 피해 증가 +712 (최대 5중첩)

                물리/마법 크리티컬 히트 +7%

                모든 상태 이상 내성 -10%</div>
                </div>
            </div> */}
        </li>
        )
    } 

    
    function ItemList(props)
    {
        
        return(
            <li className={characterData['equipName'+props.slot] == props.name ? 'on' : '' }>
                <div class="imgt">
                    <img src={'https://dundam.xyz/img/item/'+lv105ItemId[props.name] }  onClick={() => change('equipName'+props.slot,props.name)} />
                </div>
                {
                    lv105OptionMap[props.name] != null ? 
                    <div class="cus-detail-button" onClick={() => change('equipOption',props.name)}>
                        <span  >
                            <div class="imgt">
                                <img src='img/burger_line2.svg'></img>
                            </div>
                        </span>
                    
                    </div>
                    : <></>
                }
                

                {
                    characterData['equipOption'] != null ? characterData['equipOption'] == props.name ? 
                    <div class="cus-addbox">
                        
                            <div class="abtit">
                                <div class="imgt">
                                    <img src={'https://dundam.xyz/img/item/'+lv105ItemId[props.name] }  onClick={() => change('equipName'+props.slot,props.name)} />
                                </div>
                                <span class="abt_name">{props.name}</span>
                            
                            </div>
                            <div class="abop">
                                <ItemOption name={props.name} number={0}></ItemOption>
                            </div>
                            <div class="ab-close-button">
                                <div class="aclob-con" onClick={() => change('equipOption',"")}>
                                    <span>닫기</span>
                                </div>
                            </div>
                    </div> 
                    : "" : ""
                }
            </li>
        )
    }

    function Lv115Weapon(props)
    {
        return(
            
            <li className={(characterData['equipName0'] == props.name ? "on" : "" )}>
                <div className="imgt" onClick={()=>{lv115EquipChange(0, props.name)}}>
                    <img src={"https://dundam.xyz/img/lv115/weapon/"+props.name+".png"}/>
                </div>
            </li>
        )

    }
    function Weapon(props)
    {
        /*
        
            <li className={(characterData['equipName0'] == props.name ? "on" : "" )}>
                <div className="imgt" onClick={()=>{change("equipName0", props.name)}}>
                    <img  name={ props.name}  src={"img/item/" + lv105ItemId[ props.name] } loading="lazy"></img>
                </div>
            </li>
        */
        let number = weaponImage[lv105ItemId[props.name]];

        let x = 0;
        let y = 0;
        if(number != null)
        {
            x = (number-1) % 9;
            y = Math.trunc((number-1)/ 9) ;
        }
        
        return(
            
            <li className={(characterData['equipName0'] == props.name ? "on" : "" )}>
                <div className="imgt" onClick={()=>{change("equipName0", props.name)}}>
                    <div  name={ props.name} className={"weaponSprite"} loading="lazy" style={{"zoom" : "1.1785","width":"28px","height":"28px","background-position":"-"+x * 28+"px -"+y * 28+"px"} }></div>
                </div>
            </li>
        )

    }

    function Lv105Weapon(props)
    {
        /*
        
            <li className={(characterData['equipName0'] == props.name ? "on" : "" )}>
                <div className="imgt" onClick={()=>{change("equipName0", props.name)}}>
                    <img  name={ props.name}  src={"img/item/" + lv105ItemId[ props.name] } loading="lazy"></img>
                </div>
            </li>
        */
        let temp = props.name;
        //console.log(temp);

        if(temp.includes("결전의 "))
        {
            temp = temp.split(' - ')[0];
        }
        //console.log(temp);

        let number = lv105WeaponImage[temp];

        //console.log(props.name)
        //console.log(number);

        let x = 0;
        let y = 0;
        if(number != null)
        {
            x = (number-1) % 9;
            y = Math.trunc((number-1)/ 9) ;
        }
        //console.log(x);
        //console.log(y);
        
        return(
            
            <li className={(characterData['equipName0'] == props.name ? "on" : "" )}>
                <div className="imgt" onClick={()=>{change("equipName0", props.name)}}>
                    <div  name={ props.name} className={"weaponSprite"} loading="lazy" 
                    style={{"zoom" : "1.1785","background-image":"url('https://dundam.xyz/img/sprite/lv105WeaponImage.png')" , "width":"28px","height":"28px","background-position":"-"+x * 28+"px -"+y * 28+"px"} }></div>
                </div>
            </li>
        )

    }

    function Equip(props)
    {

        let temp = props.name;
        let number = lv105EquipImage[temp];

        //console.log(props.name)
        //console.log(number);

        let x = 0;
        let y = 0;
        if(number != null)
        {
            x = (number-1) % 9;
            y = Math.trunc((number-1)/ 9) ;
        }
        //console.log(x);
        //console.log(y);

        return(
            <>
            <li className={characterData['equipName'+props.slot] == props.name ? 'on' : '' }>
                <div class="imgt"  onClick={() => change('equipName'+props.slot,props.name)}>
                    <div  name={ props.name} className={"weaponSprite"} loading="lazy" 
                    style={{"zoom":"1.35714","background-image":"url('https://dundam.xyz/img/sprite/lv105EquipImage.png')"
                     , "width":"28px","height":"28px","background-position":"-"+x * 28+"px -"+y * 28+"px"} }></div>
                </div>
                {
                    lv105OptionMap[props.name] != null ? 
                    <div class="cus-detail-button" onClick={() => change('equipOption',props.name)}>
                        <span  >
                            <div class="imgt">
                                <img src='img/burger_line2.svg'></img>
                            </div>
                        </span>
                    
                    </div>
                    : <></>
                }
                

                {
                    characterData['equipOption'] != null ? characterData['equipOption'] == props.name ? 
                    <div class="cus-addbox">
                        
                            <div class="abtit">
                                <div  name={ props.name} className={"weaponSprite"} loading="lazy" 
                                style={{"background-image":"url('https://dundam.xyz/img/sprite/lv105EquipImage.png')"
                                , "width":"28px","height":"28px","background-position":"-"+x * 28+"px -"+y * 28+"px"} }></div>
                                <span class="abt_name">{props.name}</span>
                            
                            </div>
                            <div class="abop">
                                <ItemOption name={props.name} number={0}></ItemOption>
                            </div>
                            <div class="ab-close-button">
                                <div class="aclob-con" onClick={() => change('equipOption',"")}>
                                    <span>닫기</span>
                                </div>
                            </div>
                    </div> 
                    : "" : ""
                }
            </li>
            
               
            </>
        )
    }

    function LegendStone(props)
    {
        let name = "";

        switch(Number(props.slot))
        {
            case 10 : name ='보조장비'; break;
            case 11 : name ='마법석'; break;
            case 12 : name ='귀걸이'; break;
        }
        return(
            <div class="eset_legendstone_optionadd">
                <div class="et-lston-h-name">
                    <span> <b>영원의 조각 - {name} </b>     옵션 설정</span>
                </div>
                <div class="esetting_tr cusp2 o105je stone">

                    {[0,0,0].map( (a,loop) =>
                    {
                        if(selectFusionType != 1)
                        {
                            return(<></>);
                        }
                        return(
                            <>
                            <div class={"estcont cussa legendStone" + ((legendStoneArray[props.slot-10][loop] == 0) ? " view" : "")} id={'legendStone'+props.slot+"_"+loop}>
                                
                                <div class="cus_105op" >
                                    <div class="option">
                                        {characterData['legendStone'+props.slot+"_"+(loop+1)] != null ? legendStone[characterData['legendStone'+props.slot+"_"+(loop+1)] - 1].replace(/<br>/gi, "\n") : ""}

                                    </div>

                                    <div class="cus_opsetbut" onClick={() => { legendStoneChange(props.slot, loop, 1)}} >
                                        <div class="imgt">
                                            <img src="img/set1.svg" />
                                        </div>

                                    </div>

                                </div>
                                {
                                    legendStoneArray[props.slot-10][loop] == 1 ? 
                                    <>
                                    <div class={"cus_opset" + (legendStoneArray[props.slot-10][loop] == 1 ? " on" : "")}>
                                        <div class="cos_c">
                                            
                                            <div class="coscc">
                                                <ul>
                                                    {
                                                        legendStone.map((a, i) => {

                                                            return (

                                                                <li key={'legendStone'+ props.slot + loop} className={legendStoneSelectArray[props.slot-10][loop] == i + 1 
                                                                    ? "is-active" : ""} onClick={() => legendStoneSelectChange(props.slot,loop, i + 1)}>{a.replace(/<br>/gi, '\n')}
                                                                </li>
                                                            )

                                                        }) 
                                                    }
                                                </ul>
                                            </div>
                                            <div class="cos_but">
                                                <div class="csbt" slot="1" onClick={() => { if(legendStoneSelectArray[props.slot-10][loop] != 0) { change('legendStone'+props.slot+"_"+(loop+1), legendStoneSelectArray[props.slot-10][loop]);  legendStoneChange(props.slot, loop, 0); } }} >
                                                    <span class="cbut-tag" >적용</span>
                                                </div>
                                                <div class="csbt csbtc" onClick={() => { legendStoneChange(props.slot, loop, 0) }}>
                                                    <span class="cbut-tag">
                                                        <div class="imgt">
                                                            <img src="img/arrow_go_back2.svg" />
                                                        </div>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    </>
                                    :
                                    <></>

                                }
                                
                            </div>
                            </>
                        )
                    })}
                    
                    

                </div>
            </div>

        )
    }

    function getSkillImageURL(skillName, baseJob)
    {
        let temp = skillName;
        
        if((temp == "베놈 마인" || temp == "크레이지 발칸" || temp == "마운트") && baseJob == "용독문주")
        {
            temp = skillName+"(여)";
        }
        return skillImage[temp] == null ? "" : skillImage[temp];
    }
    
    function lv115EquipChange(number,src)
    {
        let temp = {...characterData};

        temp["equipName"+number] = src;
        temp["itemLevel"+number] = 115;


        setCharacterData(temp);
        getSetPoint(temp);

    }

    function isTeacho(name,loop)
    {
        if(loop > 6 && loop < 10)
        {
            let temp = name.split("_")[0];
            switch(temp % 6)
            {
                case 5 : case 0 : return true;
            }
        }
        else if( loop == 0)
        {
            let temp = name.split("_")[0];
            switch(temp)
            {
                case '5' : case '8': return true;
            }
        }
        return false;
    }
    function getSetPoint(character)
    {
        let points = [];
        let publicPoint = 0;
        for(let loop = 2 ; loop < 13 ; loop++ )
        {
            let setPoint = 0;
            let setName = "";

            if(character["itemUp"+loop] != null)
            {
                setPoint += Number(character["itemUp"+loop]) * 10
            }
            if(character["equipName"+loop].indexOf("rare") != -1)
            {
                publicPoint += 65;
            }

            if(character["equipName"+loop].indexOf("rare") == -1)
            if(loop > 6 && loop < 10)
            {

                //악세서리 부분
                let temp = character["equipName"+loop].split("_")[0];
                switch(Math.floor((temp-1)/6))
                {
                    case 0: setName ="영원히 이어지는 황금향"; break;
                    case 1: setName ="칠흑의 정화"; break;
                    case 2: setName ="세렌디피티"; break;
                    case 3: setName ="한계를 넘어선 에너지"; break;
                    case 4: setName ="소울 페어리"; break;
                    case 5: setName ="압도적인 자연"; break;
                    case 6: setName ="고대 전장의 발키리"; break;
                    case 7: setName ="에테리얼 오브 아츠"; break;
                    case 8: setName ="그림자에 숨은 죽음"; break;
                    case 9: setName ="무리 사냥의 길잡이"; break;
                    case 10: setName ="마력의 영역"; break;
                    case 11: setName ="용투장의 난"; break;
                    case 12: setName ="고유"; break;
                }

                if(setName == '고유')
                {
                    switch(temp % 6)
                    {
                        case 1 : publicPoint += 115; break;
                        case 2 : publicPoint += 165; break;
                        case 3 : publicPoint += 215; break;
                        case 5 : publicPoint += 265; break;
                        case 4 : publicPoint += 165; break;
                        case 0 : publicPoint += 215; break;
                    }
                }
                else
                {
                    switch(temp % 6)
                    {
                        case 1 : setPoint += 115; break;
                        case 2 : setPoint += 165; break;
                        case 3 : setPoint += 215; break;
                        case 5 : setPoint += 265; break;
                        case 4 : setPoint += 165; break;
                        case 0 : setPoint += 215; break;
                    }
                }
                
                if(points[setName] == null) points[setName] = 0;
                points[setName] += setPoint;
            }
            else if(loop >= 10)
            {
                
                let temp = character["equipName"+loop].split("_")[0];
                switch(Math.floor((temp-1)/3))
                {
                    case 0: setName ="영원히 이어지는 황금향"; break;
                    case 1: setName ="칠흑의 정화"; break;
                    case 2: setName ="세렌디피티"; break;
                    case 3: setName ="한계를 넘어선 에너지"; break;
                    case 4: setName ="소울 페어리"; break;
                    case 5: setName ="압도적인 자연"; break;
                    case 6: setName ="고대 전장의 발키리"; break;
                    case 7: setName ="에테리얼 오브 아츠"; break;
                    case 8: setName ="그림자에 숨은 죽음"; break;
                    case 9: setName ="무리 사냥의 길잡이"; break;
                    case 10: setName ="마력의 영역"; break;
                    case 11: setName ="용투장의 난"; break;
                    case 12: setName ="고유"; break;
                }
                if(setName == '고유')
                {
                    switch(temp % 3)
                    {
                        case 1 : publicPoint += 115; break;
                        case 2 : publicPoint += 165; break;
                        case 0 : publicPoint += 215; break;
                    }
                }
                else
                {
                    switch(temp % 3)
                    {
                        case 1 : setPoint += 115; break;
                        case 2 : setPoint += 165; break;
                        case 0 : setPoint += 215; break;
                    }
                }
                if(points[setName] == null) points[setName] = 0;
                points[setName] += setPoint;
            }
            else
            {
                let temp = Number(character["equipName"+loop].split("_")[0]);
                if(character["equipName"+loop].indexOf("_c_") != -1)
                {
                    switch(Math.floor((temp-1)/3))
                    {
                        case 0: setName ="칠흑의 정화"; break;
                        case 1: setName ="세렌디피티"; break;
                        case 2: setName ="소울 페어리"; break;
                    }
                }
                else if(character["equipName"+loop].indexOf("_l_") != -1)
                {
                    switch(Math.floor((temp-1)/3))
                    {
                        case 0: setName ="그림자에 숨은 죽음"; break;
                        case 1: setName ="무리 사냥의 길잡이"; break;
                        case 2: setName ="마력의 영역"; break;
                    }
                }
                else if(character["equipName"+loop].indexOf("_la_") != -1)
                {
                    switch(Math.floor((temp-1)/3))
                    {
                        case 0: setName ="에테리얼 오브 아츠"; break;
                    }
                }
                else if(character["equipName"+loop].indexOf("_h_") != -1)
                {
                    switch(Math.floor((temp-1)/3))
                    {
                        case 0: setName ="용투장의 난"; break;
                    }
                }
                else if(character["equipName"+loop].indexOf("_p_") != -1)
                {
                    switch(Math.floor((temp-1)/3))
                    {
                        case 0: setName ="영원히 이어지는 황금향"; break;
                        case 1: setName ="한계를 넘어선 에너지"; break;
                        case 2: setName ="압도적인 자연"; break;
                        case 3: setName ="고대 전장의 발키리"; break;
                        case 4: setName ="고유"; break;
                    }
                }


                if(setName == '고유')
                {
                    switch(temp % 3)
                    {
                        case 1 : publicPoint += 115; break;
                        case 2 : publicPoint += 165; break;
                        case 0 : publicPoint += 215; break;
                    }
                }
                else
                {
                    switch(temp % 3)
                    {
                        case 1 : setPoint += 115; break;
                        case 2 : setPoint += 165; break;
                        case 0 : setPoint += 215; break;
                    }
                    if(points[setName] == null) points[setName] = 0;
                    points[setName] += setPoint;
                }

            }
        }

        let maxPoint = 0;
        let maxSetName = "";
        
        for(let loop of Object.keys(points))
        {
            if(maxPoint < points[loop])
            {
                maxPoint = points[loop];
                maxSetName = loop;
            }
        }

        setSetPoint(maxPoint + publicPoint);
        setSetName(maxSetName);
        
    }


    function isRaidWeapon(weaponName)
    {
        return weaponName.includes("火") || 
        weaponName.includes("水") || 
        weaponName.includes("木") || 
        weaponName.includes("金") || 
        weaponName.includes("土") || 
        weaponName.includes("부조화");
    }
    
    function is110Legendary(equipName)
    {
        return equipName.includes("불가침") ;
    }
    
export default EqupEditMO


