var lv115ArmourImage = [];

lv115ArmourImage["칠흑의 정화"] = "1_c";
lv115ArmourImage["세렌디피티"] = "4_c";
lv115ArmourImage["소울 페어리"] = "7_c";
lv115ArmourImage["그림자에 숨은 죽음"] = "1_l";
lv115ArmourImage["무리 사냥의 길잡이"] = "4_l";
lv115ArmourImage["마력의 영역"] = "7_l";
lv115ArmourImage["에테리얼 오브 아츠"] = "1_la";
lv115ArmourImage["용투장의 난"] = "1_h";
lv115ArmourImage["영원히 이어지는 황금향"] = "1_p";
lv115ArmourImage["한계를 넘어선 에너지"] = "4_p";
lv115ArmourImage["압도적인 자연"] = "7_p";
lv115ArmourImage["고대 전장의 발키리"] = "10_p";
lv115ArmourImage["고유"] = "13_p";

var lv115AccAndSpeImage = [];

lv115AccAndSpeImage["영원히 이어지는 황금향"] = "1";
lv115AccAndSpeImage["칠흑의 정화"] = "2";
lv115AccAndSpeImage["세렌디피티"] = "3";
lv115AccAndSpeImage["한계를 넘어선 에너지"] = "4";
lv115AccAndSpeImage["소울 페어리"] = "5";
lv115AccAndSpeImage["압도적인 자연"] = "6";
lv115AccAndSpeImage["고대 전장의 발키리"] = "7";
lv115AccAndSpeImage["에테리얼 오브 아츠"] = "8";
lv115AccAndSpeImage["그림자에 숨은 죽음"] = "9";
lv115AccAndSpeImage["무리 사냥의 길잡이"] = "10";
lv115AccAndSpeImage["마력의 영역"] = "11";
lv115AccAndSpeImage["고유"] = "13";
lv115AccAndSpeImage["용투장의 난"] = "12";


var lv115ImageOrderList = [
    "고유",
    "영원히 이어지는 황금향",
    "칠흑의 정화",
    "세렌디피티",
    "한계를 넘어선 에너지",
    "소울 페어리",
    "압도적인 자연",
    "고대 전장의 발키리",
    "에테리얼 오브 아츠",
    "그림자에 숨은 죽음",
    "무리 사냥의 길잡이",
    "마력의 영역",
    "용투장의 난"

];

export { lv115AccAndSpeImage , lv115ArmourImage , lv115ImageOrderList  }