
import { createContext, useEffect, useState } from 'react';
import { Routes, Route, Link, useNavigate, Outlet , useLocation } from 'react-router-dom'


import {talismanList} from '../var/editVar';
import skillImage from '../var/var';
import React from 'react';

import trait from '../var/trait'

import {lv105OptionMap,lv105WeaponOptionMap,lv105CustomOptionMap,lv105WeaponThirdOptionMap,lv105ItemId} from '../var/editVar';

import axios from 'axios';
import Ad from "../ad";

import {skilltree, getSkillTree} from "../var/skilltree"
import {lv115AccAndSpeImage,lv115ArmourImage,lv115ImageOrderList} from '../lv115/euqipMap';

let [simulation,setSimulation] = [null,null];
let [simulationTime,setSimulationTime] = [null,null];
let [simulationNextMap,setSimulationNextMap] = [null,null];
let [simulationCountMap,setSimulationCountMap] = [null,null];
let [skillTree,setSkillTree] = [null,null];
function Character(){

    const location = useLocation();
    const edit = { ...location.state };
    const gradeToColor = {"에픽":"#efa900","레전더리":"#ef7000","유니크":"#db00db","레어":"rgb(147 81 237)","언커먼":"rgb(147 81 237)","커먼":"rgb(147 81 237)"};
    const emblemToColor = {"빛나는":"#00a6ac","화려한":"rgb(147 81 237)","찬란한":"#db00db","플래티넘":"#ef7000"};
    let editObj = edit.edit;
    let editMap = {};
    [skillTree,setSkillTree] = useState([]);
    // const traitList = ["","상태이상","MP관리","유틸","스킬","컨트롤/생존","버퍼"];

    let editText = "";
    if(editObj != null)
    {
        editMap["edit"] = "true";
        //editText = "&edit=true";
        //console.log(edit);
        let plus = '+';
        //성단
        editMap["cluster"] = editObj['cluster'];
        editMap["clusterEnd"] = editObj['clusterEnd'];
        editMap["rumination"] = editObj['rumination'];

        //encodeURIComponent('+');
        for(let loop = 0 ; loop < 13 ; loop++)
        {
            editMap["itemUp"+loop] = editObj['itemUp'+loop];
            if(loop == 1)
            {
                if(!editObj.isBuffer)
                {
                    editMap["equip1Stat"] = "스탯+"+editObj['equipEnchantStat1'];
                    editMap["equip1Enchant"] = "속강+"+editObj['equipEnchantEle1'];
                    editMap["equip1"] = editObj['equipName1']+" "+editObj['equipValue1']+"%";
                    continue;

                }
                else
                {
                    editMap["equip1"] = editObj['equipName1'];
                    continue;
                }
            }
            //장비 + 마법부여
            let setting = "";
            setting += plus+editObj['equipReinforceNum'+loop]+editObj['equipReinforceType'+loop];

            if(loop == 0)
            {
                setting += "("+editObj['refine0']+")";
            }
            if(editObj['equipName'+loop].indexOf("불가침의 영역") != -1)
            {
                setting += ","+editObj['equipName'+loop].replace("판금 ", "")
                .replace("천 ", "")
                .replace("경갑 ", "")
                .replace("중갑 ", "")
                .replace("가죽 ", "");
            }
            else
            {
                setting += ","+editObj['equipName'+loop];
            }
            setting += ","+editObj['equipEnchantStat'+loop];
            setting += ","+editObj['equipEnchantAttack'+loop];

            if(!editObj.isBuffer)
            {

                setting += ","+editObj['equipEnchantEle'+loop]; // 스증부위 조정
                setting += ","+editObj['equipEnchantEtc'+loop]; 
                // if(loop == 3 || loop == 6 || loop == 5 || loop == 8)
                // {
                //     setting += ","+0; // 스증부위 조정
                //     setting += ","+editObj['equipEnchantEtc'+loop]; // 스증부위만
                // }
                // else
                // {
                // }
            }
            else
            {
                if(loop == 2 || loop == 3 || loop == 4 || loop == 6 || loop == 9 || loop == 5 || loop == 10 || loop == 11 || loop == 8 || loop == 7 )
                {
                    setting += ","+0; // 스증부위 조정
                    setting += ","+editObj['equipEnchantEtc'+loop]; // 스증부위만
                }
                else
                {
                    setting += ","+editObj['equipEnchantEtc'+loop]; // 스증부위 조정
                    setting += ","+0; // 스증부위만
                }
            }
            editMap["equip"+loop] = setting;
            
            //융합
            if(editObj['siroco'+loop] != '')
            {
                editMap["siroco"+loop] = editObj['siroco'+loop];
            }
            for(let upLoop = 0 ; upLoop < 3 ; upLoop++)
            {
                if(editObj['siroco'+loop+"up"+upLoop] != null)
                {
                    editMap["siroco"+loop+"up"+upLoop] = editObj['siroco'+loop+"up"+upLoop];
                }
            }

            //레전 융합
            //console.log(editMap);
            if(editMap['siroco'+loop] != null)
            if(editMap['siroco'+loop].includes("영원의 조각 -"))
            {
                editMap['legendStone'+loop+"_1"] = editObj['legendStone'+loop+"_1"]; 
                editMap['legendStone'+loop+"_2"] = editObj['legendStone'+loop+"_2"];
                editMap['legendStone'+loop+"_3"] = editObj['legendStone'+loop+"_3"];
            }

            
            // //장비레벨
            // if(editObj['equipLevel'+loop+"C0"] != null)
            // {
                
            //     editMap["equipLevel"+loop] = editObj['equipLevel'+loop+"C0"];
            //     // let levelTemp = "";
            //     // //"&equipLevel"+loop
            //     // levelTemp += editObj['equipLevel'+loop+"C0"]+',';
                
            //     // levelTemp = levelTemp.substring(0,levelTemp.length-1);
            //     // editText += levelTemp;
            // }


            //커스텀 장비
            if(editObj['equipCustom'+loop+"C"+1] != null)
            {
                let levelTemp = "";
                for(let opLoop = 0 ; opLoop < 4 ; opLoop++)
                {
                    levelTemp += editObj['equipCustom'+loop+"C"+opLoop]+',';
                }
                levelTemp = levelTemp.substring(0,levelTemp.length-1);

                
                editMap["equipCustom"+loop] = levelTemp;
            }
                
            editMap["equipItemLevel"+loop] = editObj['itemLevel'+loop];

            if(editObj['mistGear'+loop] == 'true' && editObj['itemLevel'+loop] != 115)
            {
                editMap["mistGear"+loop] = editObj['mistGear'+loop];
            }

        }

        
        //탈리스만
        for(let loop = 0 ; loop < 3 ; loop++)
        {
            if(editObj['talis'+loop] != "")
            {
                //console.log(editObj['talis'+loop]);
                editMap["talis"+(loop+1)] = editObj['talis'+loop];
            }

        }
        
            
        //룬
        for(let loop = 0 ; loop < 9 ; loop++)
        {
            editMap["rune"+(loop+1)] = editObj['rune'+(loop+1)+"rarity"]+" "+editObj['rune'+(loop+1)+"type"]+" 룬["+editObj['rune'+(loop+1)+"Skill"]+"]";
        }
        


        //아바타
        for(let loop = 0 ; loop < 11 ; loop++)
        {
            if(loop <= 7)
            {
                editMap["aba"+(loop)] = editObj['abaRarity'+loop]+","+ (editObj['abaOption'+loop] == null ? '' : editObj['abaOption'+loop])+",";
            }
            else if( loop == 8)
            {
                editMap["aba"+(loop)] = ",";
            }
            else if( loop == 9)
            {
                
                let auraTemp = "";

                if(editObj['aba9Stat'] != 0)
                {
                    auraTemp += "스탯+"+editObj['aba9Stat']+" ";
                }
                if(editObj['aba9Attack'] != 0)
                {
                    auraTemp += "공격력+"+editObj['aba9Attack']+" ";
                }
                if(editObj['aba9Element'] != 0)
                {
                    auraTemp += "속강+"+editObj['aba9Element']+"";
                }
                editMap["aba"+(loop)] = ","+auraTemp+",";

            }
            else
            {
                editMap["aba"+(loop)] = "레어,"+ (editObj['abaOption'+loop] == null ? '' : editObj['abaOption'+loop])+","
            }
            
            //플래티넘 엠블렘
            if( loop == 3 || loop == 4)
            {
                if(editObj['emblemSkill'+loop] != null)
                {
                    if(editObj['emblemSkill'+loop].indexOf("[") != -1)
                    {
                        editMap["aba"+(loop)+"Skill"] = editObj['emblemSkill'+loop].split("[")[1].split("]")[0];

                    }
                    else
                    {
                        editMap["aba"+(loop)+"Skill"] = '';
                    }
                }
                else
                {
                    editMap["aba"+(loop)+"Skill"] = '';
                }
                //console.log(editText);

            }



            if(loop <= 11)
            {
                let temp = "";
                for(let emLoop = 0 ; emLoop < 2 ; emLoop++)
                {
                    if(editObj['emblemStat'+emLoop+""+loop] != 0)
                    {
                        temp+='s'+editObj['emblemStat'+emLoop+""+loop]+",";
                    }
                    else if(editObj['emblemAS'+emLoop+""+loop] != 0)
                    {
                        temp+='a'+editObj['emblemAS'+emLoop+""+loop]+",";
                    }
                    else if(editObj['emblemResist'+emLoop+""+loop] != 0)
                    {
                        temp+='r'+editObj['emblemResist'+emLoop+""+loop]+",";
                    }
                    else
                    {
                        
                        temp+=0+",";
                    }
                }
                temp = temp.substring(0,temp.length-1);
                editMap["aba"+(loop)] += temp;


            }

        }
        
        //어드벤처
        editMap["adventure"] = "40";
        //무기압
        
        //editText+="&aba10Skill="+encodeURIComponent("75");

        //크리쳐
        //editText+="&aba12="+encodeURIComponent(","+editObj['creaName'] +" " + editObj['creaValue']+"% 크리쳐"+",0,0");
        
        editMap["aba12"] = ","+editObj['creaName'] +" " + editObj['creaValue']+"% 크리쳐"+",0,0";
        //휘장
        editMap['flag'] = editObj['flag'];
        editMap['flagReinforceNum'] = editObj['flagReinforceNum'];
        editMap['gem0'] = editObj['gem1'];
        editMap['gem1'] = editObj['gem2'];
        editMap['gem2'] = editObj['gem3'];
        editMap['gem3'] = editObj['gem4'];

        //무기융합
        editMap["lv105WeaponPlus0"] = editObj["weapon1"]-1;
        editMap["lv105WeaponPlus1"] = editObj["weapon2"]-1;
        editMap["lv105WeaponPlus2"] = editObj["weapon3"]-1;

        // editText+="&lv105WeaponPlus0="+encodeURIComponent(editObj["weapon1"]-1);
        // editText+="&lv105WeaponPlus1="+encodeURIComponent(editObj["weapon2"]-1);
        // editText+="&lv105WeaponPlus2="+encodeURIComponent(editObj["weapon3"]-1);

        //칭호 레벨
        if(editObj["equip1Skill"] != 0)
        {
            editMap["equip1Skill"] = editObj["equip1Skill"];
            //editText+="&equip1Skill="+encodeURIComponent(editObj["equip1Skill"]);
        }

        editMap["weaponType"] = editObj['weaponType'];

        //미완
        editMap["aba11"] = ",스탯+48 공격력+30,0,0";

        if(editObj['aba10Skill'] != null &&editObj['aba10Skill'] != 0)
        {
            editMap['aba10Skill'] = editObj['aba10Skill'];
        }
        
        
        //아티팩트
        
        if(editObj["afti0"] != null)
        {
            editMap["afti0"] = editObj["afti0"];
        }
        if(editObj["afti1"] != null)
        {
            editMap["afti1"] = editObj["afti1"];
        }
        if(editObj["afti2"] != null)
        {
            editMap["afti2"] = editObj["afti2"];
        }

        // for(let temp of traitList[editObj["specIndex"]-1])
        // {   
        //     if(editObj["spec"+temp] != 0)
        //     {
        //         editMap["spec"+temp] = editObj["spec"+temp];
        //     }
            
        // }

        // if(editObj["spec0"] != null)
        // {
        //     editMap["spec0"] = editObj["spec0"];
        // }
        // if(editObj["spec1"] != null)
        // {
        //     editMap["spec1"] = editObj["spec1"];
        // }
        // if(editObj["spec2"] != null)
        // {
        //     editMap["spec2"] = editObj["spec2"];
        // }

        
        // if(editObj["spec4"] != null)
        // {
        //     editMap["spec4"] = editObj["spec4"];
        // }
        // if(editObj["spec5"] != null)
        // {
        //     editMap["spec5"] = editObj["spec5"];
        // }


        //버퍼 아바타
        //editMap["buff1"] = ;
        if(editObj.isBuffer)
        {
            editMap["buffAva0"] = editObj['abaOption113']+','+editObj['emblemSkill113']+','+editObj['emblemStat0113']+','+editObj['emblemStat1113'];
            editMap["buffAva1"] = editObj['emblemSkill114']+','+','+editObj['emblemStat0114']+','+editObj['emblemStat1114'];
        
            editMap['buff1'] =  editObj['buffTitle'];
        }

        if(editObj.job =='검제')
        {
            editMap["subWeapon"] = "근원을 삼킨 광검";
            editMap["subWeaponReinforce"] = "+"+editObj['subWeaponReinforce']+editObj['subWeaponReinforceType'];
        }
        //위에 해야함
        //console.log(editMap);
        editText = encodeQueryData(editMap);
        
        //editText = encodeURI(editText);
    }

    let [daelCount, setDaelCount] = useState();
    let [chtbase, setChtBase] = useState();
    let [time25DaelCount, setTime25DaelCount] = useState();

    let navigate = useNavigate();
    let [navigateValue,setNavigateValue] = useState();
    
    [simulation,setSimulation] = useState([]); //시뮬레이션
    [simulationTime,setSimulationTime] = useState(0);
    [simulationNextMap,setSimulationNextMap] = useState(new Map());
    [simulationCountMap,setSimulationCountMap] = useState(new Map());

    const params = new URLSearchParams(window.location.search);
    let id =params.get('key');
    let server =params.get('server');

    const abortController = new AbortController()

    const getData = ()=>
    {
        axios.post('/dat/viewData.jsp?image='+id+'&server='+server+'&reset=true',  {}, { cancelToken: source.token  })
        .then((base)=>{

          let copy = base.data;
          setChtBase(copy);
          setDaelCount( copy.damageList.vsDragons.length - 1);
          setTime25DaelCount( copy.damageList.vsGB.length - 1);
          setVsOzma(copy.damageList.vsOzma);
          //setSkillTree( getSkillTree(getSecondJobName(copy.job,copy.basejob)));
        })
    }
    

    let source = axios.CancelToken.source();

    useEffect( () => { 
      
        axios.post('/dat/viewData.jsp?image='+id+'&server='+server+"&"+editText , {}, { cancelToken: source.token  } )
        .then((base)=>{
          let copy = base.data;
          setChtBase(copy);
          setDaelCount( copy.damageList.vsDragons.length - 1);
          setVsOzma(copy.damageList.vsOzma);
          //setSkillTree( getSkillTree(getSecondJobName(copy.job,copy.basejob)));
        })

        
        return ()=>{

            source.cancel();
        }

      },[]);


      const handleClick = event => {
        // 👇️ toggle class on click
        event.currentTarget.classList.toggle('active-case');
    
        // 👇️ add class on click
        // event.currentTarget.classList.add('bg-salmon');
    
        // 👇️ remove class on click
        // event.currentTarget.classList.remove('bg-salmon');
      };

      let [activemove,setActiveMove] = useState("");
      let [choiceleft,setChoiceLeft] = useState("");
      let [choiceright,setChoiceRight] = useState("active-case");
      let [addcri,setAddCri] = useState("");
      let [vsOzma,setVsOzma] = useState("");

      let buffcalArray = [0,0,0,0,0,0,0,0,0,0];


      useEffect( () => { 
        var self = window;
        self.__VM = self.__VM || [];
        
        self.__VM.push(function (admanager, scope) {
            scope.Config.get('billboard').display('ad-container-2');
            scope.Config.get('desktop_takeover').display('ad-container-1');
            scope.Config.verticalSticky().display();
    

        });

        return () =>
        {
            self.__VM = self.__VM || [];
            self.__VM.push((admanager, scope) => {
                admanager.removeAllPlacements();
                scope.Instances.pageManager.newPageSession();
            });
            
        };

            
    },[]);

      
    // useEffect( () => { 
        
    //     var placement = document.createElement("div");
    //     (window.top).__vm_add = (window.top).__vm_add || [];
    //     (function (success) {
    //         if (window.document.readyState !== "loading") {
    //             success();
    //         } else {
    //             window.document.addEventListener("DOMContentLoaded", function () {
    //                 success();
    //             });
    //         }
    //     })(function () {
    //         //console.log("mounting2", placement); 

    //         placement.setAttribute("class", "vm-placement");
    //         placement.setAttribute("data-id", "63358a82bf161d63ec3c8e8a");
    //         document.querySelector("#ad-container-2").appendChild(placement);
    //         (window.top).__vm_add.push(placement);
    //     });

            
    //     return() => { 
    //         (window.top).__vm_remove = (window.top).__vm_remove || [];
    //         //console.log("unmounting2", placement);
    //         (window.top).__vm_remove.push(placement);

    //     };

            
    // },[]);

      

    // useEffect( () => { 
        
    //     var placement = document.createElement("div");
    //     (window.top).__vm_add = (window.top).__vm_add || [];
    //     (function (success) {
    //         if (window.document.readyState !== "loading") {
    //             success();
    //         } else {
    //             window.document.addEventListener("DOMContentLoaded", function () {
    //                 success();
    //             });
    //         }
    //     })(function () {
    //         //console.log("mounting", placement); 

    //         placement.setAttribute("class", "vm-placement");
    //         placement.setAttribute("data-id", "63358a82bf161d63ec3c8e8a");
    //         placement.setAttribute("data-display-type", "hybrid-banner");
    //         document.querySelector("#ad-container-1").appendChild(placement);
    //         (window.top).__vm_add.push(placement);
    //        
    //     });
            
    //     return() => { 
    //         (window.top).__vm_remove = (window.top).__vm_remove || [];
    //         //console.log("unmounting", placement);
    //         (window.top).__vm_remove.push(placement);


    //     };

            
    // },[]);

    
    // useEffect( () => { 
        
    //     var placement = document.createElement("div");
    //     window.__vm_add = window.__vm_add || [];
    //     (function (success) {
    //         if (window.document.readyState !== "loading") {
    //             success();
    //         } else {
    //             window.document.addEventListener("DOMContentLoaded", function () {
    //                 success();
    //             });
    //         }
    //     })(function () {

    //         placement.setAttribute("class", "vm-placement");
    //         placement.setAttribute("data-id", "63358aa395a6d454fcf66d11");
    //         document.querySelector("#rich-media-placement").appendChild(placement);
    //         window.top.__vm_add.push(placement);
    //     });
            
    //     return() => { 
    //         (window.top).__vm_remove_category = (window).__vm_remove_category || [];
    //         (window.top).__vm_remove_category.push("richmedia_all");

    //         //console.log("unmounting", (window.top).__vm_remove_category);

    //     };



            
    // },[]);

    return (
    
        <div>
            
            <section id="character" name="캐릭터 정보">


                <div class="cht container">
                    <div id="rich-media-placement"></div>
                    <div id="ad-container-1" />
                        <div id="content-container">
                            <div class="new-cinfo" name="캐릭터 정보박스">
                                <div class="cabata" name="아바타 그림">
                                    <div class="cab_hug">
                                        <div class="abata-job">
                                            <span>{chtbase && chtbase.job}</span>
                                        </div>
                                        <div class="abata-bg">
                                            <div class="imgt">
                                                <img src={'https://img-api.neople.co.kr/df/servers/'+ server +'/characters/'+ id +'?zoom=1.jpg'}/>
                                            </div>
                                        </div>
                                        <div class="mababot">
                                            <ul>
                                                <li class="abc-mhd"><span>{chtbase && chtbase.adventure}</span></li>

                                                <li class="abc-nick"><span>{chtbase && chtbase.name} </span></li>
                                                <li class="abc-sever"><span>{chtbase && chtbase.serverKR} </span></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="c-aba-stat">
                                    <div class="aba-hug">
                                        <div class="abas-top">
                                            <div class="abtop-li abt-adven">
                                                <div class="fpic"></div><span>{chtbase && chtbase.fame}</span>
                                            </div>
                                            <div class="abtop-li abt-nickname">
                                                <span>{chtbase && chtbase.name} </span>
                                            </div>
                                            <div class="abtop-li abt-advengulid">
                                                <ul>
                                                    <li class="abt-abven" onClick={()=>{navigate("/search?server=adven&name="+chtbase.adventure)}}><span>{chtbase && chtbase.adventure}</span></li>
                                                    <li class="abt-gulid"><span>{chtbase && chtbase.gulid}</span></li>
                                                </ul>
                                            </div>
                                            <div class="abtop-li abt-ranking">
                                                <ul>
                                                    <li class="abt-dealrank">
                                                        <span>딜러랭킹 <b>{chtbase && chtbase.dealerRanking}</b>위 (전체 {chtbase && chtbase.dealerRankingAll})</span>
                                                    </li>
                                               

                                                    {       
                                                        chtbase && chtbase.bufferRanking ? 
                                                        <li class="abt-buffrank">
                                                        <span>버퍼랭킹 <b>{chtbase && chtbase.bufferRanking}</b>위 (전체 {chtbase && chtbase.bufferRankingAll})</span>
                                                        </li> : ""
                                                    }
                                                </ul>
                                            </div>                                    
                                        </div>
                                        <div class="abas-bottom">
                                            <div class="abbot-alldeal">

                                            {
                                                 chtbase && chtbase.buffCal == null ? 
                                                <div class="tabc">
                                                    <div class="tab-wrap">
                                                        <input type="radio" id="tab111" name="tabGroup9" class="tab"></input>
                                                        <label for="tab111"><span>금룡</span></label>
                                                        <input type="radio" id="tab112" name="tabGroup9" class="tab" defaultChecked></input>
                                                        <label for="tab112" ><span>랭킹</span></label>
                                                        {/* <input type="radio" id="tab113" name="tabGroup9" class="tab"></input>
                                                        <label for="tab113"><span>차원회랑</span></label> */}
                                                        <input type="radio" id="tab114" name="tabGroup9" class="tab"></input>
                                                        <label for="tab114"><span>40초</span></label>

                                                        <div class="tab__content">
                                                            <div class="hd-part">
                                                                <div class="demval">
                                                                    <span class="dvtit">총딜</span>
                                                                    <span class="dval">{chtbase && chtbase.damageList.vsSendbag[daelCount].dam}</span>
                                                                    <span>
                                                                        <div class="imgt">
                                                                        </div>
                                                                    </span>
                                                                    <span class="more">
                                                
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="tab__content">
                                                            <div class="hd-part">
                                                                <div class="demval">
                                                                    <span class="dvtit">총딜</span>
                                                                    <span class="dval">{chtbase && chtbase.damageList.vsRanking[daelCount].dam}</span>
                                                                    <span>
                                                                        <div class="imgt">
                                                                        </div>
                                                                    </span>
                                                                    <span class="more">
                                                
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* <div class="tab__content">
                                                            <div class="hd-part">
                                                                <div class="demval">
                                                                    <span class="dvtit">총딜</span>
                                                                    {
                                                                        //console.log(time25DaelCount)

                                                                    }
                                                                    <span class="dval">{chtbase && time25DaelCount && chtbase.damageList.vsGB[time25DaelCount].dam}</span>
                                                                    <span>
                                                                        <div class="imgt">
                                                                        </div>
                                                                    </span>
                                                                    <span class="more">
                                                
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div> */}
                                                        <div class="tab__content">
                                                            <div class="hd-part">
                                                                <div class="demval">
                                                                    <span class="dvtit">총딜</span>
                                                                    <span class="dval">{chtbase && chtbase.damageList.vsDragons[daelCount].dam}</span>
                                                                    <span>
                                                                        <div class="imgt">
                                                                        </div>
                                                                    </span>
                                                                    <span class="more">
                                                
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div> 
                                                
                                                : 

                                                <div class="tabc">
                                                <div class="tab-wrap">
                                                    <input type="radio" id="tab121" name="tabGroup91" class="tab" defaultChecked></input>
                                                    <label for="tab121" ><span>버프력</span></label>
                                            
                                                    <div class="tab__content">
                                                        <div class="hd-part buffview">
                                                            <div class="demval">
                                                                <span class="dvtit buff">버프점수</span>
                                                                <span class="dval">{chtbase && chtbase.buffCal[chtbase.buffCal.length-1].buffScore}</span>
                                                          
                                                            </div>
                                                        </div>
                                                    </div>
                                     

                                                </div>
                                                </div>


                                            }
                                            </div>
                                            <div class="abbot-otherstat">
                                                <ul>
                                                <li className={(chtbase && chtbase.cri.replace('%','')) >= 100 ? 'abt-critical cri-blue-light' :'abt-critical cri-red-light'}>
                                                        <span>크리티컬 <b>{chtbase && chtbase.cri}</b></span>
                                                </li>
                                                <li class="abt-overdrive">
                                                    
                                                        
                                                        <div class="odv-value">{chtbase && skillImage[chtbase.switchingName.replace(" 스증","")] ? 
                                                    
                                                            <span class="odv-img-side"> <img src={chtbase && skillImage[chtbase.switchingName.replace(" 스증","")]} /></span>
                                                            : chtbase && chtbase.switchingName}
                                                            
                                                            <span class="odv-value-side"> +{chtbase && chtbase.switchingLevel} 렙 {chtbase && chtbase.switchingValue} </span>
                                                        </div>
                                                     
                                            
                                                </li>
                                            
                                                </ul>
                                            </div>

                                            
                                        </div>
                                    </div>
                                </div>
                                <div class="ref">

                                    <div class="rfbut">
                                        <div class="backbtt">
                                        </div>
                                    </div>
                                    <span>{chtbase && chtbase.refreshTime}</span>

                                </div>
                                {/* <div class="c-aba-ad">
                                    <div class="caba-ad-side">
                                        <div class="caba-ad-con">

                                        </div>
                                    </div>
                                </div> */}
                            </div>
                            {/* <div class="cinfo" name="캐릭터 정보박스">
                                <div class="cabata" name="아바타 그림">
                                    <div class="abata-bg">
                                        <div class="imgt">
                                            <img src={'https://img-api.neople.co.kr/df/servers/'+ server +'/characters/'+ id +'?zoom=1.jpg&temp='+ Math.round(Math.random() * 10000)}/>
                                        </div>
                                    </div>
                                </div>

                                <div class="cmation" name="서버 & 직업">
                                    <li class="sev" name="서버">{chtbase && chtbase.serverKR}</li>
                                    <div class="jin">
                            
                                        <li class="jbn" name="직업">
                                            {chtbase && chtbase.job}
                                        </li>
                                    </div>
                                    <li class="nk" name="닉네임">
                                        <span>
                                            {chtbase && chtbase.name} 
                                
                                        </span>
                                    </li>
                                    <li class="ag">
                                        <div class="aven" name="모험단">
                                            <span>{chtbase && chtbase.adventure}</span>
                                            <span class="aven_icon">
                                                <div class="imgt" onClick={()=>{navigate("/search?server=adven&name="+chtbase.adventure)}}>
                                                    <img src="export-arrow.svg"></img>
                                                </div>
                                            </span>
                                            
                                        </div>

                                        <div class="guild" name="길드">
                                            <span>{chtbase && chtbase.gulid}</span>

                                        </div>
                                    </li>
                                    <div class="crk" name="랭킹">
                                        <div class="cbox dm" name="딜러 랭킹">
                                            <span class="tl">
                                                랭킹
                                            </span>
                                            <div class="rankval">
                                                <span class="rval">
                                                    {chtbase && chtbase.dealerRanking}
                                                </span>
                                                <span class="bval">
                                                    {chtbase && chtbase.dealerRankingAll}
                                                </span>
                                            </div>
                                        </div>
                                        {
                                            chtbase && chtbase.bufferRanking ? 
                                            <div class="cbox bf" name="딜러 랭킹">
                                                <span class="tl">
                                                    버퍼 랭킹
                                                </span>
                                                <div class="rankval">
                                                    <span class="rval">
                                                        {chtbase && chtbase.bufferRanking}
                                                    </span>
                                                    <span class="bval">
                                                        {chtbase && chtbase.bufferRankingAll}
                                                    </span>
                                                </div>
                                            </div> : ""
                                        }
                                        
                                    </div>
                                </div>
                                <div class="cru">
                                    <div class="ref">

                                        <div class="rfbut">
                                            <div class="backbtt">
                                            </div>
                                        </div>
                                        <span>{chtbase && chtbase.refreshTime}</span>

                                    </div>
                                    <div class="ace">
                                        <div class="ap">
                                            <li class="apc">
                                                <span class="tl">{chtbase && chtbase.switchingName} + {chtbase && chtbase.switchingLevel} 렙</span>
                                                <span class="aval">{chtbase && chtbase.switchingValue}</span>
                                            </li>
                                        </div>

                                        <div class="acc">
                                            <li class="accs" style={chtbase && chtbase.cri.replace('%','') >= 100 ? {"border-color": "blue"} :{"border-color": "red"} }>
                                                <span class="tl">크리티컬</span>
                                                <span class="aval">{chtbase && chtbase.cri}</span>
                                            </li>
                                
                                            <li class="accs">
                                                <span class="tl">명성</span>
                                                <span class="aval">{chtbase && chtbase.fame}</span>
                                            </li>
                                            

                                        </div>
                                    </div>
                                </div>
                            </div> */}


                

                            <div className='eqip-change-but'>
                                <div class="ecbut_hug">
          
                                    <div class="ecbut-apply-only">
                                        <div class="ecbut-apply" style={editMap['edit'] != null ? {'display':'none'} : {}} onClick={()=>{if(chtbase != null) navigate('../equip_edit',{state:{character:chtbase}});setNavigateValue("equip_edit")}}>
                                            <span>장비변경</span>
                                        </div>
                                    </div>
                                    <div class="ecbut-apply-reset" style={editMap['edit'] == null ? {'display':'none'} : {}}>
                                        <div class="ecbut-apply" onClick={()=>{if(chtbase != null) navigate('../equip_edit',{state:{character:chtbase}});setNavigateValue("equip_edit")}}>
                                            <span>장비변경</span>
                                        </div>
                                        <div class="ecbut-reset" onClick={()=>{navigate('../character?server='+server+'&key='+id); getData(); setNavigateValue("character")}}> 
                                            <span>장비변경 초기화</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        
                            <div class="tabc maincon">
                                <div class="tab-wrap" name="캐릭터정보 탭">
                                    <input type="radio" id="tab1" name="tabGroup2" class="tab" ></input>
                                    <label for="tab1"><span>장착장비</span></label>
                        
                                    <input type="radio" id="tab2" name="tabGroup2" class="tab"></input>
                                    <label for="tab2"><span>스탯</span></label>
                        
                                    <input type="radio" id="tab3" name="tabGroup2" class="tab"></input>
                                    <label for="tab3"><span>세부스탯</span></label>
                        
                                    <input type="radio" id="tab4" name="tabGroup2" class="tab"></input>
                                    <label for="tab4"><span class="lsp">아바타 & 크리쳐</span></label>
                        
                                    <input type="radio" id="tab5" name="tabGroup2" class="tab"></input>
                                    <label for="tab5"><span>버프강화</span></label>
                        
                                    <input type="radio" id="tab6" name="tabGroup2" class="tab"></input>
                                    <label for="tab6"><span>탈리스만</span></label>
                        
                                    <input type="radio" id="tab7" name="tabGroup2" class="tab" defaultChecked></input>
                                    <label for="tab7"><span>딜표</span></label>
                        
                                    
                                    <input type="radio" id="tab8" name="tabGroup2" class="tab"></input>
                                    <label for="tab8"><span>스킬정보</span></label>

                                    {/* <input type="radio" id="tab9" name="tabGroup2" class="tab"></input>
                                    <label for="tab9"><span>스킬트리</span></label> */}

                                    {/* <input type="radio" id="tab9" name="tabGroup2" class="tab"></input>
                                    <label for="tab9"><span>시뮬레이션</span></label> */}
                                    
                                    {
                                    chtbase && chtbase.buffCal != null ? 
                                    <>
                                        <input type="radio" id="tab9" name="tabGroup2" class="tab" defaultChecked></input>
                                        <label for="tab9"><span>버프계산</span></label> 
                                    </>
                                    : ""
                                    }
                                    

                                    <div class="tab__content" name="장착장비">
                                        <div class="cwp">

                                    {
                                            /* 세트 항목 추가 */

                                       
                                        
                                        /*
                                        00*
                                        부분을 1레벨,2레벨,3레벨이 되면 발동
                                        */
                                    }

                                    { chtbase && chtbase.setsName ?
                                    <>
                                        <div class="tr cwp-e115-set">
                                            <div class="th">
                                                <span>세트</span>
                                            </div>
                                            <div class="td">
                                                <div class="wimg">
                                                    <div class="witem-img">
                                                        <div class="imgt">
                                                            <img style={{width: '40px',height: '40px',overflow: 'hidden','object-fit': 'none'}} 
                                                            src={"https://dundam.xyz/img/lv115/set/"+lv115AccAndSpeImage[chtbase.setsName]+".png"}></img>                                                   
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                                
                                                <div class="winfo">
                                                    <div class={"wn " +(chtbase.setsPoint + chtbase.publicPoint >= 2550 ? "taecho-color" : "")}>
                                                        <span style={gradeToColor[chtbase.setsGrade.split(" ")[0]] != null ? {"color": gradeToColor[chtbase.setsGrade.split(" ")[0]]} : {}}>
                                                             {chtbase.setsName} 세트 {chtbase.setsGrade.indexOf(" ") != -1 ? chtbase.setsGrade.split(" ")[1] : ""} </span>
                                                    </div>
                                                </div>
                                                <div class="wsetscore_mo">
                                                        <div class="wsetscore_m_hug">
                                                            <div class="wset-con_m">
                                                                <div class="wtcp-p" name="세트포인트">
                                                                    <span> {chtbase.setsPoint + chtbase.publicPoint}</span>
                                                                </div>
                                                                
                                                            </div>
                                                            <div class="wset-con_m">
                                                                <div class="wtcp-p" name="공용">
                                                                    <span> {chtbase.publicPoint}</span>
                                                                </div>
                                                                
                                                            </div>
                                                        
                                                        </div>
                                                
                                                </div>
                                            
                                            </div>
                                        </div>
                                    </> 
                                    
                                    
                                    
                                    
                                    : <></>
                                    
                                    }
                                    

                                        {
                                            chtbase && chtbase.equip.map((a, i) =>
                                            {
                                                return(

                                                    <div class="tr"  key={i}>
                                                        <div class="th">
                                                            <span>{chtbase.equip[i].slot}</span>
                                                        </div>
                                                        <div class="td">
                                                            <div class={("wimg " + (chtbase.equip[i].mistGear ? "plus-mistgear-v2-default" : ""))}>
                                                                <div class="witem-img">
                                                                    <div class="imgt">
                                                                        <img src={getItemImage(chtbase.equip[i],i)}  />
                                                                    </div>
                                                                </div>
                                                                <div class="witem-addbox">
                                                                    <div class="tr-op">
                                                                        <div class="cus-addbox">
                                                                            {
                                                                                chtbase.equip[i].slot != '칭호' && lv105OptionMap[chtbase.equip[i].name.replace("仙 : ","")] != null ? 
                                                                                lv105OptionMap[chtbase.equip[i].name.replace("仙 : ","")].replace(/<br>/gi,'\n').split("@@")[0] : 
                                                                                ""
                                                                                
                                                                                
                                                                            }

                                                                            {
                                                                                chtbase.equip[i].slot != '칭호' && chtbase["equipOption"+i] != null ? 
                                                                                <>
                                                                                <span className={"optionNumber"} style={{'font-size':'12px','margin-bottom':'2px'}}> 1 옵션 </span>
                                                                                {'\n'}
                                                                                <span> {lv105CustomOptionMap[chtbase["equipOption"+i].split(',')[0]] != null ? lv105CustomOptionMap[chtbase["equipOption"+i].split(',')[0]].replace(/<br>/gi,'\n') : ""} </span>
                                                                                {'\n'}
                                                                                {'\n'}
                                                                                <span className={"optionNumber"} style={{'font-size':'12px','margin-bottom':'2px'}}> 2 옵션 </span>
                                                                                {'\n'}
                                                                                <span> {lv105CustomOptionMap[chtbase["equipOption"+i].split(',')[1]] != null ? lv105CustomOptionMap[chtbase["equipOption"+i].split(',')[1]].replace(/<br>/gi,'\n') : ""} </span>
                                                                                {'\n'}
                                                                                {'\n'}
                                                                                <span className={"optionNumber"} style={{'font-size':'12px','margin-bottom':'2px'}}> 3 옵션 </span>
                                                                                {'\n'}
                                                                                <span> {lv105CustomOptionMap[chtbase["equipOption"+i].split(',')[2]] != null ? lv105CustomOptionMap[chtbase["equipOption"+i].split(',')[2]].replace(/<br>/gi,'\n') : ""} </span>
                                                                                {'\n'}
                                                                                {'\n'}
                                                                                <span className={"optionNumber"} style={{'font-size':'12px','margin-bottom':'2px'}}> 4 옵션 </span>
                                                                                {'\n'}
                                                                                <span> {lv105CustomOptionMap[chtbase["equipOption"+i].split(',')[3]] != null ? lv105CustomOptionMap[chtbase["equipOption"+i].split(',')[3]].replace(/<br>/gi,'\n') : ""} </span>
                                                                                </>
                                                                                : ""
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="winfo">
                                                                <div class="wn">
                                                                    <span class={chtbase.equip[i].rarity == "태초" ? "taecho-color" : ""} style={gradeToColor[chtbase.equip[i].rarity] != null ? {"color": gradeToColor[chtbase.equip[i].rarity]} : {}}> {chtbase.equip[i].name}

                                                                        <div class={"e115-equip-tuning-right-00"+chtbase.equip[i].itemUp}>
                                                                            <span class="tuning-level-1">Ⅰ</span>
                                                                            <span class="tuning-level-2">Ⅱ</span>
                                                                            <span class="tuning-level-3">Ⅲ</span>
                                                                        </div>
                                                                    </span>
                                                                    <span class="wn-add">{chtbase.equip[i].enchant} </span>
                                                                    
                                                                </div>
                                                            
                                                            
                                                            </div>
                                                            <div class="wadd">
                                                                <div class="wap">
                                                                    <span class={"wa1 " + (chtbase.equip[i].sirocoR == "태초" ? "taecho-color" : (chtbase.equip[i].sirocoR == "레전더리" ? "legendary-color" : "epic-color"))}>{chtbase.equip[i].siroco}</span>
                                                                </div>
                                                                
                                                                <div className={`wapop ${chtbase.equip[i].lv105Leveling == "" ? "off" : ""}`}>
                                                                       < span>{chtbase.equip[i].lv105Leveling.split('<br>')[0]}</span>
                                                                    
                                                                </div>
                                                                {
                                                                    chtbase.equip[i].sirocoUp0 != null
                                                                    || chtbase.equip[i].sirocoUp1 != null
                                                                    || chtbase.equip[i].sirocoUp2 != null ? 
                                                                    <>
                                                                        <div class="fesm-character-page">
                                                                            <div class="fesm-c-con">
                                                                                <div class="fesm-c-ul">
                                                                                    <div class="fesmc-li">
                                                                                        <div class="fesmc-op1">
                                                                                        {
                                                                                           chtbase.equip[i].sirocoUp0 != null ? 
                                                                                           Array.from({length: chtbase.equip[i].sirocoUp0}, (v, i) => 0).map(
                                                                                            (a,i) => {
                                                                                                return(
                                                                                                    <>
                                                                                                        <div class="fesmc-op is-active">
                                                                                                            <img src="img/engraving_s.png"></img>
                                                                                                        </div>
                                                                                                    </>
                                                                                                )
                                                                                            }
                                                                                           )
                                                                                           
                                                                                           : <></>
                                                                                        }
                                                                                        </div>
                                                                                        <div class="fesmc-op2">
                                                                                        {
                                                                                           chtbase.equip[i].sirocoUp1 != null ? 
                                                                                           Array.from({length: chtbase.equip[i].sirocoUp1}, (v, i) => 0).map(
                                                                                            (a,i) => {
                                                                                                return(
                                                                                                    <>
                                                                                                        <div class="fesmc-op is-active">
                                                                                                            <img src="img/engraving_f.png"></img>
                                                                                                        </div>
                                                                                                    </>
                                                                                                )
                                                                                            }
                                                                                           )
                                                                                           
                                                                                           : <></>
                                                                                        }
                                                                                        </div>
                                                                                        <div class="fesmc-op3">
                                                                                        {
                                                                                           chtbase.equip[i].sirocoUp2 != null ? 
                                                                                           Array.from({length: chtbase.equip[i].sirocoUp2}, (v, i) => 0).map(
                                                                                            (a,i) => {
                                                                                                return(
                                                                                                    <>
                                                                                                        <div class="fesmc-op is-active">
                                                                                                            <img src="img/engraving_f.png"></img>
                                                                                                        </div>
                                                                                                    </>
                                                                                                )
                                                                                            }
                                                                                           )
                                                                                           
                                                                                           : <></>
                                                                                        }
                                                                                        </div>
                                                                                            
                                                                                    
                                                                                    </div>
                                                                                    {/* <div class="fesmc-li">
                                                                                        <div class="fesmc-op is-active">
                                                                                                <img src="img/engraving_f.png"></img>
                                                                                        </div>
                                                                                        <div class="fesmc-op">
                                                                                                <img src="img/engraving_f.png"></img>
                                                                                        </div>
                                                                                        <div class="fesmc-op">
                                                                                                <img src="img/engraving_f.png"></img>
                                                                                        </div>

                                                                                    </div>
                                                                                    <div class="fesmc-li">
                                                                                        <div class="fesmc-op">
                                                                                                <img src="img/engraving_f.png"></img>
                                                                                        </div>
                                                                                        <div class="fesmc-op">
                                                                                                <img src="img/engraving_f.png"></img>
                                                                                        </div>
                                                                                        <div class="fesmc-op">
                                                                                                <img src="img/engraving_f.png"></img>
                                                                                        </div>
                                                                                    </div> */}
                                                                                </div>
                                                                                    
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    <></>
                                                                }
                                                                
                                                            </div>

                                                            <div class="wplus">
                                                                <span className="val" style={ a.reinforceType == "강화" ? {color : "unset"} : {}} >
                                                                    {a.reinforceNum}
                                                                </span>
                                                                <span className="wp-add" style={ a.reinforceType == "강화" ? {color : "unset"} : {}}>
                                                                    {a.reinforceType}
                                                                </span>
                                                            </div>
                                                            
                                                        </div>
                                        
                                                    </div>
                                                )
                                            }
                                        )}
                                        {
                                            // chtbase ? 
                                            // <div class="tr cwp_equipfeature">
                                            //     <div class="th"><span>장비특성</span></div>
                                            //     <div class="td">
                                            //         <div class="cwp_ef_hug">
                                            //             <div class="cwp_ef_l">
                                            //                 <div class="cwp_ef_mainf">
                                            //                     <div class="cef_m_img">
                                            //                         <div class="imgt">
                                            //                         <img src={"/reSpec/"+(chtbase['spec0'] != null ? chtbase['spec0'][0] : "")+"active.png"}></img>
                                            //                         </div>
                                            //                     </div>
                                                                
                                            //                     <div class="cef_m_tit">
                                            //                         <span>{chtbase['spec0'] != null ? traitList[chtbase['spec0'][0]] : ""}</span>
                                            //                     </div>
                                            //                 </div>
                                            //             </div>
                                            //             <div class="cwp_ef_r">

                                            //                 <div class="cwp_ef_sevf">
                                            //                     <div class="cwp_efs_hug">
                                            //                         <div class="cef_s_con">
                                            //                             <div class="cef_s_con_l">
                                            //                                 <div class="cef_s_tit">
                                            //                                     <span>Lv.1</span>
                                            //                                 </div>

                                            //                                 <div class="cef_s_img">
                                            //                                     <div class="imgt">
                                            //                                     <img src={"/spec/"+(chtbase['spec0'] != null ? chtbase['spec0'] : "")+"active.png"}></img>
                                            //                                     </div>
                                            //                                 </div>
                                            //                             </div>
                                            //                             <div class="cef_s_con_r">

                                            //                                 <div class="cef_s_explain">
                                            //                                 <span>{
                                                                            
                                            //                                 chtbase['spec0'] != null ? trait[chtbase['spec0']] : ""
                                            //                                 }</span>
                                            //                                 </div>
                                            //                             </div>
                                            //                         </div>
                                            //                         <div class="cef_s_con">
                                            //                             <div class="cef_s_con_l">
                                            //                                 <div class="cef_s_tit">
                                            //                                     <span>Lv.120</span>
                                            //                                 </div>

                                            //                                 <div class="cef_s_img">
                                            //                                     <div class="imgt">
                                            //                                     <img src={"/spec/"+(chtbase['spec4'] != null ? chtbase['spec4'] : "")+"active.png"}></img>
                                            //                                     </div>
                                            //                                 </div>
                                            //                             </div>
                                            //                             <div class="cef_s_con_r">

                                            //                                 <div class="cef_s_explain">
                                            //                                 <span>{
                                                                            
                                            //                                 chtbase['spec4'] != null ? trait[chtbase['spec4']] : ""
                                            //                                 }</span>
                                            //                                 </div>
                                            //                             </div>
                                            //                         </div> 
                                            //                         <div class="cef_s_con">
                                            //                             <div class="cef_s_con_l">

                                            //                                 <div class="cef_s_tit">
                                            //                                     <span>Lv.240</span>
                                            //                                 </div>
                                            //                                 <div class="cef_s_img">
                                            //                                     <div class="imgt">
                                            //                                     <img src={"/spec/"+(chtbase['spec1'] != null ? chtbase['spec1'] : "")+"active.png"}></img>
                                            //                                     </div>
                                            //                                 </div>
                                            //                             </div>
                                            //                             <div class="cef_s_con_r">

                                            //                                 <div class="cef_s_explain ">
                                            //                                 <span>{
                                                                            
                                            //                                 chtbase['spec1'] != null ? trait[chtbase['spec1']] : ""
                                            //                                 }</span>
                                            //                                 </div>
                                            //                             </div>
                                            //                         </div>
                                            //                         <div class="cef_s_con">
                                            //                             <div class="cef_s_con_l">
                                            //                                 <div class="cef_s_tit">
                                            //                                     <span>Lv.360</span>
                                            //                                 </div>

                                            //                                 <div class="cef_s_img">
                                            //                                     <div class="imgt">
                                            //                                     <img src={"/spec/"+(chtbase['spec5'] != null ? chtbase['spec5'] : "")+"active.png"}></img>
                                            //                                     </div>
                                            //                                 </div>
                                            //                             </div>
                                            //                             <div class="cef_s_con_r">

                                            //                                 <div class="cef_s_explain">
                                            //                                 <span>{
                                                                            
                                            //                                 chtbase['spec5'] != null ? trait[chtbase['spec5']] : ""
                                            //                                 }</span>
                                            //                                 </div>
                                            //                             </div>
                                            //                         </div> 
                                            //                         <div class="cef_s_con">
                                            //                             <div class="cef_s_con_l">

                                            //                                 <div class="cef_s_tit">
                                            //                                     <span>Lv.480</span>
                                            //                                 </div>
                                            //                                 <div class="cef_s_img">
                                            //                                     <div class="imgt">
                                            //                                     <img src={"/spec/"+(chtbase['spec2'] != null ? chtbase['spec2'] : "")+"active.png"}></img>
                                            //                                     </div>
                                            //                                 </div>
                                            //                             </div>
                                            //                             <div class="cef_s_con_r">

                                            //                                 <div class="cef_s_explain  ">
                                            //                                     <span>{
                                                                                
                                            //                                     chtbase['spec2'] != null ? trait[chtbase['spec2']] : ""
                                            //                                     }</span>
                                            //                                 </div>
                                            //                             </div>
                                            //                         </div>  
                                            //                     </div>
                                            //                 </div>
                                            //             </div>
                                            //         </div>
                                            //     </div>
                                            // </div>
                                            
                                            // : <></>

                                        }
                                            
                                        </div>
                                    </div>
                                    <div class="tab__content" name="스탯">
                                        <div class="cws">
                                            <div class="ws-part">
                                                <div class="ws tr se">
                                                    {
                                                    chtbase && chtbase.stats.map((a, i) =>
                                                        {
                                                            return(
                                                            <div class="wsc"  key={i}>
                                                                <div class="th se">
                                                                    <span>
                                                                        {chtbase.stats[i].name}
                                                                        <div class="stat-img">
                                                                            <div class="imgt">
                                                                                <img src={"/img/stat_img/stat-"+[i]+".png"}></img>
                                                                            </div>
                                                                        </div>
                                                                    </span>
                                                                </div>
                                                                <div class="td se">
                                                                    <span>{chtbase.stats[i].value}</span>
                                                                </div>
                                                            </div>
                                                        )}
                                                    )}
                                                </div>
                                        
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab__content" name="세부스탯">
                                        
                                        {
                                            chtbase != null ? 
                                            <div class="scws">
                                                <div class="tr">
                                                    <div class="th">
                                                        <span>공격력 증가</span>
                                                    </div>
                                                    <div class="td">
                                                        <div class="ms">
                                                            <span>{chtbase.damageUpList.power}</span>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                                <div class="tr">
                                                    <div class="th">
                                                        <span>버프력</span>
                                                    </div>
                                                    <div class="td">
                                                        <div class="ms">
                                                            <span>{chtbase.damageUpList.buffPower}</span>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                                
                                            
                                                <div class="tr">
                                                    <div class="th">
                                                        <span>최종 데미지 증가</span>
                                                    </div>
                                                    <div class="td">
                                                        <div class="ms">
                                                            <span>{chtbase.damageUpList.skillDamageUp}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <div class="tr">
                                                    <div class="th">
                                                        <span>속강 증뎀</span>
                                                    </div>
                                                    <div class="td">
                                                        <div class="ms">
                                                            <span>{chtbase.damageUpList.elementDamage}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* {
                                                    [0,1,2,3].map((a,i)=>
                                                    {
                                                        let etc = "";
                                                        switch(i)
                                                        {
                                                            case 0: etc = "중독"; break;
                                                            case 1: etc = "감전"; break;
                                                            case 2: etc = "출혈"; break;
                                                            case 3: etc = "화상"; break;
                                                        }
                                                        return(
                                                            <div class="tr">
                                                                <div class="th">
                                                                    <span>{etc} 변환(증뎀)</span>
                                                                </div>
                                                                <div class="td">
                                                                    <div class="ms">
                                                                        <span>{chtbase.damageUpList["etcDamageChange"+i]}({chtbase.damageUpList["etcDamageUp"+i]})</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })

                                                } */}
                                            
                                                
                                            
                                                <div class="tr">
                                                    <div class="th">
                                                        <span>쿨타임 감소<br/>(구간 감소 제외)</span>
                                                    </div>
                                                    <div class="td">
                                                        <div class="ms">
                                                            <span>{chtbase.damageUpList.cooltimeDown}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <div class="tr">
                                                    <div class="th">
                                                        <span>쿨타임 회복<br/>(구간 증가 제외)</span>
                                                    </div>
                                                    <div class="td">
                                                        <div class="ms">
                                                            <span>{chtbase.damageUpList.cooltimeRecover}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="tr">
                                                    <div class="th">
                                                        <span>총 쿨타임 감소</span>
                                                    </div>
                                                    <div class="td">
                                                        <div class="ms">
                                                            <span>{chtbase.damageUpList.totalCooltime}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div class="tr">
                                                    <div class="th">
                                                        <span>수면 내성</span>
                                                    </div>
                                                    <div class="td">
                                                        <div class="ms">
                                                            <span>{chtbase.damageUpList.sleepResist}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="tr">
                                                    <div class="th">
                                                        <span>가장 높은 내성 (백신)</span>
                                                    </div>
                                                    <div class="td">
                                                        <div class="ms">
                                                            <span>{chtbase.damageUpList.maxResist}</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="tr">
                                                    <div class="th">
                                                        <span>MP 소모량 증가</span>
                                                    </div>
                                                    <div class="td">
                                                        <div class="ms">
                                                            <span>{chtbase.damageUpList.mpusePersent}</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="tr">
                                                    <div class="th">
                                                        <span>스킬 범위 증가</span>
                                                    </div>
                                                    <div class="td">
                                                        <div class="ms">
                                                            <span>{chtbase.damageUpList.skillRange}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="tr">
                                                    <div class="th">
                                                        <span>트리거</span>
                                                    </div>
                                                    <div class="td">
                                                        <div class="ms">
                                                            <span style={{"font-size" : '20px'}}>{chtbase.damageUpList.trigger}</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="tr">
                                                    <div class="th">
                                                        <span>HP 제한</span>
                                                    </div>
                                                    <div class="td">
                                                        <div class="ms">
                                                            <span>{chtbase.damageUpList.hpPersent}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <div class="tr">
                                                    <div class="th">
                                                        <span>MP 제한</span>
                                                    </div>
                                                    <div class="td">
                                                        <div class="ms">
                                                            <span>{chtbase.damageUpList.mpPersent}</span>
                                                        </div>
                                                    </div>
                                                </div> */}

                                            </div> : 
                                            ""
                                        }
                                        
                                        {/* <div class="scws">
                                            {
                                                chtbase && chtbase.stats.map((a, i) =>
                                                    {
                                                    return(

                                                    <div class="tr">
                                                        <div class="th">
                                                            <span>증가데미지</span>
                                                        </div>
                                                        <div class="td">
                                                            <div class="ms">
                                                                <span>49%</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            )}
                                        </div> */}
                                    </div>
                                    <div class="tab__content" name="아바타 & 크리쳐">
                                        <div class="anc">
                                            {
                                            chtbase && chtbase.avatars.map((a, i) =>
                                                {
                                                return(
                                                    <div class="at tr">
                                                        <div class="atc th">
                                                            <span>{chtbase.avatars[i].slot}</span>
                                                        </div>
                                                        <div class="atc td">

                                                            <div class="wimg">
                                                                {
                                                                    chtbase.avatars[i].itemId != null ? 

                                                                    <div class="witem-img">
                                                                        <div class="imgt">
                                                                            <img src={"https://img-api.neople.co.kr/df/items/" + chtbase.avatars[i].itemId}>
                                                                            </img>
                                                                        </div>
                                                                    
                                                                    </div> : <></>
                                                                }
                                                                {
                                                                    chtbase.avatars[i].cloneItemId != null ?  
                                                                    <div class="witem-img">
                                                                        <div class="imgt">
                                                                            <img src={"https://img-api.neople.co.kr/df/items/" + chtbase.avatars[i].cloneItemId}>
                                                                            </img>
                                                                        </div>
                                                                    
                                                                    </div> : <></>
                                                                }
                                                                
                                                            </div>
                                                            <div class="atn">
                                                                <span>
                                                                    {chtbase.avatars[i].name}   
                                                                </span>

                                                                <div class="atv">
                                                                    <span>
                                                                        {chtbase.avatars[i].option}
                                                                    </span>
                                                                </div>
                                                            </div>

                                                            <div class="ate">
                                                                {
                                                                    chtbase.avatars[i].emblem1 ?
                                                                    <span
                                                                        style={emblemToColor[chtbase.avatars[i].emblem1.split(" ")[0]] != null ? { "color":emblemToColor[chtbase.avatars[i].emblem1.split(" ")[0]] }:{} }>
                                                                            {chtbase.avatars[i].emblem1}  
                                                                    </span> : ""

                                                                }

                                                                {
                                                                                            chtbase.avatars[i].emblem2 ?
                                                                                            <span
                                                                                                style={emblemToColor[chtbase.avatars[i].emblem2.split(" ")[0]] != null ? { "color":emblemToColor[chtbase.avatars[i].emblem2.split(" ")[0]] }:{}}>
                                                                                                    {chtbase.avatars[i].emblem2}  
                                                                                            </span> : ""

                                                                                        }

                                                                {
                                                                    chtbase.avatars[i].emblem3 ?
                                                                    <span
                                                                        style={emblemToColor[chtbase.avatars[i].emblem3.split(" ")[0]] != null ? { "color":emblemToColor[chtbase.avatars[i].emblem3.split(" ")[0]] }:{} }>
                                                                            {chtbase.avatars[i].emblem3}  
                                                                    </span> : ""

                                                                }
                                                            </div>
                                              
                                                        </div>
                                                    </div>
                                                )}
                                            )}

                                            <div class="center-borderline cripart">
                                                <div class="at cr tr">
                                                    <div class="atc th">
                                                        <span>크리쳐</span>
                                                    </div>
                                                    <div class="atc td">

                                                        <div class="wimg">
                                                            
                                                            {chtbase && chtbase.creatureId ?
                                                                
                                                                <div class="witem-img">
                                                                    <div class="imgt">
                                                                        <img src={"https://img-api.neople.co.kr/df/items/" + (chtbase && chtbase.creatureId  ? chtbase.creatureId : "")}>
                                                                        </img>
                                                                    </div>
                                                                    
                                                                </div> : <></>
                                                            }
                                                     
                                                        </div>
                                                        <div class="atn">
                                                            <span>
                                                                {chtbase && chtbase.creature}
                                                            </span>
                                                        </div>
                                                        {
                                                            console.log(chtbase)
                                                        }

                                                        <div class="wimg atc-cri-detail"> 
                                                            {chtbase && chtbase.afti && chtbase.afti[0].id ?
                                                                
                                                                <div class="witem-img">
                                                                    <div class="imgt">
                                                                        <img src={"https://img-api.neople.co.kr/df/items/" + (chtbase && chtbase.afti && chtbase.afti[0].id ? chtbase.afti[0].id : "")}>
                                                                        </img>
                                                                    </div>
                                                                    
                                                                </div> : <></>
                                                            }
                                                            {chtbase && chtbase.afti && chtbase.afti[1].id ?
                                                                
                                                                <div class="witem-img">
                                                                    <div class="imgt">
                                                                        <img src={"https://img-api.neople.co.kr/df/items/" + (chtbase && chtbase.afti && chtbase.afti[1].id ? chtbase.afti[1].id : "")}>
                                                                        </img>
                                                                    </div>
                                                                        
                                                                </div> : <></>
                                                            }
                                                            {chtbase && chtbase.afti && chtbase.afti[2].id ?
                                                            
                                                                <div class="witem-img">
                                                                    <div class="imgt">
                                                                        <img src={"https://img-api.neople.co.kr/df/items/" + (chtbase.afti[2].id)}>
                                                                        </img>
                                                                    </div>
                                                                    
                                                                </div> : <></>
                                                            }
                                                        </div>


                                                        <div class="ate">
                                                            <span style={chtbase && chtbase.afti[0] && gradeToColor[chtbase.afti[0].rarity] != null ? {"color": gradeToColor[chtbase.afti[0].rarity]} : {}}>{chtbase && chtbase.afti && chtbase.afti[0].name ? chtbase.afti[0].name : ""}</span>
                                                            <span style={chtbase && chtbase.afti[1] && gradeToColor[chtbase.afti[1].rarity] != null ? {"color": gradeToColor[chtbase.afti[1].rarity]} : {}}>{chtbase && chtbase.afti && chtbase.afti[1].name ? chtbase.afti[1].name : ""}</span>
                                                            <span style={chtbase && chtbase.afti[2] && gradeToColor[chtbase.afti[2].rarity] != null ? {"color": gradeToColor[chtbase.afti[2].rarity]} : {}}>{chtbase && chtbase.afti && chtbase.afti[2].name ? chtbase.afti[2].name : ""}</span>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab__content" name="버프강화">
                                        <div class="cwp is-buff-enhaned">

                                            {
                                            chtbase && chtbase.switching.map((a, i) =>
                                                {
                                                    return(
                                                    
                                                        <div class="tr">
                                                            <div class="th">
                                                                <span>{chtbase.switching[i].slot}</span>
                                                            </div>
                                                            <div class="td">
                                                                <div class="wimg">
                                                                    <img src={'https://img-api.neople.co.kr/df/items/'+chtbase.switching[i].itemid}  />
                                                                </div>
                                                                <div class="winfo">
                                                                    <div class="wn">
                                                                        <span style={gradeToColor[chtbase.switching[i].rarity] != null ? {"color": gradeToColor[chtbase.switching[i].rarity]} : {}}>
                                                                            {chtbase.switching[i].name}
                                                                        </span>
                                                                        <span class="wn-add">
                                                                            {chtbase.switching[i].enchant}
                                                                        </span>
                                                                    </div>
                                                                </div>

                                                                <div class="wplus">
                                                                    <span class="val" style={chtbase.switching[i].reinforceType =="강화" ? {color : "unset"} : {}}>
                                                                        {chtbase.switching[i].reinforceNum}
                                                                    </span>
                                                                    <span class="wp-add" style={chtbase.switching[i].reinforceType =="강화" ? {color : "unset"} : {}}>
                                                                        {chtbase.switching[i].reinforceType}
                                                                    </span>
                                                                </div>
                                                                
                                                            </div>
                                                        </div>
                                                    
                                                    )}
                                            )}
                                        </div>

                                    </div>
                                    <div class="tab__content" name="탈리스만">
                                        {/* <div class="tal">
                                            <div class="ta tr">
                                                <div class="tc">
                                                    <div class="tc-tit">
                                                        <span>{chtbase && chtbase.talismans[0].talisman}</span>
                                                    </div>
                                                    <div class="tc-c">
                                                        <div class="tcc">
                                                            <span>{chtbase && chtbase.talismans[0].runeType}</span>
                                                            <span>{chtbase && chtbase.talismans[0].rune1}</span>
                                                            <span>{chtbase && chtbase.talismans[0].rune2}</span>
                                                            <span>{chtbase && chtbase.talismans[0].rune3}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="ta tr">
                                                <div class="tc">
                                                    <div class="tc-tit">
                                                        <span>{chtbase && chtbase.talismans[1].talisman}</span>
                                                    </div>
                                                    <div class="tc-c">
                                                        <div class="tcc">
                                                            <span>{chtbase && chtbase.talismans[1].runeType}</span>
                                                            <span>{chtbase && chtbase.talismans[1].rune1}</span>
                                                            <span>{chtbase && chtbase.talismans[1].rune2}</span>
                                                            <span>{chtbase && chtbase.talismans[1].rune3}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="tc">
                                                    <div class="tc-tit">
                                                        <span>{chtbase && chtbase.talismans[2].talisman}</span>
                                                    </div>
                                                    <div class="tc-c">
                                                        <div class="tcc">
                                                            <span>{chtbase && chtbase.talismans[2].runeType}</span>
                                                            <span>{chtbase && chtbase.talismans[2].rune1}</span>
                                                            <span>{chtbase && chtbase.talismans[2].rune2}</span>
                                                            <span>{chtbase && chtbase.talismans[2].rune3}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                        <div class="tal">

                                            <div class="c_talisman">
                                                <div class="c_tal_select">
                                                    <div class="ctal_bag">
                                                        <div class="ctal_c ctcfirst">
                                                            <ul>
                                                                <li class="ctalc_main">
                                                                    <div class="imgt">
                                                                        <img src={chtbase && getSkillImageURL (talismanToSkillName(chtbase && chtbase.job ,chtbase && chtbase.talismans[0].talisman,chtbase && chtbase.baseJob),chtbase.baseJob)}></img>

                                                                    </div>
                                                                </li>
                                                                <li class="ctalc_tal1">
                                                                    <div class="imgt" style={{'background' : chtbase && chtbase.talismans[0].rune1 && talismanColor(chtbase.talismans[0].rune1)}}>
                                                                        <img style={{'mix-blend-mode' : 'luminosity'}}  src={chtbase && chtbase.talismans[0].rune1 && getSkillImageURL (chtbase.talismans[0].rune1.split("[")[1].replace("]",""),chtbase.baseJob)}></img>
                                                                    </div>
                                                                    
                                                                </li>
                                                                <li class="ctalc_tal2">
                                                                    <div class="imgt" style={{'background' : chtbase && chtbase.talismans[0].rune2  && talismanColor(chtbase.talismans[0].rune2)}}>
                                                                         <img style={{'mix-blend-mode' :        'luminosity'}}  src={chtbase && chtbase.talismans[0].rune2  && getSkillImageURL (chtbase.talismans[0].rune2.split("[")[1].replace("]",""),chtbase.baseJob)}></img>
                                                                    </div>
                                                                </li>
                                                                <li class="ctalc_tal3">
                                                                    <div class="imgt" style={{'background' : chtbase && chtbase.talismans[0].rune3  && talismanColor(chtbase.talismans[0].rune3)}}>
                                                                        <img style={{'mix-blend-mode' : 'luminosity'}}  src={chtbase && chtbase.talismans[0].rune3 && getSkillImageURL (chtbase.talismans[0].rune3.split("[")[1].replace("]",""),chtbase.baseJob)}></img>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div class="ctal_c ctcsecond">
                                                            <ul>
                                                                <li class="ctalc_main">
                                                                    <div class="imgt">
                                                                        <img src={chtbase && getSkillImageURL (talismanToSkillName(chtbase && chtbase.job ,chtbase && chtbase.talismans[1].talisman,chtbase && chtbase.baseJob))}></img>

                                                                    </div>
                                                                </li>
                                                                <li class="ctalc_tal1">
                                                                    <div class="imgt" style={{'background' : chtbase && chtbase.talismans[1].rune1 && talismanColor(chtbase.talismans[1].rune1)}}>
                                                                        <img style={{'mix-blend-mode' : 'luminosity'}}  src={chtbase && chtbase.talismans[1].rune1 && getSkillImageURL (chtbase.talismans[1].rune1.split("[")[1].replace("]",""),chtbase.baseJob)}></img>
                                                                    </div>
                                                                    
                                                                </li>
                                                                <li class="ctalc_tal2">
                                                                    <div class="imgt" style={{'background' : chtbase && chtbase.talismans[1].rune2  && talismanColor(chtbase.talismans[1].rune2)}}>
                                                                         <img style={{'mix-blend-mode' :        'luminosity'}}  src={chtbase && chtbase.talismans[1].rune2  && getSkillImageURL (chtbase.talismans[1].rune2.split("[")[1].replace("]",""),chtbase.baseJob)}></img>
                                                                    </div>
                                                                </li>
                                                                <li class="ctalc_tal3">
                                                                    <div class="imgt" style={{'background' : chtbase && chtbase.talismans[1].rune3  && talismanColor(chtbase.talismans[1].rune3)}}>
                                                                        <img style={{'mix-blend-mode' : 'luminosity'}}  src={chtbase && chtbase.talismans[1].rune3 && getSkillImageURL (chtbase.talismans[1].rune3.split("[")[1].replace("]",""),chtbase.baseJob)}></img>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div class="ctal_c ctcthird">
                                                            <ul>
                                                                <li class="ctalc_main">
                                                                    <div class="imgt">
                                                                        <img src={chtbase && getSkillImageURL (talismanToSkillName(chtbase && chtbase.job ,chtbase && chtbase.talismans[2].talisman,chtbase && chtbase.baseJob),chtbase.baseJob)}></img>

                                                                    </div>
                                                                </li>
                                                                <li class="ctalc_tal1">
                                                                    <div class="imgt" style={{'background' : chtbase && chtbase.talismans[2].rune1 && talismanColor(chtbase.talismans[2].rune1)}}>
                                                                        <img style={{'mix-blend-mode' : 'luminosity'}}  src={chtbase && chtbase.talismans[2].rune1 && getSkillImageURL (chtbase.talismans[2].rune1.split("[")[1].replace("]",""),chtbase.baseJob)}></img>
                                                                    </div>
                                                                    
                                                                </li>
                                                                <li class="ctalc_tal2">
                                                                    <div class="imgt" style={{'background' : chtbase && chtbase.talismans[2].rune2  && talismanColor(chtbase.talismans[2].rune2)}}>
                                                                         <img style={{'mix-blend-mode' :        'luminosity'}}  src={chtbase && chtbase.talismans[2].rune2  && getSkillImageURL (chtbase.talismans[2].rune2.split("[")[1].replace("]",""),chtbase.baseJob)}></img>
                                                                    </div>
                                                                </li>
                                                                <li class="ctalc_tal3">
                                                                    <div class="imgt" style={{'background' : chtbase && chtbase.talismans[2].rune3  && talismanColor(chtbase.talismans[2].rune3)}}>
                                                                        <img style={{'mix-blend-mode' : 'luminosity'}}  src={chtbase && chtbase.talismans[2].rune3 && getSkillImageURL (chtbase.talismans[2].rune3.split("[")[1].replace("]",""),chtbase.baseJob)}></img>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab__content damtab" name="딜표">
                                        {
                                            <div class="damage">
                                            <div class="damage">
                                                <div class="tab-wrap">
                                                    
                                                    <input type="radio" id="dam1" name="tabGroup1" class="tab" ></input>
                                                    <label for="dam1"><span>금룡</span></label>
                                                    <input type="radio" id="dam2" name="tabGroup1" class="tab" defaultChecked></input>
                                                    <label for="dam2"><span>랭킹</span></label>
                                                    <input type="radio" id="dam5" name="tabGroup1" class="tab"></input>
                                                    <label for="dam5"><span>40초</span></label>

                                                    <div class="tab__content" name="샌드백">
                                                        <div class="deal">
                                                            <div class="hd-part is-no-switch">
                                                                <div class="demval">
                                                                    <span class="dvtit">총딜</span>
                                                                    <span class="dval">
                                                                        {chtbase && chtbase.damageList.vsSendbag[chtbase.damageList.vsSendbag.length-1].dam} 
                                                                        
                                                                    </span>
                                                                    <span>
                                                                        <div class="imgt">
                                                                        </div>
                                                                    </span>
                                                                    <span class="more">
                                                                        
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div class="cc-part">
                                                                <div class="asum">

                                                                </div>
                                                                {chtbase ? <AllDamage name='vsSendbag' chtbase={chtbase} daelCount={chtbase.damageList.vsSendbag.length-1}/> : <></>}
                                                                {chtbase ? <Damages name='vsSendbag' chtbase={chtbase} daelCount={chtbase.damageList.vsSendbag.length-1}/> : <></>}
                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="tab__content" name="랭킹">
                                                        <div class="deal">

                                                            <div class="hd-part is-no-switch">
                                                                <div class="demval">
                                                                    <span class="dvtit">총딜</span>
                                                                    <span class="dval">{chtbase && chtbase.damageList.vsRanking[chtbase.damageList.vsRanking.length-1].dam}</span>
                                                                    <span>
                                                                        <div class="imgt">
                                                                        </div>
                                                                    </span>
                                                                    <span class="more">
                                                
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div class="cc-part">
                                                                <div class="asum">

                                                                </div>
                                                                {chtbase ? <AllDamage name='vsRanking' chtbase={chtbase} daelCount={chtbase.damageList.vsRanking.length-1}/> : <></>}
                                                                {chtbase ? <Damages name='vsRanking' chtbase={chtbase} daelCount={chtbase.damageList.vsRanking.length-1}/> : <></>}
                                                            </div>
                                                        </div>

                                                    </div>
                                                    {
                                                        /*
                                                    }
                                                    <div class="tab__content" name="오즈마">
                                                        <div class="deal">

                                                            <div class="hd-part">
                                                                <div class="demval">
                                                                    <span class="dvtit">1시너지 총딜</span>
                                                                    <span class="dval">23,542,784,651</span>
                                                                    <span>
                                                                    
                                                                    </span>
                                                                    <span class="more">
                                                
                                                                    </span>
                                                                </div>

                                                                <div class="swt">
                                                                    <div class="swtr">
                                                                        <div class="switch-button">
                                                                            <span 
                                                                            className={`active ${activemove}`}></span>
                                                                            
                                                                            <button 
                                                                            className={`switch-button-case ${choiceleft}`}
                                                                            onClick={()=>{
                                                                                setChoiceLeft("active-case");
                                                                                setChoiceRight("");
                                                                                setActiveMove("on");
                                                                                setAddCri("Cri");

                                                                                setVsOzma([...chtbase.damageList.vsOzmaCri]);
                                                                            }}
                                                                            
                                                                            >
                                                                                <span>크리소스</span>

                                                                            </button>
                                                                            
                                                                            <button 
                                                                            className={`switch-button-case ${choiceright}`}
                                                                            onClick={()=>{
                                                                                setChoiceLeft("");
                                                                                setChoiceRight("active-case");
                                                                                setActiveMove("off");
                                                                                setAddCri("");
                                                                                
                                                                                setVsOzma([...chtbase.damageList.vsOzma]);
                                                                            }}

                                                                            >
                                                                                <span>투함포</span>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="cc-part">
                                                                <div class="asum">

                                                                </div>

                                                                {


                                                                vsOzma && vsOzma
                                                                .map((a, i) =>
                                                                    {
                                                                    return(
                                                                            <div class="sk tr">
                                                                                <div class="th">
                                                                                    <span>
                                                                                        <div class="imgt">
                                                                                        </div>
                                                                                    </span>
                                                                                </div>
                                                                                <div class="td">
                                                                                    <div class="skn">
                                                                                        <span>{vsOzma[i].name}</span>
                                                                                    </div>
                                                                                    <div class="skc">
                                                                                        <ul>
                                                                                            <li class="skl">
                                                                                                <span class="val">
                                                                                                {vsOzma[i].dam}
                                                                                                </span>
                                                                                                <span class="ser-percent">
                                                                                                    + 0.29%
                                                                                                </span>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                    )}
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        */

                                                    }
                                                    {
                                                    /*
                                                    }
                                                    <div class="tab__content" name="차원회랑">
                                                        <div class="deal">
                                                            <div class="hd-part is-no-switch">
                                                                <div class="demval">
                                                                    <span class="dvtit">총딜</span>
                                                                    <span class="dval">{chtbase && chtbase.damageList.vsGB[chtbase.damageList.vsGB.length-1].dam}</span>
                                                                    <span>
                                                                        <div class="imgt">
                                                                        </div>
                                                                    </span>
                                                                    <span class="more">
                                                
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div class="cc-part">
                                                                <div class="asum">

                                                                </div>

                                                                {chtbase ? <AllDamage name='vsGB' chtbase={chtbase} daelCount={chtbase.damageList.vsGB.length-1}/> : <></>}
                                                                {chtbase ? <Damages name='vsGB' chtbase={chtbase} daelCount={chtbase.damageList.vsGB.length-1}/> : <></>}
                                                            </div>
                                                        </div>
                                    
                                                    </div>
                                                    {
                                                        */
                                                    }
                                                    <div class="tab__content" name="바칼">
                                                        <div class="deal">
                                                            <div class="hd-part is-no-switch">
                                                                <div class="demval">
                                                                    <span class="dvtit">총딜</span>
                                                                    <span class="dval">{chtbase && chtbase.damageList.vsDragons[chtbase.damageList.vsDragons.length-1].dam}</span>
                                                                    <span>
                                                                        <div class="imgt">
                                                                        </div>
                                                                    </span>
                                                                    <span class="more">
                                                
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div class="cc-part">
                                                                <div class="asum">

                                                                </div>
                                                                
                                                                {chtbase ? <AllDamage name='vsDragons' chtbase={chtbase} daelCount={chtbase.damageList.vsDragons.length-1}/> : <></>}
                                                                {chtbase ? <Damages name='vsDragons' chtbase={chtbase} daelCount={chtbase.damageList.vsDragons.length-1}/> : <></>}

                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                        </div> }
                                    </div>
                                    <div class="tab__content" name="스킬정보">
                                        <div class="skinfo">

                                        {
                                        chtbase && chtbase.skillInfo.map((a, i) =>
                                            {
                                            return(
                                                <div class="sk tr">
                                                    <div class="th">
                                                        <span>
                                                            <div class="imgt">
                                                                <img src={getSkillImageURL(chtbase.skillInfo[i].skillName,chtbase.baseJob)}/>
                                                            </div>
                                                        </span>
                                                    </div>
                                                    <div class="td">
                                                        <div class="skn">
                                                            <span>{chtbase.skillInfo[i].skillName}</span>
                                                        </div>
                                                        <div class="skc">
                                                            <ul>
                                                                <li class="skl">
                                                                    <span class="stit">
                                                                        레벨 (TP)
                                                                    </span>
                                                                    <span class="val">
                                                                        {chtbase.skillInfo[i].skillPoint} {chtbase.skillInfo[i].skillTP}
                                                                    </span>
                                                        
                                                                </li>
                                                    
                                                                <li class="skt">
                                                                    <span class="stit">
                                                                        쿨타임
                                                                    </span>
                                                                    <span class="val">
                                                                        {chtbase.skillInfo[i].skillCool}
                                                                    </span>
                                                                </li>
                                                                <li class="skj">
                                                                    <span class="stit">
                                                                        시전시간
                                                                    </span>
                                                                    <span class="val">
                                                                        {chtbase.skillInfo[i].skillCoolde}
                                                                    </span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        )}

                                        </div>

                                    </div>
                                    {/* <div class="tab__content" name="스킬트리">
                                        <div class="sktree">
                                            <div class="sktree-cover">
                                                <div class="stree-common">
                                                    <div class="stlev-numlabel">
                                                            <span>공용스킬</span>
                                                    </div>
                                                    <div class="stlev-ul">
                                                        

                                                        <div class="stree-content">
                                                            <div class="stce-01">
                                                                <div class="stce-01-img">
                                                                    <div class="imgt">
                                                                        <img src='/img/skill_tree_comon_01.png'>
                                                                        </img>                                                                       
                                                                    </div>
                                                                </div>
                                                                <div class="stce-01-level stce-master-toggle">
                                                                    <div class="stce-level-bef">
                                                                        <span class="stce-level-lv">Lv</span>
                                                                        <span class="stce-level-master"></span>
                                                                    </div>
                                                                    <span class="stce-level-point">1</span>
                                                                    
                                                                </div>
                                                            
                                                            </div>
                                                        </div>
                                                        <div class="stree-content">
                                                            <div class="stce-01">
                                                                    <div class="stce-01-img">
                                                                        <div class="imgt">
                                                                            <img src='/img/skill_tree_comon_02.png'>
                                                                            </img>                                                                       
                                                                        </div>
                                                                    </div>
                                                                    <div class="stce-01-level stce-master-toggle">
                                                                        <div class="stce-level-bef">
                                                                            <span class="stce-level-lv">Lv</span>
                                                                            <span class="stce-level-master"></span>
                                                                        </div>
                                                                        <span class="stce-level-point">1</span>
                                                                        
                                                                    </div>
                                                                
                                                                </div>
                                                            </div>
                                                        <div class="stree-content">
                                                            <div class="stce-01">
                                                                <div class="stce-01-img">
                                                                    <div class="imgt">
                                                                        <img src='/img/skill_tree_comon_03.png'>
                                                                        </img>                                                                       
                                                                    </div>
                                                                </div>
                                                                <div class="stce-01-level stce-master-toggle">
                                                                    <div class="stce-level-bef">
                                                                        <span class="stce-level-lv">Lv</span>
                                                                        <span class="stce-level-master"></span>
                                                                    </div>
                                                                    <span class="stce-level-point">110</span>
                                                                    
                                                                </div>
                                                            
                                                            </div>
                                                        </div>
                                                        <div class="stree-content">
                                                            <div class="stce-01">
                                                                    <div class="stce-01-img">
                                                                        <div class="imgt">
                                                                            <img src='/img/skill_tree_comon_04.png'>
                                                                            </img>                                                                       
                                                                        </div>
                                                                    </div>
                                                                    <div class="stce-01-level">
                                                                        <div class="stce-level-bef">
                                                                            <span class="stce-level-lv">Lv</span>
                                                                            <span class="stce-level-master"></span>
                                                                        </div>
                                                                        <span class="stce-level-point">1</span>
                                                                        
                                                                    </div>
                                                            </div>
                                                        </div>
                                                        <div class="stree-content">
                                                            <div class="stce-01">
                                                                    <div class="stce-01-img">
                                                                        <div class="imgt">
                                                                            <img src='/img/skill_tree_comon_05.png'>
                                                                            </img>                                                                       
                                                                        </div>
                                                                    </div>
                                                                    <div class="stce-01-level stce-master-toggle">
                                                                        <div class="stce-level-bef">
                                                                            <span class="stce-level-lv">Lv</span>
                                                                            <span class="stce-level-master"></span>
                                                                        </div>
                                                                        <span class="stce-level-point">1</span>
                                                                        
                                                                    </div>
                                                            </div>
                                                        </div>
                                                        <div class="stree-content">
                                                            <div class="stce-01 stce-skill-off-mode">
                                                                <div class="stce-01-img">
                                                                    <div class="imgt">
                                                                        <img src='/img/skill_tree_comon_06.png'>
                                                                        </img>                                                                       
                                                                    </div>
                                                                </div>
                                                                <div class="stce-01-level">
                                                                    <div class="stce-level-bef">
                                                                        <span class="stce-level-lv">Lv</span>
                                                                        <span class="stce-level-master"></span>
                                                                    </div>
                                                                    <span class="stce-level-point">0</span>
                                                                    
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="stree-content">
                                                            <div class="stce-01 stce-skill-off-mode">
                                                                <div class="stce-01-img">
                                                                    <div class="imgt">
                                                                        <img src='/img/skill_tree_comon_07.png'>
                                                                        </img>                                                                       
                                                                    </div>
                                                                </div>
                                                                <div class="stce-01-level">
                                                                    <div class="stce-level-bef">
                                                                        <span class="stce-level-lv">Lv</span>
                                                                        <span class="stce-level-master"></span>
                                                                    </div>
                                                                    <span class="stce-level-point">0</span>
                                                                    
                                                                </div>
                                                            </div>
                                                            
                                                        </div>
                                                        <div class="stree-content">
                                                            <div class="stce-01 stce-skill-off-mode">
                                                                <div class="stce-01-img">
                                                                    <div class="imgt">
                                                                        <img src='/img/skill_tree_comon_08.png'>
                                                                        </img>                                                                       
                                                                    </div>
                                                                </div>
                                                                <div class="stce-01-level">
                                                                    <div class="stce-level-bef">
                                                                        <span class="stce-level-lv">Lv</span>
                                                                        <span class="stce-level-master"></span>
                                                                    </div>
                                                                    <span class="stce-level-point">0</span>
                                                                    
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="stree-content">
                                                        </div>
                                                        <div class="stree-content">
                                                        </div>
                                                        <div class="stree-content">
                                                            <div class="stce-01">
                                                                <div class="stce-01-img">
                                                                    <div class="imgt">
                                                                        <img src='/img/skill_tree_comon_09.png'>
                                                                        </img>                                                                       
                                                                    </div>
                                                                </div>
                                                                <div class="stce-01-level">
                                                                    <div class="stce-level-bef">
                                                                        <span class="stce-level-lv">Lv</span>
                                                                        <span class="stce-level-master"></span>
                                                                    </div>
                                                                    <span class="stce-level-point">1</span>
                                                                    
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {
                                                    chtbase && skillTree ? skillTree.map((a,i)=>
                                                    {
                                                        
                                                        return(
                                                            
                                                            <div class="stree-levelside">
                                                                <div class="stlev-ul-bc">
                                                                    
                                                                    {
                                                                    skillTree[i][0] != '' ? 
                                                                    <div class="stlev-numlabel">
                                                                        <span>{skillTree[i][0]}</span>
                                                                    </div>
                                                                    : "" 
                                                                    }
                                                                    
                                                                
                                                                
                                                                    <div class="stlev-ul">

                                                                    {
                                                                        skillTree[i].map((in_a,in_i)=>
                                                                        {
                                                                            let isOff = false;
                                                                            let isMaster = false;
                                                                            if(in_i != 0)
                                                                            {
                                                                                if(in_a == '')
                                                                                {
                                                                                    return( //공백
                                                                                    <div class="stree-content">
                                                                                    </div>
                                                                                    )
                                                                                }
                                                                                else
                                                                                {
                                                                                    return( //켜짐
                                                                                        <div class={"stree-content " + (isOff ? "stce-skill-off-mode" : "")}>
                                                                                            <div class="stce-01">
                                                                                                <div class="stce-01-img">
                                                                                                    <div class="imgt">
                                                                                                        <img src={skillImage[in_a]}>
                                                                                                        </img>                                                                       
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div class={"stce-01-level " + (isMaster ? "stce-master-toggle" : "")}>
                                                                                                    <div class="stce-level-bef">
                                                                                                        <span class="stce-level-lv">Lv</span>
                                                                                                        <span class="stce-level-master"></span>
                                                                                                    </div>
                                                                                                    <span class="stce-level-point">1</span>
                                                                                                    
                                                                                                </div>
                                                                                            
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                }
                                                                            }
                                                                            else
                                                                            {
                                                                                return(<></>)
                                                                            }
                                                                        })
                                                                    }
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        )
                                                    }) : <></>
                                                } 

                                                {/* <div class="stree-levelside">
                                                    <div class="stlev-ul-bc">
                                                        <div class="stlev-numlabel">
                                                            <span>1</span>
                                                        </div>
                                                        <div class="stlev-ul">

                                                            <div class="stree-content">
                                                                <div class="stce-01">
                                                                    <div class="stce-01-img">
                                                                        <div class="imgt">
                                                                            <img src='/img/skill_tree_comon_11.png'>
                                                                            </img>                                                                       
                                                                        </div>
                                                                    </div>
                                                                    <div class="stce-01-level stce-master-toggle">
                                                                        <div class="stce-level-bef">
                                                                            <span class="stce-level-lv">Lv</span>
                                                                            <span class="stce-level-master"></span>
                                                                        </div>
                                                                        <span class="stce-level-point">1</span>
                                                                        
                                                                    </div>
                                                                
                                                                </div>
                                                            </div>
                                                            <div class="stree-content">
                                                             
                                                            </div>
                                                            <div class="stree-content">
                                                               
                                                            </div>
                                                            <div class="stree-content">
                                                                <div class="stce-01">
                                                                        <div class="stce-01-img">
                                                                            <div class="imgt">
                                                                                <img src='/img/skill_tree_comon_12.png'>
                                                                                </img>                                                                       
                                                                            </div>
                                                                        </div>
                                                                        <div class="stce-01-level">
                                                                            <div class="stce-level-bef">
                                                                                <span class="stce-level-lv">Lv</span>
                                                                                <span class="stce-level-master"></span>
                                                                            </div>
                                                                            <span class="stce-level-point">1</span>
                                                                            
                                                                        </div>
                                                                </div>
                                                            </div>
                                                            <div class="stree-content">
                                                            </div>
                                                            <div class="stree-content">
                                                                <div class="stce-01">
                                                                    <div class="stce-01-img">
                                                                        <div class="imgt">
                                                                            <img src='/img/skill_tree_comon_13.png'>
                                                                            </img>                                                                       
                                                                        </div>
                                                                    </div>
                                                                    <div class="stce-01-level">
                                                                        <div class="stce-level-bef">
                                                                            <span class="stce-level-lv">Lv</span>
                                                                            <span class="stce-level-master"></span>
                                                                        </div>
                                                                        <span class="stce-level-point">1</span>
                                                                        
                                                                    </div>
                                                                
                                                                </div>
                                                            </div>
                                                            <div class="stree-content">
                                                            </div>
                                                            <div class="stree-content">
                                                            </div>
                                                            <div class="stree-content">
                                                            </div>
                                                            <div class="stree-content">
                                                            </div>
                                                            <div class="stree-content">
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div class="stlev-ul-bc">
                                                        <div class="stlev-ul">

                                                            <div class="stree-content">
                                                            </div>
                                                            <div class="stree-content">
                                                            </div>

                                                            <div class="stree-content stce-skill-off-mode">
                                                                <div class="stce-01">
                                                                    <div class="stce-01-img">
                                                                        <div class="imgt">
                                                                            <img src='/img/skill_tree_comon_14.png'>
                                                                            </img>                                                                       
                                                                        </div>
                                                                    </div>
                                                                    <div class="stce-01-level stce-master-toggle">
                                                                        <div class="stce-level-bef">
                                                                            <span class="stce-level-lv">Lv</span>
                                                                            <span class="stce-level-master"></span>
                                                                        </div>
                                                                        <span class="stce-level-point">110</span>
                                                                        
                                                                    </div>
                                                                
                                                                </div>
                                                            </div>
                                                            <div class="stree-content">
                                                            </div>
                                                            <div class="stree-content stce-skill-off-mode">
                                                                <div class="stce-01">
                                                                        <div class="stce-01-img">
                                                                            <div class="imgt">
                                                                                <img src='/img/skill_tree_comon_15.png'>
                                                                                </img>                                                                       
                                                                            </div>
                                                                        </div>
                                                                        <div class="stce-01-level stce-master-toggle">
                                                                            <div class="stce-level-bef">
                                                                                <span class="stce-level-lv">Lv</span>
                                                                                <span class="stce-level-master"></span>
                                                                            </div>
                                                                            <span class="stce-level-point">1</span>
                                                                            
                                                                        </div>
                                                                </div>
                                                            </div>
                                                            <div class="stree-content">
                                                            </div>

                                                            <div class="stree-content ">
                                                                <div class="stce-01">
                                                                        <div class="stce-01-img">
                                                                            <div class="imgt">
                                                                                <img src='/img/skill_tree_comon_16.png'>
                                                                                </img>                                                                       
                                                                            </div>
                                                                        </div>
                                                                        <div class="stce-01-level">
                                                                            <div class="stce-level-bef">
                                                                                <span class="stce-level-lv">Lv</span>
                                                                                <span class="stce-level-master"></span>
                                                                            </div>
                                                                            <span class="stce-level-point">1</span>
                                                                            
                                                                        </div>
                                                                </div>
                                                            </div>
                                                            <div class="stree-content">
                                                            </div>
                                                            <div class="stree-content ">
                                                                <div class="stce-01">
                                                                        <div class="stce-01-img">
                                                                            <div class="imgt">
                                                                                <img src='/img/skill_tree_comon_17.png'>
                                                                                </img>                                                                       
                                                                            </div>
                                                                        </div>
                                                                        <div class="stce-01-level stce-master-toggle">
                                                                            <div class="stce-level-bef">
                                                                                <span class="stce-level-lv">Lv</span>
                                                                                <span class="stce-level-master"></span>
                                                                            </div>
                                                                            <span class="stce-level-point">5</span>
                                                                            
                                                                        </div>
                                                                </div>
                                                            </div>
                                                         
                                                            <div class="stree-content">
                                                            </div>
                                                            <div class="stree-content">
                                                                <div class="stce-01">
                                                                    <div class="stce-01-img">
                                                                        <div class="imgt">
                                                                            <img src='/img/skill_tree_comon_18.png'>
                                                                            </img>                                                                       
                                                                        </div>
                                                                    </div>
                                                                    <div class="stce-01-level stce-master-toggle">
                                                                        <div class="stce-level-bef">
                                                                            <span class="stce-level-lv">Lv</span>
                                                                            <span class="stce-level-master"></span>
                                                                        </div>
                                                                        <span class="stce-level-point">1</span>
                                                                        
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="stlev-ul-bc">
                                                        <div class="stlev-numlabel">
                                                            <span>10</span>
                                                        </div>
                                                        <div class="stlev-ul">
                                                            <div class="stree-content stce-skill-off-mode">
                                                                <div class="stce-01">
                                                                    <div class="stce-01-img">
                                                                        <div class="imgt">
                                                                            <img src='/img/skill_tree_comon_19.png'>
                                                                            </img>                                                                       
                                                                        </div>
                                                                    </div>
                                                                    <div class="stce-01-level stce-master-toggle">
                                                                        <div class="stce-level-bef">
                                                                            <span class="stce-level-lv">Lv</span>
                                                                            <span class="stce-level-master"></span>
                                                                        </div>
                                                                        <span class="stce-level-point">110</span>
                                                                        
                                                                    </div>
                                                                
                                                                </div>
                                                            </div>
                                                            <div class="stree-content stce-skill-off-mode">
                                                                <div class="stce-01">
                                                                    <div class="stce-01-img">
                                                                        <div class="imgt">
                                                                            <img src='/img/skill_tree_comon_110.png'>
                                                                            </img>                                                                       
                                                                        </div>
                                                                    </div>
                                                                    <div class="stce-01-level stce-master-toggle">
                                                                        <div class="stce-level-bef">
                                                                            <span class="stce-level-lv">Lv</span>
                                                                            <span class="stce-level-master"></span>
                                                                        </div>
                                                                        <span class="stce-level-point">110</span>
                                                                        
                                                                    </div>
                                                                
                                                                </div>
                                                            </div>
                                                            <div class="stree-content">
                                                              
                                                            </div>
                                                            <div class="stree-content">
                                                                <div class="stce-01">
                                                                        <div class="stce-01-img">
                                                                            <div class="imgt">
                                                                                <img src='/img/skill_tree_comon_111.png'>
                                                                                </img>                                                                       
                                                                            </div>
                                                                        </div>
                                                                        <div class="stce-01-level">
                                                                            <div class="stce-level-bef">
                                                                                <span class="stce-level-lv">Lv</span>
                                                                                <span class="stce-level-master"></span>
                                                                            </div>
                                                                            <span class="stce-level-point">1</span>
                                                                            
                                                                        </div>
                                                                </div>
                                                            </div>
                                                            <div class="stree-content">
                                                         
                                                            </div>
                                                            <div class="stree-content">
                                                                <div class="stce-01">
                                                                    <div class="stce-01-img">
                                                                        <div class="imgt">
                                                                            <img src='/img/skill_tree_comon_112.png'>
                                                                            </img>                                                                       
                                                                        </div>
                                                                    </div>
                                                                    <div class="stce-01-level">
                                                                        <div class="stce-level-bef">
                                                                            <span class="stce-level-lv">Lv</span>
                                                                            <span class="stce-level-master"></span>
                                                                        </div>
                                                                        <span class="stce-level-point">0</span>
                                                                        
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="stree-content">
                                                            </div>
                                                            <div class="stree-content">
                                                            </div>
                                                            <div class="stree-content">
                                                            </div>
                                                            <div class="stree-content">
                                                            </div>
                                                            <div class="stree-content">
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                    <div class="stlev-ul-bc">
                                                  
                                                        <div class="stlev-ul">
                                                            <div class="stree-content stce-skill-off-mode">
                                                                <div class="stce-01">
                                                                    <div class="stce-01-img">
                                                                        <div class="imgt">
                                                                            <img src='/img/skill_tree_comon_113.png'>
                                                                            </img>                                                                       
                                                                        </div>
                                                                    </div>
                                                                    <div class="stce-01-level stce-master-toggle">
                                                                        <div class="stce-level-bef">
                                                                            <span class="stce-level-lv">Lv</span>
                                                                            <span class="stce-level-master"></span>
                                                                        </div>
                                                                        <span class="stce-level-point">110</span>
                                                                        
                                                                    </div>
                                                                
                                                                </div>
                                                            </div>
                                                            <div class="stree-content stce-skill-off-mode">
                                                                <div class="stce-01">
                                                                    <div class="stce-01-img">
                                                                        <div class="imgt">
                                                                            <img src='/img/skill_tree_comon_114.png'>
                                                                            </img>                                                                       
                                                                        </div>
                                                                    </div>
                                                                    <div class="stce-01-level stce-master-toggle">
                                                                        <div class="stce-level-bef">
                                                                            <span class="stce-level-lv">Lv</span>
                                                                            <span class="stce-level-master"></span>
                                                                        </div>
                                                                        <span class="stce-level-point">110</span>
                                                                        
                                                                    </div>
                                                                
                                                                </div>
                                                            </div>
                                                            <div class="stree-content stce-skill-off-mode">
                                                                <div class="stce-01">
                                                                    <div class="stce-01-img">
                                                                        <div class="imgt">
                                                                            <img src='/img/skill_tree_comon_115.png'>
                                                                            </img>                                                                       
                                                                        </div>
                                                                    </div>
                                                                    <div class="stce-01-level stce-master-toggle">
                                                                        <div class="stce-level-bef">
                                                                            <span class="stce-level-lv">Lv</span>
                                                                            <span class="stce-level-master"></span>
                                                                        </div>
                                                                        <span class="stce-level-point">110</span>
                                                                        
                                                                    </div>
                                                                
                                                                </div>
                                                            </div>
                                                            <div class="stree-content">
                                                                <div class="stce-01">
                                                                        <div class="stce-01-img">
                                                                            <div class="imgt">
                                                                                <img src='/img/skill_tree_comon_116.png'>
                                                                                </img>                                                                       
                                                                            </div>
                                                                        </div>
                                                                        <div class="stce-01-level">
                                                                            <div class="stce-level-bef">
                                                                                <span class="stce-level-lv">Lv</span>
                                                                                <span class="stce-level-master"></span>
                                                                            </div>
                                                                            <span class="stce-level-point">1</span>
                                                                            
                                                                        </div>
                                                                </div>
                                                            </div>
                                                            <div class="stree-content">
                                                         
                                                            </div>
                                                            <div class="stree-content">
                                                                <div class="stce-01">
                                                                    <div class="stce-01-img">
                                                                        <div class="imgt">
                                                                            <img src='/img/skill_tree_comon_117.png'>
                                                                            </img>                                                                       
                                                                        </div>
                                                                    </div>
                                                                    <div class="stce-01-level">
                                                                        <div class="stce-level-bef">
                                                                            <span class="stce-level-lv">Lv</span>
                                                                            <span class="stce-level-master"></span>
                                                                        </div>
                                                                        <span class="stce-level-point">0</span>
                                                                        
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="stree-content">
                                                                <div class="stce-01">
                                                                    <div class="stce-01-img">
                                                                        <div class="imgt">
                                                                            <img src='/img/skill_tree_comon_118.png'>
                                                                            </img>                                                                       
                                                                        </div>
                                                                    </div>
                                                                    <div class="stce-01-level">
                                                                        <div class="stce-level-bef">
                                                                            <span class="stce-level-lv">Lv</span>
                                                                            <span class="stce-level-master"></span>
                                                                        </div>
                                                                        <span class="stce-level-point">0</span>
                                                                        
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="stree-content">
                                                            </div>
                                                            <div class="stree-content">
                                                                <div class="stce-01">
                                                                    <div class="stce-01-img">
                                                                        <div class="imgt">
                                                                            <img src='/img/skill_tree_comon_120.png'>
                                                                            </img>                                                                       
                                                                        </div>
                                                                    </div>
                                                                    <div class="stce-01-level">
                                                                        <div class="stce-level-bef">
                                                                            <span class="stce-level-lv">Lv</span>
                                                                            <span class="stce-level-master"></span>
                                                                        </div>
                                                                        <span class="stce-level-point">0</span>
                                                                        
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="stree-content stce-skill-off-mode">
                                                                <div class="stce-01">
                                                                    <div class="stce-01-img">
                                                                        <div class="imgt">
                                                                            <img src='/img/skill_tree_comon_121.png'>
                                                                            </img>                                                                       
                                                                        </div>
                                                                    </div>
                                                                    <div class="stce-01-level">
                                                                        <div class="stce-level-bef">
                                                                            <span class="stce-level-lv">Lv</span>
                                                                            <span class="stce-level-master"></span>
                                                                        </div>
                                                                        <span class="stce-level-point">0</span>
                                                                        
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="stree-content stce-skill-off-mode">
                                                                <div class="stce-01">
                                                                    <div class="stce-01-img">
                                                                        <div class="imgt">
                                                                            <img src='/img/skill_tree_comon_122.png'>
                                                                            </img>                                                                       
                                                                        </div>
                                                                    </div>
                                                                    <div class="stce-01-level">
                                                                        <div class="stce-level-bef">
                                                                            <span class="stce-level-lv">Lv</span>
                                                                            <span class="stce-level-master"></span>
                                                                        </div>
                                                                        <span class="stce-level-point">0</span>
                                                                        
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                
                                                </div> 
                                                <div class="stree-tppoint">

                                                    <div class="stlev-ul-bc">
                                                        <div class="stlev-numlabel">
                                                            <span>TP</span>
                                                        </div>
                                                        <div class="stlev-ul">
                                                            <div class="stree-content stce-skill-off-mode">
                                                                <div class="stce-01">
                                                                    <div class="stce-01-img">
                                                                        <div class="imgt">
                                                                            <img src='/img/skill_tree_comon_19.png'>
                                                                            </img>                                                                       
                                                                        </div>
                                                                    </div>
                                                                    <div class="stce-01-level stce-master-toggle">
                                                                        <div class="stce-level-bef">
                                                                            <span class="stce-level-lv">Lv</span>
                                                                            <span class="stce-level-master"></span>
                                                                        </div>
                                                                        <span class="stce-level-point">110</span>
                                                                        
                                                                    </div>
                                                                
                                                                </div>
                                                            </div>
                                                            <div class="stree-content stce-skill-off-mode">
                                                                <div class="stce-01">
                                                                    <div class="stce-01-img">
                                                                        <div class="imgt">
                                                                            <img src='/img/skill_tree_comon_110.png'>
                                                                            </img>                                                                       
                                                                        </div>
                                                                    </div>
                                                                    <div class="stce-01-level stce-master-toggle">
                                                                        <div class="stce-level-bef">
                                                                            <span class="stce-level-lv">Lv</span>
                                                                            <span class="stce-level-master"></span>
                                                                        </div>
                                                                        <span class="stce-level-point">110</span>
                                                                        
                                                                    </div>
                                                                
                                                                </div>
                                                            </div>
                                                            <div class="stree-content">
                                                              
                                                            </div>
                                                            <div class="stree-content">
                                                                <div class="stce-01">
                                                                        <div class="stce-01-img">
                                                                            <div class="imgt">
                                                                                <img src='/img/skill_tree_comon_111.png'>
                                                                                </img>                                                                       
                                                                            </div>
                                                                        </div>
                                                                        <div class="stce-01-level">
                                                                            <div class="stce-level-bef">
                                                                                <span class="stce-level-lv">Lv</span>
                                                                                <span class="stce-level-master"></span>
                                                                            </div>
                                                                            <span class="stce-level-point">1</span>
                                                                            
                                                                        </div>
                                                                </div>
                                                            </div>
                                                            <div class="stree-content">
                                                         
                                                            </div>
                                                            <div class="stree-content">
                                                                <div class="stce-01">
                                                                    <div class="stce-01-img">
                                                                        <div class="imgt">
                                                                            <img src='/img/skill_tree_comon_112.png'>
                                                                            </img>                                                                       
                                                                        </div>
                                                                    </div>
                                                                    <div class="stce-01-level">
                                                                        <div class="stce-level-bef">
                                                                            <span class="stce-level-lv">Lv</span>
                                                                            <span class="stce-level-master"></span>
                                                                        </div>
                                                                        <span class="stce-level-point">0</span>
                                                                        
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="stree-content">
                                                            </div>
                                                            <div class="stree-content">
                                                            </div>
                                                            <div class="stree-content">
                                                            </div>
                                                            <div class="stree-content">
                                                            </div>
                                                            <div class="stree-content">
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                    {
                                        /*
                                    }
                                    <div class="tab__content" name="시뮬레이션">
                                        <Simulation chtbase={chtbase}/>
                                        
                                    </div>
                                    {
                                        */
                                    }
                                    
                                    {

                                        chtbase && chtbase.buffCal != null ? 
                                        <div class="tab__content" name="버프계산">
                                        <div class="bufval">
                                            <div class="hd-part">
                                                <div class="demval">
                                                    <div class="synergyp">
                                                        <div class="buffpoint-box" name="'hrt' 떼면 편애 빨간점 사라짐">
                                                            <span class="dvtit buff">{chtbase && chtbase.buffCal[chtbase.buffCal.length-1]['3PBuffScore'] ? "2인 점수": "버프 점수"}</span>
                                                            <span class="dval">
                                                                {chtbase && chtbase.buffCal[chtbase.buffCal.length-1].buffScore}
                                                            </span>
                                                        </div>
                                                        {
                                                            chtbase && chtbase.buffCal[chtbase.buffCal.length-1]['3PBuffScore'] ?
                                                                <div class="buffpoint-box">
                                                                <span class="dvtit buff secend">{chtbase && chtbase.buffCal[chtbase.buffCal.length-1]['3PBuffScore'] ? "3인 점수": "30Lv 점수"}</span>
                                                                <span class="dval secend">
                                                                    {chtbase && chtbase.buffCal[chtbase.buffCal.length-1]['3PBuffScore']}
                                                                </span>
                                                            </div> : <></>
                                                        }

                                                        {
                                                            chtbase && chtbase.buffCal[chtbase.buffCal.length-1]['4PBuffScore'] ?
                                                                <div class="buffpoint-box">
                                                                <span class="dvtit buff secend">{chtbase && chtbase.buffCal[chtbase.buffCal.length-1]['4PBuffScore'] ? "4인 점수": "30Lv 점수"}</span>
                                                                <span class="dval secend">
                                                                    {chtbase && chtbase.buffCal[chtbase.buffCal.length-1]['4PBuffScore']}
                                                                </span>
                                                            </div> : <></>
                                                        }
                                                         {
                                                            chtbase && chtbase.buffCal[chtbase.buffCal.length-1]['30LvBuffScore'] ?
                                                                <div class="buffpoint-box">
                                                                <span class="dvtit buff secend">{chtbase && chtbase.buffCal[chtbase.buffCal.length-1]['30LvBuffScore'] ? "30Lv 점수": ""}</span>
                                                                <span class="dval secend">
                                                                    {chtbase && chtbase.buffCal[chtbase.buffCal.length-1]['30LvBuffScore']}
                                                                </span>
                                                            </div> : <></>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div class="bufh-part">
                                                <div class="tr-heg buftop">
                                                    <div class="sk tr">
                                                        <div class="th">
                                                            <span>
                                                                <div class="imgt">
                                                                    <img src={skillImage[chtbase.buffCal[0].buffSkillName]}/>
                                                                </div>
                                                            </span>
                                                        </div>
                                                        <div class="td">
                                                            <div class="skn">
                                                                <span>{chtbase && chtbase.buffCal[0].buffSkillName}</span>
                                                                <span class="heart">
                                                                    {chtbase && chtbase.buffCal[0].passName}
                                                                </span>
                                                            </div>
                                                            <div class="bufstat hrt">
                                                                <ul>
                                                                    <li class="skl">
                                                                        <span class="stit">
                                                                            스탯
                                                                        </span>
                                                                        <span class="val">
                                                                            {chtbase && chtbase.buffCal[0].stat}
                                                                        </span>
                                                            
                                                                    </li>
                                                                    <li class="skl">
                                                                        <span class="stit">
                                                                            물공
                                                                        </span>
                                                                        <span class="val">
                                                                            {chtbase && chtbase.buffCal[0].phys}
                                                                        </span>
                                                                    </li>
                                                                    <li class="skl">
                                                                        <span class="stit">
                                                                            마공
                                                                        </span>
                                                                        <span class="val">
                                                                            {chtbase && chtbase.buffCal[0].mag}
                                                                        </span>
                                                                    </li>
                                                                    <li class="skl">
                                                                        <span class="stit">
                                                                            독공
                                                                        </span>
                                                                        <span class="val">
                                                                            {chtbase && chtbase.buffCal[0].fixed}
                                                                        </span>
                                                                    </li>
                                                                    
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {
                                                        chtbase && chtbase.buffCal && buffcalArray.map((a, i) =>
                                                            {
                                                                if(chtbase.buffCal[i] == null)
                                                                {
                                                                    return;
                                                                }
                                                                if(chtbase.buffCal[i].buffSkillName == null)
                                                                {
                                                                    return;
                                                                }
                                                                if(chtbase.buffCal[i].buffSkillName == "총 합")
                                                                {
                                                                    return;
                                                                }
                                                                if(chtbase.baseJob == "프리스트(남)")
                                                                {
                                                                    if(i == 3 || i == 5)
                                                                    {
                                                                        return;
                                                                    }
                                                                    
                                                                    //console.log(chtbase.buffCal[i]);
                                                                    //console.log(i);

                                                                }

                                                                return(
                                                                    <div class="sk tr">
                                                                        <div class="th">
                                                                            <span>
                                                                                <div class="imgt">
                                                                                    <img src={skillImage[chtbase.buffCal[i].buffSkillName]}/>
                                                                                </div>
                                                                            </span>
                                                                        </div>
                                                                        <div class="td">
                                                                            <div class="skn">
                                                                                <span>{chtbase && chtbase.buffCal[i].buffSkillName}</span>
                                                                                {
                                                                                    chtbase && chtbase.buffCal[i].passName != null ?
                                                                                    <span class="heart">
                                                                                        {chtbase.buffCal[i].passName}
                                                                                    </span> : <></>
                                                                                }
                                                                                
                                                                            </div>
                                                                            <div class="bufstat">
                                                                                <ul>
                                                                                    <li class="skl">
                                                                                        <span class="stit">
                                                                                            스탯
                                                                                        </span>
                                                                                        <span class="val">
                                                                                            {chtbase && chtbase.buffCal[i].stat}
                                                                                        </span>
                                                                            
                                                                                    </li>

                                                                                    
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                        )
                                                    }
                                                </div>

                                                <div class="tr-heg allp">
                                                    <div class="sk tr">
                                                        <div class="th">
                                                            <span>
                                                                <div class="imgt">
                                                                    
                                                                </div>
                                                            </span>
                                            
                                                        </div>
                                                        <div class="td">
                                                            <div class="skn">
                                                                <span>{chtbase && chtbase.buffCal[chtbase.baseJob == "프리스트(남)" ? 7 : 5].buffSkillName}</span>
                                                                <span class="heart">
                                                                    {chtbase && chtbase.buffCal[chtbase.baseJob == "프리스트(남)" ? 7 : 5].passName}
                                                                </span>
                                                            </div>
                                                            <div class="bufstat hrt">
                                                                <ul>
                                                                    <li class="skl">
                                                                        <span class="stit">
                                                                            스탯
                                                                        </span>
                                                                        <span class="val">
                                                                            {chtbase && chtbase.buffCal[chtbase.baseJob == "프리스트(남)" ? 7 : 5].stat}
                                                                        </span>
                                                            
                                                                    </li>
                                                                    <li class="skl">
                                                                        <span class="stit">
                                                                            물공
                                                                        </span>
                                                                        <span class="val">
                                                                            {chtbase && chtbase.buffCal[chtbase.baseJob == "프리스트(남)" ? 7 : 5].phys}
                                                                        </span>
                                                                    </li>
                                                                    <li class="skl">
                                                                        <span class="stit">
                                                                            마공
                                                                        </span>
                                                                        <span class="val">
                                                                            {chtbase && chtbase.buffCal[chtbase.baseJob == "프리스트(남)" ? 7 : 5].mag}
                                                                        </span>
                                                                    </li>
                                                                    <li class="skl">
                                                                        <span class="stit">
                                                                            독공
                                                                        </span>
                                                                        <span class="val">
                                                                            {chtbase && chtbase.buffCal[chtbase.baseJob == "프리스트(남)" ? 7 : 5].fixed}
                                                                        </span>
                                                                    </li>
                                                                    
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="sk tr">
                                                        <div class="th">
                                                            <span>
                                                                <div class="imgt">
                                                                </div>
                                                            </span>
                                                        </div>
                                                        <div class="td">
                                                            <div class="skn">
                                                        
                                                            </div>
                                                            <div class="bufstat">
                                                                <ul>
                                                                    <li class="skl">
                                                                        <span class="stit">
                                                                            스탯
                                                                        </span>
                                                                        <span class="val">
                                                                            {chtbase && chtbase.buffCal[chtbase.baseJob == "프리스트(남)" ? 8 : 6].stat}
                                                                        </span>
                                                            
                                                                    </li>
                                                                    <li class="skl">
                                                                        <span class="stit">
                                                                            물공
                                                                        </span>
                                                                        <span class="val">
                                                                            {chtbase && chtbase.buffCal[chtbase.baseJob == "프리스트(남)" ? 8 : 6].phys}
                                                                        </span>
                                                                    </li>
                                                                    <li class="skl">
                                                                        <span class="stit">
                                                                            마공
                                                                        </span>
                                                                        <span class="val">
                                                                            {chtbase && chtbase.buffCal[chtbase.baseJob == "프리스트(남)" ? 8 : 6].mag}
                                                                        </span>
                                                                    </li>
                                                                    <li class="skl">
                                                                        <span class="stit">
                                                                            독공
                                                                        </span>
                                                                        <span class="val">
                                                                            {chtbase && chtbase.buffCal[chtbase.baseJob == "프리스트(남)" ? 8 : 6].fixed}
                                                                        </span>
                                                                    </li>
                                                                    
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="bufinfo-part">
                                                <div class="bufinfo-tit" name="버프 정보 (표기수치)">
                                                    <span>버프 정보 (표기 수치)</span>
                                                </div>
                                                <div class="sk tr">
                                                    <div class="th">
                                                        <span>
                                                            <div class="imgt">
                                                                <img src={skillImage[chtbase.buffInfo[0].buffSkillName]}/>
                                                            </div>
                                                        </span>
                                                    </div>
                                                    <div class="td">
                                                        <div class="skn">
                                                            <span>{chtbase && chtbase.buffInfo[0].buffSkillName}</span>
                                                            <span class="infostat">스탯 {chtbase && chtbase.buffInfo[0].buffSkillStat} </span>

                                                        </div>
                                                        <div class="bufstat">
                                                            <ul>
                                                                <li class="skl">
                                                                    <span class="stit">
                                                                        스탯
                                                                    </span>
                                                                    <span class="val">
                                                                        {chtbase && chtbase.buffInfo[0].stat}	
                                                                    </span>
                                                        
                                                                </li>
                                                                <li class="skl">
                                                                    <span class="stit">
                                                                        물공
                                                                    </span>
                                                                    <span class="val">
                                                                        {chtbase && chtbase.buffInfo[0].phys}
                                                                    </span>
                                                                </li>
                                                                <li class="skl">
                                                                    <span class="stit">
                                                                        마공
                                                                    </span>
                                                                    <span class="val">
                                                                        {chtbase && chtbase.buffInfo[0].mag}	
                                                                    </span>
                                                                </li>
                                                                <li class="skl">
                                                                    <span class="stit">
                                                                        독공
                                                                    </span>
                                                                    <span class="val">
                                                                        {chtbase && chtbase.buffInfo[0].fixed}
                                                                    </span>
                                                                </li>
                                                                
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>

                                                
                                                {
                                                    chtbase && chtbase.buffInfo.map((a, i) =>
                                                            {
                                                                


                                                                return(
                                                                    <div class="sk tr"  key={i}>
                                                                        <div class="th">
                                                                            <span>
                                                                                <div class="imgt">
                                                                                    <img src={skillImage[chtbase.buffInfo[i].buffSkillName]}/>
                                                                                </div>
                                                                            </span>
                                                                        </div>
                                                                        <div class="td">
                                                                            <div class="skn">
                                                                                <span>{chtbase && chtbase.buffInfo[i].buffSkillName}</span>
                                                                                <span class="infostat">스탯 {chtbase && chtbase.buffInfo[i].buffSkillStat} </span>

                                                                            </div>
                                                                            <div class="bufstat">
                                                                                <ul>
                                                                                    <li class="skl">
                                                                                        <span class="stit">
                                                                                            스탯
                                                                                        </span>
                                                                                        <span class="val">
                                                                                        {chtbase && chtbase.buffInfo[i].stat}
                                                                                        </span>
                                                                            
                                                                                    </li>

                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                    )
                                                }
                                            </div>
                                        </div>

                                    </div> : ""
                                    }
                                    
                                    </div>
                                </div>
                            </div>
                </div>

                
            </section>

            <div id="ad-container-2" />

        </div>
        
        )}


        function cuttingSkillName(skillNmae)
        {
            var image = "";

            if(skillNmae.indexOf('회') != -1)
            {
                if(skillImage[skillNmae] != null)
                {
                    image = skillNmae;
                    return image;
                }

                try
                {
                    if(isNaN(Number(skillNmae.charAt(skillNmae.lastIndexOf('회')-1))))
                    {
                        image = skillNmae;
                    }
                    else if(skillNmae.charAt(skillNmae.lastIndexOf('회')-2) == ' ')
                    {
                        image = skillNmae.substring(0,skillNmae.lastIndexOf('회')-2);
                    }
                    else if(skillNmae.charAt(skillNmae.lastIndexOf('회')-3) == ' ')
                    {
                        image = skillNmae.substring(0,skillNmae.lastIndexOf('회')-3);
                    }
                    else if(skillNmae.charAt(skillNmae.lastIndexOf('회')-4) == ' ')
                    {
                        image = skillNmae.substring(0,skillNmae.lastIndexOf('회')-4);
                    }
                    else
                    {
                        image = skillNmae;
                    }
                }
                catch(error)
                {
                    image = skillNmae;
                }
            }
            else
            {	
                image = skillNmae;
            }

            return image.replace(' 방생','');
        }

        function encodeQueryData(data) {
            const ret = [];
            for (let d in data)
              ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
            return ret.join('&');
         }

        function AllDamage(props)
        {
            let chtbase = props.chtbase;
            let daelCount = props.daelCount;
            let type = props.name;
            let compare = 0;
            let mino = false;
            
            if(chtbase.damageList[type][daelCount].compare != null)
            {
                if(chtbase.damageList[type][daelCount].compare.replaceAll != null)
                {
                    let dam = Number(chtbase.damageList[type][daelCount].dam.replaceAll(',',''));
                    let compareDam = Number(chtbase.damageList[type][daelCount].compare.replaceAll(',',''));

                    compare = ( Math.max(compareDam,dam) / Math.min(compareDam,dam) - 1) * 100.0;

                    if(dam >= compareDam)
                    {
                        mino = true;
                    }
                }
                
            }
            return(
                <div class="sk tr">
                    <div class="th">
                        <span>
                            <div class="imgt">
                            </div>
                        </span>
                    </div>
                    <div class="td">
                        <div class="skn">
                            <span>{chtbase.damageList[type][daelCount].name}</span>
                        </div>
                        <div class="skc">
                            <ul>
                                <li class="skl">
                                    <span class="val">
                                    {chtbase.damageList[type][daelCount].dam}
                                    </span>
                                    {
                                        chtbase.damageList[type][daelCount].compare != null ? 
                                        <span className={"ser-percent " + ( mino ? 'plus': 'mino') }>
                                            {compare.toFixed(2)}%
                                        </span>
                                        : <></>

                                    }
                                
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            )
        }

        function Damages(props)
        {
            let chtbase = props.chtbase;
            let daelCount = props.daelCount;
            let type = props.name;
            return(
                chtbase && chtbase.damageList[type]
                .map((a, i) =>
                {
                    
                    let share = ((Number(chtbase.damageList[type][i].dam.replaceAll(',',''))
                    / Number(chtbase.damageList[type][daelCount].dam.replaceAll(',','')  ) ) * 100.0);

                    let compare = 0;
                    let mino = false;
                    if(chtbase.damageList[type][i].compare != null)
                    {
                        if(chtbase.damageList[type][i].compare.replaceAll != null)
                        {
                            let dam = Number(chtbase.damageList[type][i].dam.replaceAll(',',''));
                            let compareDam = Number(chtbase.damageList[type][i].compare.replaceAll(',',''));
        
                            compare = ( Math.max(compareDam,dam) / Math.min(compareDam,dam) - 1) * 100.0;

                            if(dam >= compareDam)
                            {
                                mino = true;
                            }
                        }
                    }
                if(i<daelCount)
                    return(
                    <div class="sk tr">
                        <div class="th">
                            <span>
                                <div class="imgt">
                                    <img src={getSkillImageURL(cuttingSkillName(chtbase.damageList[type][i].name),chtbase.baseJob)}/>
                                </div>
                            </span>
                        </div>
                        <div class="td">
                            <div class="skn">
                                <span>{chtbase.damageList[type][i].name}</span>
                            </div>
                            
                            {
                                chtbase.damageList[type][i].count != null ? 
                                <div class="skcount">
                                    <div class="sc-count-con">
                                        <div class={"skc-count-c " + (chtbase.damageList[type][i].comparec != null ? (chtbase.damageList[type][i].comparec >= 0 ? "skc-plus" : "skc-minos") : "")}>
                                            <div class="skc-count-p"><span>{chtbase.damageList[type][i].count}회</span></div>
                                            {/* + 제거버전 + 있는버전은 모바일에서 가져옴
                                            
                                            {
                                                chtbase.damageList[type][i].comparec != null ? 
                                                <div class="skc-change-p"><span>{(chtbase.damageList[type][i].comparec != 0 ? chtbase.damageList[type][i].comparec : "")}</span></div> 
                                                : ""
                                            } 
                                            
                                            */}
                                            {
                                            chtbase.damageList[type][i].comparec != null ? 
                                            <div class="skc-change-p"><span>{(chtbase.damageList[type][i].comparec != 0 ? (chtbase.damageList[type][i].comparec >= 0 ? "+"+ chtbase.damageList[type][i].comparec : chtbase.damageList[type][i].comparec ) : "")}</span></div> 
                                            : ""
                                            }
                                            
                                        </div>
                                        
                                        
                                    </div>
                                </div>
                                :<></>
                            }
                            
                            
                            <div class="skc">
                                <ul>
                                    <li class="skl">
                                        <span class="val">
                                        {chtbase.damageList[type][i].dam}
                                        </span>

                                        {
                                            chtbase.damageList[type][i].compare != null ? 
                                            <span className={"ser-percent " + (mino ? 'plus': 'mino') }>
                                                {compare.toFixed(2)}%
                                            </span>
                                            : <></>

                                        }

                                        <span class="ser-percent share">
                                            {share.toFixed(2)}%
                                        </span>

                                    
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    )
                })
            )
        }
         

        function talismanColor(talisType)
        {
            var color = "";

            if(talisType.indexOf("테라코타") != -1)
            {
                color = 'purple'; 
                return color;
            }
            else if(talisType.indexOf("서클 메이지") != -1)
            {
                color = 'green'; 
                return color;
            }
            else if(talisType.indexOf("수호자들") != -1)
            {
                color = 'dodgerblue'; 
                return color;
            }
            else if(talisType.indexOf("고대 도서관") != -1)
            {
                color = 'yellow'; 
                return color;
            }
            else if(talisType.indexOf("세컨드 팩트") != -1)
            {
                color = 'red'; 
                return color;
            }

            return "";


        }
        function getSecondJobName(Jin_job, basejob)
        {
            let job = Jin_job;
            let baseJob = basejob;

            switch(Jin_job)
            {
                case "眞 소드마스터": job = "마제스티"; break;
                case "眞 데몬슬레이어": job = "디어사이드"; break;
                case "眞 베가본드": job = "검제"; break;
                case "眞 다크템플러": job = "네메시스"; break;
                case "眞 블레이드": job = "벤데타"; break;
                
                case "眞 넨마스터": if(baseJob.indexOf("(여)") != -1) job = "염제 폐월수화"; else job = "염황 광풍제월"; break;
                case "眞 그래플러": if(baseJob.indexOf("(여)") != -1) job = "얼티밋 디바"; else job = "그랜드 마스터"; break;
                case "眞 스트리트파이터": if(baseJob.indexOf("(여)") != -1) job = "용독문주";else  job = "명왕";  break;
                case "眞 스트라이커": if(baseJob.indexOf("(여)") != -1) job = "카이저"; else job = "패황"; break;
                
                case "眞 웨펀마스터": job = "검신"; break;
                case "眞 버서커": job = "블러드 이블"; break;
                case "眞 아수라": job = "인다라천"; break;
                case "眞 소울브링어": job = "다크로드"; break;
                case "眞 검귀": job = "악귀나찰"; break;
                

                case "眞 레인저": if(baseJob.indexOf("(여)") != -1) job = "크림슨 로제"; else job = "레이븐"; break;
                case "眞 런처": if(baseJob.indexOf("(여)") != -1) job = "스톰 트루퍼"; else job = "디스트로이어"; break;
                case "眞 메카닉": if(baseJob.indexOf("(여)") != -1) job = "옵티머스"; else job = "프라임"; break;
                case "眞 스핏파이어":if(baseJob.indexOf("(여)") != -1) job = "프레이야"; else  job = "커맨더"; break;
                case "眞 어썰트": job = "엑스마키나"; break;


                case "眞 크루세이더": if(baseJob.indexOf("(여)") != -1) job = "세라핌"; else job = "세인트"; break;
                case "眞 인파이터": job = "저스티스"; break;
                case "眞 퇴마사": job = "태을선인"; break;
                case "眞 어벤저": job = "이모탈"; break;
                
                case "眞 엘레멘탈마스터": job = "오버마인드"; break;
                case "眞 배틀메이지": job = "아슈타르테"; break;
                case "眞 마도학자": job = "지니위즈"; break;
                case "眞 소환사": job = "이클립스"; break;
                case "眞 인챈트리스": job = "헤카테"; break;
                case "블랙 메이든": job = "헤카테"; break;
                
                case "眞 미스트리스": job = "리디머"; break;
                case "眞 무녀": job = "천선낭랑"; break;
                case "眞 이단심판관": job = "인페르노"; break;
                
                case "眞 로그": job = "알키오네"; break;
                case "眞 사령술사": job = "타나토스"; break;
                case "眞 섀도우댄서": job = "그림리퍼"; break;
                case "眞 쿠노이치": job = "시라누이"; break;
                
                case "眞 스위프트 마스터": job = "아이올로스"; break;
                case "眞 블러드 메이지": job = "뱀파이어 로드"; break;
                case "眞 엘레멘탈 바머": job = "오블리비언"; break;
                case "眞 디멘션워커": job = "어센션"; break;
                case "眞 빙결사": job = "이터널"; break;
                

                case "眞 팔라딘": job = "세이비어"; break;
                case "眞 엘븐나이트": job = "가이아"; break;
                case "眞 드래곤나이트": job = "드레드노트"; break;
                case "眞 카오스": job = "마신"; break;
                

                case "眞 뱅가드": job = "워로드"; break;
                case "眞 듀얼리스트": job = "듀란달"; break;
                case "眞 다크 랜서": job = "에레보스"; break;
                case "眞 드래고니안 랜서": job = "제노사이더"; break;
                
                case "眞 요원": job ="레퀴엠"; break;
                case "眞 트러블 슈터": job = "언터처블"; break;
                case "眞 스페셜리스트":job = "패스파인더"; break;
                case "眞 히트맨": job = "갓파더"; break;
            
                case "眞 다크나이트": job = "다크나이트(자각2)"; break;
                case "眞 크리에이터": job = "크리에이터(자각2)"; break;
                
                case "眞 뮤즈": job ="트렌드세터"; break;
                case "眞 트래블러": job ="셀레스티얼"; break;
                case "眞 헌터": job ="메이븐"; break;
                case "眞 비질란테": job ="프레데터"; break;
                
                
            }
            console.log("직업 : " + Jin_job);
            return job;
        }
        function talismanToSkillName(Jin_job,talisName, basejob)
        {
            let job = Jin_job;
            let baseJob = basejob;

            switch(Jin_job)
            {
                case "眞 소드마스터": job = "마제스티"; break;
                case "眞 데몬슬레이어": job = "디어사이드"; break;
                case "眞 베가본드": job = "검제"; break;
                case "眞 다크템플러": job = "네메시스"; break;
                case "眞 블레이드": job = "벤데타"; break;
                
                case "眞 넨마스터": if(baseJob.indexOf("(여)") != -1) job = "염제 폐월수화"; else job = "염황 광풍제월"; break;
                case "眞 그래플러": if(baseJob.indexOf("(여)") != -1) job = "얼티밋 디바"; else job = "그랜드 마스터"; break;
                case "眞 스트리트파이터": if(baseJob.indexOf("(여)") != -1) job = "용독문주";else  job = "명왕";  break;
                case "眞 스트라이커": if(baseJob.indexOf("(여)") != -1) job = "카이저"; else job = "패황"; break;
                
                case "眞 웨펀마스터": job = "검신"; break;
                case "眞 버서커": job = "블러드 이블"; break;
                case "眞 아수라": job = "인다라천"; break;
                case "眞 소울브링어": job = "다크로드"; break;
                case "眞 검귀": job = "악귀나찰"; break;
                

                case "眞 레인저": if(baseJob.indexOf("(여)") != -1) job = "크림슨 로제"; else job = "레이븐"; break;
                case "眞 런처": if(baseJob.indexOf("(여)") != -1) job = "스톰 트루퍼"; else job = "디스트로이어"; break;
                case "眞 메카닉": if(baseJob.indexOf("(여)") != -1) job = "옵티머스"; else job = "프라임"; break;
                case "眞 스핏파이어":if(baseJob.indexOf("(여)") != -1) job = "프레이야"; else  job = "커맨더"; break;
                case "眞 어썰트": job = "엑스마키나"; break;


                case "眞 크루세이더": if(baseJob.indexOf("(여)") != -1) job = "세라핌"; else job = "세인트"; break;
                case "眞 인파이터": job = "저스티스"; break;
                case "眞 퇴마사": job = "태을선인"; break;
                case "眞 어벤저": job = "이모탈"; break;
                
                case "眞 엘레멘탈마스터": job = "오버마인드"; break;
                case "眞 배틀메이지": job = "아슈타르테"; break;
                case "眞 마도학자": job = "지니위즈"; break;
                case "眞 소환사": job = "이클립스"; break;
                case "眞 인챈트리스": job = "헤카테"; break;
                case "블랙 메이든": job = "헤카테"; break;
                
                case "眞 미스트리스": job = "리디머"; break;
                case "眞 무녀": job = "천선낭랑"; break;
                case "眞 이단심판관": job = "인페르노"; break;
                
                case "眞 로그": job = "알키오네"; break;
                case "眞 사령술사": job = "타나토스"; break;
                case "眞 섀도우댄서": job = "그림리퍼"; break;
                case "眞 쿠노이치": job = "시라누이"; break;
                
                case "眞 스위프트 마스터": job = "아이올로스"; break;
                case "眞 블러드 메이지": job = "뱀파이어 로드"; break;
                case "眞 엘레멘탈 바머": job = "오블리비언"; break;
                case "眞 디멘션워커": job = "어센션"; break;
                case "眞 빙결사": job = "이터널"; break;
                

                case "眞 팔라딘": job = "세이비어"; break;
                case "眞 엘븐나이트": job = "가이아"; break;
                case "眞 드래곤나이트": job = "드레드노트"; break;
                case "眞 카오스": job = "마신"; break;
                

                case "眞 뱅가드": job = "워로드"; break;
                case "眞 듀얼리스트": job = "듀란달"; break;
                case "眞 다크 랜서": job = "에레보스"; break;
                case "眞 드래고니안 랜서": job = "제노사이더"; break;
                
                case "眞 요원": job ="레퀴엠"; break;
                case "眞 트러블 슈터": job = "언터처블"; break;
                case "眞 스페셜리스트":job = "패스파인더"; break;
                case "眞 히트맨": job = "갓파더"; break;
            
                case "眞 다크나이트": job = "다크나이트(자각2)"; break;
                case "眞 크리에이터": job = "크리에이터(자각2)"; break;
                
                case "眞 뮤즈": job ="트렌드세터"; break;
                case "眞 트래블러": job ="셀레스티얼"; break;
                case "眞 헌터": job ="메이븐"; break;
                case "眞 비질란테": job ="프레데터"; break;
                
                
            }
            

            if(talisName == null)
            {
                return;
            }
            let skillName = '';
            if(talisName.indexOf('[') != -1)
            {
                skillName = talisName.split('[')[1].split(']')[0];
            }
            else
            {
                
                let stop = false;
                for(let loop = 0 ; loop < talismanList[job].length ; loop++)
                {
                    if(talismanList[job][loop] == talisName)
                    {
                        stop = true;
                    }

                    if(stop)
                    if(talismanList[job][loop].indexOf('[') != -1)
                    {
                        skillName = talismanList[job][loop].split('[')[1].split(']')[0];
                        break;
                    }


                }
            }
            return skillName;
        }
        class Node {
            constructor(skillName, time,skillUseTime) 
            {
                this.skillName = skillName;
                this.time = time;
                this.skillUseTime = skillUseTime;
            }
        }
        let coolMap = new Map();
        let skillUseTimeMap = new Map();
        let skillDamageMap = new Map();
        function auto_simulation(chtbase)
        {
            if(coolMap.size == 0)
            {
                for(let loop of chtbase.skillInfo)
                {
                    coolMap.set(loop.skillName,loop.skillCool);
                    skillUseTimeMap.set(loop.skillName,loop.skillCoolde);
                }

                if(chtbase.damageList != null)
                for(let loop of chtbase.damageList.vsRanking)
                {
                    skillDamageMap.set(loop.name,Number(loop.dam.replaceAll(',',''))/loop.count);
                }
            }

            let name = "";
            let min = 10000;
            for(let loop of simulationNextMap.keys())
            {
                if(loop== "대기")
                {
                    continue;
                }

                if(simulationTime >= simulationNextMap.get(loop) )
                {
                    add_simulation(loop,coolMap.get(loop),skillUseTimeMap.get(loop));
                    return;
                }
                let temp = min;
                min = Math.min(min,simulationNextMap.get(loop));
                if(min != temp) { name = loop; }
            }
            console.log(name);
            console.log(min);
            add_simulation(name,coolMap.get(name),skillUseTimeMap.get(name),min);
            

        }

        function clear_simulation()
        {
            setSimulationNextMap((prev) => new Map(prev.clear()));
            setSimulationCountMap((prev) => new Map(prev.clear()));
            setSimulation([]);
            setSimulationTime(0.0);
        }
        function add_simulation(skillName,cooltime,skillUseTime,jumpTime = 0)
        {
            if(simulationTime > 40 || jumpTime > 40 )
            {
                return;
            }
            if(jumpTime == 0)
            {
                for(let loop of simulation)
                {
                    if(skillName == loop.skillName)
                    {
                        if(simulationTime <= Number(loop.time) + Number(cooltime) )
                        {
                            return;
                        }
                    }
                }
            }

            let time = jumpTime == 0 ? simulationTime : jumpTime;
            console.log(Number(jumpTime == 0 ? simulationTime : jumpTime) + Number(cooltime))
            let temp = [...simulation];
            console.log(temp);
            const node = new Node(skillName,time,skillUseTime);
            temp.push(node);

            setSimulationNextMap((prev) => new Map([...prev, [skillName, Number(time) + Number(cooltime)]]));
            setSimulationCountMap((prev) => new Map([...prev, [skillName, simulationCountMap.get(skillName) >= 0 ? simulationCountMap.get(skillName)+1 : 1]]));
            setSimulation(temp);
            setSimulationTime(Number(time) + Number(skillUseTime));
            
        }
        function Simulation(props)
        {
            let chtbase = props.chtbase;
            return(
                <div class="skinfo">
                    {
                    chtbase && chtbase.skillInfo.map((a, i) =>
                        {
                        return(
                            <>
                            <div style={{"display": "flex"}} >
                                <span >
                                    <div class="imgt" style={{"width": "40px"}} onClick={()=>{add_simulation(chtbase.skillInfo[i].skillName,chtbase.skillInfo[i].skillCool,chtbase.skillInfo[i].skillCoolde)}}>
                                        <img src={skillImage[chtbase.skillInfo[i].skillName] != null
                                            ? skillImage[chtbase.skillInfo[i].skillName] : ""}/>
                                    </div>
                                </span>
                                
                                <span style={{"margin-right": "10px"}}>
                                    쿨타임 : {chtbase.skillInfo[i].skillCool}     
                                </span>
                                <span style={{"margin-right": "10px"}}>
                                    시전시간 : {chtbase.skillInfo[i].skillCoolde}    
                                </span>
                                <span style={{"margin-right": "10px"}}>
                                    Next : {simulationNextMap.get(chtbase.skillInfo[i].skillName)}    
                                </span>
                                <span style={{"margin-right": "10px"}}>
                                    Count : {simulationCountMap.get(chtbase.skillInfo[i].skillName)}    
                                </span>
                                <span style={{"margin-right": "10px"}}>
                                    DAM : { simulationCountMap.get(chtbase.skillInfo[i].skillName) * skillDamageMap.get(chtbase.skillInfo[i].skillName)}    
                                </span>
                            </div>
                            
                            </>
                        )}
                    )}

                    <div style={{"display": "flex","min-height":"40px"}} >
                        <span >
                            <div class="imgt" style={{"width": "40px"}} onClick={()=>{auto_simulation(chtbase)}}>
                                Auto
                            </div>
                        </span>
                        
                    </div>
                    <div style={{"display": "flex","min-height":"40px"}} >
                        <span >
                            <div class="imgt" style={{"width": "40px"}} onClick={()=>{add_simulation("대기",0,0.1)}}>
                                대기
                            </div>
                        </span>
                        
                    </div>
                    <div style={{"display": "flex","min-height":"40px"}} >
                        <span >
                            <div class="imgt" style={{"width": "40px"}} onClick={()=>{clear_simulation()}}>
                                초기화
                            </div>
                        </span>
                        
                    </div>
                    
                    <span >
                        현재시간 : {simulationTime}
                    </span>
                    <div style={{"display": "inline-flex","flex-wrap":"wrap"}}>
                    {
                        // 타임스탬프
                    }
                    {
                        simulation && simulation.map((a, i) =>
                        {
                            return(
                                <span >
                                    <div class="imgt" style={{"width": simulation[i].skillUseTime * 100 + "px","min-height":"40px", "background":"yellow"}}>
                                        <img  style={{"width": "40px"}} src={skillImage[ simulation[i].skillName ] != null
                                            ? skillImage[simulation[i].skillName] : ""}/>
                                    </div>
                                </span>
                            )

                        })

                    }
                    
                    

                    </div>
                    

                </div>
            )

           
        }

        function getSkillImageURL(skillName, baseJob)
        {
            let temp = skillName;
            
            if((temp == "베놈 마인" || temp == "크레이지 발칸" || temp == "마운트") && baseJob == "격투가(여)")
            {
                temp = skillName+"(여)";
            }
            return skillImage[temp] == null ? "" : skillImage[temp];
        }
        function getItemImage(equip,loop)
        {
            if(equip.code != null)
            {
                let folderName = "weapon";
                switch(loop)
                {
                    case 2: folderName ="coat"; break;
                    case 4: folderName ="pants"; break;
                    case 3: folderName ="neck"; break;
                    case 6: folderName ="belt"; break;
                    case 5: folderName ="shoes"; break;

                    case 8: folderName ="bracelet"; break;
                    case 7: folderName ="necklace"; break;
                    case 9: folderName ="ring"; break;

                    case 10: folderName ="support"; break;
                    case 11: folderName ="magicstone"; break;
                    case 12: folderName ="earrring"; break;
                }
                return 'https://dundam.xyz/img/lv115/'+folderName+'/'+equip.code+'.png';
            }
            else
            {
                return 'https://img-api.neople.co.kr/df/items/'+equip.itemid;
            }
            
        }
        export default Character