
import './App.css';
import './mobile.css';


import "./css/dark_FIX.css";
import { createContext, useEffect, useState } from 'react';
import { Routes, Route, Link, useNavigate, Outlet } from 'react-router-dom'

import React from 'react';

import axios from 'axios';

import BuffRank from './pages/buff_ranking.js'
import DamageRank from './pages/damage_ranking.js'
import Main from './pages/main.js'
import Character from './pages/character.js' 
import Edit from './pages/edit'
import EqupEdit from './pages/epuip_edit.js'
import SearhResult from './pages/search_result.js'

import MainMO from './pages/mainMO.js'
import BuffRankMO from './pages/buff_rankingMO.js' 
import DamageRankMO from './pages/damage_ranking_MO.js'
import SearhResultMO from './pages/search_result_MO.js'
import CharacterMO from './pages/character_MO.js'
import EqupEditMO from './pages/equip_edit_MO.js'
import Ad from "./ad";
import RouteChangeTracker from "./RouteChangeTracker";

/*
css 관리부
*/

/* eslint-disable */
import { useMediaQuery } from 'react-responsive'
import { BrowserView, MobileView, isMobile } from 'react-device-detect'



function App() {


  RouteChangeTracker();

  useEffect(() => {
    const bgMode = window.localStorage.getItem("bgMode");
    if (bgMode === "dark") {
      document.getElementsByTagName("html")[0].classList.add("dark");
      setDarkMomdeType("dark");

    }
  }, []);


  const darkOnOff = () => {
    if (
      document.getElementsByTagName("html")[0].classList.contains("dark")
      
    ) {
      document.getElementsByTagName("html")[0].classList.remove("dark");
      window.localStorage.setItem("bgMode", "light");
      setDarkMomdeType("light");
    } else {
      document.getElementsByTagName("html")[0].classList.add("dark");
      window.localStorage.setItem("bgMode", "dark");
      setDarkMomdeType("dark");
    }
  };

  
  const [darkmodetype, setDarkMomdeType] = useState("");

  let [equipfilter, setEquipFilter] = useState(false);




  let navigate = useNavigate();
  let [navigateValue,setNavigateValue] = useState();
  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 992 })
    return isDesktop ? children : null
  }
  const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 })
    return isTablet ? children : null
  }
  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 })
    return isMobile ? children : null
  }
  const Default = ({ children }) => {
    const isNotMobile = useMediaQuery({ minWidth: 768 })
    return isNotMobile ? children : null
  }


  
  

  return (

    <div className="App">
          
          <BrowserView>
              
                  <div class="app_hug">
                    
                    <header className="App-header main-header" >
                      

                      <div className="headermenu" name="헤더 목록" >
                          <a onClick={()=>{navigate('/');setNavigateValue("main")}} className={navigateValue == "main" ? "menu-item is-active" : "menu-item"}>
                          캐릭터 검색
                          </a>
                          <a onClick={()=>{navigate('buff_ranking');setNavigateValue("buff_ranking")}}  className={navigateValue == "buff_ranking" ? "menu-item is-active" : "menu-item"} >
                          버프 랭킹
                          </a>
                          <a onClick={()=>{navigate('damage_ranking');setNavigateValue("damage_ranking")}} className={navigateValue == "damage_ranking" ? "menu-item is-active" : "menu-item"}>
                          딜러 랭킹
                          </a>
                          <div class="darkmode-button">
                              <div class="dmb-wrap">
                                  <div class="dmb">
                                      <button className={darkmodetype === 'dark' ? 'darkmode' : 'null'}onClick={darkOnOff} >
                                  
                                      </button>
                                      <div class="ballon">
                                      {darkmodetype === 'dark' ? '다크모드' : '라이트모드'}상태입니다. {"\n"}클릭시 {darkmodetype === 'dark' ? '라이트모드' : '다크모드'}로 바뀝니다.
                                      </div>
                                  </div>
                              </div>
                          </div>


                          <span className="menu-indicator"></span>
                      </div>    
                    </header>



                  

                    <Routes>
                      <Route path="/" element={ <Main darkmodetype={darkmodetype} />
                      } />
                      <Route path="/buff_ranking" element={ <BuffRank/>
                      }/>
                      <Route path="/damage_ranking" element={ <DamageRank/>
                      }/>
                      <Route path="/character" element={ <Character/>
                      }/>
                      <Route path="/search" element={ <SearhResult/>
                      }/>
                      <Route path="/equip_edit" element={<EqupEdit/>}
                      />
                      <Route path="/edit" element={<Edit/>}
                      />

                    </Routes>

                    <footer id="footer">
                        <div class="main-footer">
                            <div class="foop1">
                                <ul>
                      
                                    <li class="fapi">
                                        <a href="https://developers.neople.co.kr" target="_blank">

                                            <div class="imgt">
                                            </div>
                                        </a>

                                    </li>
                                    <li class="ftit">
                                        <div class="fti1">
                                        </div>
                                        <div class="fti2">
                                            <span class="pinfo"><a href="https://dundam.xyz/policy.html">개인정보 처리방침</a></span>

                                            <span>Copyright©  DUNDAM All rights reserved.</span>
                                        </div>

                                    </li>
                                
                              
                                </ul>
                            </div>
                        </div>
                    </footer>
              
                    
                  </div>

          </BrowserView>
          <MobileView>
              <div class="mo_app_hug">
              <footer className={"bottom-fix " + (equipfilter == true ? 'dn' : 'bc' )}>
                  <div className="bot-info">
                    <div class="foop1">
                      
                        <ul>
              
                            <li class="fapi">
                                <a>

                                    <div class="imgt">
                                    </div>
                                </a>

                            </li>
                            <li class="mftit">
                                <div class="mfti">
                                    <span class="pinfo"><a href="https://dundam.xyz/policy.html">개인정보 처리방침</a></span>
                                    <span className={darkmodetype === 'dark' ? 'dark' : 'light'}onClick={darkOnOff} >

                                    {darkmodetype === 'dark' ? '라이트모드' : '다크모드'}
                                    </span>
                                </div>
                                <div class="mfti">
                                <span>Copyright©  DUNDAM All rights reserved.</span>

                                </div>

                            </li>
                        
                      
                        </ul>
                    </div>
                  </div>
                  
                  {/* <div class="bot-menu">
                    <ul>
                      <li onClick={()=>{navigate('/damage_ranking');setNavigateValue("damage_ranking_MO")}}>
                        <div class="imgt ranking-svg">

                        </div>
                        <span class="bmenun">랭킹</span>
                      </li>
                      <li onClick={()=>{navigate('/buff_ranking');setNavigateValue("buff_ranking_MO")}}>
                        <div class="imgt ranking-svg">

                        </div>
                        <span class="bmenun">버프 랭킹</span>
                      </li>

 
                      <li onClick={()=>{navigate('/');setNavigateValue("main_MO")}}>
                        <div class="imgt home-svg">
                        </div>
                        <span class="bmenun">홈</span>
                      </li>
              
                
                    </ul>
                  </div> */}

                  <div class="top-menu">
                     
                      <div class="t-mu-con">
                        <div onClick={()=>{navigate('/');setNavigateValue("main_MO")}} class="topmec-img">
                        </div>
                        <div class="topmec-menu">
                          <ul>
                          <li onClick={()=>{navigate('/');setNavigateValue("main_MO")}}>캐릭터 검색</li>
                            <li onClick={()=>{navigate('/damage_ranking');setNavigateValue("damage_ranking_MO")}}>딜러 랭킹</li>
                            <li onClick={()=>{navigate('/buff_ranking');setNavigateValue("buff_ranking_MO")}}>버프 랭킹</li>
                            
                          </ul>
                        </div>
                      </div>

                      
                  </div>
              </footer>

                  <Routes>
                    <Route  path="/" element={ <MainMO   darkmodetype={darkmodetype} />} 
                    />

                    <Route path="/buff_ranking" element={ <BuffRankMO/>} 
                    />
                    <Route path="/damage_ranking" element={ <DamageRankMO equipfilter={equipfilter} setEquipFilter={setEquipFilter} />
                    } 
                    />
                    <Route path="/character" element={ <CharacterMO/>}
                    />
                    <Route path="/search" element={ <SearhResultMO/>
                    }/>
                    <Route path="/equip_edit" element={ <EqupEditMO/>}
                    />
                  </Routes>
                  

                            
               
              </div>
          </MobileView>
    </div>

  );

}


export default App;
