var lv115ItemId = [];

lv115ItemId['개시를 위한 준비'] = 'rare_sup'
lv115ItemId['개시의 역사가 담긴 신발'] = 'rare_shoes'
lv115ItemId['개시의 순간'] = 'rare_ring'
lv115ItemId['개시의 극기 훈련 하의'] = 'rare_pants'
lv115ItemId['개시를 위한 노력'] = 'rare_neck'
lv115ItemId['개시의 꺾이지 않는 마음'] = 'rare_ms'
lv115ItemId['개시를 전하는 목소리'] = 'rare_ear'
lv115ItemId['개시의 극기 훈련 상의'] = 'rare_coat'
lv115ItemId['개시의 마음가짐 로브'] = 'rare_c_neck'
lv115ItemId['개시를 알리는 벨'] = 'rare_brac'
lv115ItemId['개시의 전설 벨트'] = 'rare_belt'
lv115ItemId['확고한 행운의 마스크'] = '9_sup'
lv115ItemId['칠흑의 정화 반지'] = '9_ring'
lv115ItemId['자연의 폭주 신발'] = '9_p_shoes'
lv115ItemId['자연의 폭주 하의'] = '9_p_pants'
lv115ItemId['자연의 폭주 어깨'] = '9_p_neck'
lv115ItemId['자연의 폭주 상의'] = '9_p_coat'
lv115ItemId['자연의 폭주 벨트'] = '9_p_belt'
lv115ItemId['칠흑의 정화 목걸이'] = '9_neck'
lv115ItemId['확고한 행운의 보석'] = '9_ms'
lv115ItemId['가득찬 마력의 영역 신발'] = '9_l_shoes'
lv115ItemId['가득찬 마력의 영역 하의'] = '9_l_pants'
lv115ItemId['가득찬 마력의 영역 어깨'] = '9_l_neck'
lv115ItemId['가득찬 마력의 영역 상의'] = '9_l_coat'
lv115ItemId['가득찬 마력의 영역 벨트'] = '9_l_belt'
lv115ItemId['확고한 행운의 귀걸이'] = '9_ear'
lv115ItemId['노블 페어리 신발'] = '9_c_shoes'
lv115ItemId['노블 페어리 하의'] = '9_c_pants'
lv115ItemId['노블 페어리 어깨'] = '9_c_neck'
lv115ItemId['노블 페어리 상의'] = '9_c_coat'
lv115ItemId['노블 페어리 벨트'] = '9_c_belt'
lv115ItemId['칠흑의 정화 팔찌'] = '9_brac'
lv115ItemId['로드 오브 호러'] = '8_wand'
lv115ItemId['샤이닝 인페르노'] = '8_twins'
lv115ItemId['폭군의 본의'] = '8_totem'
lv115ItemId['파운더 오브 마나'] = '8_tonfa'
lv115ItemId['굳건한 행운의 마스크'] = '8_sup'
lv115ItemId['해방된 지식'] = '8_staff'
lv115ItemId['무형검 - 생사경'] = '8_ss'
lv115ItemId['앱솔루트 제로'] = '8_spear'
lv115ItemId['소울 프레데터'] = '8_scythe'
lv115ItemId['시크릿 콘서트'] = '8_sbow'
lv115ItemId['나스카 : 영혼의 심판'] = '8_sbla'
lv115ItemId['폴링 스타 로저리'] = '8_rosa'
lv115ItemId['양치기의 마지막 진실'] = '8_rod'
lv115ItemId['타버린 정화 반지'] = '8_ring'
lv115ItemId['노블레스 오브 레인저'] = '8_revol'
lv115ItemId['하쿠나마타타 : 원더풀'] = '8_pole'
lv115ItemId['전장의 투신'] = '8_pike'
lv115ItemId['자연의 격노 신발'] = '8_p_shoes'
lv115ItemId['자연의 격노 하의'] = '8_p_pants'
lv115ItemId['자연의 격노 어깨'] = '8_p_neck'
lv115ItemId['자연의 격노 상의'] = '8_p_coat'
lv115ItemId['자연의 격노 벨트'] = '8_p_belt'
lv115ItemId['타버린 정화 목걸이'] = '8_neck'
lv115ItemId['리턴드 스나이퍼 오브 블랙 로즈'] = '8_musk'
lv115ItemId['Brutal-Saw'] = '8_mswd'
lv115ItemId['굳건한 행운의 보석'] = '8_ms'
lv115ItemId['미스트 파이오니어'] = '8_lbow'
lv115ItemId['만월 : 월광야천도'] = '8_lbla'
lv115ItemId['은은한 마력의 영역 신발'] = '8_l_shoes'
lv115ItemId['은은한 마력의 영역 하의'] = '8_l_pants'
lv115ItemId['은은한 마력의 영역 어깨'] = '8_l_neck'
lv115ItemId['은은한 마력의 영역 상의'] = '8_l_coat'
lv115ItemId['은은한 마력의 영역 벨트'] = '8_l_belt'
lv115ItemId['넨 아스트론'] = '8_knu'
lv115ItemId['역작 금인필살도'] = '8_katana'
lv115ItemId['무영흑단살'] = '8_jav'
lv115ItemId['우르반의 걸작'] = '8_hcan'
lv115ItemId['眞 : 흑룡언월도'] = '8_hb'
lv115ItemId['특형 - 호위무신의 운검'] = '8_gs'
lv115ItemId['미완성 코스믹 건틀릿'] = '8_gau'
lv115ItemId['인요의 황혼'] = '8_ebow'
lv115ItemId['굳건한 행운의 귀걸이'] = '8_ear'
lv115ItemId['여제의 영롱한 은장도'] = '8_dagger'
lv115ItemId['영원한 올리브 나무 십자가'] = '8_cross'
lv115ItemId['코어 오리진'] = '8_core'
lv115ItemId['양얼의 나뭇가지 : 초'] = '8_club'
lv115ItemId['리리스, 디 이블'] = '8_claw'
lv115ItemId['육도의 수레바퀴'] = '8_chakra'
lv115ItemId['패밀리 팔케'] = '8_cbow'
lv115ItemId['엘더 페어리 신발'] = '8_c_shoes'
lv115ItemId['엘더 페어리 하의'] = '8_c_pants'
lv115ItemId['엘더 페어리 어깨'] = '8_c_neck'
lv115ItemId['엘더 페어리 상의'] = '8_c_coat'
lv115ItemId['엘더 페어리 벨트'] = '8_c_belt'
lv115ItemId['성장군 : 유성계'] = '8_bs'
lv115ItemId['칠흑의 저주'] = '8_bro'
lv115ItemId['타버린 정화 팔찌'] = '8_brac'
lv115ItemId['얼어붙은 불꽃의 살'] = '8_bowgun'
lv115ItemId['엠프레스 벳즈'] = '8_bg'
lv115ItemId['멸룡검 발뭉'] = '8_beams'
lv115ItemId['멸망의 근원'] = '8_axe'
lv115ItemId['에볼루션 오토매틱 건'] = '8_auto'
lv115ItemId['로드 오브 피어'] = '7_wand'
lv115ItemId['블레이징 인페르노'] = '7_twins'
lv115ItemId['폭군의 자비'] = '7_totem'
lv115ItemId['마나 액티베이션'] = '7_tonfa'
lv115ItemId['강인한 행운의 마스크'] = '7_sup'
lv115ItemId['금지된 지식'] = '7_staff'
lv115ItemId['무형검 - 절정'] = '7_ss'
lv115ItemId['프로즌 익스터미네이터'] = '7_spear'
lv115ItemId['소울 헌터'] = '7_scythe'
lv115ItemId['시크릿 듀엣'] = '7_sbow'
lv115ItemId['나스카 : 영혼의 비명'] = '7_sbla'
lv115ItemId['슈팅 스타 로저리'] = '7_rosa'
lv115ItemId['양치기의 두번째 거짓말'] = '7_rod'
lv115ItemId['그을린 정화 반지'] = '7_ring'
lv115ItemId['레인저의 숙명'] = '7_revol'
lv115ItemId['카르페디엠 : 모어 해피~'] = '7_pole'
lv115ItemId['전장의 패왕'] = '7_pike'
lv115ItemId['자연의 경고 신발'] = '7_p_shoes'
lv115ItemId['자연의 경고 하의'] = '7_p_pants'
lv115ItemId['자연의 경고 어깨'] = '7_p_neck'
lv115ItemId['자연의 경고 상의'] = '7_p_coat'
lv115ItemId['자연의 경고 벨트'] = '7_p_belt'
lv115ItemId['그을린 정화 목걸이'] = '7_neck'
lv115ItemId['리턴드 스나이퍼 오브 플레임'] = '7_musk'
lv115ItemId['Beast-Saw'] = '7_mswd'
lv115ItemId['강인한 행운의 보석'] = '7_ms'
lv115ItemId['미스트 익스플로러'] = '7_lbow'
lv115ItemId['반월야천도'] = '7_lbla'
lv115ItemId['미약한 마력의 영역 신발'] = '7_l_shoes'
lv115ItemId['미약한 마력의 영역 하의'] = '7_l_pants'
lv115ItemId['미약한 마력의 영역 어깨'] = '7_l_neck'
lv115ItemId['미약한 마력의 영역 상의'] = '7_l_coat'
lv115ItemId['미약한 마력의 영역 벨트'] = '7_l_belt'
lv115ItemId['넨 비아렉티아'] = '7_knu'
lv115ItemId['명작 금인예도'] = '7_katana'
lv115ItemId['무영흑주살'] = '7_jav'
lv115ItemId['초기형 우르반 거포'] = '7_hcan'
lv115ItemId['改 : 흑룡언월도'] = '7_hb'
lv115ItemId['제2형 - 수식, 방어의 운검'] = '7_gs'
lv115ItemId['미완성 셀레스티얼 건틀릿'] = '7_gau'
lv115ItemId['인요의 여명'] = '7_ebow'
lv115ItemId['강인한 행운의 귀걸이'] = '7_ear'
lv115ItemId['여제의 찬란한 은장도'] = '7_dagger'
lv115ItemId['구원의 올리브 나무 십자가'] = '7_cross'
lv115ItemId['오버클럭 코어'] = '7_core'
lv115ItemId['양얼의 나뭇가지 : 극'] = '7_club'
lv115ItemId['리리스의 한'] = '7_claw'
lv115ItemId['선도의 수레바퀴'] = '7_chakra'
lv115ItemId['프렌즈 팔케'] = '7_cbow'
lv115ItemId['리틀 페어리 신발'] = '7_c_shoes'
lv115ItemId['리틀 페어리 하의'] = '7_c_pants'
lv115ItemId['리틀 페어리 어깨'] = '7_c_neck'
lv115ItemId['리틀 페어리 상의'] = '7_c_coat'
lv115ItemId['리틀 페어리 벨트'] = '7_c_belt'
lv115ItemId['성장군 : 유성우'] = '7_bs'
lv115ItemId['칠흑의 기원'] = '7_bro'
lv115ItemId['그을린 정화 팔찌'] = '7_brac'
lv115ItemId['타버린 얼음의 살'] = '7_bowgun'
lv115ItemId['카운테스 벳즈'] = '7_bg'
lv115ItemId['살룡검 발뭉'] = '7_beams'
lv115ItemId['행성분쇄자'] = '7_axe'
lv115ItemId['트랜스포메이션 오토매틱 건'] = '7_auto'
lv115ItemId['고유 - 모독자의 고혹 반지'] = '75_ring'
lv115ItemId['고유 - 무결한 죽음의 다정함 네클리스'] = '75_neck'
lv115ItemId['고유 - 모독자의 조소 팔찌'] = '75_brac'
lv115ItemId['고유 - 모독자의 매혹 반지'] = '74_ring'
lv115ItemId['고유 - 무결한 죽음의 희망 네클리스'] = '74_neck'
lv115ItemId['고유 - 모독자의 계획 팔찌'] = '74_brac'
lv115ItemId['고유 - 모독자의 유혹 반지'] = '73_ring'
lv115ItemId['고유 - 무결한 죽음의 추억 네클리스'] = '73_neck'
lv115ItemId['고유 - 모독자의 흥미 팔찌'] = '73_brac'
lv115ItemId['흑아 : 용제의 재해를 막는 비늘'] = '72_ring'
lv115ItemId['흑아 : 용제의 공포를 심는 포효'] = '72_neck'
lv115ItemId['흑아 : 용제의 피를 머금은 흉아'] = '72_brac'
lv115ItemId['용제의 재해를 막는 비늘'] = '71_ring'
lv115ItemId['용제의 공포를 심는 포효'] = '71_neck'
lv115ItemId['용제의 피를 머금은 흉아'] = '71_brac'
lv115ItemId['흑아 : 용왕의 재해를 막는 비늘'] = '70_ring'
lv115ItemId['흑아 : 용왕의 공포를 심는 포효'] = '70_neck'
lv115ItemId['흑아 : 용왕의 피를 머금은 흉아'] = '70_brac'
lv115ItemId['로드 오브 테러'] = '6_wand'
lv115ItemId['웨이빙 인페르노'] = '6_twins'
lv115ItemId['폭군의 친절'] = '6_totem'
lv115ItemId['마나 서플라이'] = '6_tonfa'
lv115ItemId['칠흑의 정화 보조장비'] = '6_sup'
lv115ItemId['봉인된 지식'] = '6_staff'
lv115ItemId['무형검 - 고수'] = '6_ss'
lv115ItemId['익스터미네이터'] = '6_spear'
lv115ItemId['소울 스토커'] = '6_scythe'
lv115ItemId['시크릿 솔로'] = '6_sbow'
lv115ItemId['나스카 : 영혼의 속삭임'] = '6_sbla'
lv115ItemId['코멧 로저리'] = '6_rosa'
lv115ItemId['양치기의 첫번째 거짓말'] = '6_rod'
lv115ItemId['흑아 : 영롱한 황금향의 꿈 - 반지'] = '6_ring'
lv115ItemId['레인저의 길'] = '6_revol'
lv115ItemId['케세라세라 : 포츈'] = '6_pole'
lv115ItemId['전장의 지배자'] = '6_pike'
lv115ItemId['충전 - 한계를 파괴하는 발'] = '6_p_shoes'
lv115ItemId['충전 - 한계를 무너뜨리는 다리'] = '6_p_pants'
lv115ItemId['충전 - 한계를 부수는 어깨'] = '6_p_neck'
lv115ItemId['충전 - 한계를 허무는 힘'] = '6_p_coat'
lv115ItemId['충전 - 한계를 분쇄하는 허리'] = '6_p_belt'
lv115ItemId['흑아 : 영롱한 황금향의 축복 - 목걸이'] = '6_neck'
lv115ItemId['리턴드 스나이퍼 오브 파이어'] = '6_musk'
lv115ItemId['Burst-Saw'] = '6_mswd'
lv115ItemId['칠흑의 정화 마법석'] = '6_ms'
lv115ItemId['미스트 트래블러'] = '6_lbow'
lv115ItemId['현월야천도'] = '6_lbla'
lv115ItemId['무리의 베테랑 신발'] = '6_l_shoes'
lv115ItemId['무리의 베테랑 하의'] = '6_l_pants'
lv115ItemId['무리의 베테랑 투구'] = '6_l_neck'
lv115ItemId['무리의 베테랑 상의'] = '6_l_coat'
lv115ItemId['무리의 베테랑 벨트'] = '6_l_belt'
lv115ItemId['넨 스텔라'] = '6_knu'
lv115ItemId['범작 금인도'] = '6_katana'
lv115ItemId['무영흑적살'] = '6_jav'
lv115ItemId['젊은 우르반의 치기'] = '6_hcan'
lv115ItemId['흑룡언월도'] = '6_hb'
lv115ItemId['제1형 - 공식, 필중의 운검'] = '6_gs'
lv115ItemId['미완성 플래닛 건틀릿'] = '6_gau'
lv115ItemId['인요의 새벽'] = '6_ebow'
lv115ItemId['칠흑의 정화 귀걸이'] = '6_ear'
lv115ItemId['여제의 화려한 은장도'] = '6_dagger'
lv115ItemId['영광의 올리브 나무 십자가'] = '6_cross'
lv115ItemId['오버클럭 하트'] = '6_core'
lv115ItemId['양얼의 나뭇가지 : 강'] = '6_club'
lv115ItemId['리리스의 슬픔'] = '6_claw'
lv115ItemId['악도의 수레바퀴'] = '6_chakra'
lv115ItemId['파트너 팔케'] = '6_cbow'
lv115ItemId['확고한 행운을 부르는 부츠'] = '6_c_shoes'
lv115ItemId['확고한 행운을 부르는 바지'] = '6_c_pants'
lv115ItemId['확고한 행운을 부르는 견장'] = '6_c_neck'
lv115ItemId['확고한 행운을 부르는 자켓'] = '6_c_coat'
lv115ItemId['확고한 행운을 부르는 벨트'] = '6_c_belt'
lv115ItemId['성장군 : 유성광'] = '6_bs'
lv115ItemId['칠흑의 기도'] = '6_bro'
lv115ItemId['흑아 : 영롱한 황금향의 축복 - 팔찌'] = '6_brac'
lv115ItemId['얼어버린 불꽃의 살'] = '6_bowgun'
lv115ItemId['더체스 벳즈'] = '6_bg'
lv115ItemId['대룡검 발뭉'] = '6_beams'
lv115ItemId['대지파괴자'] = '6_axe'
lv115ItemId['이노베이션 오토매틱 건'] = '6_auto'
lv115ItemId['용왕의 재해를 막는 비늘'] = '69_ring'
lv115ItemId['용왕의 공포를 심는 포효'] = '69_neck'
lv115ItemId['용왕의 피를 머금은 흉아'] = '69_brac'
lv115ItemId['용의 재해를 막는 비늘'] = '68_ring'
lv115ItemId['용의 공포를 심는 포효'] = '68_neck'
lv115ItemId['용의 피를 머금은 흉아'] = '68_brac'
lv115ItemId['이무기의 재해를 막는 비늘'] = '67_ring'
lv115ItemId['이무기의 공포를 심는 포효'] = '67_neck'
lv115ItemId['이무기의 피를 머금은 흉아'] = '67_brac'
lv115ItemId['흑아 : 넘치는 마력의 영역 반지'] = '66_ring'
lv115ItemId['흑아 : 넘치는 마력의 영역 목걸이'] = '66_neck'
lv115ItemId['흑아 : 넘치는 마력의 영역 팔찌'] = '66_brac'
lv115ItemId['넘치는 마력의 영역 반지'] = '65_ring'
lv115ItemId['넘치는 마력의 영역 목걸이'] = '65_neck'
lv115ItemId['넘치는 마력의 영역 팔찌'] = '65_brac'
lv115ItemId['흑아 : 가득찬 마력의 영역 반지'] = '64_ring'
lv115ItemId['흑아 : 가득찬 마력의 영역 목걸이'] = '64_neck'
lv115ItemId['흑아 : 가득찬 마력의 영역 팔찌'] = '64_brac'
lv115ItemId['가득찬 마력의 영역 반지'] = '63_ring'
lv115ItemId['가득찬 마력의 영역 목걸이'] = '63_neck'
lv115ItemId['가득찬 마력의 영역 팔찌'] = '63_brac'
lv115ItemId['은은한 마력의 영역 반지'] = '62_ring'
lv115ItemId['은은한 마력의 영역 목걸이'] = '62_neck'
lv115ItemId['은은한 마력의 영역 팔찌'] = '62_brac'
lv115ItemId['미약한 마력의 영역 반지'] = '61_ring'
lv115ItemId['미약한 마력의 영역 목걸이'] = '61_neck'
lv115ItemId['미약한 마력의 영역 팔찌'] = '61_brac'
lv115ItemId['흑아 : 무리의 길잡이 반지'] = '60_ring'
lv115ItemId['흑아 : 무리의 길잡이 목걸이'] = '60_neck'
lv115ItemId['흑아 : 무리의 길잡이 팔목 보호대'] = '60_brac'
lv115ItemId['태초의 별 - 완드'] = '5_wand'
lv115ItemId['태초의 별 - 쌍검'] = '5_twins'
lv115ItemId['태초의 별 - 토템'] = '5_totem'
lv115ItemId['태초의 별 - 통파'] = '5_tonfa'
lv115ItemId['타버린 정화 보조장비'] = '5_sup'
lv115ItemId['태초의 별 - 스태프'] = '5_staff'
lv115ItemId['태초의 별 - 소검'] = '5_ss'
lv115ItemId['태초의 별 - 창'] = '5_spear'
lv115ItemId['태초의 별 - 낫'] = '5_scythe'
lv115ItemId['태초의 별 - 선현궁'] = '5_sbow'
lv115ItemId['태초의 별 - 소태도'] = '5_sbla'
lv115ItemId['태초의 별 - 염주'] = '5_rosa'
lv115ItemId['태초의 별 - 로드'] = '5_rod'
lv115ItemId['영롱한 황금향의 꿈 - 반지'] = '5_ring'
lv115ItemId['태초의 별 - 리볼버'] = '5_revol'
lv115ItemId['태초의 별 - 봉'] = '5_pole'
lv115ItemId['태초의 별 - 장창'] = '5_pike'
lv115ItemId['절전 - 한계를 파괴하는 발'] = '5_p_shoes'
lv115ItemId['절전 - 한계를 무너뜨리는 다리'] = '5_p_pants'
lv115ItemId['절전 - 한계를 부수는 어깨'] = '5_p_neck'
lv115ItemId['절전 - 한계를 허무는 힘'] = '5_p_coat'
lv115ItemId['절전 - 한계를 분쇄하는 허리'] = '5_p_belt'
lv115ItemId['영롱한 황금향의 축복 - 목걸이'] = '5_neck'
lv115ItemId['태초의 별 - 머스켓'] = '5_musk'
lv115ItemId['태초의 별 - 중검'] = '5_mswd'
lv115ItemId['타버린 정화 마법석'] = '5_ms'
lv115ItemId['태초의 별 - 장궁'] = '5_lbow'
lv115ItemId['태초의 별 - 장도'] = '5_lbla'
lv115ItemId['무리의 유망주 신발'] = '5_l_shoes'
lv115ItemId['무리의 유망주 하의'] = '5_l_pants'
lv115ItemId['무리의 유망주 투구'] = '5_l_neck'
lv115ItemId['무리의 유망주 상의'] = '5_l_coat'
lv115ItemId['무리의 유망주 벨트'] = '5_l_belt'
lv115ItemId['태초의 별 - 너클'] = '5_knu'
lv115ItemId['태초의 별 - 도'] = '5_katana'
lv115ItemId['태초의 별 - 투창'] = '5_jav'
lv115ItemId['태초의 별 - 핸드캐넌'] = '5_hcan'
lv115ItemId['태초의 별 - 미늘창'] = '5_hb'
lv115ItemId['태초의 별 - 대검'] = '5_gs'
lv115ItemId['태초의 별 - 건틀릿'] = '5_gau'
lv115ItemId['태초의 별 - 에테리얼 보우'] = '5_ebow'
lv115ItemId['타버린 정화 귀걸이'] = '5_ear'
lv115ItemId['태초의 별 - 단검'] = '5_dagger'
lv115ItemId['태초의 별 - 십자가'] = '5_cross'
lv115ItemId['태초의 별 - 코어블레이드'] = '5_core'
lv115ItemId['태초의 별 - 둔기'] = '5_club'
lv115ItemId['태초의 별 - 클로'] = '5_claw'
lv115ItemId['태초의 별 - 차크라 웨펀'] = '5_chakra'
lv115ItemId['태초의 별 - 크로스보우'] = '5_cbow'
lv115ItemId['굳건한 행운을 부르는 부츠'] = '5_c_shoes'
lv115ItemId['굳건한 행운을 부르는 바지'] = '5_c_pants'
lv115ItemId['굳건한 행운을 부르는 견장'] = '5_c_neck'
lv115ItemId['굳건한 행운을 부르는 자켓'] = '5_c_coat'
lv115ItemId['굳건한 행운을 부르는 벨트'] = '5_c_belt'
lv115ItemId['태초의 별 - 광창'] = '5_bs'
lv115ItemId['태초의 별 - 빗자루'] = '5_bro'
lv115ItemId['영롱한 황금향의 축복 - 팔찌'] = '5_brac'
lv115ItemId['태초의 별 - 보우건'] = '5_bowgun'
lv115ItemId['태초의 별 - 권투글러브'] = '5_bg'
lv115ItemId['태초의 별 - 광검'] = '5_beams'
lv115ItemId['태초의 별 - 배틀액스'] = '5_axe'
lv115ItemId['태초의 별 - 자동권총'] = '5_auto'
lv115ItemId['무리의 길잡이 반지'] = '59_ring'
lv115ItemId['무리의 길잡이 목걸이'] = '59_neck'
lv115ItemId['무리의 길잡이 팔목 보호대'] = '59_brac'
lv115ItemId['흑아 : 무리의 베테랑 반지'] = '58_ring'
lv115ItemId['흑아 : 무리의 베테랑 목걸이'] = '58_neck'
lv115ItemId['흑아 : 무리의 베테랑 팔목 보호대'] = '58_brac'
lv115ItemId['무리의 베테랑 반지'] = '57_ring'
lv115ItemId['무리의 베테랑 목걸이'] = '57_neck'
lv115ItemId['무리의 베테랑 팔목 보호대'] = '57_brac'
lv115ItemId['무리의 유망주 반지'] = '56_ring'
lv115ItemId['무리의 유망주 목걸이'] = '56_neck'
lv115ItemId['무리의 유망주 팔목 보호대'] = '56_brac'
lv115ItemId['무리의 일원 반지'] = '55_ring'
lv115ItemId['무리의 일원 목걸이'] = '55_neck'
lv115ItemId['무리의 일원 팔목 보호대'] = '55_brac'
lv115ItemId['흑아 : 칠흑같은 그림자 속 반지 - 독'] = '54_ring'
lv115ItemId['흑아 : 칠흑같은 그림자 속 목걸이 - 혈'] = '54_neck'
lv115ItemId['흑아 : 칠흑같은 그림자 속 팔찌 - 화'] = '54_brac'
lv115ItemId['칠흑같은 그림자 속 반지 - 독'] = '53_ring'
lv115ItemId['칠흑같은 그림자 속 목걸이 - 혈'] = '53_neck'
lv115ItemId['칠흑같은 그림자 속 팔찌 - 화'] = '53_brac'
lv115ItemId['흑아 : 짙은 그림자 속 반지 - 독'] = '52_ring'
lv115ItemId['흑아 : 짙은 그림자 속 목걸이 - 혈'] = '52_neck'
lv115ItemId['흑아 : 짙은 그림자 속 팔찌 - 화'] = '52_brac'
lv115ItemId['짙은 그림자 속 반지 - 독'] = '51_ring'
lv115ItemId['짙은 그림자 속 목걸이 - 혈'] = '51_neck'
lv115ItemId['짙은 그림자 속 팔찌 - 화'] = '51_brac'
lv115ItemId['어두운 그림자 속 반지 - 독'] = '50_ring'
lv115ItemId['어두운 그림자 속 목걸이 - 혈'] = '50_neck'
lv115ItemId['어두운 그림자 속 팔찌 - 화'] = '50_brac'
lv115ItemId['서사시의 영웅담 - 완드'] = '4_wand'
lv115ItemId['서사시의 영웅담 - 쌍검'] = '4_twins'
lv115ItemId['서사시의 영웅담 - 토템'] = '4_totem'
lv115ItemId['서사시의 영웅담 - 통파'] = '4_tonfa'
lv115ItemId['그을린 정화 보조장비'] = '4_sup'
lv115ItemId['서사시의 영웅담 - 스태프'] = '4_staff'
lv115ItemId['서사시의 영웅담 - 소검'] = '4_ss'
lv115ItemId['서사시의 영웅담 - 창'] = '4_spear'
lv115ItemId['서사시의 영웅담 - 낫'] = '4_scythe'
lv115ItemId['서사시의 영웅담 - 선현궁'] = '4_sbow'
lv115ItemId['서사시의 영웅담 - 소태도'] = '4_sbla'
lv115ItemId['서사시의 영웅담 - 염주'] = '4_rosa'
lv115ItemId['서사시의 영웅담 - 로드'] = '4_rod'
lv115ItemId['흑아 : 찬란한 황금향의 꿈 - 반지'] = '4_ring'
lv115ItemId['서사시의 영웅담 - 리볼버'] = '4_revol'
lv115ItemId['서사시의 영웅담 - 봉'] = '4_pole'
lv115ItemId['서사시의 영웅담 - 장창'] = '4_pike'
lv115ItemId['방전 - 한계를 파괴하는 발'] = '4_p_shoes'
lv115ItemId['방전 - 한계를 무너뜨리는 다리'] = '4_p_pants'
lv115ItemId['방전 - 한계를 부수는 어깨'] = '4_p_neck'
lv115ItemId['방전 - 한계를 허무는 힘'] = '4_p_coat'
lv115ItemId['방전 - 한계를 분쇄하는 허리'] = '4_p_belt'
lv115ItemId['흑아 : 찬란한 황금향의 축복 - 목걸이'] = '4_neck'
lv115ItemId['서사시의 영웅담 - 머스켓'] = '4_musk'
lv115ItemId['서사시의 영웅담 - 중검'] = '4_mswd'
lv115ItemId['그을린 정화 마법석'] = '4_ms'
lv115ItemId['서사시의 영웅담 - 장궁'] = '4_lbow'
lv115ItemId['서사시의 영웅담 - 장도'] = '4_lbla'
lv115ItemId['무리의 일원 신발'] = '4_l_shoes'
lv115ItemId['무리의 일원 하의'] = '4_l_pants'
lv115ItemId['무리의 일원 투구'] = '4_l_neck'
lv115ItemId['무리의 일원 상의'] = '4_l_coat'
lv115ItemId['무리의 일원 벨트'] = '4_l_belt'
lv115ItemId['서사시의 영웅담 - 너클'] = '4_knu'
lv115ItemId['서사시의 영웅담 - 도'] = '4_katana'
lv115ItemId['서사시의 영웅담 - 투창'] = '4_jav'
lv115ItemId['서사시의 영웅담 - 핸드캐넌'] = '4_hcan'
lv115ItemId['서사시의 영웅담 - 미늘창'] = '4_hb'
lv115ItemId['서사시의 영웅담 - 대검'] = '4_gs'
lv115ItemId['서사시의 영웅담 - 건틀릿'] = '4_gau'
lv115ItemId['서사시의 영웅담 - 에테리얼 보우'] = '4_ebow'
lv115ItemId['그을린 정화 귀걸이'] = '4_ear'
lv115ItemId['서사시의 영웅담 - 단검'] = '4_dagger'
lv115ItemId['서사시의 영웅담 - 십자가'] = '4_cross'
lv115ItemId['서사시의 영웅담 - 코어블레이드'] = '4_core'
lv115ItemId['서사시의 영웅담 - 둔기'] = '4_club'
lv115ItemId['서사시의 영웅담 - 클로'] = '4_claw'
lv115ItemId['서사시의 영웅담 - 차크라 웨펀'] = '4_chakra'
lv115ItemId['서사시의 영웅담 - 크로스보우'] = '4_cbow'
lv115ItemId['강인한 행운을 부르는 부츠'] = '4_c_shoes'
lv115ItemId['강인한 행운을 부르는 바지'] = '4_c_pants'
lv115ItemId['강인한 행운을 부르는 견장'] = '4_c_neck'
lv115ItemId['강인한 행운을 부르는 자켓'] = '4_c_coat'
lv115ItemId['강인한 행운을 부르는 벨트'] = '4_c_belt'
lv115ItemId['서사시의 영웅담 - 광창'] = '4_bs'
lv115ItemId['서사시의 영웅담 - 빗자루'] = '4_bro'
lv115ItemId['흑아 : 찬란한 황금향의 축복 - 팔찌'] = '4_brac'
lv115ItemId['서사시의 영웅담 - 보우건'] = '4_bowgun'
lv115ItemId['서사시의 영웅담 - 권투글러브'] = '4_bg'
lv115ItemId['서사시의 영웅담 - 광검'] = '4_beams'
lv115ItemId['서사시의 영웅담 - 배틀액스'] = '4_axe'
lv115ItemId['서사시의 영웅담 - 자동권총'] = '4_auto'
lv115ItemId['옅은 그림자 속 반지 - 독'] = '49_ring'
lv115ItemId['옅은 그림자 속 목걸이 - 혈'] = '49_neck'
lv115ItemId['옅은 그림자 속 팔찌 - 화'] = '49_brac'
lv115ItemId['흑아 : 대 여우의 매혹 반지'] = '48_ring'
lv115ItemId['흑아 : 대 여우의 영혼 목걸이'] = '48_neck'
lv115ItemId['흑아 : 대 여우의 지혜 팔찌'] = '48_brac'
lv115ItemId['대 여우의 매혹 반지'] = '47_ring'
lv115ItemId['대 여우의 영혼 목걸이'] = '47_neck'
lv115ItemId['대 여우의 지혜 팔찌'] = '47_brac'
lv115ItemId['흑아 : 고위 여우의 매혹 반지'] = '46_ring'
lv115ItemId['흑아 : 고위 여우의 영혼 목걸이'] = '46_neck'
lv115ItemId['흑아 : 고위 여우의 지혜 팔찌'] = '46_brac'
lv115ItemId['고위 여우의 매혹 반지'] = '45_ring'
lv115ItemId['고위 여우의 영혼 목걸이'] = '45_neck'
lv115ItemId['고위 여우의 지혜 팔찌'] = '45_brac'
lv115ItemId['수습 여우의 매혹 반지'] = '44_ring'
lv115ItemId['수습 여우의 영혼 목걸이'] = '44_neck'
lv115ItemId['수습 여우의 지혜 팔찌'] = '44_brac'
lv115ItemId['견습 여우의 매혹 반지'] = '43_ring'
lv115ItemId['견습 여우의 영혼 목걸이'] = '43_neck'
lv115ItemId['견습 여우의 지혜 팔찌'] = '43_brac'
lv115ItemId['흑아 : 발할라의 여신 발키리 축복 반지'] = '42_ring'
lv115ItemId['흑아 : 발할라의 여신 발키리 천상 목걸이'] = '42_neck'
lv115ItemId['흑아 : 발할라의 여신 발키리 수호 팔찌'] = '42_brac'
lv115ItemId['발할라의 여신 발키리 축복 반지'] = '41_ring'
lv115ItemId['발할라의 여신 발키리 천상 목걸이'] = '41_neck'
lv115ItemId['발할라의 여신 발키리 수호 팔찌'] = '41_brac'
lv115ItemId['흑아 : 불멸의 발키리 축복 반지'] = '40_ring'
lv115ItemId['흑아 : 불멸의 발키리 천상 목걸이'] = '40_neck'
lv115ItemId['흑아 : 불멸의 발키리 수호 팔찌'] = '40_brac'
lv115ItemId['전설 속의 전승 - 완드'] = '3_wand'
lv115ItemId['전설 속의 전승 - 쌍검'] = '3_twins'
lv115ItemId['전설 속의 전승 - 토템'] = '3_totem'
lv115ItemId['전설 속의 전승 - 통파'] = '3_tonfa'
lv115ItemId['찬란한 황금향의 집착 - 보조장비'] = '3_sup'
lv115ItemId['전설 속의 전승 - 스태프'] = '3_staff'
lv115ItemId['전설 속의 전승 - 소검'] = '3_ss'
lv115ItemId['전설 속의 전승 - 창'] = '3_spear'
lv115ItemId['전설 속의 전승 - 낫'] = '3_scythe'
lv115ItemId['전설 속의 전승 - 선현궁'] = '3_sbow'
lv115ItemId['전설 속의 전승 - 소태도'] = '3_sbla'
lv115ItemId['전설 속의 전승 - 염주'] = '3_rosa'
lv115ItemId['전설 속의 전승 - 로드'] = '3_rod'
lv115ItemId['찬란한 황금향의 꿈 - 반지'] = '3_ring'
lv115ItemId['전설 속의 전승 - 리볼버'] = '3_revol'
lv115ItemId['전설 속의 전승 - 봉'] = '3_pole'
lv115ItemId['전설 속의 전승 - 장창'] = '3_pike'
lv115ItemId['찬란한 황금향의 플레이트 부츠'] = '3_p_shoes'
lv115ItemId['찬란한 황금향의 플레이트 레깅스'] = '3_p_pants'
lv115ItemId['찬란한 황금향의 플레이트 숄더'] = '3_p_neck'
lv115ItemId['찬란한 황금향의 플레이트 아머'] = '3_p_coat'
lv115ItemId['찬란한 황금향의 플레이트 코일'] = '3_p_belt'
lv115ItemId['찬란한 황금향의 축복 - 목걸이'] = '3_neck'
lv115ItemId['전설 속의 전승 - 머스켓'] = '3_musk'
lv115ItemId['전설 속의 전승 - 중검'] = '3_mswd'
lv115ItemId['찬란한 황금향의 저주 - 마법석'] = '3_ms'
lv115ItemId['전설 속의 전승 - 장궁'] = '3_lbow'
lv115ItemId['전설 속의 전승 - 장도'] = '3_lbla'
lv115ItemId['고위 여우의 은빛 신발'] = '3_la_shoes'
lv115ItemId['고위 여우의 그림자 하의'] = '3_la_pants'
lv115ItemId['고위 여우의 보호 어깨'] = '3_la_neck'
lv115ItemId['고위 여우의 상의'] = '3_la_coat'
lv115ItemId['고위 여우의 비밀 벨트'] = '3_la_belt'
lv115ItemId['짙은 그림자의 걸음'] = '3_l_shoes'
lv115ItemId['짙은 그림자를 녹여내는 하의'] = '3_l_pants'
lv115ItemId['짙은 그림자가 드리운 얼굴'] = '3_l_neck'
lv115ItemId['짙은 그림자를 입은 죽음 상의'] = '3_l_coat'
lv115ItemId['짙은 그림자를 자아내는 벨트'] = '3_l_belt'
lv115ItemId['전설 속의 전승 - 너클'] = '3_knu'
lv115ItemId['전설 속의 전승 - 도'] = '3_katana'
lv115ItemId['전설 속의 전승 - 투창'] = '3_jav'
lv115ItemId['전설 속의 전승 - 핸드캐넌'] = '3_hcan'
lv115ItemId['전설 속의 전승 - 미늘창'] = '3_hb'
lv115ItemId['용왕의 하늘을 찢는 발톱'] = '3_h_shoes'
lv115ItemId['용왕의 벼락 치는 각반'] = '3_h_pants'
lv115ItemId['용왕의 태풍을 부르는 어깨'] = '3_h_neck'
lv115ItemId['용왕의 불을 뿜는 갑주'] = '3_h_coat'
lv115ItemId['용왕의 대지를 가르는 허리'] = '3_h_belt'
lv115ItemId['전설 속의 전승 - 대검'] = '3_gs'
lv115ItemId['전설 속의 전승 - 건틀릿'] = '3_gau'
lv115ItemId['전설 속의 전승 - 에테리얼 보우'] = '3_ebow'
lv115ItemId['찬란한 황금향의 이면 - 귀걸이'] = '3_ear'
lv115ItemId['전설 속의 전승 - 단검'] = '3_dagger'
lv115ItemId['전설 속의 전승 - 십자가'] = '3_cross'
lv115ItemId['전설 속의 전승 - 코어블레이드'] = '3_core'
lv115ItemId['전설 속의 전승 - 둔기'] = '3_club'
lv115ItemId['전설 속의 전승 - 클로'] = '3_claw'
lv115ItemId['전설 속의 전승 - 차크라 웨펀'] = '3_chakra'
lv115ItemId['전설 속의 전승 - 크로스보우'] = '3_cbow'
lv115ItemId['칠흑의 정화 신발'] = '3_c_shoes'
lv115ItemId['칠흑의 정화 하의'] = '3_c_pants'
lv115ItemId['칠흑의 정화 어깨'] = '3_c_neck'
lv115ItemId['칠흑의 정화 상의'] = '3_c_coat'
lv115ItemId['칠흑의 정화 벨트'] = '3_c_belt'
lv115ItemId['전설 속의 전승 - 광창'] = '3_bs'
lv115ItemId['전설 속의 전승 - 빗자루'] = '3_bro'
lv115ItemId['찬란한 황금향의 영광 - 팔찌'] = '3_brac'
lv115ItemId['전설 속의 전승 - 보우건'] = '3_bowgun'
lv115ItemId['전설 속의 전승 - 권투글러브'] = '3_bg'
lv115ItemId['전설 속의 전승 - 광검'] = '3_beams'
lv115ItemId['전설 속의 전승 - 배틀액스'] = '3_axe'
lv115ItemId['전설 속의 전승 - 자동권총'] = '3_auto'
lv115ItemId['고유 - 잠식된 갑판장의 미스트 기어'] = '39_sup'
lv115ItemId['불멸의 발키리 축복 반지'] = '39_ring'
lv115ItemId['불멸의 발키리 천상 목걸이'] = '39_neck'
lv115ItemId['고유 - 뒤섞인 자아의 포효'] = '39_ms'
lv115ItemId['고유 - 적막이 울려퍼진 미사'] = '39_ear'
lv115ItemId['불멸의 발키리 수호 팔찌'] = '39_brac'
lv115ItemId['고유 - 침식된 갑판장의 미스트 기어'] = '38_sup'
lv115ItemId['역전의 발키리 축복 반지'] = '38_ring'
lv115ItemId['역전의 발키리 천상 목걸이'] = '38_neck'
lv115ItemId['고유 - 가라앉은 자아의 포효'] = '38_ms'
lv115ItemId['고유 - 통곡이 울려퍼진 미사'] = '38_ear'
lv115ItemId['역전의 발키리 수호 팔찌'] = '38_brac'
lv115ItemId['고유 - 혼탁한 갑판장의 미스트 기어'] = '37_sup'
lv115ItemId['선봉의 발키리 축복 반지'] = '37_ring'
lv115ItemId['선봉의 발키리 천상 목걸이'] = '37_neck'
lv115ItemId['고유 - 드러난 자아의 포효'] = '37_ms'
lv115ItemId['고유 - 단말마가 울려퍼진 미사'] = '37_ear'
lv115ItemId['선봉의 발키리 수호 팔찌'] = '37_brac'
lv115ItemId['용왕의 숨겨둔 역린'] = '36_sup'
lv115ItemId['흑아 : 자연의 천재지변 반지'] = '36_ring'
lv115ItemId['흑아 : 자연의 천재지변 목걸이'] = '36_neck'
lv115ItemId['용왕의 영롱한 여의주'] = '36_ms'
lv115ItemId['용왕의 솟구친 뿔'] = '36_ear'
lv115ItemId['흑아 : 자연의 천재지변 팔찌'] = '36_brac'
lv115ItemId['용의 숨겨둔 역린'] = '35_sup'
lv115ItemId['자연 - 천재지변 반지'] = '35_ring'
lv115ItemId['자연 - 천재지변 목걸이'] = '35_neck'
lv115ItemId['용의 영롱한 여의주'] = '35_ms'
lv115ItemId['용의 솟구친 뿔'] = '35_ear'
lv115ItemId['자연 - 천재지변 팔찌'] = '35_brac'
lv115ItemId['이무기의 숨겨둔 역린'] = '34_sup'
lv115ItemId['흑아 : 자연의 폭주 반지'] = '34_ring'
lv115ItemId['흑아 : 자연의 폭주 목걸이'] = '34_neck'
lv115ItemId['이무기의 영롱한 여의주'] = '34_ms'
lv115ItemId['이무기의 솟구친 뿔'] = '34_ear'
lv115ItemId['흑아 : 자연의 폭주 팔찌'] = '34_brac'
lv115ItemId['가득찬 마력의 영역 보조장비'] = '33_sup'
lv115ItemId['자연의 폭주 반지'] = '33_ring'
lv115ItemId['자연의 폭주 목걸이'] = '33_neck'
lv115ItemId['가득찬 마력의 영역 마법석'] = '33_ms'
lv115ItemId['가득찬 마력의 영역 귀걸이'] = '33_ear'
lv115ItemId['자연의 폭주 팔찌'] = '33_brac'
lv115ItemId['은은한 마력의 영역 보조장비'] = '32_sup'
lv115ItemId['자연의 격노 반지'] = '32_ring'
lv115ItemId['자연의 격노 목걸이'] = '32_neck'
lv115ItemId['은은한 마력의 영역 마법석'] = '32_ms'
lv115ItemId['은은한 마력의 영역 귀걸이'] = '32_ear'
lv115ItemId['자연의 격노 팔찌'] = '32_brac'
lv115ItemId['미약한 마력의 영역 보조장비'] = '31_sup'
lv115ItemId['자연의 경고 반지'] = '31_ring'
lv115ItemId['자연의 경고 목걸이'] = '31_neck'
lv115ItemId['미약한 마력의 영역 마법석'] = '31_ms'
lv115ItemId['미약한 마력의 영역 귀걸이'] = '31_ear'
lv115ItemId['자연의 경고 팔찌'] = '31_brac'
lv115ItemId['무리의 베테랑 손도끼'] = '30_sup'
lv115ItemId['흑아 : 로열 페어리 반지'] = '30_ring'
lv115ItemId['흑아 : 로열 페어리 목걸이'] = '30_neck'
lv115ItemId['무리의 베테랑 마법석'] = '30_ms'
lv115ItemId['무리의 베테랑 귀걸이'] = '30_ear'
lv115ItemId['흑아 : 로열 페어리 팔찌'] = '30_brac'
lv115ItemId['유일무이한 완드'] = '2_wand'
lv115ItemId['유일무이한 쌍검'] = '2_twins'
lv115ItemId['유일무이한 토템'] = '2_totem'
lv115ItemId['유일무이한 통파'] = '2_tonfa'
lv115ItemId['화려한 황금향의 집착 - 보조장비'] = '2_sup'
lv115ItemId['유일무이한 스태프'] = '2_staff'
lv115ItemId['유일무이한 소검'] = '2_ss'
lv115ItemId['유일무이한 창'] = '2_spear'
lv115ItemId['유일무이한 낫'] = '2_scythe'
lv115ItemId['유일무이한 선현궁'] = '2_sbow'
lv115ItemId['유일무이한 소태도'] = '2_sbla'
lv115ItemId['유일무이한 염주'] = '2_rosa'
lv115ItemId['유일무이한 로드'] = '2_rod'
lv115ItemId['화려한 황금향의 꿈 - 반지'] = '2_ring'
lv115ItemId['유일무이한 리볼버'] = '2_revol'
lv115ItemId['유일무이한 봉'] = '2_pole'
lv115ItemId['유일무이한 장창'] = '2_pike'
lv115ItemId['화려한 황금향의 플레이트 부츠'] = '2_p_shoes'
lv115ItemId['화려한 황금향의 플레이트 레깅스'] = '2_p_pants'
lv115ItemId['화려한 황금향의 플레이트 숄더'] = '2_p_neck'
lv115ItemId['화려한 황금향의 플레이트 아머'] = '2_p_coat'
lv115ItemId['화려한 황금향의 플레이트 코일'] = '2_p_belt'
lv115ItemId['화려한 황금향의 축복 - 목걸이'] = '2_neck'
lv115ItemId['유일무이한 머스켓'] = '2_musk'
lv115ItemId['유일무이한 중검'] = '2_mswd'
lv115ItemId['화려한 황금향의 저주 - 마법석'] = '2_ms'
lv115ItemId['유일무이한 장궁'] = '2_lbow'
lv115ItemId['유일무이한 장도'] = '2_lbla'
lv115ItemId['수습 여우의 은빛 신발'] = '2_la_shoes'
lv115ItemId['수습 여우의 그림자 하의'] = '2_la_pants'
lv115ItemId['수습 여우의 보호 어깨'] = '2_la_neck'
lv115ItemId['수습 여우의 상의'] = '2_la_coat'
lv115ItemId['수습 여우의 비밀 벨트'] = '2_la_belt'
lv115ItemId['어두운 그림자의 걸음'] = '2_l_shoes'
lv115ItemId['어두운 그림자를 녹여내는 하의'] = '2_l_pants'
lv115ItemId['어두운 그림자가 드리운 얼굴'] = '2_l_neck'
lv115ItemId['어두운 그림자를 입은 죽음 상의'] = '2_l_coat'
lv115ItemId['어두운 그림자를 자아내는 벨트'] = '2_l_belt'
lv115ItemId['유일무이한 너클'] = '2_knu'
lv115ItemId['유일무이한 도'] = '2_katana'
lv115ItemId['유일무이한 투창'] = '2_jav'
lv115ItemId['유일무이한 핸드캐넌'] = '2_hcan'
lv115ItemId['유일무이한 미늘창'] = '2_hb'
lv115ItemId['용의 하늘을 찢는 발톱'] = '2_h_shoes'
lv115ItemId['용의 벼락 치는 각반'] = '2_h_pants'
lv115ItemId['용의 태풍을 부르는 어깨'] = '2_h_neck'
lv115ItemId['용의 불을 뿜는 갑주'] = '2_h_coat'
lv115ItemId['용의 대지를 가르는 허리'] = '2_h_belt'
lv115ItemId['유일무이한 대검'] = '2_gs'
lv115ItemId['유일무이한 건틀릿'] = '2_gau'
lv115ItemId['유일무이한 에테리얼 보우'] = '2_ebow'
lv115ItemId['화려한 황금향의 이면 - 귀걸이'] = '2_ear'
lv115ItemId['유일무이한 단검'] = '2_dagger'
lv115ItemId['유일무이한 십자가'] = '2_cross'
lv115ItemId['유일무이한 코어블레이드'] = '2_core'
lv115ItemId['유일무이한 둔기'] = '2_club'
lv115ItemId['유일무이한 클로'] = '2_claw'
lv115ItemId['유일무이한 차크라 웨펀'] = '2_chakra'
lv115ItemId['유일무이한 크로스보우'] = '2_cbow'
lv115ItemId['때 묻지 않은 정화 신발'] = '2_c_shoes'
lv115ItemId['때 묻지 않은 정화 하의'] = '2_c_pants'
lv115ItemId['때 묻지 않은 정화 어깨'] = '2_c_neck'
lv115ItemId['때 묻지 않은 정화 상의'] = '2_c_coat'
lv115ItemId['때 묻지 않은 정화 벨트'] = '2_c_belt'
lv115ItemId['유일무이한 광창'] = '2_bs'
lv115ItemId['유일무이한 빗자루'] = '2_bro'
lv115ItemId['화려한 황금향의 영광 - 팔찌'] = '2_brac'
lv115ItemId['유일무이한 보우건'] = '2_bowgun'
lv115ItemId['유일무이한 권투글러브'] = '2_bg'
lv115ItemId['유일무이한 광검'] = '2_beams'
lv115ItemId['유일무이한 배틀액스'] = '2_axe'
lv115ItemId['유일무이한 자동권총'] = '2_auto'
lv115ItemId['무리의 유망주 손도끼'] = '29_sup'
lv115ItemId['로열 페어리 반지'] = '29_ring'
lv115ItemId['로열 페어리 목걸이'] = '29_neck'
lv115ItemId['무리의 유망주 마법석'] = '29_ms'
lv115ItemId['무리의 유망주 귀걸이'] = '29_ear'
lv115ItemId['로열 페어리 팔찌'] = '29_brac'
lv115ItemId['무리의 일원 손도끼'] = '28_sup'
lv115ItemId['흑아 : 노블 페어리 반지'] = '28_ring'
lv115ItemId['흑아 : 노블 페어리 목걸이'] = '28_neck'
lv115ItemId['무리의 일원 마법석'] = '28_ms'
lv115ItemId['무리의 일원 귀걸이'] = '28_ear'
lv115ItemId['흑아 : 노블 페어리 팔찌'] = '28_brac'
lv115ItemId['짙은 그림자 속 보조장비 - 예'] = '27_sup'
lv115ItemId['노블 페어리 반지'] = '27_ring'
lv115ItemId['노블 페어리 목걸이'] = '27_neck'
lv115ItemId['짙은 그림자 속 마법석 - 폭'] = '27_ms'
lv115ItemId['짙은 그림자 속 귀걸이 - 전'] = '27_ear'
lv115ItemId['노블 페어리 팔찌'] = '27_brac'
lv115ItemId['어두운 그림자 속 보조장비 - 예'] = '26_sup'
lv115ItemId['엘더 페어리 반지'] = '26_ring'
lv115ItemId['엘더 페어리 목걸이'] = '26_neck'
lv115ItemId['어두운 그림자 속 마법석 - 폭'] = '26_ms'
lv115ItemId['어두운 그림자 속 귀걸이 - 전'] = '26_ear'
lv115ItemId['엘더 페어리 팔찌'] = '26_brac'
lv115ItemId['옅은 그림자 속 보조장비 - 예'] = '25_sup'
lv115ItemId['리틀 페어리 반지'] = '25_ring'
lv115ItemId['리틀 페어리 목걸이'] = '25_neck'
lv115ItemId['옅은 그림자 속 마법석 - 폭'] = '25_ms'
lv115ItemId['옅은 그림자 속 귀걸이 - 전'] = '25_ear'
lv115ItemId['리틀 페어리 팔찌'] = '25_brac'
lv115ItemId['고위 여우의 숨결 부적 보조장비'] = '24_sup'
lv115ItemId['흑아 : 과충전 - 한계를 돌파하는 균열 반지'] = '24_ring'
lv115ItemId['흑아 : 과충전 - 한계를 여는 열쇠 목걸이'] = '24_neck'
lv115ItemId['고위 여우의 구슬 마법석'] = '24_ms'
lv115ItemId['고위 여우의 속삭임 귀걸이'] = '24_ear'
lv115ItemId['흑아 : 과충전 - 한계를 가리키는 지침 팔찌'] = '24_brac'
lv115ItemId['수습 여우의 숨결 부적 보조장비'] = '23_sup'
lv115ItemId['과충전 - 한계를 돌파하는 균열 반지'] = '23_ring'
lv115ItemId['과충전 - 한계를 여는 열쇠 목걸이'] = '23_neck'
lv115ItemId['수습 여우의 구슬 마법석'] = '23_ms'
lv115ItemId['수습 여우의 속삭임 귀걸이'] = '23_ear'
lv115ItemId['과충전 - 한계를 가리키는 지침 팔찌'] = '23_brac'
lv115ItemId['견습 여우의 숨결 부적 보조장비'] = '22_sup'
lv115ItemId['흑아 : 충전 - 한계를 돌파하는 균열 반지'] = '22_ring'
lv115ItemId['흑아 : 충전 - 한계를 여는 열쇠 목걸이'] = '22_neck'
lv115ItemId['견습 여우의 구슬 마법석'] = '22_ms'
lv115ItemId['견습 여우의 속삭임 귀걸이'] = '22_ear'
lv115ItemId['흑아 : 충전 - 한계를 가리키는 지침 팔찌'] = '22_brac'
lv115ItemId['불멸의 발키리 전투 투구'] = '21_sup'
lv115ItemId['충전 - 한계를 돌파하는 균열 반지'] = '21_ring'
lv115ItemId['충전 - 한계를 여는 열쇠 목걸이'] = '21_neck'
lv115ItemId['불멸의 발키리 천상 보옥'] = '21_ms'
lv115ItemId['불멸의 발키리 귀걸이'] = '21_ear'
lv115ItemId['충전 - 한계를 가리키는 지침 팔찌'] = '21_brac'
lv115ItemId['역전의 발키리 전투 투구'] = '20_sup'
lv115ItemId['절전 - 한계를 돌파하는 균열 반지'] = '20_ring'
lv115ItemId['절전 - 한계를 여는 열쇠 목걸이'] = '20_neck'
lv115ItemId['역전의 발키리 천상 보옥'] = '20_ms'
lv115ItemId['역전의 발키리 귀걸이'] = '20_ear'
lv115ItemId['절전 - 한계를 가리키는 지침 팔찌'] = '20_brac'
lv115ItemId['백에 하나 나온다는 완드'] = '1_wand'
lv115ItemId['백에 하나 나온다는 쌍검'] = '1_twins'
lv115ItemId['백에 하나 나온다는 토템'] = '1_totem'
lv115ItemId['백에 하나 나온다는 통파'] = '1_tonfa'
lv115ItemId['빛나는 황금향의 집착 - 보조장비'] = '1_sup'
lv115ItemId['백에 하나 나온다는 스태프'] = '1_staff'
lv115ItemId['백에 하나 나온다는 소검'] = '1_ss'
lv115ItemId['백에 하나 나온다는 창'] = '1_spear'
lv115ItemId['백에 하나 나온다는 낫'] = '1_scythe'
lv115ItemId['백에 하나 나온다는 선현궁'] = '1_sbow'
lv115ItemId['백에 하나 나온다는 소태도'] = '1_sbla'
lv115ItemId['백에 하나 나온다는 염주'] = '1_rosa'
lv115ItemId['백에 하나 나온다는 로드'] = '1_rod'
lv115ItemId['빛나는 황금향의 꿈 - 반지'] = '1_ring'
lv115ItemId['백에 하나 나온다는 리볼버'] = '1_revol'
lv115ItemId['백에 하나 나온다는 봉'] = '1_pole'
lv115ItemId['백에 하나 나온다는 장창'] = '1_pike'
lv115ItemId['빛나는 황금향의 플레이트 부츠'] = '1_p_shoes'
lv115ItemId['빛나는 황금향의 플레이트 레깅스'] = '1_p_pants'
lv115ItemId['빛나는 황금향의 플레이트 숄더'] = '1_p_neck'
lv115ItemId['빛나는 황금향의 플레이트 아머'] = '1_p_coat'
lv115ItemId['빛나는 황금향의 플레이트 코일'] = '1_p_belt'
lv115ItemId['빛나는 황금향의 축복 - 목걸이'] = '1_neck'
lv115ItemId['백에 하나 나온다는 머스켓'] = '1_musk'
lv115ItemId['백에 하나 나온다는 중검'] = '1_mswd'
lv115ItemId['빛나는 황금향의 저주 - 마법석'] = '1_ms'
lv115ItemId['백에 하나 나온다는 장궁'] = '1_lbow'
lv115ItemId['백에 하나 나온다는 장도'] = '1_lbla'
lv115ItemId['견습 여우의 은빛 신발'] = '1_la_shoes'
lv115ItemId['견습 여우의 그림자 하의'] = '1_la_pants'
lv115ItemId['견습 여우의 보호 어깨'] = '1_la_neck'
lv115ItemId['견습 여우의 상의'] = '1_la_coat'
lv115ItemId['견습 여우의 비밀 벨트'] = '1_la_belt'
lv115ItemId['옅은 그림자의 걸음'] = '1_l_shoes'
lv115ItemId['옅은 그림자를 녹여내는 하의'] = '1_l_pants'
lv115ItemId['옅은 그림자가 드리운 얼굴'] = '1_l_neck'
lv115ItemId['옅은 그림자를 입은 죽음 상의'] = '1_l_coat'
lv115ItemId['옅은 그림자를 자아내는 벨트'] = '1_l_belt'
lv115ItemId['백에 하나 나온다는 너클'] = '1_knu'
lv115ItemId['백에 하나 나온다는 도'] = '1_katana'
lv115ItemId['백에 하나 나온다는 투창'] = '1_jav'
lv115ItemId['백에 하나 나온다는 핸드캐넌'] = '1_hcan'
lv115ItemId['백에 하나 나온다는 미늘창'] = '1_hb'
lv115ItemId['이무기의 하늘을 찢는 발톱'] = '1_h_shoes'
lv115ItemId['이무기의 벼락 치는 각반'] = '1_h_pants'
lv115ItemId['이무기의 태풍을 부르는 어깨'] = '1_h_neck'
lv115ItemId['이무기의 불을 뿜는 갑주'] = '1_h_coat'
lv115ItemId['이무기의 대지를 가르는 허리'] = '1_h_belt'
lv115ItemId['백에 하나 나온다는 대검'] = '1_gs'
lv115ItemId['백에 하나 나온다는 건틀릿'] = '1_gau'
lv115ItemId['백에 하나 나온다는 에테리얼 보우'] = '1_ebow'
lv115ItemId['빛나는 황금향의 이면 - 귀걸이'] = '1_ear'
lv115ItemId['백에 하나 나온다는 단검'] = '1_dagger'
lv115ItemId['백에 하나 나온다는 십자가'] = '1_cross'
lv115ItemId['백에 하나 나온다는 코어블레이드'] = '1_core'
lv115ItemId['백에 하나 나온다는 둔기'] = '1_club'
lv115ItemId['백에 하나 나온다는 클로'] = '1_claw'
lv115ItemId['백에 하나 나온다는 차크라 웨펀'] = '1_chakra'
lv115ItemId['백에 하나 나온다는 크로스보우'] = '1_cbow'
lv115ItemId['순결한 정화 신발'] = '1_c_shoes'
lv115ItemId['순결한 정화 하의'] = '1_c_pants'
lv115ItemId['순결한 정화 어깨'] = '1_c_neck'
lv115ItemId['순결한 정화 상의'] = '1_c_coat'
lv115ItemId['순결한 정화 벨트'] = '1_c_belt'
lv115ItemId['백에 하나 나온다는 광창'] = '1_bs'
lv115ItemId['백에 하나 나온다는 빗자루'] = '1_bro'
lv115ItemId['빛나는 황금향의 영광 - 팔찌'] = '1_brac'
lv115ItemId['백에 하나 나온다는 보우건'] = '1_bowgun'
lv115ItemId['백에 하나 나온다는 권투글러브'] = '1_bg'
lv115ItemId['백에 하나 나온다는 광검'] = '1_beams'
lv115ItemId['백에 하나 나온다는 배틀액스'] = '1_axe'
lv115ItemId['백에 하나 나온다는 자동권총'] = '1_auto'
lv115ItemId['선봉의 발키리 전투 투구'] = '19_sup'
lv115ItemId['방전 - 한계를 돌파하는 균열 반지'] = '19_ring'
lv115ItemId['방전 - 한계를 여는 열쇠 목걸이'] = '19_neck'
lv115ItemId['선봉의 발키리 천상 보옥'] = '19_ms'
lv115ItemId['선봉의 발키리 귀걸이'] = '19_ear'
lv115ItemId['방전 - 한계를 가리키는 지침 팔찌'] = '19_brac'
lv115ItemId['자연의 폭주 보조장비'] = '18_sup'
lv115ItemId['흑아 : 견고한 행운이 깃는 반지'] = '18_ring'
lv115ItemId['흑아 : 견고한 행운이 깃든 목걸이'] = '18_neck'
lv115ItemId['자연의 폭주 마법석'] = '18_ms'
lv115ItemId['자연의 폭주 귀걸이'] = '18_ear'
lv115ItemId['흑아 : 견고한 행운이 깃든 팔찌'] = '18_brac'
lv115ItemId['자연의 격노 보조장비'] = '17_sup'
lv115ItemId['견고한 행운이 깃는 반지'] = '17_ring'
lv115ItemId['견고한 행운이 깃든 목걸이'] = '17_neck'
lv115ItemId['자연의 격노 마법석'] = '17_ms'
lv115ItemId['자연의 격노 귀걸이'] = '17_ear'
lv115ItemId['견고한 행운이 깃든 팔찌'] = '17_brac'
lv115ItemId['자연의 경고 보조장비'] = '16_sup'
lv115ItemId['흑아 : 확고한 행운이 깃는 반지'] = '16_ring'
lv115ItemId['흑아 : 확고한 행운이 깃든 목걸이'] = '16_neck'
lv115ItemId['자연의 경고 마법석'] = '16_ms'
lv115ItemId['자연의 경고 귀걸이'] = '16_ear'
lv115ItemId['흑아 : 확고한 행운이 깃든 팔찌'] = '16_brac'
lv115ItemId['노블 페어리 보조장비'] = '15_sup'
lv115ItemId['확고한 행운이 깃는 반지'] = '15_ring'
lv115ItemId['고유 - 비통한 달 사냥꾼의 신발'] = '15_p_shoes'
lv115ItemId['고유 - 청해의 굳건한 각반'] = '15_p_pants'
lv115ItemId['고유 - 자라난 요기의 어깨'] = '15_p_neck'
lv115ItemId['고유 - 철면의 읍곡 아머'] = '15_p_coat'
lv115ItemId['고유 - 요살자의 분노 벨트'] = '15_p_belt'
lv115ItemId['확고한 행운이 깃든 목걸이'] = '15_neck'
lv115ItemId['노블 페어리 마법석'] = '15_ms'
lv115ItemId['노블 페어리 귀걸이'] = '15_ear'
lv115ItemId['확고한 행운이 깃든 팔찌'] = '15_brac'
lv115ItemId['엘더 페어리 보조장비'] = '14_sup'
lv115ItemId['굳건한 행운이 깃는 반지'] = '14_ring'
lv115ItemId['고유 - 결의의 달 사냥꾼의 신발'] = '14_p_shoes'
lv115ItemId['고유 - 청해의 강건한 각반'] = '14_p_pants'
lv115ItemId['고유 - 자라난 불길함의 어깨'] = '14_p_neck'
lv115ItemId['고유 - 철면의 가소 아머'] = '14_p_coat'
lv115ItemId['고유 - 요살자의 상실 벨트'] = '14_p_belt'
lv115ItemId['굳건한 행운이 깃든 목걸이'] = '14_neck'
lv115ItemId['엘더 페어리 마법석'] = '14_ms'
lv115ItemId['엘더 페어리 귀걸이'] = '14_ear'
lv115ItemId['굳건한 행운이 깃든 팔찌'] = '14_brac'
lv115ItemId['리틀 페어리 보조장비'] = '13_sup'
lv115ItemId['강인한 행운이 깃는 반지'] = '13_ring'
lv115ItemId['고유 - 자애로운 달 사냥꾼의 신발'] = '13_p_shoes'
lv115ItemId['고유 - 청해의 강직한 각반'] = '13_p_pants'
lv115ItemId['고유 - 자라난 불쾌감의 어깨'] = '13_p_neck'
lv115ItemId['고유 - 철면의 폭소 아머'] = '13_p_coat'
lv115ItemId['고유 - 요살자의 의지 벨트'] = '13_p_belt'
lv115ItemId['강인한 행운이 깃든 목걸이'] = '13_neck'
lv115ItemId['리틀 페어리 마법석'] = '13_ms'
lv115ItemId['리틀 페어리 귀걸이'] = '13_ear'
lv115ItemId['강인한 행운이 깃든 팔찌'] = '13_brac'
lv115ItemId['충전 - 한계를 넘나드는 가능성 보조장비'] = '12_sup'
lv115ItemId['흑아 : 혼돈의 정화 반지'] = '12_ring'
lv115ItemId['불멸의 발키리 발걸음'] = '12_p_shoes'
lv115ItemId['불멸의 발키리 드레스'] = '12_p_pants'
lv115ItemId['불멸의 발키리 어깨 보호대'] = '12_p_neck'
lv115ItemId['불멸의 발키리 신성 갑옷'] = '12_p_coat'
lv115ItemId['불멸의 발키리 수호띠'] = '12_p_belt'
lv115ItemId['흑아 : 혼돈의 정화 목걸이'] = '12_neck'
lv115ItemId['충전 - 한계를 뛰어넘은 증명 마법석'] = '12_ms'
lv115ItemId['충전 - 한계를 뒤흔드는 신호 귀걸이'] = '12_ear'
lv115ItemId['흑아 : 혼돈의 정화 팔찌'] = '12_brac'
lv115ItemId['절전 - 한계를 넘나드는 가능성 보조장비'] = '11_sup'
lv115ItemId['혼돈의 정화 반지'] = '11_ring'
lv115ItemId['역전의 발키리 발걸음'] = '11_p_shoes'
lv115ItemId['역전의 발키리 드레스'] = '11_p_pants'
lv115ItemId['역전의 발키리 어깨 보호대'] = '11_p_neck'
lv115ItemId['역전의 발키리 신성 갑옷'] = '11_p_coat'
lv115ItemId['역전의 발키리 수호띠'] = '11_p_belt'
lv115ItemId['혼돈의 정화 목걸이'] = '11_neck'
lv115ItemId['절전 - 한계를 뛰어넘은 증명 마법석'] = '11_ms'
lv115ItemId['절전 - 한계를 뒤흔드는 신호 귀걸이'] = '11_ear'
lv115ItemId['혼돈의 정화 팔찌'] = '11_brac'
lv115ItemId['방전 - 한계를 넘나드는 가능성 보조장비'] = '10_sup'
lv115ItemId['흑아 : 칠흑의 정화 반지'] = '10_ring'
lv115ItemId['선봉의 발키리 발걸음'] = '10_p_shoes'
lv115ItemId['선봉의 발키리 드레스'] = '10_p_pants'
lv115ItemId['선봉의 발키리 어깨 보호대'] = '10_p_neck'
lv115ItemId['선봉의 발키리 신성 갑옷'] = '10_p_coat'
lv115ItemId['선봉의 발키리 수호띠'] = '10_p_belt'
lv115ItemId['흑아 : 칠흑의 정화 목걸이'] = '10_neck'
lv115ItemId['방전 - 한계를 뛰어넘은 증명 마법석'] = '10_ms'
lv115ItemId['방전 - 한계를 뒤흔드는 신호 귀걸이'] = '10_ear'

export { lv115ItemId }